import { useTable, useSortBy, usePagination } from "react-table";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useReactToPrint } from "react-to-print";
import { Store, ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import imagenProfile from "../../assets/img/user/user-13.jpg";
import { AppSettings } from "./../../config/app-settings";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import Moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import React, { useState, useEffect, useRef,useContext } from "react";
import {
  getEntities,
  getFilesArchived,
  getFilesArchivedSearch,
  getPersonsAssignments,
  getPersonsCustomer,
  getUsers,
} from "../../actions/index.js";
import { useDownloadExcel } from "react-export-table-to-excel";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  classesStatus,
  CopyRight,
  instance,
  status,
  successPermissions,
} from "../../utils/index.js";
import Select from "react-select";

const Archivados = (props) => {
  const tableRef = useRef(null);
  const [timer, setTimer] = useState(null);
  const [modalDialog, setmodalDialog] = useState(false);
  const [modalAsignment, setmodalAsignment] = useState(false);
  const [PersonaAsignar, setPersonaAsignar] = useState("");
  const context = useContext(AppSettings);
  const [nuevoNombre, setNuevoNombre] = useState("");
  const [modalDialogFileCreate, setmodalDialogFileCreate] = useState(false);

  const [ComentarioAsignar, setComentarioAsignar] = useState("");
  const [ErrorReOpen, setErrorReOpen] = useState("");
  const [IdSelected, setIdSelected] = useState(null);
  const [reasons, setreasons] = useState(null);
  const [value_reason, setvalue_reason] = useState("");
  const [initialPageIndex, setinitialPageIndex] = useState(0);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Expedientes archivados table",
    sheet: "Expedientes",
  });
  const [inputs, setInputs] = useState({
    open_date: Moment().format("YYYY-MM-DD"),
    isLoading: false,
    isLoadingBen: false,
    isLoadingCausante: false,
    isLoadingFree: false,
    isLoadingBy: false,
    beneficiaries: [],
  });
  const fakeApi = (e) => {
    dispatch(getFilesArchivedSearch(e, false));
    setinitialPageIndex(0);
  };
  const toDay = Moment().format("YYYY-MM-DD");
  var handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setInputs((val) => ({ ...val, [name]: value }));
  };
  function CreateFile(e) {
    e.preventDefault();
    var bodyFormData = new FormData();
    var beneficiariosMap = inputs.beneficiaries.map((benef) => benef.value);
    bodyFormData.append("number", Number(inputs.number));
    bodyFormData.append("beneficiaries", beneficiariosMap);
    bodyFormData.append("freelance", inputs.freelance);
    bodyFormData.append("founder", inputs.founder);
    bodyFormData.append("entity", inputs.entity);
    bodyFormData.append("reason", inputs.reason);
    bodyFormData.append("open_date", inputs.open_date);
    bodyFormData.append("customer", inputs.customer);
    bodyFormData.append("created_by", 80);

    instance
      .post(`files/`, bodyFormData)
      .then((res) => {})
      .catch((e) => console.log("Error in asignament", e.response));
  }
  function reAbrirExpediente() {
    var bodyFormData = new FormData();
    bodyFormData.append("receiver", PersonaAsignar);
    bodyFormData.append("number", nuevoNombre);
    bodyFormData.append("comment", ComentarioAsignar);

    instance
      .post(`files/${IdSelected}/reopen`, bodyFormData)
      .then((res) => {
        // dispatch(getFilesArchived(null, false));
        fakeApi("");
        setmodalAsignment(false);
      })
      .catch((e) => {
        if (e.response) {
          setErrorReOpen(e.response.data);
          setTimeout(() => {
            setErrorReOpen("");
          }, 3000);
        }
      });
  }

  useEffect(() => {
    if (
      context &&
      localStorage.email &&
      localStorage.email.includes("siep.com.co")
    ) {
      context.handleSetActualizarEmail();
    }

    // context.handleSetAppContentClass("p-0");
    // return () => {
    //   context.handleSetAppContentClass(false);
    // };
  }, []);

  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const {
    filesArchived,
    users,
    Entities,
    personsCustomers,
    paginaArchivedFiles,
  } = state.counterSlice;

  useEffect(() => {
    dispatch(getFilesArchived(null, false));

    dispatch(getUsers());
    dispatch(getEntities());
    dispatch(getPersonsAssignments());
    instance
      // .get(`http://localhost:3005/persons.json`)
      .get(`files/reasons`)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        var reason_all = data.map((val) => ({
          value: val,
          label: val,
        }));

        setreasons(reason_all);
      })
      .catch((e) => console.warn("Error in saveDataLog", e.response));
  }, []);

  function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <img src={imagenProfile} width="52" alt="" className="rounded" />
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }
  const sortees = React.useMemo(
    () => [
      {
        id: "id",
        desc: false,
      },
    ],
    []
  );

  const columns = React.useMemo(
    () => successPermissions("can_view_archive_files","can_set_file_status_to_reopen") == "d-none" ? [  {
      Header: "#Expediente",
      accessor: "number",
      sortable: true,
    },
    {
      Header: "Cliente",
      accessor: "customer",
      sortable: true,
    },
    {
      Header: "Entidad",
      accessor: "entity",
      sortable: true,
    },

    {
      Header: "Estado",
      accessor: "status",
      sortable: true,
    },
    {
      Header: "Freelance",
      accessor: "freelance",
      sortable: true,
    },
    {
      Header: "Modificado",
      accessor: "updated_at",
      sortable: true,
    },] : [
      {
        Header: "#Expediente",
        accessor: "number",
        sortable: true,
      },
      {
        Header: "Cliente",
        accessor: "customer",
        sortable: true,
      },
      {
        Header: "Entidad",
        accessor: "entity",
        sortable: true,
      },

      {
        Header: "Estado",
        accessor: "status",
        sortable: true,
      },
      {
        Header: "Freelance",
        accessor: "freelance",
        sortable: true,
      },
      {
        Header: "Modificado",
        accessor: "updated_at",
        sortable: true,
      },

      {
        Header: "Acciones",
        accessor: "Acciones",
        sortable: false,
      },
    ],
    []
  );

  const data = filesArchived;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { sortBy: sortees, pageIndex: initialPageIndex },
    },
    useSortBy,
    usePagination
  );

  function pdfdownload(current) {
    const doc = new jsPDF();
    autoTable(doc, { html: current });
    doc.save("table.pdf");
  }
  const handlePrint = useReactToPrint({
    pageStyle: "@page {size:auto;margin-bottom: 20mm }",
    content: () => {
      const tableStat = tableRef.current.cloneNode(true);
      const PrintElem = document.createElement("div");
      PrintElem.setAttribute("class", "containerPrint");
      const header =
        `<div class="page-header"><h1>Expedientes archivados</div></h1>` +
        `<img src="https://siep.restrepofajardo.com/img/logo.png" alt="" class="watermark"/>` +
        `<div class="page-footer"><span>${
          localStorage.full_name
        }</span><span>${Moment().format(
          "D [de] MMMM [del] YYYY, h:mm a"
        )}</span></div>`;
      PrintElem.innerHTML = header;
      PrintElem.appendChild(tableStat);
      return PrintElem;
    },
  });

  // function borrar(e) {
  //   dispatch(removeFilesOpen(e));
  // }

  useEffect(() => {
    const counter = 100;
    let other = (pageIndex + 1) * pageSize;
    if (filesArchived.length / pageSize - 1 <= pageIndex + 1) {
      if (
        (other % counter >= counter - pageSize && paginaArchivedFiles[1]) ||
        (other % counter == 0 && pageSize == 50)
      ) {
        // if ((pageIndex + 1) % pageSize == 0 && pagina[1]) {
        dispatch(
          getFilesArchived(
            paginaArchivedFiles[1].replace(
              "https://beta.siep.restrepofajardo.com/api/v1/files/",
              ""
            ),
            false
          )
        );
      }
    }
    setinitialPageIndex(pageIndex);
  }, [pageIndex]);

  const inputChanged = (e) => {
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      fakeApi(e.target.value);
    }, 500);

    setTimer(newTimer);
  };
  return (
    <div>
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">
          <Link to="/table/data">Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/Expedientes">Expedientes archivados</Link>
        </li>
        {/* <li className="breadcrumb-item active">Data Tables</li> */}
      </ol>
      <h1 className="page-header">
        Expedientes archivados <small></small>
      </h1>
      <Panel>
        <PanelHeader>Expedientes archivados</PanelHeader>
        <PanelBody>
          <div className="invoice-company">
            <span className="float-end hidden-print">
              {/* <button
                onClick={() => setmodalDialogFileCreate(true)}
                className="btn btn-sm btn-white mb-10px me-1"
              >
                <i className="fa fa-folder-plus t-plus-1 text-gray fa-fw fa-lg"></i>
              </button> */}
              {successPermissions("can_export_archive_excel_files") ==
              "d-none" ? null : (
                <>
                  {" "}
                  <button
                    onClick={() => pdfdownload(tableRef.current)}
                    className="btn btn-sm btn-white mb-10px me-1"
                  >
                    <i className="fa fa-file-pdf t-plus-1 text-blue fa-fw fa-lg"></i>{" "}
                  </button>
                  <button
                    onClick={onDownload}
                    className="btn btn-sm btn-white mb-10px me-1"
                  >
                    <i className="fa fa-file-excel t-plus-1 text-green fa-fw fa-lg"></i>
                  </button>
                  <button
                    onClick={handlePrint}
                    className="btn btn-sm btn-white mb-10px me-1"
                  >
                    <i className="fa fa-print t-plus-1 fa-fw fa-lg"></i>
                  </button>
                </>
              )}
            </span>
            <div className="d-block d-md-flex align-items-center">
              <label className="d-none d-lg-block pe-2 mb-0">
                Expedientes por página:
              </label>
              <div className="d-none d-lg-block">
                <select
                  className="form-select"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Mostrar {pageSize}
                    </option>
                  ))}
                </select>
              </div>
              <form action="" method="POST" name="search_form">
                <div className="form-group position-relative mx-auto mx-md-3 width_seach_type w-sm-50 pe-md-4 d-flex">
                  {" "}
                  <button
                    className="btn btn-search position-absolute border-0"
                    disabled={true}
                  >
                    <i className="fa fa-search me-20px icon-search-action"></i>
                  </button>{" "}
                  <input
                    type="text"
                    id="inputTextSearch"
                    className="form-control px-5"
                    placeholder="Buscar Expediente ..."
                    onChange={inputChanged}
                  />
                </div>
              </form>
            </div>
          </div>
        </PanelBody>
        <div className="table-responsive">
          <ReactNotifications />
          {filesArchived && filesArchived.length === 0 ? (
            <div className="fa-3x d-flex justify-content-center mb-3">
              <i className="fas fa-cog fa-spin"></i>{" "}
            </div>
          ) : filesArchived && typeof filesArchived[0] === "string" ? (
            <div className="fw-bold text-center mb-3">{filesArchived[0]}</div>
          ) : (
            <>
              <table
                ref={tableRef}
                style={{ background: "var(--app-sidebar-bg)" }}
                className="table table-panel table-bordered mb-0 "
                {...getTableProps()}
              >
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <>
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="nav-Tables-head"
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            className="text-center"
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            <div
                              className="d-flex align-items-center "
                              style={{
                                minWidth: "100px",
                                justifyContent: "center",
                              }}
                            >
                              <span>{column.render("Header")}</span>
                              <span className="">
                                {column.sortable ? (
                                  column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <i className="fa fa-sort-down fa-fw fs-14px text-blue"></i>
                                    ) : (
                                      <i className="fa fa-sort-up fa-fw fs-14px text-blue"></i>
                                    )
                                  ) : (
                                    <i className="fa fa-sort fa-fw fs-14px opacity-3"></i>
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </th>
                        ))}
                      </tr>
                    </>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="colorRow-Tables">
                        {row.cells.map((cell, index) => {
                          return (
                            <td key={row.id} {...cell.getCellProps()}>
                              {cell.column.Header == "Acciones" ? (
                                <td
                                  nowrap="true "
                                  className="vertical-middle"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {successPermissions(
                                    "can_view_archive_files"
                                  ) == "d-none" ? null : (
                                    <Link
                                      to={`/ExpedienteArchivado/${row.original["id"]}`}
                                      title="Ver"
                                      className="btn btn-sm btn-green  me-1 p-auto"
                                    >
                                      <i className="fab fa-lg fa-fw fs-14px fa-sistrix text-white"></i>
                                    </Link>
                                  )}

                                  {successPermissions(
                                    "can_set_file_status_to_reopen"
                                  ) == "d-none" ? null : (
                                    <button
                                      title="Re-abrir"
                                      onClick={() =>
                                        setmodalAsignment(true) ||
                                        setIdSelected(row.original["id"])
                                      }
                                      className="btn btn-sm btn-success p-auto"
                                    >
                                      <i className="fas fa-lg fa-fw fs-14px fa-share-from-square"></i>
                                    </button>
                                  )}
                                </td>
                              ) : cell.column.Header == "Estado" ? (
                                <span
                                  className={classesStatus(
                                    row.original["status"]
                                  )}
                                >
                                  <i className="fa fa-circle fs-9px fa-fw me-5px"></i>{" "}
                                  {/* {cell.render("Cell")} */}{" "}
                                  {status(row.original["status"])}
                                </span>
                              ) : cell.column.Header == "Modificado" ? (
                                Moment(row.original["updated_at"]).format(
                                  "L[-]LT"
                                )
                              ) : cell.column.Header == "Cliente" ? (
                                row.original["customer"]?.full_name
                              ) : cell.column.Header == "Entidad" ? (
                                row.original["entity"]?.name
                              ) : cell.column.Header == "Freelance" ? (
                                row.original["freelance"]?.full_name
                              ) : (
                                cell.render("Cell")
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <PanelBody>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="me-1 d-none d-sm-block">Ir a página:</div>
                  <div className="w-50px mx-2 me-auto d-none d-sm-block">
                    <input
                      className="form-control px-2"
                      type="number"
                      value={pageIndex + 1}
                      onChange={(e) => {
                        const page = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        gotoPage(page);
                      }}
                    />
                  </div>
                  <ul className="pagination mb-0">
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                      >
                        <i className="fa fa-angle-double-left"></i>
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                      >
                        <i className="fa fa-angle-left"></i>
                      </button>
                    </li>
                    <li className="page-item d-flex align-items-center px-2">
                      <div>
                        Pág.{" "}
                        <strong>
                          {pageIndex + 1} de{" "}
                          {paginaArchivedFiles[2] / pageSize -
                            Math.round(paginaArchivedFiles[2] / pageSize) >
                          0
                            ? Math.round(paginaArchivedFiles[2] / pageSize) + 1
                            : Math.round(paginaArchivedFiles[2] / pageSize)}
                        </strong>
                      </div>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                      >
                        <i className="fa fa-angle-right"></i>
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                      >
                        <i className="fa fa-angle-double-right"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </PanelBody>
              {paginaArchivedFiles[2] ? (
                <div className="hljs-wrapper">
                  <div className="p-3">
                    {" "}
                    <span style={{ color: "#4a9be5", fontSize: "12px" }}>
                      {paginaArchivedFiles[2]}
                    </span>{" "}
                    resultados
                  </div>
                </div>
              ) : null}
            </>
          )}
        </div>{" "}
        <Modal isOpen={modalDialogFileCreate}>
          <ModalHeader
            close={
              <button
                className="btn-close"
                onClick={() => setmodalDialogFileCreate(!modalDialogFileCreate)}
              ></button>
            }
          >
            Expediente
          </ModalHeader>
          <form onSubmit={CreateFile}>
            <ModalBody className="m-auto w-100" style={{ overflowY: "auto" }}>
              <tr className="my-1 d-flex" style={{ justifyContent: "center" }}>
                <td className="d-flex column-create-inputs flex-md-row">
                  <div>
                    <td className="field">Expediente</td>{" "}
                    <input
                      required
                      name="number"
                      style={{
                        border: "1px solid hsl(0, 0%, 80%)",
                        borderRadius: "4px",
                        padding: "10px",
                      }}
                      onChange={handleChange}
                      className="widthInput w-md-200px py-2  m-1"
                      type="text"
                    ></input>
                  </div>
                  <div>
                    <td className="field">Fecha de apertura</td>
                    <input
                      name="open_date"
                      style={{
                        border: "1px solid hsl(0, 0%, 80%)",
                        borderRadius: "4px",
                        paddingTop: "0.3rem",
                        paddingBottom: "0.3rem",
                      }}
                      onChange={handleChange}
                      defaultValue={toDay}
                      className="form-control widthInput w-md-200px p-2  m-1"
                      type="date"
                    ></input>
                  </div>
                </td>
              </tr>
              <tr className="my-1 d-flex" style={{ justifyContent: "center" }}>
                <td className="d-flex column-create-inputs flex-md-row">
                  <div>
                    <td className="field">Tipo Consulta</td>{" "}
                    <CreatableSelect
                      className="widthInput w-md-200px   m-1"
                      placeholder="Selecciona"
                      isClearable
                      onChange={(newValue) => {
                        setvalue_reason(newValue);
                        setInputs((val) => ({
                          ...val,
                          ["reason"]: newValue.value,
                        }));
                      }}
                      options={reasons}
                      value={value_reason}
                    />
                  </div>
                  <div>
                    <td className="field">Entidad</td>
                    <Select
                      className="widthInput w-md-200px   m-1"
                      options={Entities.map((entitie) => ({
                        value: entitie.id,
                        label: entitie.name,
                      }))}
                      placeholder="Selecciona"
                      defaultValue={false}
                      name="entity"
                      onChange={(e) =>
                        setInputs((val) => ({
                          ...val,
                          ["entity"]: e.value,
                        }))
                      }
                    />
                  </div>
                </td>
              </tr>
              <tr className="my-1 d-flex" style={{ justifyContent: "center" }}>
                <td className="d-flex column-create-inputs flex-md-row">
                  <div>
                    <td className="field">Cliente</td>{" "}
                    <Select
                      className="widthInput w-md-200px   m-1"
                      isLoading={inputs.isLoading}
                      onInputChange={(e) => {
                        setInputs((val) => ({
                          ...val,
                          ["isLoading"]: true,
                        }));
                        setTimeout(() => {
                          setInputs((val) => ({
                            ...val,
                            ["isLoading"]: false,
                          }));
                        }, 700);
                        if (e.length > 1) {
                          dispatch(getPersonsCustomer(e, "&is_customer=true"));
                        }
                      }}
                      options={
                        personsCustomers &&
                        personsCustomers.map((personCus) => ({
                          value: personCus.id,
                          label:
                            personCus.first_name + " " + personCus.last_name,
                        }))
                      }
                      placeholder="Selecciona"
                      defaultValue={false}
                      name="customer"
                      onChange={(e) => {
                        setInputs((val) => ({
                          ...val,
                          ["customer"]: e.value,
                        }));
                      }}
                    />
                  </div>
                  <div>
                    <td className="field">Causante</td>
                    <Select
                      className="widthInput w-md-200px   m-1"
                      isLoading={inputs.isLoadingCausante}
                      onInputChange={(e) => {
                        setInputs((val) => ({
                          ...val,
                          ["isLoadingCausante"]: true,
                        }));
                        setTimeout(() => {
                          setInputs((val) => ({
                            ...val,
                            ["isLoadingCausante"]: false,
                          }));
                        }, 700);
                        if (e.length > 1) {
                          dispatch(getPersonsCustomer(e));
                        }
                      }}
                      options={
                        personsCustomers &&
                        personsCustomers.map((personCus) => ({
                          value: personCus.id,
                          label:
                            personCus.first_name + " " + personCus.last_name,
                        }))
                      }
                      placeholder="Selecciona"
                      defaultValue={false}
                      name="founder"
                      onChange={(e) => {
                        setInputs((val) => ({
                          ...val,
                          ["founder"]: e.value,
                        }));
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr className="my-1 d-flex" style={{ justifyContent: "center" }}>
                <td className="d-flex column-create-inputs flex-md-row">
                  <div>
                    <td className="field">Freelance</td>{" "}
                    <Select
                      id="clientec"
                      className="widthInput w-md-200px   m-1"
                      isLoading={inputs.isLoadingFree}
                      onInputChange={(e) => {
                        setInputs((val) => ({
                          ...val,
                          ["isLoadingFree"]: true,
                        }));
                        setTimeout(() => {
                          setInputs((val) => ({
                            ...val,
                            ["isLoadingFree"]: false,
                          }));
                        }, 700);
                        if (e.length > 1) {
                          dispatch(getPersonsCustomer(e, "&is_freelance=true"));
                        }
                      }}
                      options={
                        personsCustomers &&
                        personsCustomers.map((personCus) => ({
                          value: personCus.id,
                          label:
                            personCus.first_name + " " + personCus.last_name,
                        }))
                      }
                      placeholder="Selecciona"
                      defaultValue={false}
                      name="freelance"
                      onChange={(e) => {
                        setInputs((val) => ({
                          ...val,
                          ["freelance"]: e.value,
                        }));
                      }}
                    />
                  </div>
                  <div>
                    <td className="field">Beneficiarios</td>
                    <Select
                      className="widthInput w-md-200px   m-1"
                      options={
                        personsCustomers.length > 0 &&
                        personsCustomers.map((personCus) => ({
                          value: personCus.id,
                          label:
                            personCus.first_name + " " + personCus.last_name,
                        }))
                      }
                      placeholder="Selecciona"
                      isLoading={inputs.isLoadingBen}
                      onInputChange={(e) => {
                        setInputs((val) => ({
                          ...val,
                          ["isLoadingBen"]: true,
                        }));
                        setTimeout(() => {
                          setInputs((val) => ({
                            ...val,
                            ["isLoadingBen"]: false,
                          }));
                        }, 700);
                        if (e.length > 1) {
                          getPersonsCustomer(e);
                        }
                      }}
                      defaultValue={false}
                      isMulti
                      name="Beneficiarios"
                      onChange={(e) =>
                        setInputs((val) => ({
                          ...val,
                          ["beneficiaries"]: e,
                        }))
                      }
                    />
                  </div>
                </td>
              </tr>
              <tr className="my-1 d-flex" style={{ justifyContent: "center" }}>
                <td className="d-flex column-create-inputs flex-md-row">
                  <div>
                    <td className="field">Creado por</td>{" "}
                    <Select
                      id="clientec"
                      className="widthInput w-md-200px   m-1"
                      isLoading={inputs.isLoadingBy}
                      onInputChange={(e) => {
                        setInputs((val) => ({
                          ...val,
                          ["isLoadingBy"]: true,
                        }));
                        setTimeout(() => {
                          setInputs((val) => ({
                            ...val,
                            ["isLoadingBy"]: false,
                          }));
                        }, 700);
                        if (e.length > 1) {
                          dispatch(getPersonsCustomer(e));
                        }
                      }}
                      options={
                        personsCustomers &&
                        personsCustomers.map((personCus) => ({
                          value: personCus.id,
                          label:
                            personCus.first_name + " " + personCus.last_name,
                        }))
                      }
                      placeholder="Selecciona"
                      defaultValue={false}
                      name="created_by"
                      onChange={(e) => {
                        setInputs((val) => ({
                          ...val,
                          ["created_by"]: e.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="widthInput w-md-200px   m-1"></div>
                </td>
              </tr>
              <br />
              <br />
            </ModalBody>
            <ModalFooter>
              <button
                className="btn btn-white"
                onClick={() => setmodalDialogFileCreate(!modalDialogFileCreate)}
              >
                Cerrar
              </button>
              <button type="submit" className="btn btn-warning">
                Crear
              </button>
            </ModalFooter>
          </form>
        </Modal>
        <Modal
          isOpen={modalAsignment}
          toggle={() => setmodalAsignment(!modalAsignment)}
        >
          <ModalHeader
            toggle={() => setmodalAsignment(!modalAsignment)}
            close={
              <button
                className="btn-close"
                onClick={() => setmodalAsignment(!modalAsignment)}
              ></button>
            }
          >
            Re-abrir Expediente
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div className="d-flex mx-auto" style={{ width: "80%" }}>
                <div className=" field m-auto ">Número</div>
                <div>
                  <input
                    style={{
                      border: "1px solid hsl(0, 0%, 80%)",
                      borderRadius: "4px",
                      padding: "9px",
                    }}
                    type="text"
                    className="w-200px w-md-300px"
                    placeholder="Nuevo número de expediente"
                    onChange={(e) => setNuevoNombre(e.target.value)}
                  />
                </div>
              </div>
              <br />
              <div className="d-flex mx-auto" style={{ width: "80%" }}>
                <div className=" field m-auto ">Asignar a</div>
                <div>
                  <Select
                    className="w-200px w-md-300px m-auto"
                    options={users.map((person) => ({
                      label: person.first_name + person.last_name,
                      value: person.id,
                    }))}
                    placeholder="Selecciona"
                    defaultValue={false}
                    name="Personas"
                    onChange={(e) => setPersonaAsignar(e.value)}
                  />
                </div>
              </div>
              <br />
              <div className="d-flex  mx-auto" style={{ width: "80%" }}>
                <div className=" field m-auto ">Comentario</div>
                <div>
                  <textarea
                    onChange={(e) => setComentarioAsignar(e.target.value)}
                    placeholder="Escribe tu comentario"
                    className="w-200px w-md-300px m-auto p-2"
                    style={{
                      borderColor: "hsl(0, 0%, 80%)",
                      borderRadius: "4px",
                    }}
                  ></textarea>
                </div>
              </div>
              <span className="text-red text-center m-auto">
                {" "}
                {ErrorReOpen}
              </span>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-white"
              onClick={() => setmodalAsignment(!modalAsignment)}
            >
              Cerrar
            </button>
            <button
              onClick={() => reAbrirExpediente()}
              className="btn btn-success"
            >
              Asignar
            </button>
          </ModalFooter>
        </Modal>
        {modalDialog ? (
          <SweetAlert
            danger
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title="Are you sure?"
            onConfirm={() => setmodalDialog(!modalDialog)}
            onCancel={() => setmodalDialog(!modalDialog)}
          >
            You will not be able to recover this imaginary file!
          </SweetAlert>
        ) : null}
      </Panel>
    </div>
  );
};

export default Archivados;
