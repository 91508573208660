import React from "react";
import { Link } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import NVD3Chart from "react-nvd3";
import Chart from "react-apexcharts";
import d3 from "d3";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";
import Select from "react-select";
import Moment from "moment";
import { AppSettings } from "./../../config/app-settings.js";
import { connect } from "react-redux";
import {
  upPermissions,
  getFilesRejected,
  getFilesAssigned,
  getFilesAccepted,
  getUsers,
  getMetrics,
} from "../../actions/index.js";
import PerfectScrollbar from "react-perfect-scrollbar";
import { instance, successPermissions } from "../../utils/index.js";
class DashboardV2 extends React.Component {
  static contextType = AppSettings;
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      rechazadosFlag: [],
      aceptadosFlag: [],
      aceptados: [],
      rechazados: [],
      modalReject: false,
      modalAccept: false,
      buttonsError: "",
      comentarioAsignar: "",
      comentarioAceptar: "",
      idSelected: "",
      modalAsignment: false,
      AsignaraError: "",
      Asignara: "",
      totalCounts: 0,
      percentageAssigned: 0,
      percentageRejected: 0,
    };

    this.handleSubmitAsignar = this.handleSubmitAsignar.bind(this);
  }

  onChange = (date) => {
    this.setState((state) => ({
      date: date,
    }));
  };
  componentDidMount() {
    const {
      getFilesRejected,
      getFilesAssigned,
      getUsers,
      getMetrics,
      getFilesAccepted,
    } = this.props;

    getUsers();
    getFilesRejected();
    getFilesAssigned();
    getFilesAccepted();
    getMetrics();

    this.context.handleSetAppSidebarSearch(true);
    if (localStorage.email && localStorage.email.includes("siep.com.co")) {
      this.context.handleSetActualizarEmail();
    }
  }
  componentDidUpdate(prevProps) {
    // Actualiza el estado global si cambia la prop myGlobalState
    if (
      this.props.RejectedsDashboard !== prevProps.RejectedsDashboard ||
      this.props.AssignmentsDashboard !== prevProps.AssignmentsDashboard ||
      this.props.AcceptedDashboard !== prevProps.AcceptedDashboard
    ) {
      getFilesRejected();
      getFilesAssigned();
      getFilesAccepted();
      let countsAssign = Number(this.props.AssignmentsDashboard.count);

      let countsReject = Number(this.props.RejectedsDashboard.count);

      let totalCounts = countsReject + countsAssign;

      const countRejected = this.props.RejectedsDashboard?.count;
      const countAssigned = this.props.AssignmentsDashboard?.count;
      const percentageRejected = (100 * totalCounts) / countRejected;
      const percentageAssigned = (100 * totalCounts) / countAssigned;
      this.setState({
        totalCounts: totalCounts,
        percentageAssigned: isNaN(percentageAssigned) ? 0 : percentageAssigned,
        percentageRejected: isNaN(percentageRejected) ? 0 : percentageRejected,
      });
    }
  }

  toggleModal(name) {
    switch (name) {
      case "modalAddTask":
        this.setState({ modalAsignment: !this.state.modalAsignment });
        break;
      case "modalAccept":
        this.setState({ modalAccept: !this.state.modalAccept });
        break;
      case "modalReject":
        this.setState({ modalReject: !this.state.modalReject });
        break;
      default:
        break;
    }
  }
  submitReject(event, state) {
    event.preventDefault();

    var bodyFormData = new FormData();
    bodyFormData.append("comment", state.comentarioAsignar);
    if (state.comentarioAsignar.length > 0 && state.idSelected) {
      instance
        .post(`files/${state.idSelected}/reject`, bodyFormData)
        .then((res) => {
          if (res.data) {
            this.setState({
              Asignara: "",
              comentarioAsignar: "",
            });
            this.toggleModal("modalReject");
            this.props.getFilesAssigned();
            this.props.getFilesRejected();
          }
        })
        .catch((e) => {
          if (e.response) {
            this.setState({
              buttonsError: e.response.data,
              comentarioAsignar: "",
            });

            setTimeout(() => {
              this.setState({
                buttonsError: "",
              });
            }, 3000);
          }
        });
    } else {
      this.setState({
        buttonsError: "Debes explicar el motivo",
        comentarioAsignar: "",
      });

      setTimeout(() => {
        this.setState({
          buttonsError: "",
        });
      }, 3000);
    }
  }
  handleSubmitAsignar(event) {
    event.preventDefault();

    if (
      this.state.Asignara.length === 0 ||
      this.state.comentarioAsignar.length === 0
    ) {
      if (this.state.Asignara.length === 0) {
        this.setState({ AsignaraError: !this.state.AsignaraError });
        setTimeout(() => {
          this.setState({ AsignaraError: !this.state.AsignaraError });
        }, 3000);
      }
      if (this.state.comentarioAsignar.length === 0) {
        this.setState({ AsignaraCommentError: "Este campo es necesario" });
        setTimeout(() => {
          this.setState({
            AsignaraCommentError: "",
          });
        }, 3000);
      }
    } else {
      var bodyFormData = new FormData();
      bodyFormData.append("receiver", this.state.Asignara);
      bodyFormData.append("comment", this.state.comentarioAsignar);
      instance
        .post(`files/${this.state.idSelected}/assign`, bodyFormData)
        .then((res) => {
          if (res.status === 201 || res.data) {
            this.setState({
              Asignara: "",
              comentarioAsignar: "",
            });

            this.toggleModal("modalAddTask");
            this.props.getFilesAssigned();
            this.props.getFilesRejected();
          }
        })
        .catch((e) => {
          if (e.response) {
            this.setState({
              AsignaraCommentError: e.response.data,
            });
            setTimeout(() => {
              this.setState({
                AsignaraCommentError: "",
              });
            }, 3000);
          }
        });
    }
  }
  handleSubmit(event, state) {
    event.preventDefault();

    var bodyFormData = new FormData();

    bodyFormData.append("comment", state.comentarioAceptar);
    instance
      .post(`files/${state.idSelected}/accept`, bodyFormData)
      .then((res) => {
        if (res.status === 201 || res.status === 200 || res.data) {
          // this.addNotification(
          //   "default",
          //   "",
          //   `Expediente # ${this.state.path} copiado`,
          //   "bottom-center"
          // );
          this.toggleModal("modalAccept");
          this.props.getFilesAssigned();
        }
      })
      .catch((e) => {
        if (e.response) {
          this.setState({
            buttonsError: e.response.data,
          });

          setTimeout(() => {
            this.setState({
              buttonsError: "",
            });
          }, 3000);
        }
      });
  }

  componentWillUnmount() {
    this.context.handleSetAppSidebarSearch(false);
  }

  render() {
    const {
      RejectedsDashboard,
      AssignmentsDashboard,
      users,
      metrics,
      AcceptedDashboard,
    } = this.props;
    // this.componentWillMountedverdad(RejectedsDashboard,AssignmentsDashboard);

    console.log(AcceptedDashboard, metrics);

    var convertNumberWithCommas = function (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    this.getDate = (minusDate) => {
      var d = new Date();
      d = d.setDate(d.getDate() - minusDate);

      return d;
    };

    this.donutChartOptions = {
      donut: true,
      showLegend: false,
      growOnHover: false,
      arcsRadius: [
        { inner: 0.65, outer: 0.93 },
        { inner: 0.6, outer: 1 },
      ],
      margin: { left: 10, right: 10, top: 10, bottom: 10 },
      donutRatio: 0.5,
      labelFormat: d3.format(",.0f"),
    };

    this.getSumatorie = (data) => {
      let sumCount = 0;
      if (data && data.length > 0) {
        console.log(data);

        for (const item of data) {
          sumCount += item.count;
        }
      }
      return sumCount;
    };

    this.donutChartData = [
      {
        label: "Asignados",
        value: this.getSumatorie(metrics.files_assigned),
        color: this.context.color.teal,
      },
      {
        label: "Rechazados",
        value: this.getSumatorie(metrics.files_rejected),
        color: this.context.color.blue,
      },
      // {
      //   label: "Aceptados",
      //   value: this.getSumatorie(metrics.files_accepted),
      //   color: this.context.color.teal,
      // },
    ];

    var pieChartSeries = [
      this.getSumatorie(metrics.files_assigned),
      this.getSumatorie(metrics.files_rejected),
      // this.getSumatorie(metrics.files_accepted),
    ];
    var pieChartOptions = {
      labels: ["Asignados", "Rechazados", "Aceptados"],
      chart: { type: "donut" },
      dataLabels: {
        dropShadow: { enabled: false },
        style: { colors: ["#fff"] },
      },
      stroke: { show: false },
      colors: ["#00acac", "#dc3545", "#348fe2"],
      legend: { show: false },
    };

    // grafica

    // function handleGetDate(minusDate) {
    //   var d = new Date();
    //   d = d.setDate(d.getDate() - minusDate);
    //   return d;
    // }
    //   <Chart
    //   type="area"
    //   height="254"
    //   width="100%"
    //   options={visitorChartOptions}
    //   series={visitorChartSeries}
    // />
    //  var visitorChartSeries = [
    //   {
    //     name: "Unique Visitors",
    //     data: [
    //       [handleGetDate(77), 13],
    //       [handleGetDate(70), 63],
    //     ],
    //   },
    //   {
    //     name: "Page Views",
    //     data: [],
    //   },
    // ];
    // var visitorChartOptions = {
    //   colors: ["#00acac", "#348fe2"],
    //   fill: { opacity: 0.75, type: "solid" },
    //   legend: {
    //     position: "top",
    //     horizontalAlign: "right",
    //     offsetY: 15,
    //     offsetX: 500,
    //     labels: { colors: "#ffffff" },
    //   },
    //   xaxis: {
    //     type: "datetime",
    //     tickAmount: 6,
    //     labels: { style: { colors: "#ffffff" } },
    //   },
    //   yaxis: { labels: { style: { colors: "#ffffff" } } },
    //   tooltip: {
    //     y: {
    //       formatter: function (val) {
    //         return "$ " + val + " thousands";
    //       },
    //     },
    //   },
    //   chart: {
    //     height: "100%",
    //     type: "area",
    //     toolbar: { show: false },
    //     stacked: true,
    //   },
    //   plotOptions: {
    //     bar: { horizontal: false, columnWidth: "55%", endingShape: "rounded" },
    //   },
    //   dataLabels: { enabled: false },
    //   grid: {
    //     show: true,
    //     borderColor: "rgba(255,255,255, .15)",
    //     xaxis: { lines: { show: true } },
    //     yaxis: { lines: { show: true } },
    //     padding: { top: -40, right: 3, bottom: 0, left: 10 },
    //   },
    //   stroke: { show: false, curve: "straight" },
    // };

    // this.areaChartOptions = {
    //   pointSize: 1,
    //   useInteractiveGuideline: true,
    //   durection: 300,
    //   showControls: false,
    //   controlLabels: {
    //     stacked: "Stacked",
    //   },
    //   fillOpacity: 1,

    //   yAxis: {
    //     tickFormat: d3.format(",.0f"),
    //   },
    //   xAxis: {
    //     tickFormat: function (d) {
    //       var monthsName = [
    //         "Ene",
    //         "Feb",
    //         "Mar",
    //         "Abr",
    //         "May",
    //         "Jun",
    //         "Jul",
    //         "Ago",
    //         "Sep",
    //         "Oct",
    //         "Nov",
    //         "Dic",
    //       ];

    //       const date = new Date(d);
    //       // const options = { month: "long", day: "numeric" };
    //       const day = String(date.getDate()).padStart(2, "0");
    //       const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses en JavaScript son 0-11
    //       const formattedDate = `${day}/${month}`;
    //       d = d ? formattedDate : formattedDate;

    //       return d;
    //     },
    //   },
    //   tooltip: {
    //     y: {
    //       formatter: function (val) {
    //         return "Q" + val;
    //       },
    //     },
    //   },
    // };
    // function handleGetDate(minusDate) {
    //   var d = new Date();
    //   d = d.setDate(d.getDate() - minusDate);
    //   console.log(d);
    //   return d;
    // }

    // this.areaChartOptionsProductivity = {
    //   color: function (d, i) {
    //     console.log("d.series", d.series, d);
    //     return d.series == 1 ? "#348fe2" : "red";
    //   },
    //   pointSize: 0.5,
    //   useInteractiveGuideline: true,
    //   durection: 300,
    //   showControls: false,
    //   controlLabels: {
    //     stacked: "Stacked",
    //   },
    //   fillOpacity: 1,

    //   // yAxis: {
    //   //   tickFormat: d3.format(",.0f"),
    //   // },
    //   xAxis: {
    //     tickFormat: function (d) {
    //       var monthsName = [
    //         "Ene",
    //         "Feb",
    //         "Mar",
    //         "Abr",
    //         "May",
    //         "Jun",
    //         "Jul",
    //         "Ago",
    //         "Sep",
    //         "Oct",
    //         "Nov",
    //         "Dic",
    //       ];

    //       const date = new Date(d);
    //       // const options = { month: "long", day: "numeric" };
    //       const day = String(date.getDate()).padStart(2, "0");
    //       const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses en JavaScript son 0-11
    //       const formattedDate = `${day}/${month}`;
    //       d = d ? formattedDate : formattedDate;

    //       return d;
    //     },
    //   },
    // };
    this.aceptadosArray = (metrics) => {
      var totalEjes;
      if (metrics && metrics.length > 0) {
        console.log(metrics);
        totalEjes = metrics.map((objeto) => ({
          x: new Date(objeto.day),
          y: objeto.count,
        }));
      } else {
        totalEjes = [{ x: 0, y: 0 }];
      }

      return totalEjes;
    };
    this.aceptadosNuevoArray = (metrics) => {
      var totalEjes;
      if (metrics) {
        console.log(metrics.map((objeto) => [
          new Date(objeto.day.slice(0,11) + "00:00:00").getTime(),
          objeto.count,
        ]));
        totalEjes = metrics.map((objeto) => [
          new Date(objeto.day.slice(0,11) + "00:00:00").getTime(),
          objeto.count,
        ]);
      } else {
        totalEjes = [[0, 0]];
      }

      return totalEjes;
    };

    this.areaChartData = [
      {
        key: "Rechazados",
        color: "red",
        values: this.aceptadosArray(metrics.files_rejected),
      },
      {
        key: "Asignados",
        color: this.context.color.cyan,
        values: this.aceptadosArray(metrics.files_assigned),
      },
    ];
    this.areaChartDataCount = [
      {
        key: "Conteos",
        color: this.context.color.cyan,
        values: this.aceptadosArray(metrics.count_month),
      },
    ];

    var visitorChartSeries = [
      {
        name: "Asignados",
        data: this.aceptadosNuevoArray(metrics.files_assigned),
      },
      {
        name: "Rechazados",
        data: this.aceptadosNuevoArray(metrics.files_rejected),
      },
    ];
    var visitorChartOptions = {
      colors: ["#00acac", "#FF0000"],
      fill: { opacity: 0.85, type: "solid" },
      stroke: {
        curve: "smooth",
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetY: 0,
        offsetX: 350,
        labels: { colors: "#ffffff" },
      },
      xaxis: {
        type: "datetime",
        tickAmount: 6,
        labels: { style: { colors: "#ffffff" } },
      },
      yaxis: { labels: { style: { colors: "#ffffff" } } },
      tooltip: {
        y: {
          formatter: function (val) {
            return val == 1 ? val + " Expediente" : val + " Expedientes";
          },
        },
      },
      chart: {
        height: "100%",
        type: "area",
        toolbar: { show: false },
        stacked: true,
      },
      plotOptions: {
        bar: { horizontal: false, columnWidth: "55%", endingShape: "rounded" },
      },
      dataLabels: { enabled: false },
      grid: {
        show: true,
        borderColor: "rgba(255,255,255, .15)",
        xaxis: { lines: { show: true } },
        yaxis: { lines: { show: true } },
        padding: { top: -40, right: 3, bottom: 0, left: 10 },
      },
      stroke: { show: false, curve: "straight" },
    };
    this.map = {
      center: {
        lat: 59.95,
        lng: 30.33,
      },
      zoom: 9,
    };
    this.date = new Date();

    // this.totalSalesChart = {
    //   options: {
    //     chart: {
    //       type: "line",
    //       width: 200,
    //       height: 36,
    //       sparkline: {
    //         enabled: true,
    //       },
    //       stacked: true,
    //     },
    //     stroke: {
    //       curve: "smooth",
    //       width: 3,
    //     },
    //     fill: {
    //       type: "gradient",
    //       gradient: {
    //         opacityFrom: 1,
    //         opacityTo: 1,
    //         colorStops: [
    //           {
    //             offset: 0,
    //             color: this.context.color.blue,
    //             opacity: 1,
    //           },
    //           {
    //             offset: 100,
    //             color: this.context.color.indigo,
    //             opacity: 1,
    //           },
    //         ],
    //       },
    //     },
    //     tooltip: {
    //       theme: "dark",
    //       fixed: {
    //         enabled: false,
    //       },
    //       x: {
    //         show: false,
    //       },
    //       y: {
    //         title: {
    //           formatter: function (seriesName) {
    //             return "";
    //           },
    //         },
    //         formatter: (value) => {
    //           return "$" + convertNumberWithCommas(value);
    //         },
    //       },
    //       marker: {
    //         show: false,
    //       },
    //     },
    //     responsive: [
    //       {
    //         breakpoint: 1500,
    //         options: {
    //           chart: {
    //             width: 130,
    //           },
    //         },
    //       },
    //       {
    //         breakpoint: 1300,
    //         options: {
    //           chart: {
    //             width: 100,
    //           },
    //         },
    //       },
    //       {
    //         breakpoint: 1200,
    //         options: {
    //           chart: {
    //             width: 200,
    //           },
    //         },
    //       },
    //       {
    //         breakpoint: 576,
    //         options: {
    //           chart: {
    //             width: 180,
    //           },
    //         },
    //       },
    //       {
    //         breakpoint: 400,
    //         options: {
    //           chart: {
    //             width: 120,
    //           },
    //         },
    //       },
    //     ],
    //   },
    //   series: [
    //     {
    //       data: [
    //         9452.37, 11018.87, 7296.37, 6274.29, 7924.05, 6581.34, 12918.14,
    //       ],
    //     },
    //   ],
    // };

    this.conteosSeries = [
      {
        name: "Conteos",
        data: this.aceptadosArray(metrics.count_month).map((e) => e.y),
      },
    ];
    this.conteosOptions = {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        labels: { style: { colors: "#ffffff" } },

        categories: this.aceptadosArray(metrics.count_month).map((e) => {
          const date = new Date(e.x);
          const day = date.getDate();
          const monthNames = [
            "Ene",
            "Febr",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Agos",
            "Sept",
            "Oct",
            "Nov",
            "Dic",
          ];
          const month = monthNames[date.getMonth()];
          return `${month} ${day}`;
        }),
      },
      yaxis: {
        labels: { style: { colors: "#ffffff" } },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        color: "white",
        y: {
          formatter: function (val) {
            return val == 1 ? val + " Conteo" : val + " Conteos";
          },
        },
      },
      legend: {
        labels: { colors: "#ffffff" },
      },
    };
    // this.conversionRateChart = {
    //   options: {
    //     chart: {
    //       type: "line",
    //       width: 160,
    //       height: 28,
    //       sparkline: {
    //         enabled: true,
    //       },
    //     },
    //     stroke: {
    //       curve: "smooth",
    //       width: 3,
    //     },
    //     fill: {
    //       type: "gradient",
    //       gradient: {
    //         opacityFrom: 1,
    //         opacityTo: 1,
    //         colorStops: [
    //           {
    //             offset: 0,
    //             color: this.context.color.red,
    //             opacity: 1,
    //           },
    //           {
    //             offset: 50,
    //             color: this.context.color.orange,
    //             opacity: 1,
    //           },
    //           {
    //             offset: 100,
    //             color: this.context.color.lime,
    //             opacity: 1,
    //           },
    //         ],
    //       },
    //     },
    //     labels: [
    //       "Jun 23",
    //       "Jun 24",
    //       "Jun 25",
    //       "Jun 26",
    //       "Jun 27",
    //       "Jun 28",
    //       "Jun 29",
    //     ],
    //     xaxis: {
    //       crosshairs: {
    //         width: 1,
    //       },
    //     },
    //     tooltip: {
    //       theme: "dark",
    //       fixed: {
    //         enabled: false,
    //       },
    //       x: {
    //         show: false,
    //       },
    //       y: {
    //         title: {
    //           formatter: function (seriesName) {
    //             return "";
    //           },
    //         },
    //         formatter: (value) => {
    //           return value + "%";
    //         },
    //       },
    //       marker: {
    //         show: false,
    //       },
    //     },
    //     responsive: [
    //       {
    //         breakpoint: 1500,
    //         options: {
    //           chart: {
    //             width: 120,
    //           },
    //         },
    //       },
    //       {
    //         breakpoint: 1300,
    //         options: {
    //           chart: {
    //             width: 100,
    //           },
    //         },
    //       },
    //       {
    //         breakpoint: 1200,
    //         options: {
    //           chart: {
    //             width: 160,
    //           },
    //         },
    //       },
    //       {
    //         breakpoint: 900,
    //         options: {
    //           chart: {
    //             width: 120,
    //           },
    //         },
    //       },
    //       {
    //         breakpoint: 576,
    //         options: {
    //           chart: {
    //             width: 180,
    //           },
    //         },
    //       },
    //       {
    //         breakpoint: 400,
    //         options: {
    //           chart: {
    //             width: 120,
    //           },
    //         },
    //       },
    //     ],
    //   },
    //   series: [
    //     {
    //       data: [2.68, 2.93, 2.04, 1.61, 1.88, 1.62, 2.8],
    //     },
    //   ],
    // };
    // this.storeSessionChart = {
    //   options: {
    //     chart: {
    //       type: "line",
    //       width: 160,
    //       height: 28,
    //       sparkline: {
    //         enabled: true,
    //       },
    //       stacked: false,
    //     },
    //     stroke: {
    //       curve: "smooth",
    //       width: 3,
    //     },
    //     fill: {
    //       type: "gradient",
    //       gradient: {
    //         opacityFrom: 1,
    //         opacityTo: 1,
    //         colorStops: [
    //           {
    //             offset: 0,
    //             color: this.context.color.teal,
    //             opacity: 1,
    //           },
    //           {
    //             offset: 50,
    //             color: this.context.color.blue,
    //             opacity: 1,
    //           },
    //           {
    //             offset: 100,
    //             color: this.context.color.cyan,
    //             opacity: 1,
    //           },
    //         ],
    //       },
    //     },
    //     labels: [
    //       "Jun 23",
    //       "Jun 24",
    //       "Jun 25",
    //       "Jun 26",
    //       "Jun 27",
    //       "Jun 28",
    //       "Jun 29",
    //     ],
    //     xaxis: {
    //       crosshairs: {
    //         width: 1,
    //       },
    //     },
    //     tooltip: {
    //       theme: "dark",
    //       fixed: {
    //         enabled: false,
    //       },
    //       x: {
    //         show: false,
    //       },
    //       y: {
    //         title: {
    //           formatter: function (seriesName) {
    //             return "";
    //           },
    //         },
    //         formatter: (value) => {
    //           return convertNumberWithCommas(value);
    //         },
    //       },
    //       marker: {
    //         show: false,
    //       },
    //     },
    //     responsive: [
    //       {
    //         breakpoint: 1500,
    //         options: {
    //           chart: {
    //             width: 120,
    //           },
    //         },
    //       },
    //       {
    //         breakpoint: 1300,
    //         options: {
    //           chart: {
    //             width: 100,
    //           },
    //         },
    //       },
    //       {
    //         breakpoint: 1200,
    //         options: {
    //           chart: {
    //             width: 160,
    //           },
    //         },
    //       },
    //       {
    //         breakpoint: 900,
    //         options: {
    //           chart: {
    //             width: 120,
    //           },
    //         },
    //       },
    //       {
    //         breakpoint: 576,
    //         options: {
    //           chart: {
    //             width: 180,
    //           },
    //         },
    //       },
    //       {
    //         breakpoint: 400,
    //         options: {
    //           chart: {
    //             width: 120,
    //           },
    //         },
    //       },
    //     ],
    //   },
    //   series: [
    //     {
    //       data: [10812, 11393, 7311, 6834, 9612, 11200, 13557],
    //     },
    //   ],
    // };

    this.startDate = Moment().subtract(7, "days");
    this.endDate = Moment();

    this.dateRange = {
      currentWeek:
        Moment().subtract("days", 7).format("D MMMM YYYY") +
        " - " +
        Moment().format("D MMMM YYYY"),
      prevWeek:
        Moment().subtract("days", 15).format("D MMMM") +
        " - " +
        Moment().subtract("days", 8).format("D MMMM YYYY"),
    };

    this.handleDateApplyEvent = (event, picker) => {
      var startDate = picker.startDate;
      var endDate = picker.endDate;
      var gap = endDate.diff(startDate, "days");

      var currentWeek =
        startDate.format("D MMMM YYYY") + " - " + endDate.format("D MMMM YYYY");
      var prevWeek =
        Moment(startDate).subtract("days", gap).format("D MMMM") +
        " - " +
        Moment(startDate).subtract("days", 1).format("D MMMM YYYY");

      this.dateRange.currentWeek = currentWeek;
      this.dateRange.prevWeek = prevWeek;

      this.setState((dateRange) => ({
        currentWeek: currentWeek,
        prevWeek: prevWeek,
      }));
    };

    return (
      <div>
        <ol className="breadcrumb float-xl-end">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Escritorio</Link>
          </li>
          <li className="breadcrumb-item active">Escritorio</li>
        </ol>
        <h1 className="page-header mb-3">Escritorio</h1>
        <div className="row">
          <div className="col-12">
            <div className="card border-0 mb-3 overflow-hidden bg-gray-800 text-white">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3 text-gray-500">
                      <b>EXPEDIENTES PENDIENTES</b>
                      <span className="ms-2">
                        <i className="fa fa-info-circle" id="popover1"></i>
                        <UncontrolledPopover
                          trigger="hover"
                          placement="top"
                          target="popover1"
                        >
                          <PopoverHeader>Pendientes</PopoverHeader>
                          <PopoverBody>
                            Total de expedientes pendientes.
                          </PopoverBody>
                        </UncontrolledPopover>
                      </span>
                    </div>
                    <div className="d-flex mb-1">
                      <h2 className="mb-0">
                        {this.state.totalCounts}
                        {this.state.totalCounts == 1
                          ? "  Pendiente"
                          : "  Pendientes"}
                      </h2>
                      <div className="ms-auto mt-n1 mb-n1"></div>
                    </div>

                    <hr className="bg-white-transparent-2" />
                    <div className="row text-truncate">
                      <div className="col-6">
                        <div className="fs-12px text-gray-500">Asignados</div>
                        <div className="fs-18px mb-5px fw-bold">
                          {AssignmentsDashboard.count
                            ? AssignmentsDashboard.count
                            : 0}
                        </div>
                        <div className="progress h-5px rounded-3 bg-gray-900 mb-5px">
                          <div
                            className="progress-bar progress-bar-striped rounded-right bg-teal"
                            style={{
                              width: this.state.percentageAssigned + "%",
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="fs-12px text-gray-500">Rechazados</div>
                        <div className="fs-18px mb-5px fw-bold">
                          {RejectedsDashboard.count
                            ? RejectedsDashboard.count
                            : 0}
                        </div>
                        <div className="progress h-5px rounded-3 bg-gray-900 mb-5px">
                          <div
                            className="progress-bar progress-bar-striped rounded-right"
                            style={{
                              width: this.state.percentageRejected + "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-sm-4 ">
                <div className="card border-0 text-truncate mb-3 bg-gray-800 text-white">
                  <div className="card-body">
                    <div className="mb-3 text-gray-500">
                      <b className="mb-3">EXPEDIENTES ASIGNADOS</b>
                      <span className="ms-2">
                        <i className="fa fa-info-circle" id="popover2"></i>
                        <UncontrolledPopover
                          trigger="hover"
                          placement="top"
                          target="popover2"
                        >
                          <PopoverHeader>Asignados</PopoverHeader>
                          <PopoverBody>
                            Aquellos expedientes que puedes aceptar o rechazar.
                          </PopoverBody>
                        </UncontrolledPopover>
                      </span>
                    </div>

                    <PerfectScrollbar className="heigthDashborad">
                      {AssignmentsDashboard.results &&
                      AssignmentsDashboard.results.length > 0 ? (
                        AssignmentsDashboard.results.map((assigned, index) => (
                          <div key={index} className="d-flex mb-2">
                            <div className="d-flex flex-column">
                              <div className="d-flex align-items-center">
                                <i className="fa fa-circle text-lime fs-8px me-2"></i>
                                Expediente # {assigned?.number}
                              </div>{" "}
                              <span className="ms-3 text-gray-500 small">
                                {assigned?.last_assignment?.sender?.full_name}
                              </span>
                              <span
                                className="ms-3 text-gray-500 small"
                                style={{
                                  maxWidth: "10rem",
                                  overflow: "hidden",
                                }}
                              >
                                <em>{assigned?.last_assignment?.comment}</em>
                              </span>
                            </div>
                            <div className="d-flex align-items-center ms-auto">
                              {/* <Link
                                to={`/Expediente/${assigned.id}`}
                                className="btn btn-sm btn-green  me-1 p-auto"
                              >
                                <i className="fab fa-lg fa-fw fs-14px fa-sistrix text-white"></i>
                              </Link>{" "} */}
                              <div
                                onClick={() => {
                                  this.toggleModal("modalAccept");
                                  this.setState({
                                    idSelected: assigned.id,
                                  });
                                }}
                                className={`${successPermissions(
                                  "can_accept_file_assignment"
                                )} btn btn-sm btn-success  me-1 p-auto`}
                              >
                                Aceptar
                              </div>{" "}
                              <div
                                onClick={() => {
                                  this.toggleModal("modalReject");
                                  this.setState({
                                    idSelected: assigned.id,
                                  });
                                }}
                                className={`${successPermissions(
                                  "can_decline_file_assignment"
                                )} btn btn-sm btn-danger  me-1 p-auto`}
                              >
                                Rechazar
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <span>No hay expedientes</span>
                      )}
                    </PerfectScrollbar>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 ">
                <div className="card border-0 text-truncate mb-3 bg-gray-800 text-white">
                  <div className="card-body">
                    <div className="mb-3 text-gray-500">
                      <b className="mb-3">EXPEDIENTES RECHAZADOS</b>
                      <span className="ms-2">
                        <i className="fa fa-info-circle" id="popover3"></i>
                        <UncontrolledPopover
                          trigger="hover"
                          placement="top"
                          target="popover3"
                        >
                          <PopoverHeader>Rechazados</PopoverHeader>
                          <PopoverBody>
                            Fueron rechazados y podras ver la razón del mismo.
                          </PopoverBody>
                        </UncontrolledPopover>
                      </span>
                    </div>

                    <PerfectScrollbar className="heigthDashborad">
                      {RejectedsDashboard.results &&
                      RejectedsDashboard.results.length > 0 ? (
                        RejectedsDashboard.results.map((rejected, index) => (
                          <div key={index} className="d-flex mb-2">
                            <div className="d-flex flex-column">
                              {" "}
                              <div className="d-flex align-items-center">
                                <i className="fa fa-circle text-red fs-8px me-2"></i>
                                Expediente # {rejected?.number}
                                <br />
                              </div>
                              <span className="ms-3 text-gray-500 small">
                                {rejected?.last_assignment?.sender?.full_name}
                              </span>
                              <span
                                className="ms-3  small"
                                style={{
                                  maxWidth: "10rem",
                                  overflow: "hidden",
                                }}
                              >
                                <em>{rejected?.last_assignment?.comment}</em>
                              </span>
                            </div>
                            <div className="d-flex align-items-center ms-auto">
                              <Link
                                to={`/Expediente/${rejected.id}`}
                                className="btn btn-sm btn-green  me-1 p-auto"
                              >
                                <i className="fab fa-lg fa-fw fs-14px fa-sistrix text-white"></i>
                              </Link>
                              <div
                                onClick={() => {
                                  this.toggleModal("modalAddTask");
                                  this.setState({
                                    idSelected: rejected.id,
                                  });
                                }}
                                className={`${successPermissions(
                                  "can_create_file_assignment"
                                )} btn btn-sm btn-success  me-1 p-auto`}
                              >
                                Re-asignar
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <span>No hay expedientes</span>
                      )}
                    </PerfectScrollbar>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 ">
                <div className="card border-0 text-truncate mb-3 bg-gray-800 text-white">
                  <div className="card-body">
                    <div className="mb-3 text-gray-500">
                      <b className="mb-3">EXPEDIENTES ACEPTADOS</b>
                      <span className="ms-2">
                        <i className="fa fa-info-circle" id="popover3"></i>
                        <UncontrolledPopover
                          trigger="hover"
                          placement="top"
                          target="popover3"
                        >
                          <PopoverHeader>Aceptados</PopoverHeader>
                          <PopoverBody>
                            Fueron aceptados y podras ver el detalle.
                          </PopoverBody>
                        </UncontrolledPopover>
                      </span>
                    </div>

                    <PerfectScrollbar className="heigthDashborad">
                      {AcceptedDashboard.results &&
                      AcceptedDashboard.results.length > 0 ? (
                        AcceptedDashboard.results.map((accepted, index) => (
                          <div key={index} className="d-flex mb-2">
                            <div className="d-flex flex-column">
                              {" "}
                              <div className="d-flex align-items-center">
                                <i
                                  className="fa fa-circle text-green
                                 fs-8px me-2"
                                ></i>
                                Expediente # {accepted?.number}
                                <br />
                              </div>
                              <span className="ms-3 text-gray-500 small">
                                {accepted?.last_assignment?.sender?.full_name}
                              </span>
                              <span
                                className="ms-3  small"
                                style={{
                                  maxWidth: "10rem",
                                  overflow: "hidden",
                                }}
                              >
                                <em>{accepted?.last_assignment?.comment}</em>
                              </span>
                            </div>
                            <div className="d-flex align-items-center ms-auto">
                              <Link
                                to={`/Expediente/${accepted.id}`}
                                className="btn btn-sm btn-green  me-1 p-auto"
                              >
                                <i className="fab fa-lg fa-fw fs-14px fa-sistrix text-white"></i>
                              </Link>
                            </div>
                          </div>
                        ))
                      ) : (
                        <span>No hay expedientes</span>
                      )}
                    </PerfectScrollbar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row d-md-flex">
          <div className="col-xl-4 col-lg-4">
            <div className="card border-0 mb-0 bg-gray-800 text-white">
              <div className="card-body">
                <div className="mb-3 text-gray-500">
                  <b>CONTEOS MES A MES</b>
                  <span className="ms-2">
                    <i className="fa fa-info-circle" id="popover4"></i>
                    <UncontrolledPopover
                      trigger="hover"
                      placement="top"
                      target="popover4"
                    >
                      <PopoverHeader>Estado de expedientes</PopoverHeader>
                      <PopoverBody>
                        Muestra el estado de los expedientes en el mes
                      </PopoverBody>
                    </UncontrolledPopover>
                  </span>
                  <div className="row"></div>
                </div>
                <div className="card-body p-0">
                  <div style={{ height: "269px" }}>
                    <div
                      className="widget-chart-full-width dark-mode"
                      style={{ height: "254px" }}
                    >
                      {/* <NVD3Chart
                        type="discreteBarChart"
                        datum={this.areaChartDataCount}
                        height={260}
                        options={this.areaChartOptions}
                      /> */}
                      <Chart
                        options={this.conteosOptions}
                        series={this.conteosSeries}
                        type="bar"
                        height={260}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
          <div className="col-xl-8 col-lg-6">
            <div className="card border-0 mb-3 bg-gray-800 text-white">
              <div className="card-body">
                <div className="mb-3 text-gray-500">
                  <b>VISITORS ANALYTICS</b>
                  <span className="ms-2">
                    <i className="fa fa-info-circle" id="popover4"></i>
                    <UncontrolledPopover
                      trigger="hover"
                      placement="top"
                      target="popover4"
                    >
                      <PopoverHeader>
                        Top products with units sold
                      </PopoverHeader>
                      <PopoverBody>
                        Products with the most individual units sold. Includes
                        orders from all sales channels.
                      </PopoverBody>
                    </UncontrolledPopover>
                  </span>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-4">
                    <h3 className="mb-1">127.1K</h3>
                    <div>New Visitors</div>
                    <div className="text-gray-500 small text-truncate">
                      <i className="fa fa-caret-up"></i> 25.5% from previous 7
                      days
                    </div>
                  </div>
                  <div className="col-xl-3 col-4">
                    <h3 className="mb-1">179.9K</h3>
                    <div>Returning Visitors</div>
                    <div className="text-gray-500 small text-truncate">
                      <i className="fa fa-caret-up"></i> 5.33% from previous 7
                      days
                    </div>
                  </div>
                  <div className="col-xl-3 col-4">
                    <h3 className="mb-1">766.8K</h3>
                    <div>Total Page Views</div>
                    <div className="text-gray-500 small text-truncate">
                      <i className="fa fa-caret-up"></i> 0.323% from previous 7
                      days
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <div style={{ height: "269px" }}>
                  <div
                    className="widget-chart-full-width dark-mode"
                    style={{ height: "254px" }}
                  >
                    <NVD3Chart
                      type="stackedAreaChart"
                      datum={this.areaChartData}
                      height={260}
                      options={this.areaChartOptions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6">
            <div className="card bg-gray-800 border-0 text-white mb-3">
              <div className="card-body">
                <div className="mb-2 text-grey">
                  <b>SESSION BY LOCATION</b>
                  <span className="ms-2">
                    <i className="fa fa-info-circle" id="popover5"></i>
                    <UncontrolledPopover
                      trigger="hover"
                      placement="top"
                      target="popover5"
                    >
                      <PopoverHeader>Total sales</PopoverHeader>
                      <PopoverBody>
                        Net sales (gross sales minus discounts and returns) plus
                        taxes and shipping. Includes orders from all sales
                        channels.
                      </PopoverBody>
                    </UncontrolledPopover>
                  </span>
                </div>
                <div className="bg-black mb-3" style={{ height: "192px" }}>
                  <GoogleMapReact
                    defaultCenter={this.map.center}
                    defaultZoom={this.map.zoom}
                  ></GoogleMapReact>
                </div>
                <div>
                  <div className="d-flex align-items-center text-white mb-2">
                    <div
                      className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px"
                      style={{
                        backgroundImage: "url(/assets/img/flag/us.jpg)",
                      }}
                    ></div>
                    <div className="d-flex w-100">
                      <div>United States</div>
                      <div className="ms-auto text-gray-500">39.85%</div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center text-white mb-2">
                    <div
                      className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px"
                      style={{
                        backgroundImage: "url(/assets/img/flag/cn.jpg)",
                      }}
                    ></div>
                    <div className="d-flex w-100">
                      <div>China</div>
                      <div className="ms-auto text-gray-500">14.23%</div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center text-white mb-2">
                    <div
                      className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px"
                      style={{
                        backgroundImage: "url(/assets/img/flag/de.jpg)",
                      }}
                    ></div>
                    <div className="d-flex w-100">
                      <div>Germany</div>
                      <div className="ms-auto text-gray-500">12.83%</div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center text-white mb-2">
                    <div
                      className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px"
                      style={{
                        backgroundImage: "url(/assets/img/flag/fr.jpg)",
                      }}
                    ></div>
                    <div className="d-flex w-100">
                      <div>France</div>
                      <div className="ms-auto text-gray-500">11.14%</div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center text-white mb-0">
                    <div
                      className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px"
                      style={{
                        backgroundImage: "url(/assets/img/flag/jp.jpg)",
                      }}
                    ></div>
                    <div className="d-flex w-100">
                      <div>Japan</div>
                      <div className="ms-auto text-gray-500">10.75%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-6">
            <div className="card border-0 mb-3 bg-gray-900 text-white">
              <div
                className="card-body"
                style={{
                  background: "no-repeat bottom right",
                  backgroundImage: "url(/assets/img/svg/img-4.svg)",
                  backgroundSize: "auto 60%",
                }}
              >
                <div className="mb-3 text-gray-500 ">
                  <b>SALES BY SOCIAL SOURCE</b>
                  <span className="text-gray-500 ms-2">
                    <i className="fa fa-info-circle" id="popover6"></i>
                    <UncontrolledPopover
                      trigger="hover"
                      placement="top"
                      target="popover6"
                    >
                      <PopoverHeader>Sales by social source</PopoverHeader>
                      <PopoverBody>
                        Total online store sales that came from a social
                        referrer source.
                      </PopoverBody>
                    </UncontrolledPopover>
                  </span>
                </div>
                <h3 className="mb-10px">$55,547.89</h3>
                <div className="text-gray-500 mb-1px">
                  <i className="fa fa-caret-up"></i> 45.76% increased
                </div>
              </div>
              <div className="widget-list rounded-bottom dark-mode">
                <Link
                  to="/dashboard"
                  className="widget-list-item rounded-0 pt-3px"
                >
                  <div className="widget-list-media icon">
                    <i className="fab fa-apple bg-indigo text-white"></i>
                  </div>
                  <div className="widget-list-content">
                    <div className="widget-list-title">Apple Store</div>
                  </div>
                  <div className="widget-list-action text-nowrap text-gray-500">
                    $34,840.17
                  </div>
                </Link>
                <Link to="/dashboard" className="widget-list-item">
                  <div className="widget-list-media icon">
                    <i className="fab fa-facebook-f bg-blue text-white"></i>
                  </div>
                  <div className="widget-list-content">
                    <div className="widget-list-title">Facebook</div>
                  </div>
                  <div className="widget-list-action text-nowrap text-gray-500">
                    $12,502.67
                  </div>
                </Link>
                <Link to="/dashboard" className="widget-list-item">
                  <div className="widget-list-media icon">
                    <i className="fab fa-twitter bg-info text-white"></i>
                  </div>
                  <div className="widget-list-content">
                    <div className="widget-list-title">Twitter</div>
                  </div>
                  <div className="widget-list-action text-nowrap text-gray-500">
                    $4,799.20
                  </div>
                </Link>
                <Link to="/dashboard" className="widget-list-item">
                  <div className="widget-list-media icon">
                    <i className="fab fa-google bg-red text-white"></i>
                  </div>
                  <div className="widget-list-content">
                    <div className="widget-list-title">Google Adwords</div>
                  </div>
                  <div className="widget-list-action text-nowrap text-gray-500">
                    $3,405.85
                  </div>
                </Link>
                <Link
                  to="/dashboard"
                  className="widget-list-item pb-3px rounded-bottom"
                >
                  <div className="widget-list-media icon">
                    <i className="fab fa-instagram bg-pink text-white"></i>
                  </div>
                  <div className="widget-list-content">
                    <div className="widget-list-title">Instagram</div>
                  </div>
                  <div className="widget-list-action text-nowrap text-gray-500">
                    $0.00
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6">
            <div className="card border-0 mb-3 bg-gray-800 text-white">
              <div className="card-body">
                <div className="mb-3 text-gray-500">
                  <b>TOP PRODUCTS BY UNITS SOLD</b>
                  <span className="ms-2">
                    <i className="fa fa-info-circle" id="popover7"></i>
                    <UncontrolledPopover
                      trigger="hover"
                      placement="top"
                      target="popover7"
                    >
                      <PopoverHeader>
                        Top products with units sold
                      </PopoverHeader>
                      <PopoverBody>
                        Products with the most individual units sold. Includes
                        orders from all sales channels.
                      </PopoverBody>
                    </UncontrolledPopover>
                  </span>
                </div>
                <div className="d-flex align-items-center mb-15px">
                  <div className="widget-img rounded-3 me-10px bg-white p-3px w-30px">
                    <div
                      className="h-100 w-100"
                      style={{
                        background:
                          "url(/assets/img/product/product-8.jpg) center no-repeat",
                        backgroundSize: "auto 100%",
                      }}
                    ></div>
                  </div>
                  <div className="text-truncate">
                    <div>Apple iPhone XR (2021)</div>
                    <div className="text-gray-500">$799.00</div>
                  </div>
                  <div className="ms-auto text-center">
                    <div className="fs-13px">195</div>
                    <div className="text-gray-500 fs-10px">sold</div>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-15px">
                  <div className="widget-img rounded-3 me-10px bg-white p-3px w-30px">
                    <div
                      className="h-100 w-100"
                      style={{
                        background:
                          "url(/assets/img/product/product-9.jpg) center no-repeat",
                        backgroundSize: "auto 100%",
                      }}
                    ></div>
                  </div>
                  <div className="text-truncate">
                    <div>Apple iPhone XS (2021)</div>
                    <div className="text-gray-500">$1,199.00</div>
                  </div>
                  <div className="ms-auto text-center">
                    <div className="fs-13px">185</div>
                    <div className="text-gray-500 fs-10px">sold</div>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-15px">
                  <div className="widget-img rounded-3 me-10px bg-white p-3px w-30px">
                    <div
                      className="h-100 w-100"
                      style={{
                        background:
                          "url(/assets/img/product/product-10.jpg) center no-repeat",
                        backgroundSize: "auto 100%",
                      }}
                    ></div>
                  </div>
                  <div className="text-truncate">
                    <div>Apple iPhone XS Max (2021)</div>
                    <div className="text-gray-500">$3,399</div>
                  </div>
                  <div className="ms-auto text-center">
                    <div className="fs-13px">129</div>
                    <div className="text-gray-500 fs-10px">sold</div>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-15px">
                  <div className="widget-img rounded-3 me-10px bg-white p-3px w-30px">
                    <div
                      className="h-100 w-100"
                      style={{
                        background:
                          "url(/assets/img/product/product-11.jpg) center no-repeat",
                        backgroundSize: "auto 100%",
                      }}
                    ></div>
                  </div>
                  <div className="text-truncate">
                    <div>Huawei Y5 (2021)</div>
                    <div className="text-gray-500">$99.00</div>
                  </div>
                  <div className="ms-auto text-center">
                    <div className="fs-13px">96</div>
                    <div className="text-gray-500 fs-10px">sold</div>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="widget-img rounded-3 me-10px bg-white p-3px w-30px">
                    <div
                      className="h-100 w-100"
                      style={{
                        background:
                          "url(/assets/img/product/product-12.jpg) center no-repeat",
                        backgroundSize: "auto 100%",
                      }}
                    ></div>
                  </div>
                  <div className="text-truncate">
                    <div>Huawei Nova 4 (2021)</div>
                    <div className="text-gray-500">$499.00</div>
                  </div>
                  <div className="ms-auto text-center">
                    <div className="fs-13px">55</div>
                    <div className="text-gray-500 fs-10px">sold</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6">
            <div className="card border-0 mb-3 bg-gray-800 text-white">
              <div className="card-body">
                <div className="mb-3 text-gray-500">
                  <b>MARKETING CAMPAIGN</b>
                  <span className="ms-2">
                    <i className="fa fa-info-circle" id="popover8"></i>
                    <UncontrolledPopover
                      trigger="hover"
                      placement="top"
                      target="popover8"
                    >
                      <PopoverHeader>Marketing Campaign</PopoverHeader>
                      <PopoverBody>
                        Campaign that run for getting more returning customers.
                      </PopoverBody>
                    </UncontrolledPopover>
                  </span>
                </div>
                <div className="row align-items-center pb-1px">
                  <div className="col-4">
                    <div className="h-100px d-flex align-items-center justify-content-center">
                      <img
                        src="/assets/img/svg/img-2.svg"
                        alt=""
                        className="mw-100 mh-100"
                      />
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="mb-2px text-truncate">
                      Email Marketing Campaign
                    </div>
                    <div className="mb-2px  text-gray-500  fs-11px">
                      Mon 12/6 - Sun 18/6
                    </div>
                    <div className="d-flex align-items-center mb-2px">
                      <div className="flex-grow-1">
                        <div className="progress h-5px rounded-pill bg-white bg-opacity-10">
                          <div
                            className="progress-bar progress-bar-striped bg-indigo"
                            style={{ width: "80%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="ms-2 fs-11px w-30px text-center">80%</div>
                    </div>
                    <div className="text-gray-500 small mb-15px text-truncate">
                      57.5% people click the email
                    </div>
                    <Link
                      to="/dashboard"
                      className="btn btn-xs btn-indigo fs-10px ps-2 pe-2"
                    >
                      View campaign
                    </Link>
                  </div>
                </div>
                <hr className="bg-white-transparent-2 mt-20px mb-20px" />
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="h-100px d-flex align-items-center justify-content-center">
                      <img
                        src="/assets/img/svg/img-3.svg"
                        alt=""
                        className="mw-100 mh-100"
                      />
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="mb-2px text-truncate">
                      Facebook Marketing Campaign
                    </div>
                    <div className="mb-2px  text-gray-500  fs-11px">
                      Sat 10/6 - Sun 18/6
                    </div>
                    <div className="d-flex align-items-center mb-2px">
                      <div className="flex-grow-1">
                        <div className="progress h-5px rounded-pill bg-white bg-opacity-10">
                          <div
                            className="progress-bar progress-bar-striped bg-warning"
                            style={{ width: "60%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="ms-2 fs-11px w-30px text-center">60%</div>
                    </div>
                    <div className="text-gray-500 small mb-15px text-truncate">
                      +124k visitors from facebook
                    </div>
                    <Link
                      to="/dashboard"
                      className="btn btn-xs btn-warning fs-10px ps-2 pe-2"
                    >
                      View campaign
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
              <Modal
                isOpen={this.state.modalReject}
                toggle={() => this.toggleModal("modalReject")}
              >
                <ModalHeader
                  // toggle={() => setmodalAsignment(!modalAsignment)}
                  close={
                    <button
                      className="btn-close"
                      onClick={() => {
                        this.toggleModal("modalReject");
                      }}
                    ></button>
                  }
                >
                  Rechazar Expediente
                </ModalHeader>
                <form>
                  <ModalBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div className="d-flex  mx-auto" style={{ width: "80%" }}>
                        <div className="field m-auto">Comentario</div>
                        <div>
                          <textarea
                            onChange={(e) =>
                              this.setState({
                                comentarioAsignar: e.target.value,
                              })
                            }
                            placeholder="Escribe tu comentario"
                            className="w-300px m-auto p-2"
                            style={{
                              borderColor: "hsl(0, 0%, 80%)",
                              borderRadius: "4px",
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <span className="text-red">
                        {this.state.buttonsError}
                      </span>
                      {/* {this.state.AsignaraCommentError ? (
                    <div className="d-flex mx-auto" style={{ width: "80%" }}>
                      <div className="w-75px field m-auto "></div>
                      <div className="w-300px m-auto">
                        {" "}
                        <span style={{ color: "red" }}>
                          Este campo es necesario
                        </span>{" "}
                      </div>{" "}
                    </div>
                  ) : null} */}
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <button
                      className="btn btn-white"
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleModal("modalReject");
                        this.setState({
                          comentarioAsignar: "",
                        });
                      }}
                    >
                      Cerrar
                    </button>
                    <button
                      onClick={(e) => this.submitReject(e, this.state)}
                      className="btn btn-danger"
                    >
                      Rechazar
                    </button>
                  </ModalFooter>
                </form>
              </Modal>
              <Modal
                isOpen={this.state.modalAccept}
                toggle={() => this.toggleModal("modalAccept")}
              >
                <ModalHeader
                  // toggle={() => setmodalAsignment(!modalAsignment)}
                  close={
                    <button
                      className="btn-close"
                      onClick={() => {
                        this.toggleModal("modalAccept");
                      }}
                    ></button>
                  }
                >
                  Aceptar Expediente
                </ModalHeader>
                <form>
                  <ModalBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div className="d-flex  mx-auto" style={{ width: "80%" }}>
                        <div className="field m-auto">Comentario</div>
                        <div>
                          <textarea
                            onChange={(e) =>
                              this.setState({
                                comentarioAceptar: e.target.value,
                              })
                            }
                            placeholder="Escribe tu comentario"
                            className="w-300px m-auto p-2"
                            style={{
                              borderColor: "hsl(0, 0%, 80%)",
                              borderRadius: "4px",
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <span className="text-red">
                        {this.state.buttonsError}
                      </span>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <button
                      className="btn btn-white"
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleModal("modalAccept");
                        this.setState({
                          comentarioAceptar: "",
                        });
                      }}
                    >
                      Cerrar
                    </button>
                    <button
                      onClick={(e) => this.handleSubmit(e, this.state)}
                      className="btn btn-success"
                    >
                      Aceptar
                    </button>
                  </ModalFooter>
                </form>
              </Modal>
              <Modal
                isOpen={this.state.modalAsignment}
                toggle={() => this.toggleModal("modalAddTask")}
              >
                <ModalHeader
                  // toggle={() => setmodalAsignment(!modalAsignment)}
                  close={
                    <button
                      className="btn-close"
                      onClick={() => {
                        this.toggleModal("modalAddTask");
                      }}
                    ></button>
                  }
                >
                  Asignar Expediente
                </ModalHeader>
                <form onSubmit={this.handleSubmitAsignar}>
                  <ModalBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div className="d-flex mx-auto" style={{ width: "80%" }}>
                        <div className=" field m-auto ">Asignar a</div>
                        <div>
                          <Select
                            className="w-300px m-auto"
                            options={users.map((person) => ({
                              label: person.first_name + person.last_name,
                              value: person.id,
                            }))}
                            placeholder="Selecciona"
                            name="Personas"
                            onChange={(e) =>
                              this.setState({ Asignara: e.value })
                            }
                          />
                        </div>
                      </div>
                      {this.state.AsignaraError ? (
                        <div
                          className="d-flex mx-auto"
                          style={{ width: "80%" }}
                        >
                          <div className="w-75px field m-auto "></div>
                          <div className="w-300px m-auto">
                            {" "}
                            <span style={{ color: "red" }}>
                              Este campo es necesario
                            </span>{" "}
                          </div>{" "}
                        </div>
                      ) : null}

                      <br />

                      <div className="d-flex  mx-auto" style={{ width: "80%" }}>
                        <div className="field m-auto">Comentario</div>
                        <div>
                          <textarea
                            onChange={(e) =>
                              this.setState({
                                comentarioAsignar: e.target.value,
                              })
                            }
                            placeholder="Escribe tu comentario"
                            className="w-300px m-auto p-2"
                            style={{
                              borderColor: "hsl(0, 0%, 80%)",
                              borderRadius: "4px",
                            }}
                          ></textarea>
                        </div>
                      </div>
                      {this.state.AsignaraCommentError ? (
                        <div
                          className="d-flex mx-auto"
                          style={{ width: "80%" }}
                        >
                          <div className="w-75px field m-auto "></div>
                          <div className="w-300px m-auto">
                            {" "}
                            <span style={{ color: "red" }}>
                              {this.state.AsignaraCommentError}
                            </span>{" "}
                          </div>{" "}
                        </div>
                      ) : null}
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <button
                      className="btn btn-white"
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleModal("modalAddTask");
                      }}
                    >
                      Cerrar
                    </button>
                    <button type="submit" className="btn btn-success">
                      Asignar
                    </button>
                  </ModalFooter>
                </form>
              </Modal>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div className="card border-0 mb-0 bg-gray-800 text-white">
              <div className="card-body">
                <div className="mb-3 text-gray-500">
                  <b>PRODUCTIVIDAD MES A MES</b>
                  <span className="ms-2">
                    <i className="fa fa-info-circle" id="popover4"></i>
                    <UncontrolledPopover
                      trigger="hover"
                      placement="top"
                      target="popover4"
                    >
                      <PopoverHeader>Estado de expedients</PopoverHeader>
                      <PopoverBody>
                        Muestra el estado de los expedientes en el mes
                      </PopoverBody>
                    </UncontrolledPopover>
                  </span>
                  <div className="row"></div>
                </div>
                <div className="card-body p-0">
                  <div style={{ height: "269px" }}>
                    <div
                      className="widget-chart-full-width dark-mode"
                      style={{ height: "254px" }}
                    >
                      {/* <Chart
                        type="area"
                        height={260}
                        width="100%"
                        options={visitorChartOptions}
                        series={visitorChartSeries}
                      /> */}
                      <Chart
                        type="area"
                        height={260}
                        width="100%"
                        options={{
                          colors: ["#00acac", "#FF0000"],
                          dataLabels: {
                            enabled: false,
                          },
                          legend: {
                            labels: { colors: "#ffffff" },
                          },
                          stroke: {
                            curve: "smooth",
                          },
                          xaxis: {
                            type: "datetime",
                            labels: { style: { colors: "#ffffff" } },
                          },
                          yaxis: {
                            min: 0,
                            labels: { style: { colors: "#ffffff" } },
                          },

                          tooltip: {
                            color: "white",
                            x: {
                              format: "dd/MM/yy",
                            },
                          },
                          chart: {
        height: "100%",
        type: "area",
        toolbar: { show: false },
        // stacked: true,
      },
                          grid: {
                            show: true,
                            borderColor: "rgba(255,255,255, .65)",
                            xaxis: { lines: { show: true } },
                            yaxis: { lines: { show: true } },
                           
                          plotOptions: { bar: { horizontal: false, columnWidth: '55%', endingShape: 'rounded' } },
                          },
                          
                        }}
                        series={[
                          {
                            name: "Asignados",
                            data: this.aceptadosNuevoArray(
                              metrics.files_assigned
                            ),
                          },
                          {
                            name: "Rechazados",
                            data: this.aceptadosNuevoArray(
                              metrics.files_rejected
                            ),
                          },
                        ]}
                      />
                      {/* <NVD3Chart
                        type="discreteBarChart"
                        datum={this.areaChartData}
                        height={260}
                        options={this.areaChartOptionsProductivity}
                        showValues={true}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
          <div className="col-xl-8 col-lg-6">
            <div className="card border-0 mb-3 bg-gray-800 text-white">
              <div className="card-body">
                <div className="mb-3 text-gray-500">
                  <b>VISITORS ANALYTICS</b>
                  <span className="ms-2">
                    <i className="fa fa-info-circle" id="popover4"></i>
                    <UncontrolledPopover
                      trigger="hover"
                      placement="top"
                      target="popover4"
                    >
                      <PopoverHeader>
                        Top products with units sold
                      </PopoverHeader>
                      <PopoverBody>
                        Products with the most individual units sold. Includes
                        orders from all sales channels.
                      </PopoverBody>
                    </UncontrolledPopover>
                  </span>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-4">
                    <h3 className="mb-1">127.1K</h3>
                    <div>New Visitors</div>
                    <div className="text-gray-500 small text-truncate">
                      <i className="fa fa-caret-up"></i> 25.5% from previous 7
                      days
                    </div>
                  </div>
                  <div className="col-xl-3 col-4">
                    <h3 className="mb-1">179.9K</h3>
                    <div>Returning Visitors</div>
                    <div className="text-gray-500 small text-truncate">
                      <i className="fa fa-caret-up"></i> 5.33% from previous 7
                      days
                    </div>
                  </div>
                  <div className="col-xl-3 col-4">
                    <h3 className="mb-1">766.8K</h3>
                    <div>Total Page Views</div>
                    <div className="text-gray-500 small text-truncate">
                      <i className="fa fa-caret-up"></i> 0.323% from previous 7
                      days
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <div style={{ height: "269px" }}>
                  <div
                    className="widget-chart-full-width dark-mode"
                    style={{ height: "254px" }}
                  >
                    <NVD3Chart
                      type="stackedAreaChart"
                      datum={this.areaChartData}
                      height={260}
                      options={this.areaChartOptions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6">
            <div className="card bg-gray-800 border-0 text-white mb-3">
              <div className="card-body">
                <div className="mb-2 text-grey">
                  <b>SESSION BY LOCATION</b>
                  <span className="ms-2">
                    <i className="fa fa-info-circle" id="popover5"></i>
                    <UncontrolledPopover
                      trigger="hover"
                      placement="top"
                      target="popover5"
                    >
                      <PopoverHeader>Total sales</PopoverHeader>
                      <PopoverBody>
                        Net sales (gross sales minus discounts and returns) plus
                        taxes and shipping. Includes orders from all sales
                        channels.
                      </PopoverBody>
                    </UncontrolledPopover>
                  </span>
                </div>
                <div className="bg-black mb-3" style={{ height: "192px" }}>
                  <GoogleMapReact
                    defaultCenter={this.map.center}
                    defaultZoom={this.map.zoom}
                  ></GoogleMapReact>
                </div>
                <div>
                  <div className="d-flex align-items-center text-white mb-2">
                    <div
                      className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px"
                      style={{
                        backgroundImage: "url(/assets/img/flag/us.jpg)",
                      }}
                    ></div>
                    <div className="d-flex w-100">
                      <div>United States</div>
                      <div className="ms-auto text-gray-500">39.85%</div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center text-white mb-2">
                    <div
                      className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px"
                      style={{
                        backgroundImage: "url(/assets/img/flag/cn.jpg)",
                      }}
                    ></div>
                    <div className="d-flex w-100">
                      <div>China</div>
                      <div className="ms-auto text-gray-500">14.23%</div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center text-white mb-2">
                    <div
                      className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px"
                      style={{
                        backgroundImage: "url(/assets/img/flag/de.jpg)",
                      }}
                    ></div>
                    <div className="d-flex w-100">
                      <div>Germany</div>
                      <div className="ms-auto text-gray-500">12.83%</div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center text-white mb-2">
                    <div
                      className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px"
                      style={{
                        backgroundImage: "url(/assets/img/flag/fr.jpg)",
                      }}
                    ></div>
                    <div className="d-flex w-100">
                      <div>France</div>
                      <div className="ms-auto text-gray-500">11.14%</div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center text-white mb-0">
                    <div
                      className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px"
                      style={{
                        backgroundImage: "url(/assets/img/flag/jp.jpg)",
                      }}
                    ></div>
                    <div className="d-flex w-100">
                      <div>Japan</div>
                      <div className="ms-auto text-gray-500">10.75%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-6">
            <div className="card border-0 mb-3 bg-gray-900 text-white">
              <div
                className="card-body"
                style={{
                  background: "no-repeat bottom right",
                  backgroundImage: "url(/assets/img/svg/img-4.svg)",
                  backgroundSize: "auto 60%",
                }}
              >
                <div className="mb-3 text-gray-500 ">
                  <b>SALES BY SOCIAL SOURCE</b>
                  <span className="text-gray-500 ms-2">
                    <i className="fa fa-info-circle" id="popover6"></i>
                    <UncontrolledPopover
                      trigger="hover"
                      placement="top"
                      target="popover6"
                    >
                      <PopoverHeader>Sales by social source</PopoverHeader>
                      <PopoverBody>
                        Total online store sales that came from a social
                        referrer source.
                      </PopoverBody>
                    </UncontrolledPopover>
                  </span>
                </div>
                <h3 className="mb-10px">$55,547.89</h3>
                <div className="text-gray-500 mb-1px">
                  <i className="fa fa-caret-up"></i> 45.76% increased
                </div>
              </div>
              <div className="widget-list rounded-bottom dark-mode">
                <Link
                  to="/dashboard"
                  className="widget-list-item rounded-0 pt-3px"
                >
                  <div className="widget-list-media icon">
                    <i className="fab fa-apple bg-indigo text-white"></i>
                  </div>
                  <div className="widget-list-content">
                    <div className="widget-list-title">Apple Store</div>
                  </div>
                  <div className="widget-list-action text-nowrap text-gray-500">
                    $34,840.17
                  </div>
                </Link>
                <Link to="/dashboard" className="widget-list-item">
                  <div className="widget-list-media icon">
                    <i className="fab fa-facebook-f bg-blue text-white"></i>
                  </div>
                  <div className="widget-list-content">
                    <div className="widget-list-title">Facebook</div>
                  </div>
                  <div className="widget-list-action text-nowrap text-gray-500">
                    $12,502.67
                  </div>
                </Link>
                <Link to="/dashboard" className="widget-list-item">
                  <div className="widget-list-media icon">
                    <i className="fab fa-twitter bg-info text-white"></i>
                  </div>
                  <div className="widget-list-content">
                    <div className="widget-list-title">Twitter</div>
                  </div>
                  <div className="widget-list-action text-nowrap text-gray-500">
                    $4,799.20
                  </div>
                </Link>
                <Link to="/dashboard" className="widget-list-item">
                  <div className="widget-list-media icon">
                    <i className="fab fa-google bg-red text-white"></i>
                  </div>
                  <div className="widget-list-content">
                    <div className="widget-list-title">Google Adwords</div>
                  </div>
                  <div className="widget-list-action text-nowrap text-gray-500">
                    $3,405.85
                  </div>
                </Link>
                <Link
                  to="/dashboard"
                  className="widget-list-item pb-3px rounded-bottom"
                >
                  <div className="widget-list-media icon">
                    <i className="fab fa-instagram bg-pink text-white"></i>
                  </div>
                  <div className="widget-list-content">
                    <div className="widget-list-title">Instagram</div>
                  </div>
                  <div className="widget-list-action text-nowrap text-gray-500">
                    $0.00
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6">
            <div className="card border-0 mb-3 bg-gray-800 text-white">
              <div className="card-body">
                <div className="mb-3 text-gray-500">
                  <b>TOP PRODUCTS BY UNITS SOLD</b>
                  <span className="ms-2">
                    <i className="fa fa-info-circle" id="popover7"></i>
                    <UncontrolledPopover
                      trigger="hover"
                      placement="top"
                      target="popover7"
                    >
                      <PopoverHeader>
                        Top products with units sold
                      </PopoverHeader>
                      <PopoverBody>
                        Products with the most individual units sold. Includes
                        orders from all sales channels.
                      </PopoverBody>
                    </UncontrolledPopover>
                  </span>
                </div>
                <div className="d-flex align-items-center mb-15px">
                  <div className="widget-img rounded-3 me-10px bg-white p-3px w-30px">
                    <div
                      className="h-100 w-100"
                      style={{
                        background:
                          "url(/assets/img/product/product-8.jpg) center no-repeat",
                        backgroundSize: "auto 100%",
                      }}
                    ></div>
                  </div>
                  <div className="text-truncate">
                    <div>Apple iPhone XR (2021)</div>
                    <div className="text-gray-500">$799.00</div>
                  </div>
                  <div className="ms-auto text-center">
                    <div className="fs-13px">195</div>
                    <div className="text-gray-500 fs-10px">sold</div>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-15px">
                  <div className="widget-img rounded-3 me-10px bg-white p-3px w-30px">
                    <div
                      className="h-100 w-100"
                      style={{
                        background:
                          "url(/assets/img/product/product-9.jpg) center no-repeat",
                        backgroundSize: "auto 100%",
                      }}
                    ></div>
                  </div>
                  <div className="text-truncate">
                    <div>Apple iPhone XS (2021)</div>
                    <div className="text-gray-500">$1,199.00</div>
                  </div>
                  <div className="ms-auto text-center">
                    <div className="fs-13px">185</div>
                    <div className="text-gray-500 fs-10px">sold</div>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-15px">
                  <div className="widget-img rounded-3 me-10px bg-white p-3px w-30px">
                    <div
                      className="h-100 w-100"
                      style={{
                        background:
                          "url(/assets/img/product/product-10.jpg) center no-repeat",
                        backgroundSize: "auto 100%",
                      }}
                    ></div>
                  </div>
                  <div className="text-truncate">
                    <div>Apple iPhone XS Max (2021)</div>
                    <div className="text-gray-500">$3,399</div>
                  </div>
                  <div className="ms-auto text-center">
                    <div className="fs-13px">129</div>
                    <div className="text-gray-500 fs-10px">sold</div>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-15px">
                  <div className="widget-img rounded-3 me-10px bg-white p-3px w-30px">
                    <div
                      className="h-100 w-100"
                      style={{
                        background:
                          "url(/assets/img/product/product-11.jpg) center no-repeat",
                        backgroundSize: "auto 100%",
                      }}
                    ></div>
                  </div>
                  <div className="text-truncate">
                    <div>Huawei Y5 (2021)</div>
                    <div className="text-gray-500">$99.00</div>
                  </div>
                  <div className="ms-auto text-center">
                    <div className="fs-13px">96</div>
                    <div className="text-gray-500 fs-10px">sold</div>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="widget-img rounded-3 me-10px bg-white p-3px w-30px">
                    <div
                      className="h-100 w-100"
                      style={{
                        background:
                          "url(/assets/img/product/product-12.jpg) center no-repeat",
                        backgroundSize: "auto 100%",
                      }}
                    ></div>
                  </div>
                  <div className="text-truncate">
                    <div>Huawei Nova 4 (2021)</div>
                    <div className="text-gray-500">$499.00</div>
                  </div>
                  <div className="ms-auto text-center">
                    <div className="fs-13px">55</div>
                    <div className="text-gray-500 fs-10px">sold</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6">
            <div className="card border-0 mb-3 bg-gray-800 text-white">
              <div className="card-body">
                <div className="mb-3 text-gray-500">
                  <b>MARKETING CAMPAIGN</b>
                  <span className="ms-2">
                    <i className="fa fa-info-circle" id="popover8"></i>
                    <UncontrolledPopover
                      trigger="hover"
                      placement="top"
                      target="popover8"
                    >
                      <PopoverHeader>Marketing Campaign</PopoverHeader>
                      <PopoverBody>
                        Campaign that run for getting more returning customers.
                      </PopoverBody>
                    </UncontrolledPopover>
                  </span>
                </div>
                <div className="row align-items-center pb-1px">
                  <div className="col-4">
                    <div className="h-100px d-flex align-items-center justify-content-center">
                      <img
                        src="/assets/img/svg/img-2.svg"
                        alt=""
                        className="mw-100 mh-100"
                      />
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="mb-2px text-truncate">
                      Email Marketing Campaign
                    </div>
                    <div className="mb-2px  text-gray-500  fs-11px">
                      Mon 12/6 - Sun 18/6
                    </div>
                    <div className="d-flex align-items-center mb-2px">
                      <div className="flex-grow-1">
                        <div className="progress h-5px rounded-pill bg-white bg-opacity-10">
                          <div
                            className="progress-bar progress-bar-striped bg-indigo"
                            style={{ width: "80%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="ms-2 fs-11px w-30px text-center">80%</div>
                    </div>
                    <div className="text-gray-500 small mb-15px text-truncate">
                      57.5% people click the email
                    </div>
                    <Link
                      to="/dashboard"
                      className="btn btn-xs btn-indigo fs-10px ps-2 pe-2"
                    >
                      View campaign
                    </Link>
                  </div>
                </div>
                <hr className="bg-white-transparent-2 mt-20px mb-20px" />
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="h-100px d-flex align-items-center justify-content-center">
                      <img
                        src="/assets/img/svg/img-3.svg"
                        alt=""
                        className="mw-100 mh-100"
                      />
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="mb-2px text-truncate">
                      Facebook Marketing Campaign
                    </div>
                    <div className="mb-2px  text-gray-500  fs-11px">
                      Sat 10/6 - Sun 18/6
                    </div>
                    <div className="d-flex align-items-center mb-2px">
                      <div className="flex-grow-1">
                        <div className="progress h-5px rounded-pill bg-white bg-opacity-10">
                          <div
                            className="progress-bar progress-bar-striped bg-warning"
                            style={{ width: "60%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="ms-2 fs-11px w-30px text-center">60%</div>
                    </div>
                    <div className="text-gray-500 small mb-15px text-truncate">
                      +124k visitors from facebook
                    </div>
                    <Link
                      to="/dashboard"
                      className="btn btn-xs btn-warning fs-10px ps-2 pe-2"
                    >
                      View campaign
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
              <Modal
                isOpen={this.state.modalReject}
                toggle={() => this.toggleModal("modalReject")}
              >
                <ModalHeader
                  // toggle={() => setmodalAsignment(!modalAsignment)}
                  close={
                    <button
                      className="btn-close"
                      onClick={() => {
                        this.toggleModal("modalReject");
                      }}
                    ></button>
                  }
                >
                  Rechazar Expediente
                </ModalHeader>
                <form>
                  <ModalBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div className="d-flex  mx-auto" style={{ width: "80%" }}>
                        <div className="field m-auto">Comentario</div>
                        <div>
                          <textarea
                            onChange={(e) =>
                              this.setState({
                                comentarioAsignar: e.target.value,
                              })
                            }
                            placeholder="Escribe tu comentario"
                            className="w-300px m-auto p-2"
                            style={{
                              borderColor: "hsl(0, 0%, 80%)",
                              borderRadius: "4px",
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <span className="text-red">
                        {this.state.buttonsError}
                      </span>
                      {/* {this.state.AsignaraCommentError ? (
                    <div className="d-flex mx-auto" style={{ width: "80%" }}>
                      <div className="w-75px field m-auto "></div>
                      <div className="w-300px m-auto">
                        {" "}
                        <span style={{ color: "red" }}>
                          Este campo es necesario
                        </span>{" "}
                      </div>{" "}
                    </div>
                  ) : null} */}
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <button
                      className="btn btn-white"
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleModal("modalReject");
                        this.setState({
                          comentarioAsignar: "",
                        });
                      }}
                    >
                      Cerrar
                    </button>
                    <button
                      onClick={(e) => this.submitReject(e, this.state)}
                      className="btn btn-danger"
                    >
                      Rechazar
                    </button>
                  </ModalFooter>
                </form>
              </Modal>
              <Modal
                isOpen={this.state.modalAccept}
                toggle={() => this.toggleModal("modalAccept")}
              >
                <ModalHeader
                  // toggle={() => setmodalAsignment(!modalAsignment)}
                  close={
                    <button
                      className="btn-close"
                      onClick={() => {
                        this.toggleModal("modalAccept");
                      }}
                    ></button>
                  }
                >
                  Aceptar Expediente
                </ModalHeader>
                <form>
                  <ModalBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div className="d-flex  mx-auto" style={{ width: "80%" }}>
                        <div className="field m-auto">Comentario</div>
                        <div>
                          <textarea
                            onChange={(e) =>
                              this.setState({
                                comentarioAceptar: e.target.value,
                              })
                            }
                            placeholder="Escribe tu comentario"
                            className="w-300px m-auto p-2"
                            style={{
                              borderColor: "hsl(0, 0%, 80%)",
                              borderRadius: "4px",
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <span className="text-red">
                        {this.state.buttonsError}
                      </span>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <button
                      className="btn btn-white"
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleModal("modalAccept");
                        this.setState({
                          comentarioAceptar: "",
                        });
                      }}
                    >
                      Cerrar
                    </button>
                    <button
                      onClick={(e) => this.handleSubmit(e, this.state)}
                      className="btn btn-success"
                    >
                      Aceptar
                    </button>
                  </ModalFooter>
                </form>
              </Modal>
              <Modal
                isOpen={this.state.modalAsignment}
                toggle={() => this.toggleModal("modalAddTask")}
              >
                <ModalHeader
                  // toggle={() => setmodalAsignment(!modalAsignment)}
                  close={
                    <button
                      className="btn-close"
                      onClick={() => {
                        this.toggleModal("modalAddTask");
                      }}
                    ></button>
                  }
                >
                  Asignar Expediente
                </ModalHeader>
                <form onSubmit={this.handleSubmitAsignar}>
                  <ModalBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div className="d-flex mx-auto" style={{ width: "80%" }}>
                        <div className=" field m-auto ">Asignar a</div>
                        <div>
                          <Select
                            className="w-300px m-auto"
                            options={users.map((person) => ({
                              label: person.first_name + person.last_name,
                              value: person.id,
                            }))}
                            placeholder="Selecciona"
                            name="Personas"
                            onChange={(e) =>
                              this.setState({ Asignara: e.value })
                            }
                          />
                        </div>
                      </div>
                      {this.state.AsignaraError ? (
                        <div
                          className="d-flex mx-auto"
                          style={{ width: "80%" }}
                        >
                          <div className="w-75px field m-auto "></div>
                          <div className="w-300px m-auto">
                            {" "}
                            <span style={{ color: "red" }}>
                              Este campo es necesario
                            </span>{" "}
                          </div>{" "}
                        </div>
                      ) : null}

                      <br />

                      <div className="d-flex  mx-auto" style={{ width: "80%" }}>
                        <div className="field m-auto">Comentario</div>
                        <div>
                          <textarea
                            onChange={(e) =>
                              this.setState({
                                comentarioAsignar: e.target.value,
                              })
                            }
                            placeholder="Escribe tu comentario"
                            className="w-300px m-auto p-2"
                            style={{
                              borderColor: "hsl(0, 0%, 80%)",
                              borderRadius: "4px",
                            }}
                          ></textarea>
                        </div>
                      </div>
                      {this.state.AsignaraCommentError ? (
                        <div
                          className="d-flex mx-auto"
                          style={{ width: "80%" }}
                        >
                          <div className="w-75px field m-auto "></div>
                          <div className="w-300px m-auto">
                            {" "}
                            <span style={{ color: "red" }}>
                              {this.state.AsignaraCommentError}
                            </span>{" "}
                          </div>{" "}
                        </div>
                      ) : null}
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <button
                      className="btn btn-white"
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleModal("modalAddTask");
                      }}
                    >
                      Cerrar
                    </button>
                    <button type="submit" className="btn btn-success">
                      Asignar
                    </button>
                  </ModalFooter>
                </form>
              </Modal>
            </div>
          </div>{" "}
          <div className="col-xl-4 col-lg-4">
            <div className="widget-chart-sidebar bg-gray-900 h-100 card">
              <div className="chart-number">
                <h3 className="text-white text-center mt-4">Productividad</h3>
              </div>
              <div className="flex-grow-1 d-flex align-items-center dark-mode justify-content-center">
                <Chart
                  type="donut"
                  height={"230"}
                  options={pieChartOptions}
                  series={pieChartSeries}
                />
              </div>
              <ul className="chart-legend fs-11px">
                <li>
                  <i className="fa fa-circle fa-fw text-teal fs-9px me-5px t-minus-1"></i>
                  <span className="text-white">Asignados</span>
                </li>
                <li>
                  <i className="fa fa-circle fa-fw text-red fs-9px me-5px t-minus-1"></i>
                  <span className="text-white">Rechazados</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permissions: state.counterSlice.permissions,
  AssignmentsDashboard: state.counterSlice.AssignmentsDashboard,
  RejectedsDashboard: state.counterSlice.RejectedsDashboard,
  AcceptedDashboard: state.counterSlice.AcceptedDashboard,
  users: state.counterSlice.users,
  metrics: state.counterSlice.metrics,
});
const mapDispatchToProps = {
  upPermissions,
  getFilesRejected,
  getFilesAssigned,
  getFilesAccepted,
  getUsers,
  getMetrics,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardV2);
