import axios from "axios";
import { decodeJwt } from "jose";
export function CopyRight(inputValue) {
  // Get the text field
  var copyText = document.getElementById(`${inputValue}`);
  
 
  // Select the text field
  //   copyText.select();
  //   copyText.setSelectionRange(0, 99999); // For mobile devices

  // Copy the text inside the text field
  if (copyText) navigator.clipboard.writeText(copyText.title);

  // Alert the copied text
}
export function pension_type(number) {
  switch (number) {
    case 10:
      return "Vejez";
    case 20:
      return "Inválidez";
    case 30:
      return "Indemnización";
    case 40:
      return "Sobreviviente";
    default:
      break;
  }
}
export function count_type(number) {
  switch (number) {
    case 10:
      return "360";
    case 20:
      return "365";

    default:
      break;
  }
}
export function useCompareIngreso(a, b) {
  if (a.start_date == b.start_date) {
    if (a.end_date < b.end_date) return -1;
    if (a.end_date > b.end_date) return 1;
  } else {
    if (a.start_date < b.start_date) return -1;
    if (a.start_date > b.start_date) return 1;
  }

  return 0;
}
export function contribution_type(number) {
  switch (number) {
    case 10:
      return "Privado";
    case 20:
      return "Fondo";
    case 30:
      return "Público no cajas";
    case 40:
      return "público cajas";
    default:
      return "no tiene"
      
  }
}
export const optionsStatus = [
  {
    value: 10,
    label: "Abierto",
  },

  {
    value: 30,
    label: "Pendiente",
  },
  {
    value: 50,
    label: "Asignado",
  },

  {
    value: 40,
    label: "Rechazado",
  },

  {
    value: 90,
    label: "No asignado",
  },
];
export function classesStatus(number) {
  switch (number) {
    case 10:
      return "badge border border-warning text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center";
    case 20:
      return "badge border border-black text-black px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center";
    case 30:
      return "badge border border-purple text-purple px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center";
    case 40:
      return "badge border border-red text-red px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center";
    case 50:
      return "badge border border-blue text-blue px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center";
    case 60:
      return "badge border border-green text-green px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center";
    case 70:
      return "badge border border-yellow text-yellow px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center";
      case 80:
      return "badge border border-warning text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center";
    case 90:
      return "badge border border-gray text-gray px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center";

    default:
      break;
  }

}
export function status(number) {
  switch (number) {
    case 10:
      return "Abierto";
    case 20:
      return "Archivado";
    case 30:
      return "Pendiente";
    case 40:
      return "Rechazado";
    case 50:
      return "Asignado";
    case 60:
      return "Aceptado";
    case 70:
      return "Comentado";
      case 80:
      return "Re-abierto";
    case 90:
      return "No asignado";

    default:
      break;
  }
}
export const AxiosURL = `https://beta.siep.restrepofajardo.com/api/v1/`;
export const AxiosURLImage = `https://beta.siep.restrepofajardo.com`;
export const instance = axios.create({
  baseURL: AxiosURL,
  params: {
    t: new Date().getTime(),
  },
});
instance.interceptors.request.use(async function (response) {
  let refresh = "users/auth/login/refresh";
  if (localStorage.my_code) {
    const { exp } = decodeJwt(localStorage.my_code);
    
    // var tiempoexp = exp * 1000 - 3580000;

    var tiempoexp = exp * 1000;
    var tiempoahora = Date.now();
    // console.log(tiempoahora - tiempoexp);
    // console.log("refresh", localStorage.refresh);
    // console.log("accces", localStorage.my_code);
    if (tiempoahora >= tiempoexp) {
      var formData = new FormData();
      formData.append("refresh", localStorage.refresh);
      await axios({
        method: "post",
        contentType: "application/json",
        url: `${AxiosURL}${refresh}`,
        data: formData,
      })
        .then((res) => {
          const data = res.data;
          // console.log("datarararefreshokoko---------------", data);
          if (data) {
            localStorage.my_code = data.access;
            localStorage.refresh = data.refresh;
          }
        })
        .catch((err) => {
        
          window.location.reload();
          localStorage.clear();
        });
      response.params["t"] = new Date().getTime();
      response.headers["Authorization"] = "Bearer " + localStorage.my_code;
      return response;
    } else {
      response.params["t"] = new Date().getTime();
      response.headers["Authorization"] = "Bearer " + localStorage.my_code;
      return response;
    }
  } else {
    window.location.href ="/";
    localStorage.clear();
    // response.params["t"] = new Date().getTime();
    // response.headers["Authorization"] = "Bearer " + localStorage.my_code;
    // return response;
  }
});

export function useCompareId(a, b) {
  if (parseInt(a.number) < parseInt(b.number)) return -1;
  if (parseInt(a.number) > parseInt(b.number)) return 1;
  return 0;
}

// funtion permision success
export function successPermissions(path,pathTwo){
	if(localStorage.permissions){
	const permisos =JSON.parse(localStorage?.permissions);
	const permisosArray = Object.keys(permisos);

  if (permisos?.is_superuser === false) {
    if (
      path &&
      permisosArray.includes("full_access_entity") &&
      path == "full_access_entity"
    ) {
      return "";
    } else if (
      path &&
      permisosArray.includes("can_archive_files") &&
      path == "can_archive_files"
    ) {
      return "";
    } else if (
      (path &&
        permisosArray.includes("full_access_counts") &&
        path == "full_access_counts") ||
      (pathTwo &&
        permisosArray.includes("can_view_counts_detail") &&
        pathTwo == "can_view_counts_detail")
    ) {
      return "";
    } else if (
      path &&
      permisosArray.includes("can_view_files_detail") &&
      path == "can_view_files_detail"
    ) {
      return "";
    } else if (
      (path &&
        permisosArray.includes("full_access_people") &&
        path == "full_access_people") ||
      (pathTwo &&
        permisosArray.includes("can_view_customers") &&
        pathTwo == "can_view_customers") ||
      (pathTwo &&
        permisosArray.includes("can_view_freelancers") &&
        pathTwo == "can_view_freelancers") ||
      (pathTwo &&
        permisosArray.includes("can_view_staff") &&
        pathTwo == "can_view_staff")
    ) {
      return "";
    } else if (
      (path &&
        permisosArray.includes("can_view_archive_files") &&
        path == "can_view_archive_files") ||
      (path &&
        permisosArray.includes("can_set_file_status_to_reopen") &&
        path == "can_set_file_status_to_reopen")
    ) {
      return "";
    } else if (
      path &&
      permisosArray.includes("can_export_archive_excel_files") &&
      path == "can_export_archive_excel_files"
    ) {
      return "";
    } else if (
      (path &&
        permisosArray.includes("can_view_archive_files") &&
        path == "can_view_archive_files") ||
      (pathTwo &&
        permisosArray.includes("can_set_file_status_to_reopen") &&
        pathTwo == "can_set_file_status_to_reopen")
    ) {
      return "";
    } else if (
      (permisosArray.includes("can_read_all_files") &&
        path == "can_read_all_files") ||
      (permisosArray.includes("can_create_file_assignment") &&
        path == "can_create_file_assignment")
    ) {
      return "";
    } else if (
      (permisosArray.includes("can_decline_file_assignment") &&
        path == "can_decline_file_assignment") ||
      (permisosArray.includes("can_accept_file_assignment") &&
        path == "can_accept_file_assignment")
    ) {
      return "";
    } else if (
      (permisosArray.includes("can_export_open_excel_files") &&
        path == "can_export_open_excel_files") ||
      (permisosArray.includes("can_write_files") && path == "can_write_files")
    ) {
      return "";
    } else if (
      (permisosArray.includes("can_write_counts") &&
        path == "can_write_counts") ||
      (permisosArray.includes("can_read_people") && path == "can_read_people")
    ) {
      return "";
    } else return "d-none";
  } else return "";
}else return "d-none";
}
