import axios from "axios";
import React from "react";
import { Link, Redirect } from "react-router-dom";
import { AxiosURL, instance } from "../../utils/index.js";
import { AppSettings } from "./../../config/app-settings.js";

class UpdateData extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      redirect: false,
      textoCorreo:""
    };
  }

  componentDidMount() {
    this.context.handleSetAppSidebarNone(true);
    this.context.handleSetAppHeaderNone(true);
    this.context.handleSetAppContentClass("p-0");
  }

  componentWillUnmount() {
    this.context.handleSetAppSidebarNone(false);
    this.context.handleSetAppHeaderNone(false);
    this.context.handleSetAppContentClass("");
  }

  handleSubmit(event) {
    event.preventDefault();
    var bodyFormData = new FormData();
    
    const value= event.target[0].value
    bodyFormData.append("email", value);
    instance
    .patch(`${AxiosURL}users/current/${localStorage.userId}`,bodyFormData)
    .then((res) => {
      if (res.data || res.status === 200 || res.status === 202) {
        
    
        this.setState((state) => ({
          textoCorreo: "Enviado con éxito",
        }));
        setTimeout(() => {
          this.setState((state) => ({
            textoCorreo: "",
          }));
        },3000)
      }
    })
    .catch((e) => {
      if(e.response){
        
        this.setState((state) => ({
          textoCorreo: e.response.data,
        }));

        setTimeout(() => {
          this.setState((state) => ({
            textoCorreo: "",
          }));
        },3000)
      }
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <div className="register register-with-news-feed">
        <div className="news-feed">
          <div className="news-image_register"></div>
          <div className="news-caption">
            <h4 className="caption-title">
              <b>Restrepo Fajardo </b> abogados
            </h4>
            <p>
              El éxito en la abogacía no es medido por el número de casos
              ganados, sino por la satisfacción del cliente.
            </p>
          </div>
        </div>
        <div className="register-container">
          <div className="register-header mb-25px h1">
            <div className="mb-1">Actualizar datos</div>
            <small className="d-block fs-15px lh-16">
              Aquí podrás ingresar tu correo para actualizarlo.
            </small>
          </div>
          <div className="register-content">
            <form onSubmit={this.handleSubmit} className="fs-13px">
              <div className="mb-3">
                <label className="mb-2">
                  Correo electrónico <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  value={this.state.emailText}
                  onChange={(e) => {
                    this.setState((state) => ({
                      emailText: e.target.value,
                    }));
                  }}
                  className="form-control fs-13px"
                  placeholder="Ingresar email"
                />
              </div>

              <div className="mb-4">
                <button
                  type="submit"
                  className="btn btn-primary d-block w-100 btn-lg h-45px fs-13px"
                >
                  Actualizar
                </button>
              </div>
              <span
                className={
                  this.state.textoCorreo == "Enviado con éxito"
                    ? "text-green"
                    : "text-red"
                }
              >
                {this.state.textoCorreo}
              </span>
              <div className="mb-4 pb-5">
                Dar click <Link to="/user/login">aquí</Link> para ingresar.
              </div>
              <hr className="bg-gray-600 opacity-2" />
              <p className="text-center text-gray-600">
                &copy; All Right Reserved 2023
              </p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default UpdateData;
