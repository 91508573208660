import React from "react";
import { Link, Redirect } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Collapse, CardHeader, CardBody, Card } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AppSettings } from "./../../config/app-settings.js";
import Select from "react-select";
import {
    deleteCountId,
  removeFilesOpen,
  getFilesId,
  getUsers,
  getFileRoadmap,
} from "../../actions/index.js";
import SweetAlert from "react-bootstrap-sweetalert";

import { connect } from "react-redux";
import {
  count_type,
  instance,
  pension_type,
  successPermissions,
} from "../../utils/index.js";
import Moment from "moment";
class SidebarRighCount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapse: [{ id: "Datos", collapse: true }],
      // path: window.location.pathname.replace("/Expediente/", ""),
      sweetAlertPrimary: false,
      sweetAlertInfo: false,
      sweetAlertWarning: false,
      sweetAlertSuccess: false,
      sweetAlertError: false,
      redirect: false,
      redirectCountEdit: false,
      modalAsignment: false,

      AsignaraError: false,
      AsignaraCommentError: false,
      Asignara: "",
      comentarioAsignar: "",
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentWillMount() {
    const { getUsers } = this.props;

    getUsers();
  }

  toggleSweetAlert(name) {
    switch (name) {
      case "primary":
        this.setState({ sweetAlertPrimary: !this.state.sweetAlertPrimary });
        break;
      case "info":
        this.setState({ sweetAlertInfo: !this.state.sweetAlertInfo });
        break;
      case "success":
        this.setState({ sweetAlertSuccess: !this.state.sweetAlertSuccess });
        break;
      case "warning":
        this.setState({ sweetAlertWarning: !this.state.sweetAlertWarning });
        break;
      case "error":
        this.setState({ sweetAlertError: !this.state.sweetAlertError });
        break;
      default:
        break;
    }
  }
  toggleCollapse(index) {
    var newArray = [];
    for (let collapseObj of this.state.collapse) {
      if (collapseObj.id === index) {
        collapseObj.collapse = !collapseObj.collapse;
      } else {
        collapseObj.collapse = false;
      }
      newArray.push(collapseObj);
    }

    this.setState({
      collapse: newArray,
    });
  }
  handleSubmit() {
    this.setState((state) => ({
      redirect: true,
    }));
  }
  toggleModal(name) {
    switch (name) {
      case "modalAddTask":
        this.setState({ modalAsignment: !this.state.modalAsignment });
        break;
      case "modalReject":
        this.setState({ modalReject: !this.state.modalReject });
        break;
      default:
        break;
    }
  }

  handleRedirectEdit() {
    this.setState((state) => ({
      redirectCountEdit: true,
    }));
  }

  render() {
    const { deleteCountId, users, countId } = this.props;

    if (this.state.redirect) {
      return <Redirect to="/Conteos" />;
    }

    if (this.state.redirectCountEdit) {
      if (localStorage.inputs_counts) {
        localStorage.removeItem("inputs_counts");
      }
      return <Redirect to={`/EditarConteo/${countId?.id}`} />;
    }

    const actions = [
      successPermissions("full_access_counts") == "d-none"
        ? null
        : [
            "Editar conteo",
            <i className="fas fa-lg fa-fw me-10px fa-calculator"></i>,
          ],

      successPermissions("full_access_count_buttons_options") == "d-none"
        ? null
        : ["Borrar", <i className="fas fa-lg fa-fw me-10px fa-trash-can"></i>],
    ];
    return (
      <AppSettings.Consumer>
        {({ appSidebarTwo_count, toggleAppSidebarEndMobile }) => (
          <React.Fragment>
            {appSidebarTwo_count && (
              <React.Fragment>
                <div id="sidebar-right" className="app-sidebar app-sidebar-end">
                  <PerfectScrollbar
                    className="app-sidebar-content h-100"
                    options={{ suppressScrollX: true }}
                  >
                    <div
                      className="p-20px text-white d-flex "
                      style={{
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      {actions
                        .filter((e) => e)
                        .map((e) => (
                          <div
                            className="btn  btn-primary my-1"
                            onClick={() => {
                              if (e[0] == "Borrar") {
                                this.toggleSweetAlert("error");
                              } else if (e[0] == "Editar conteo") {
                                if (localStorage.inputs_counts) {
                                  localStorage.removeItem("inputs_counts");
                                }
                                this.handleRedirectEdit();
                              }
                            }}
                          >
                            <span
                              className="d-flex align-items-center text-center"
                              style={{
                                justifyContent: "center",
                              }}
                            >
                              {e[1]}
                              <span>
                                <span className="d-flex">
                                  <b>{e[0]}</b>
                                </span>
                              </span>
                            </span>
                          </div>
                        ))}
                      <br />

                      <div
                        id="accordion"
                        className="accordion rounded overflow-hidden"
                      >
                        {this.state.collapse.map((value, i) => (
                          <Card
                            className="bg-gray-700 text-white border-0 rounded-0 cursor-pointer"
                            key={i}
                          >
                            <CardHeader
                              className={
                                "card-header bg-gray-900 text-white pointer-cursor border-0 d-flex align-items-center rounded-0 " +
                                (!value.collapse ? "collapsed " : "")
                              }
                              onClick={() => this.toggleCollapse(value.id)}
                            >
                              <i className="fa fa-circle text-teal fs-6px me-2"></i>{" "}
                              {value.id}
                            </CardHeader>
                            {value.id == "Acciones" ? (
                              <Collapse isOpen={value.collapse}>
                                <CardBody className="p-3">Hola</CardBody>
                              </Collapse>
                            ) : (
                              <Collapse isOpen={value.collapse}>
                                <CardBody className="p-3">
                                  <strong> Expediente</strong>
                                  <br />
                                  {successPermissions(
                                    "can_view_files_detail"
                                  ) == "d-none" ? (
                                    <strong> {countId?.file?.number}</strong>
                                  ) : (
                                    <Link
                                      to={`/Expediente/${countId?.file?.id}`}
                                    >
                                      {" "}
                                      {countId?.file?.number}{" "}
                                    </Link>
                                  )}
                                  <br />
                                  <strong> Nombre Cliente</strong>
                                  <br />
                                  {countId?.name} <br />
                                  <strong> Identificación</strong>
                                  <br />
                                  {countId?.document} <br />{" "}
                                  <strong>Fecha de Nacimiento</strong>
                                  <br /> {countId?.birth}
                                  <br />
                                  <strong>Tipo de Pensión</strong>
                                  <br />{" "}
                                  {countId.pension_type
                                    ? pension_type(countId.pension_type)
                                    : null}
                                  <br />
                                  <strong>Tipo de Conteo</strong>
                                  <br />{" "}
                                  {countId.count_type
                                    ? count_type(countId.count_type) + " Días"
                                    : null}
                                  <br />
                                  <strong>Responsable</strong>
                                  <br /> {countId?.user?.full_name}
                                  <br />
                                  <strong>Modificado</strong>
                                  <br />{" "}
                                  {Moment(countId?.updated_at).format(
                                    "YYYY-MM-DD HH:mm"
                                  )}
                                  {/* <br />
                                  <Link to="/ExpedienteNuevo">
                                    Ver mas{" "}
                                    <i className="fas fa-lg fa-fw me-10px fa-circle-plus"></i>{" "}
                                  </Link> */}
                                </CardBody>
                              </Collapse>
                            )}
                          </Card>
                        ))}
                      </div>
                    </div>
                  </PerfectScrollbar>
                </div>
                <Modal
                  isOpen={this.state.modalAsignment}
                  toggle={() => this.toggleModal("modalAddTask")}
                >
                  <ModalHeader
                    // toggle={() => setmodalAsignment(!modalAsignment)}
                    close={
                      <button
                        className="btn-close"
                        onClick={() => {
                          this.toggleModal("modalAddTask");
                        }}
                      ></button>
                    }
                  >
                    Asignar Expediente
                  </ModalHeader>
                  <form onSubmit={this.handleSubmit}>
                    <ModalBody>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          className="d-flex mx-auto"
                          style={{ width: "80%" }}
                        >
                          <div className=" field m-auto ">Asignar a</div>
                          <div>
                            <Select
                              className="w-300px m-auto"
                              options={users.map((person) => ({
                                label: person.first_name + person.last_name,
                                value: person.id,
                              }))}
                              placeholder="Selecciona"
                              name="Personas"
                              onChange={(e) =>
                                this.setState({ Asignara: e.value })
                              }
                            />
                          </div>
                        </div>
                        {this.state.AsignaraError ? (
                          <div
                            className="d-flex mx-auto"
                            style={{ width: "80%" }}
                          >
                            <div className="w-75px field m-auto "></div>
                            <div className="w-300px m-auto">
                              {" "}
                              <span style={{ color: "red" }}>
                                Este campo es necesario
                              </span>{" "}
                            </div>{" "}
                          </div>
                        ) : null}

                        <br />

                        <div
                          className="d-flex  mx-auto"
                          style={{ width: "80%" }}
                        >
                          <div className="field m-auto">Comentario</div>
                          <div>
                            <textarea
                              onChange={(e) =>
                                this.setState({
                                  comentarioAsignar: e.target.value,
                                })
                              }
                              placeholder="Escribe tu comentario"
                              className="w-300px m-auto p-2"
                              style={{
                                borderColor: "hsl(0, 0%, 80%)",
                                borderRadius: "4px",
                              }}
                            ></textarea>
                          </div>
                        </div>
                        {this.state.AsignaraCommentError ? (
                          <div
                            className="d-flex mx-auto"
                            style={{ width: "80%" }}
                          >
                            <div className="w-75px field m-auto "></div>
                            <div className="w-300px m-auto">
                              {" "}
                              <span style={{ color: "red" }}>
                                Este campo es necesario
                              </span>{" "}
                            </div>{" "}
                          </div>
                        ) : null}
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <button
                        className="btn btn-white"
                        onClick={() => {
                          this.toggleModal("modalAddTask");
                        }}
                      >
                        Cerrar
                      </button>
                      <button type="submit" className="btn btn-success">
                        Asignar
                      </button>
                    </ModalFooter>
                  </form>
                </Modal>
                <div className="app-sidebar-bg app-sidebar-end"></div>
                <div className="app-sidebar-mobile-backdrop app-sidebar-end">
                  <Link
                    to="/"
                    onClick={toggleAppSidebarEndMobile}
                    className="stretched-link"
                  ></Link>
                </div>
                {this.state.sweetAlertError && (
                  <SweetAlert
                    danger
                    showCancel
                    confirmBtnText="Sí,borrar!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    cancelBtnText="Cancelar"
                    title="Estás seguro de borrar?"
                    onConfirm={() => {
                      deleteCountId(countId.id);
                      this.handleSubmit();
                    }}
                    onCancel={() => this.toggleSweetAlert("error")}
                  >
                    el conteo # {countId.id}
                  </SweetAlert>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </AppSettings.Consumer>
    );
  }
}
const mapStateToProps = (state) => ({
  filesLoaded: state.counterSlice.filesLoaded,
  fileId: state.counterSlice.fileId,
  users: state.counterSlice.users,
  countId: state.counterSlice.countId,
});
const mapDispatchToProps = {
    deleteCountId,
  removeFilesOpen,
  getFilesId,
  getUsers,
  getFileRoadmap,
};

// bind our actions to this.props

export default connect(mapStateToProps, mapDispatchToProps)(SidebarRighCount);
