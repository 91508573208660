import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import imagenProfile from "../../assets/img/user/user-13.jpg";
import { Modal, ModalHeader, ModalBody, ModalFooter, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown, Dropdown  } from "reactstrap";
import { Store, ReactNotifications } from "react-notifications-component";
import PerfectScrollbar from "react-perfect-scrollbar";

import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import { AppSettings } from "./../../config/app-settings.js";
import SweetAlert from "react-bootstrap-sweetalert";
import person from "../../assets/img/user/user-13.jpg";
import { status, useCompareId } from "../../utils/index.js";
import Moment from "moment";
import { instance } from "../../utils/index.js";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import {
  deleteFilesId,
  removeFilesOpen,
  getFilesArchiveId,
  getFileRoadmap,
  getUsers,
} from "../../actions/index.js";
import DateRangePicker from "react-bootstrap-daterangepicker";

class ExpedienteArchivado extends React.Component {
  static contextType = AppSettings;
  constructor(props) {
    super(props);
    this.state = {
      path: props.match.params.id,
      actual_date: Moment().format("L"),
      before_date: Moment().subtract(1, "days").format("L"),
      sweetAlertPrimary: false,
      sweetAlertInfo: false,
      sweetAlertWarning: false,
      sweetAlertSuccess: false,
      sweetAlertError: false,
      modalAsignment: false,
      modalReject: false,
      AsignaraError: false,
      AsignaraCommentError: false,
      Asignara: "",
      ido: "",
      comentarioAsignar: "",
      comentarioFacebook: "",
      toggleCalendar: false,
      timer: null,

      startDate: Moment().subtract(7, "days"),
      endDate: Moment(),
      currentWeek:
        Moment().subtract("days", 7).format("D MMMM YYYY") +
        " - " +
        Moment().format("D MMMM YYYY"),
      prevWeek:
        Moment().subtract("days", 15).format("D MMMM") +
        " - " +
        Moment().subtract("days", 8).format("D MMMM YYYY"),
    };

    this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitComentarioFace = this.submitComentarioFace.bind(this);
    this.addNotification = this.addNotification.bind(this);
    this.submitReject = this.submitReject.bind(this);
  }
  addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <img src={imagenProfile} width="52" alt="" className="rounded" />
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }
  componentWillMount() {
    const { getUsers } = this.props;
    getUsers();
  }
  componentWillMountedverdad(personaId) {
    const { getFilesArchiveId, getFileRoadmap } = this.props;
    if (personaId != this.state.ido) {
      this.setState((state) => ({
        ido: personaId,
      }));

      getFilesArchiveId(personaId);
      getFileRoadmap(personaId);
    }
  }

  toggleModal(name) {
    switch (name) {
      case "modalAddTask":
        this.setState({ modalAsignment: !this.state.modalAsignment });
        break;
      case "modalReject":
        this.setState({ modalReject: !this.state.modalReject });
        break;
      default:
        break;
    }
  }

  toggleSweetAlert(name) {
    switch (name) {
      case "primary":
        this.setState({ sweetAlertPrimary: !this.state.sweetAlertPrimary });
        break;
      case "info":
        this.setState({ sweetAlertInfo: !this.state.sweetAlertInfo });
        break;
      case "success":
        this.setState({ sweetAlertSuccess: !this.state.sweetAlertSuccess });
        break;
      case "warning":
        this.setState({ sweetAlertWarning: !this.state.sweetAlertWarning });
        break;
      case "error":
        this.setState({ sweetAlertError: !this.state.sweetAlertError });
        break;
      default:
        break;
    }
  }

  submitComentarioFace(event) {
    event.target.reset();
    event.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.append("parent", event.target.id);
    bodyFormData.append("comment", this.state.comentarioFacebook);
    instance
      .post(`files/${this.state.path}/comment`, bodyFormData)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          this.setState({
            comentarioFacebook: "",
          });

          this.props.getFileRoadmap(this.state.path);
        }
      })
      .catch((e) => console.log("Error in asignament", e));
  }
  borrar(e) {
    this.props.removeFilesOpen(e);
  }
  submitReject(event) {
    event.preventDefault();
    if (this.state.comentarioAsignar === "") {
      this.setState({ AsignaraCommentError: !this.state.AsignaraCommentError });
      setTimeout(() => {
        this.setState({
          AsignaraCommentError: !this.state.AsignaraCommentError,
        });
      }, 1000);
    }
    var bodyFormData = new FormData();
    bodyFormData.append("comment", this.state.comentarioAsignar);
    instance
      .post(`files/${this.state.path}/assign`, bodyFormData)
      .then((res) => {
        if (res.status === 201) {
          this.setState({
            Asignara: "",
            comentarioAsignar: "",
          });
          this.toggleModal("modalAddTask");
          this.props.getFileRoadmap(this.state.path);
        }
      })
      .catch((e) => console.log("Error in asignament", e));
  }

  handleDelete() {
    this.setState((state) => ({
      redirect: true,
    }));
  }
  handleSubmit() {
    instance
      .post(`files/${this.state.path}/accept`)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          this.addNotification(
            "default",
            "",
            `Expediente # ${this.state.path} copiado`,
            "bottom-center"
          );
          this.props.getFileRoadmap(this.state.path);
        }
      })
      .catch((e) => console.log("Error in asignament", e));
  }
  componentDidMount() {
    this.context.handleSetAppSidebarTwo_archivado(true);
  }

  componentWillUnmount() {
    this.context.handleSetAppSidebarTwo_archivado(false);
  }

  upPlease() {
    this.setState((state) => ({
      toggleCalendar: false,
    }));
  }
  render() {
    const { fileIdArchive, roadmap, deleteFilesId } = this.props;
    const { toggleCalendar } = this.state;
    console.log(fileIdArchive);

    this.componentWillMountedverdad(this.props.match.params.id);
    if (this.state.redirect) {
      return <Redirect to="/Archivados" />;
    }

    this.handleDateApplyEvent = (event, picker) => {
      var startDate = picker.startDate;
      var endDate = picker.endDate;
      var gap = endDate.diff(startDate, "days");

      var currentWeek =
        startDate.format("D MMMM YYYY") + " - " + endDate.format("D MMMM YYYY");
      var prevWeek =
        Moment(startDate).subtract("days", gap).format("D MMMM") +
        " - " +
        Moment(startDate).subtract("days", 1).format("D MMMM YYYY");
      inputChanged(
        "",
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")
      );
      this.setState((state) => ({
        startDate: startDate,
        endDate: endDate,
        currentWeek: currentWeek,
        prevWeek: prevWeek,
        toggleCalendar: !this.state.toggleCalendar,
      }));
    };

    const inputChanged = (e, startDate, endDate) => {
      if ((e != "" && e != " " && e.length > 0) || (startDate && endDate)) {
        const valueSearch = e.toLowerCase();
        clearTimeout(this.state.timer);
        const newTimer = setTimeout(() => {
          const idfind = roadmap.filter(
            (element) =>
              (valueSearch.length > 0 &&
                element.comment.toLowerCase().includes(valueSearch)) ||
              (valueSearch.length > 0 &&
                element.receiver?.full_name
                  .toLowerCase()
                  .includes(valueSearch)) ||
              (valueSearch.length > 0 &&
                element.sender?.full_name
                  .toLowerCase()
                  .includes(valueSearch)) ||
              (element?.created_at.slice(0, 10) >= startDate &&
                element?.created_at.slice(0, 10) <= endDate)
          );
          if (idfind.length > 0) {
            const element = document.getElementById(`M${idfind[0].id}`);
            const colorOn = element.querySelector(`.timeline-icon a`);
            colorOn.classList.add("backgroundTimeLine");
            element.scrollIntoView({ behavior: "auto" });
          }
        }, 0);
        this.setState((state) => ({
          timer: newTimer,
        }));
      }
      if (e != "" && e != " " && e.length > 0 && startDate && endDate) {
        const valueSearch = e.toLowerCase();
        clearTimeout(this.state.timer);
        const newTimer = setTimeout(() => {
          const idfind = roadmap.filter(
            (element) =>
              (valueSearch.length > 0 &&
                element.comment.toLowerCase().includes(valueSearch)) ||
              (valueSearch.length > 0 &&
                element.receiver?.full_name
                  .toLowerCase()
                  .includes(valueSearch)) ||
              (valueSearch.length > 0 &&
                element.sender?.full_name.toLowerCase().includes(valueSearch) &&
                element?.created_at.slice(0, 10) >= startDate &&
                element?.created_at.slice(0, 10) <= endDate)
          );
          if (idfind.length > 0) {
            const element = document.getElementById(`M${idfind[0].id}`);
            const colorOn = element.querySelector(`.timeline-icon a`);
            colorOn.classList.add("backgroundTimeLine");
            element.scrollIntoView({ behavior: "auto" });
          }
        }, 0);
        this.setState((state) => ({
          timer: newTimer,
        }));
      } else {
        let padreHilos = document.getElementById("perfectScrollBar");
        let hilos = padreHilos.querySelectorAll(
          ".timeline .timeline-item .timeline-icon a"
        );
        hilos.forEach((e) => {
          let valueInterno = e;
          while (valueInterno.classList.contains("backgroundTimeLine")) {
            valueInterno.classList.remove("backgroundTimeLine");
          }
        });
        padreHilos.scrollIntoView(true);
      }
    };
    // tener en cuenta para el otro
    // window.addEventListener('click', (e)=> {
    //   // console.log(document.querySelector(".daterangepicker .drp-calendar .calendar-table .table-condensed .prev"),e.target.classList)

    //   if (!document.querySelector(".dropDownChange").contains(e.target) && !e.target.classList.contains("prev") && !e.target.classList.contains("next") ) {
    //     console.log(toggleCalendar)
    //      if(toggleCalendar) this.upPlease()
    //   }
    // })
    const opciones = {
      locale: {
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
      },
    };
    return (
      <div>
        <ol className="breadcrumb float-xl-end">
          <li className="breadcrumb-item">
            <Link to="/Expedientes">Expedientes</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`#`}>Hoja de ruta</Link>
          </li>
        </ol>
        <h1 className="page-header">
          Expediente # {fileIdArchive.number}
          <small></small>
        </h1>
        <Panel>
          <PanelHeader>Expediente # {fileIdArchive.number}</PanelHeader>
          <PanelBody>
            <div className="invoice-company">
              <span className="float-end hidden-print d-flex"></span>
              <div className="d-block d-flex flex-column flex-md-row align-items-center">
                <form id="formsearchTimeline" name="search_form">
                  <div className="form-group position-relative mx-3 width_seach_type w-sm-50 pe-4 d-flex">
                    {" "}
                    <button
                      className="btn btn-search position-absolute border-0"
                      disabled={true}
                    >
                      <i className="fa fa-search me-20px icon-search-action"></i>
                    </button>{" "}
                    <input
                      type="text"
                      id="inputTextSearch"
                      className="form-control px-5"
                      placeholder="Buscar..."
                      onChange={(e) => inputChanged(e.target.value, null, null)}
                    />
                  </div>
                </form>
                <div
                  style={{ padding: "0.625rem" }}
                  className="calendarExpedientes"
                >
                  <DateRangePicker
                    startDate={this.startDate}
                    endDate={this.endDate}
                    onApply={this.handleDateApplyEvent}
                    initialSettings={opciones}
                  >
                    <input type="text" className="form-control  col-4" />
                  </DateRangePicker>
                </div>

                {fileIdArchive.archived_by ? (
                  <div className="ms-md-auto ms-lg-auto">
                    <strong>Archivado por:</strong>{" "}
                    {fileIdArchive.archived_by.full_name}
                  </div>
                ) : null}
                {/* <Dropdown className="dropDownChange" isOpen={this.state.toggleCalendar}
               toggle={()=>{ 
          this.setState((state) => ({
          toggleCalendar:true,
          }))}
        }>
              <DropdownToggle
            
                color="default"
                className="dropdown-toggle "
                style={{
                  borderColor: "#adb5bd",
                  color: "#ffff",
                  background: "transparent"
                }}
              >
                <i className="fa fa-list text-gray fa-fw fa-lg "></i>
              </DropdownToggle>
              <DropdownMenu>
            
             <div style={{padding: '0.625rem'}} className="calendarExpedientes">
             <td className="field">Rango de fechas</td>
             <DateRangePicker 
            startDate={this.startDate}
            endDate={this.endDate}
            onApply={this.handleDateApplyEvent 
            }
          >
            <button className="btn btn-inverse me-2 text-truncate">
              <i className="fa fa-calendar fa-fw text-white text-opacity-50 ms-n1 me-1"></i>
              <span>{this.state.currentWeek}</span>
              <b className="caret ms-1 opacity-5"></b>
            </button>
          </DateRangePicker></div>
              </DropdownMenu>
            </Dropdown> */}
              </div>
            </div>
          </PanelBody>
          <PerfectScrollbar
            id="perfectScrollBar"
            className="hljs-wrapper"
            style={{ height: "calc(100vh - 280px)" }}
            options={{ suppressScrollX: true }}
          >
            {roadmap &&
              roadmap.map((e, index) => (
                <div key={e.id} id={"M" + e.id} className="timeline">
                  <div className="timeline-item">
                    <div className="timeline-time">
                      <span className="date">
                        {this.state["actual_date"] ===
                        Moment(e["created_at"]).format("L")
                          ? "Hoy"
                          : this.state["before_date"] ===
                            Moment(e["created_at"]).format("L")
                          ? "Ayer"
                          : Moment(e["created_at"]).format("L")}
                      </span>
                      <span className="time">
                        {Moment(e["created_at"]).format("LT")}
                      </span>
                    </div>
                    <div className="timeline-icon">
                      <Link to="#">&nbsp;</Link>
                    </div>
                    <div
                      className="timeline-content"
                      style={{ maxWidth: "560px" }}
                    >
                      <div className="timeline-header">
                        {e.status != 60 ? (
                          <div className="userimage">
                            <img alt="imgperson" src={person} />
                          </div>
                        ) : (
                          <div
                            className="userimage"
                            style={{
                              display: "flex",
                              color: "white",
                              flexDirection: "column",
                              background: "var(--bs-body-color)",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>{e.sender.full_name.slice(0, 1)}</span>
                          </div>
                        )}
                        <div className="username">
                          <Link to="#">{e.sender.full_name} </Link>
                          <div className="text-muted fs-12px">
                            8 mins{" "}
                            <i className="fa fa-globe-americas opacity-5 ms-1"></i>
                          </div>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <span
                            className={
                              e.status === 40
                                ? "badge border border-red text-red px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
                                : e.status === 60
                                ? "badge border border-green text-green px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
                                : "badge border border-blue text-blue px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
                            }
                          >
                            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>{" "}
                            {status(e.status)}
                          </span>{" "}
                          <br />
                          <div className="my-1 fw-bold">
                            {e.receiver && e.receiver.full_name
                              ? e.receiver.full_name
                              : null}
                          </div>
                          <div className="dropdown-menu dropdown-menu-end">
                            <Link
                              to="#"
                              className="dropdown-item d-flex align-items-center"
                            >
                              <i className="fa fa-fw fa-bookmark fa-lg"></i>
                              <div className="flex-1 ps-1">
                                <div>Save Post</div>
                                <div className="mt-n1 text-gray-500">
                                  <small>Add this to your saved items</small>
                                </div>
                              </div>
                            </Link>
                            <div className="dropdown-divider"></div>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-body">
                        {e.comment ? (
                          <div className="lead mb-3">
                            {/* <i className="fa fa-quote-left fa-fw float-start opacity-5 me-3 mb-3 mt-3 fa-lg"></i> */}
                            {e.comment}
                            {/* <i className="fa fa-quote-right fa-fw float-end opacity-5 ms-3 mt-n2 fa-lg"></i> */}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </PerfectScrollbar>
          {/* <Modal
            isOpen={this.state.modalAsignment}
            toggle={() => this.toggleModal("modalAddTask")}
          >
            <ModalHeader
              // toggle={() => setmodalAsignment(!modalAsignment)}
              close={
                <button
                  className="btn-close"
                  onClick={() => {
                    this.toggleModal("modalAddTask");
                  }}
                ></button>
              }
            >
              Asignar Expediente
            </ModalHeader>
            <form onSubmit={this.handleSubmit}>
              <ModalBody>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="d-flex mx-auto" style={{ width: "80%" }}>
                    <div className=" field m-auto ">Asignar a</div>
                    <div>
                      <Select
                        className="w-300px m-auto"
                        options={users.map((person) => ({
                          label: person.first_name + person.last_name,
                          value: person.id,
                        }))}
                        placeholder="Selecciona"
                        name="Personas"
                        onChange={(e) => this.setState({ Asignara: e.value })}get.value
                      />
                    </div>
                  </div>
                  {this.state.AsignaraError ? (
                    <div className="d-flex mx-auto" style={{ width: "80%" }}>
                      <div className="w-75px field m-auto "></div>
                      <div className="w-300px m-auto">
                        {" "}
                        <span style={{ color: "red" }}>
                          Este campo es necesario
                        </span>{" "}
                      </div>{" "}
                    </div>
                  ) : null}

                  <br />

                  <div className="d-flex  mx-auto" style={{ width: "80%" }}>
                    <div className="field m-auto">Comentario</div>
                    <div>
                      <textarea
                        onChange={(e) =>
                          this.setState({ comentarioAsignar: e.target.value })
                        }
                        placeholder="Escribe tu comentario"
                        className="w-300px m-auto p-2"
                        style={{
                          borderColor: "hsl(0, 0%, 80%)",
                          borderRadius: "4px",
                        }}
                      ></textarea>
                    </div>
                  </div>
                  {this.state.AsignaraCommentError ? (
                    <div className="d-flex mx-auto" style={{ width: "80%" }}>
                      <div className="w-75px field m-auto "></div>
                      <div className="w-300px m-auto">
                        {" "}
                        <span style={{ color: "red" }}>
                          Este campo es necesario
                        </span>{" "}
                      </div>{" "}
                    </div>
                  ) : null}
                </div>
              </ModalBody>
              <ModalFooter>
                <button
                  className="btn btn-white"
                  onClick={() => {
                    this.toggleModal("modalAddTask");
                  }}
                >
                  Cerrar
                </button>
                <button type="submit" className="btn btn-success">
                  Asignar
                </button>
              </ModalFooter>
            </form>
          </Modal> */}
          <ReactNotifications />
          <Modal
            isOpen={this.state.modalReject}
            toggle={() => this.toggleModal("modalReject")}
          >
            <ModalHeader
              // toggle={() => setmodalAsignment(!modalAsignment)}
              close={
                <button
                  className="btn-close"
                  onClick={() => {
                    this.toggleModal("modalReject");
                  }}
                ></button>
              }
            >
              Rechazar Expediente
            </ModalHeader>
            <form onSubmit={this.submitReject}>
              <ModalBody>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="d-flex  mx-auto" style={{ width: "80%" }}>
                    <div className="field m-auto">Comentario</div>
                    <div>
                      <textarea
                        onChange={(e) =>
                          this.setState({ comentarioAsignar: e.target.value })
                        }
                        placeholder="Escribe tu comentario"
                        className="w-300px m-auto p-2"
                        style={{
                          borderColor: "hsl(0, 0%, 80%)",
                          borderRadius: "4px",
                        }}
                      ></textarea>
                    </div>
                  </div>
                  {this.state.AsignaraCommentError ? (
                    <div className="d-flex mx-auto" style={{ width: "80%" }}>
                      <div className="w-75px field m-auto "></div>
                      <div className="w-300px m-auto">
                        {" "}
                        <span style={{ color: "red" }}>
                          Este campo es necesario
                        </span>{" "}
                      </div>{" "}
                    </div>
                  ) : null}
                </div>
              </ModalBody>
              <ModalFooter>
                <button
                  className="btn btn-white"
                  onClick={() => {
                    this.toggleModal("modalReject");
                  }}
                >
                  Cerrar
                </button>
                <button type="submit" className="btn btn-danger">
                  Rechazar
                </button>
              </ModalFooter>
            </form>
          </Modal>
          {this.state.sweetAlertError && (
            <SweetAlert
              danger
              showCancel
              confirmBtnText="Sí,borrar!"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              cancelBtnText="Cancelar"
              title="Estás seguro de borrar?"
              onConfirm={() => {
                deleteFilesId(fileIdArchive.id);
                this.handleDelete();
              }}
              onCancel={() => this.toggleSweetAlert("error")}
            >
              el expediente archivado # {fileIdArchive.number}
            </SweetAlert>
          )}
        </Panel>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  filesLoaded: state.counterSlice.filesLoaded,
  fileIdArchive: state.counterSlice.fileIdArchive,
  roadmap: state.counterSlice.roadmap,
  users: state.counterSlice.users,
  filesopen: state.counterSlice.filesopen,
});
const mapDispatchToProps = {
  deleteFilesId,
  removeFilesOpen,
  getFilesArchiveId,
  getFileRoadmap,
  getUsers,
};

// bind our actions to this.props

export default connect(mapStateToProps, mapDispatchToProps)(ExpedienteArchivado);
