import { useTable, useSortBy, usePagination } from "react-table";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import { AppSettings } from "./../../config/app-settings";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import React, { useState, useEffect, useRef,useContext } from "react";
import Moment from "moment";
import { useDownloadExcel } from "react-export-table-to-excel";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Tooltip from "rc-tooltip";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";
import autoTable from "jspdf-autotable";
import {
  deleteCountId,
  getCounts,
  getCountsSearch,
} from "../../actions/index.js";
import {
  CopyRight,
  pension_type,
  successPermissions,
} from "../../utils/index.js";
import { Store, ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import imagenProfile from "../../assets/img/user/user-13.jpg";
import axios from "axios";
const Counts = () => {
  const tableRef = useRef(null);
  const [initialPageIndex, setinitialPageIndex] = useState(0);
  const [modalDialog, setmodalDialog] = useState(false);
  const [timer, setTimer] = useState(null);
  const [NameDelete, setNameDelete] = useState(null);
  const [modalDeletePerson, setmodalDeletePerson] = useState(false);
  const context = useContext(AppSettings);
  const [IdSelected, setIdSelected] = useState(null);
  const [Cities, setCities] = useState([]);
  const [inputs, setInputs] = useState({
    is_client: false,
    is_freelance: false,
    is_staff: false,
    document_type: 10,
    city: "",
    department: 1,
  });
  const fakeApi = (e) => {
    dispatch(getCountsSearch(e));
    setinitialPageIndex(0);
  };
  const inputChanged = (e) => {
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      fakeApi(e.target.value);
    }, 200);

    setTimer(newTimer);
  };
  var handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setInputs((val) => ({ ...val, [name]: value }));
  };
  useEffect(() => {
    if (
      context &&
      localStorage.email &&
      localStorage.email.includes("siep.com.co")
    ) {
      context.handleSetActualizarEmail();
    }
  }, []);

  function pdfdownload(current) {
    const doc = new jsPDF();
    autoTable(doc, { html: current });
    doc.save("table.pdf");
  }
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Counts table",
    sheet: "Counts",
  });
  const handlePrint = useReactToPrint({
    pageStyle: "@page {size:auto;margin-bottom: 20mm }",
    content: () => {
      const tableStat = tableRef.current.cloneNode(true);
      const PrintElem = document.createElement("div");
      PrintElem.setAttribute("class", "containerPrint");
      const header =
        `<div class="page-header"><h1>Conteos</div></h1>` +
        `<img src="https://siep.restrepofajardo.com/img/logo.png" alt="" class="watermark"/>` +
        `<div class="page-footer"><span>${
          localStorage.full_name
        }</span><span>${Moment().format(
          "D [de] MMMM [del] YYYY, h:mm a"
        )}</span></div>`;
      PrintElem.innerHTML = header;
      PrintElem.appendChild(tableStat);
      return PrintElem;
    },
  });
  const state = useSelector((state) => state);

  const dispatch = useDispatch();
  const { counts, paginaCounts, errorDeleteCount } = state.counterSlice;
  console.log("errorDeleteCount", state, errorDeleteCount);
  useEffect(() => {
    dispatch(getCounts());
    axios
      .get("/city.json")
      .then((res) => setCities(res.data))
      .catch((err) => console.log(err.response));
  }, []);

  function handleSubmit() {
    if (IdSelected) {
      dispatch(deleteCountId(IdSelected));
      setmodalDeletePerson(false);
    }
  }

  const columns = React.useMemo(
    () =>
      successPermissions("full_access_counts", "can_view_counts_detail") ==
      "d-none"
        ? [
            {
              Header: "Id",
              accessor: "id",
              sortable: true,
            },
            {
              Header: "Cliente",
              accessor: "name",
              sortable: true,
            },
            {
              Header: "Expediente",
              accessor: "file",
              sortable: true,
            },
            {
              Header: "Semanas totales",
              accessor: "days_total",
              sortable: false,
            },
            {
              Header: "Tipo de pensión",
              accessor: "pension_type",
              sortable: true,
            },
            {
              Header: "Modificado",
              accessor: "updated_at",
              sortable: true,
            },
          ]
        : [
            {
              Header: "Id",
              accessor: "id",
              sortable: true,
            },
            {
              Header: "Cliente",
              accessor: "name",
              sortable: true,
            },
            {
              Header: "Expediente",
              accessor: "file",
              sortable: true,
            },
            {
              Header: "Semanas totales",
              accessor: "days_total",
              sortable: false,
            },
            {
              Header: "Tipo de pensión",
              accessor: "pension_type",
              sortable: true,
            },
            {
              Header: "Modificado",
              accessor: "updated_at",
              sortable: true,
            },
            {
              Header: "Acciones",
              accessor: "Acciones",
              sortable: false,
            },
          ],
    []
  );

  function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <img src={imagenProfile} width="52" alt="" className="rounded" />
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }
  const data = counts && counts;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: initialPageIndex },
    },
    useSortBy,
    usePagination
  );
  useEffect(() => {
    const counter = 100;
    let other = (pageIndex + 1) * pageSize;
    if (counts.length / pageSize - 1 <= pageIndex + 1) {
      if (
        (other % counter >= counter - pageSize && paginaCounts[1]) ||
        (other % counter == 0 && pageSize == 50)
      ) {
        dispatch(
          getCounts(
            paginaCounts[1].replace(
              "https://beta.siep.restrepofajardo.com/api/v1/files/counts/",
              ""
            )
          )
        );
      }
    }
    setinitialPageIndex(pageIndex);
  }, [pageIndex]);

  return (
    <div>
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">
          <Link to="/dashboard">Panel</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to="/Conteos">Conteos</Link>
        </li>
        {/* <li className="breadcrumb-item active">Data Tables</li> */}
      </ol>
      <h1 className="page-header">Conteos </h1>
      <Panel>
        <PanelHeader>Conteos</PanelHeader>
        <PanelBody>
          <div className="invoice-company">
            <span className="float-end hidden-print">
              {successPermissions("full_access_counts") == "d-none" ? null : (
                <Link
                  to={`/ConteoNuevo/count`}
                  onClick={() => {
                    if (localStorage.inputs_counts) {
                      localStorage.removeItem("inputs_counts");
                    }
                  }}
                >
                  <button className="btn btn-sm btn-primary mb-10px me-1">
                    Crear conteo
                  </button>
                </Link>
              )}
              {successPermissions("full_access_counts_buttons_options") ==
              "d-none" ? null : (
                <>
                  <button
                    onClick={() => pdfdownload(tableRef.current)}
                    className="btn btn-sm btn-white mb-10px me-1"
                  >
                    <i className="fa fa-file-pdf t-plus-1 text-blue fa-fw fa-lg"></i>{" "}
                  </button>
                  <button
                    onClick={onDownload}
                    className="btn btn-sm btn-white mb-10px me-1"
                  >
                    <i className="fa fa-file-excel t-plus-1 text-green fa-fw fa-lg"></i>
                  </button>
                  <button
                    onClick={handlePrint}
                    className="btn btn-sm btn-white mb-10px me-1"
                  >
                    <i className="fa fa-print t-plus-1 fa-fw fa-lg"></i>
                  </button>
                </>
              )}
            </span>
            <div className="d-block d-md-none">
              <br />
              <br />
            </div>

            <div className="d-block d-md-flex align-items-center">
              <label className="d-none d-lg-block form-label pe-2 mb-0">
                Conteos por página:
              </label>
              <div className="d-none d-lg-block">
                <select
                  className="form-select"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Mostrar {pageSize}
                    </option>
                  ))}
                </select>
              </div>
              <form action="" method="POST" name="search_form">
                <div className="form-group position-relative mx-3 width_seach_type w-sm-50 pe-4 d-flex">
                  {" "}
                  <button
                    className="btn btn-search position-absolute border-0"
                    disabled={true}
                  >
                    <i className="fa fa-search me-20px icon-search-action"></i>
                  </button>{" "}
                  <input
                    type="text"
                    id="inputTextSearch"
                    className="form-control px-5 "
                    onChange={inputChanged}
                    // onChange={(e) => {
                    //   dispatch(
                    //     getPersonsSearch(e.target.value, "&is_customer=true")
                    //   );
                    //   setinitialPageIndex(0);
                    // }}
                    placeholder="Buscar conteo ..."
                  />
                </div>
              </form>
            </div>
          </div>
        </PanelBody>
        <div className="table-responsive">
          <ReactNotifications />
          {counts && counts.length === 0 ? (
            <div className="fa-3x d-flex justify-content-center mb-3">
              <i className="fas fa-cog fa-spin"></i>{" "}
            </div>
          ) : counts && typeof counts[0] === "string" ? (
            <div className="fw-bold text-center mb-3">{counts[0]}</div>
          ) : (
            <>
              <table
                ref={tableRef}
                // style={{ background: "var(--app-sidebar-bg)" }}
                className="table table-panel table-bordered mb-0"
                {...getTableProps()}
              >
                <thead>
                  {headerGroups.map((headerGroup, i) => (
                    <tr
                      key={i}
                      {...headerGroup.getHeaderGroupProps()}
                      className="nav-Tables-head"
                    >
                      {headerGroup.headers.map((column, index) => (
                        <th
                          key={index}
                          className="text-center"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div
                            className="d-flex align-items-center text-center"
                            style={{
                              minWidth: "100px",
                              justifyContent: "center",
                            }}
                          >
                            <span>{column.render("Header")}</span>
                            <span className="">
                              {column.sortable ? (
                                column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <i className="fa fa-sort-down fa-fw fs-14px text-blue"></i>
                                  ) : (
                                    <i className="fa fa-sort-up fa-fw fs-14px text-blue"></i>
                                  )
                                ) : (
                                  <i className="fa fa-sort fa-fw fs-14px opacity-3"></i>
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="colorRow-Tables">
                        {row.cells.map((cell, index) => {
                          return (
                            <td key={row.id} {...cell.getCellProps()}>
                              {cell.column.Header == "Acciones" ? (
                                <div
                                  nowrap="true "
                                  className="vertical-middle"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Link
                                    to={`/Conteo/${row.original["id"]}`}
                                    className={`${successPermissions(
                                      "full_access_counts",
                                      "can_view_counts_detail"
                                    )} btn btn-sm btn-green  me-1 p-auto`}
                                  >
                                    <i className="fab fa-lg fa-fw fs-14px fa-sistrix text-white"></i>
                                  </Link>

                                  <button
                                    title="Borrar"
                                    onClick={() =>
                                      setmodalDeletePerson(true) ||
                                      setIdSelected(row.original["id"]) ||
                                      setNameDelete(row.original["id"])
                                    }
                                    className={`${successPermissions(
                                      "full_access_count_Delete"
                                    )}  btn btn-sm btn-danger p-auto`}
                                  >
                                    <i className="fa fa-trash fs-14px text-white"></i>
                                  </button>
                                </div>
                              ) : cell.column.Header == "Modificado" ? (
                                Moment(row.original["updated_at"]).format(
                                  "L[-]LT"
                                )
                              ) : cell.column.Header == "Expediente" ? (
                                row.original["file"].number
                              ) : cell.column.Header == "Tipo de pensión" ? (
                                pension_type(row.original["pension_type"])
                              ) : cell.column.Header == "Semanas totales" ? (
                                Math.round(row.original["days_total"] / 7)
                              ) : (
                                cell.render("Cell")
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>{" "}
              <PanelBody>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="me-1 d-none d-sm-block">Ir a página:</div>
                  <div className="w-50px mx-2 me-auto d-none d-sm-block">
                    <input
                      className="form-control px-2"
                      type="number"
                      value={pageIndex + 1}
                      onChange={(e) => {
                        const page = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        gotoPage(page);
                      }}
                    />{" "}
                  </div>

                  <ul className="pagination mb-0">
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                      >
                        <i className="fa fa-angle-double-left"></i>
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                      >
                        <i className="fa fa-angle-left"></i>
                      </button>
                    </li>
                    <li className="page-item d-flex align-items-center px-2">
                      <div>
                        Pág.{" "}
                        <strong>
                          {pageIndex + 1} de{" "}
                          {paginaCounts[2] / pageSize -
                            Math.round(paginaCounts[2] / pageSize) >
                          0
                            ? Math.round(paginaCounts[2] / pageSize) + 1
                            : Math.round(paginaCounts[2] / pageSize)}
                        </strong>
                      </div>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                      >
                        <i className="fa fa-angle-right"></i>
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                      >
                        <i className="fa fa-angle-double-right"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </PanelBody>
              {paginaCounts[2] ? (
                <div className="hljs-wrapper">
                  <div className="p-3">
                    {" "}
                    <span style={{ color: "#4a9be5", fontSize: "12px" }}>
                      {paginaCounts[2]}
                    </span>{" "}
                    resultados
                  </div>
                </div>
              ) : null}
            </>
          )}
        </div>
        {modalDeletePerson ? (
          <SweetAlert
            danger
            showCancel
            confirmBtnText="Sí, borrar!"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            cancelBtnText="Cancelar"
            title="Estás seguro de borrar"
            onConfirm={() => handleSubmit()}
            onCancel={() => setmodalDeletePerson(!modalDeletePerson)}
          >
            <span
              style={{ fontStyle: "italic", color: "red", fontWeight: "600" }}
            >
              el conteo {NameDelete} ?
            </span>
          </SweetAlert>
        ) : null}
        <Modal
          isOpen={modalDialog}
          // toggle={() => this.toggleModal("modalDialog")}
        >
          <ModalHeader
            // toggle={() => this.toggleModal("modalDialog")}
            close={
              <button
                className="btn-close"
                onClick={() => setmodalDialog(!modalDialog)}
              ></button>
            }
          >
            Modal Dialog
          </ModalHeader>
          <ModalBody>
            <p>Modal body content here...</p>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-white"
              onClick={() => setmodalDialog(!modalDialog)}
            >
              Close
            </button>
            <button className="btn btn-success">Action</button>
          </ModalFooter>
        </Modal>
      </Panel>
    </div>
  );
};
// people?is_customer=true&search=diego
export default Counts;
