import {
  GET_FILES_DETAILS,
  GET_FILES_ASSIGNED,
  GET_FILES_REJECTED,
  GET_FILES_ACCEPTED,
  GET_ASSIGNMENTS,
  REMOVE_FILES_OPEN,
  GET_ENTITIES,
  GET_FILES,
  RE_OPEN,
  GET_FILES_ARCHIVED,
  ADD_FILES_OPEN,
  GET_PERSONS,
  GET_ENTITIES_SEARCH,
  GET_NEWS,
  GET_FILES_ID,
  GET_FILES_ARCHIVE_ID,
  DELETE_FILES_ID,
  GET_PERSONS_ASSIGNMENTS,
  GET_PERSONS_CUSTOMER,
  GET_USERS,
  GET_REASON,
  GET_PERSONS_SEARCH,
  GET_FILES_SEARCH,
  GET_ARCHIVED_SEARCH,
  DELETE_ENTITIES_ID,
  DELETE_PERSON_ID,
  GET_ALL_SEARCH,
  GET_FREELANCE,
  RESET_PERSONS,
  GET_PERMISSIONS,
  GET_METRICS,
  UP_PERMISSIONS,
  GET_FILE_ROADMAP,
  GET_COUNTS,
  GET_COUNTS_SEARCH,
  DELETE_COUNT_ID,
  GET_COUNTS_ID,
  GET_FILES_TEST,
  RESET_FILES,
  RESET_ERRORS,
} from "../actions/index.js";
const initialState = {
  permissions: [],
  filesLoaded: [],
  filesArchived: [],
  filesLoadedTest: [],
  fileId: {},
  fileIdArchive: {},
  countId: {},
  filesopen: [],
  persons: [],
  counts: [],
  freelancers: [],
  pagina: [],
  paginaFiles: [],
  paginaArchivedFiles: [],
  paginaCounts: [],
  NewsAll: [],
  Entities: [],
  Assignments: [],
  AssignmentsDashboard: [],
  RejectedsDashboard: [],
  AcceptedDashboard: [],
  personsAssignments: [],
  personsCustomers: [],
  users: [],
  reasons: [],
  personsSearch: [],
  filesSearch: [],
  allsearch: [],
  roadmap: [],
  paginaRoadmap: [],
  metrics: [],
  errorDeletePerson: "",
};

export default function counterSlice(state = initialState, action) {
  switch (action.type) {
    case RESET_PERSONS:
      return {
        ...state,
        persons: [],
        pagina: [],
      };
    case RESET_ERRORS:
      return {
        ...state,
        errorDeletePerson: "",
      };
    case RESET_FILES:
      return {
        ...state,
        filesLoaded: [],
        filesSearch: [],
        paginaFiles: [],
      };
    case GET_FILES:
      return {
        ...state,
        filesLoaded:
          action.payload.results.length < 100
            ? action.payload.results
            : [...action.payload.results, ...state.filesLoaded],
        paginaFiles: [
          action.payload.previous,
          action.payload.next,
          action.payload.count,
        ],
      };

    case RE_OPEN:
      return {
        ...state,
        filesArchived: state.filesArchived.filter(
          (file) => file.id !== action.payload
        ),
      };
    case GET_PERMISSIONS:
      return {
        ...state,
        permissions: state.permissions,
      };
    case UP_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };

    case GET_FILES_ARCHIVED:
      return {
        ...state,
        filesArchived:
          action.payload.results.length < 100
            ? action.payload.results
            : [...action.payload.results, ...state.filesArchived],
        paginaArchivedFiles: [
          action.payload.previous,
          action.payload.next,
          action.payload.count,
        ],
      };
    case GET_FILES_TEST:
      if (action.payload.results.length < 100) {
        return {
          ...state,
          filesLoadedTest: [action.payload],
        };
      } else {
        return {
          ...state,
          filesLoadedTest: [
            Object.assign({}, action.payload, {
              results:
                state.filesLoadedTest.length > 0
                  ? [
                      ...state.filesLoadedTest[0].results,
                      ...action.payload.results,
                    ]
                  : [...action.payload.results],
            }),
          ],
        };
      }

    case GET_FILES_ASSIGNED:
      return {
        ...state,
        AssignmentsDashboard: action.payload,
      };
    case GET_FILES_ACCEPTED:
      return {
        ...state,
        AcceptedDashboard: action.payload,
      };
    case GET_FILES_REJECTED:
      return {
        ...state,
        RejectedsDashboard: action.payload,
      };

    case GET_COUNTS:
      return {
        ...state,
        counts:
          action.payload.results.length < 100
            ? action.payload.results
            : [...state.counts, ...action.payload.results],
        paginaCounts: [
          action.payload.previous,
          action.payload.next,
          action.payload.count,
        ],
      };
    case GET_FILE_ROADMAP:
      return {
        ...state,
        roadmap: action.payload,
        errorRoadmap: action.errorRoadmap,
      };
    case DELETE_FILES_ID:
      return {
        ...state,
        filesLoaded: state.filesLoaded.filter(
          (file) => file.id !== action.payload
        ),
      };

    case DELETE_COUNT_ID:
      return {
        ...state,
        counts: state.counts.filter((count) => count.id != action.payload),
        errorDeleteCount: action.errorDeleteCount,
      };
    case GET_PERSONS:
      return {
        ...state,
        persons:
          action.payload.results.length < 100
            ? action.payload.results
            : [...state.persons, ...action.payload.results],
        pagina: [
          action.payload.previous,
          action.payload.next,
          action.payload.count,
        ],
      };
    case GET_FREELANCE:
      return {
        ...state,
        freelancers: [...state.freelancers, ...action.payload.results],
        paginaFreelancer: [
          action.payload.previous,
          action.payload.next,
          action.payload.count,
        ],
      };
    case GET_PERSONS_SEARCH:
      return {
        ...state,
        persons: action.payload.results,
        pagina: [
          action.payload.previous,
          action.payload.next,
          action.payload.count,
        ],
      };

    case GET_ENTITIES_SEARCH:
      if (action.payload == []) {
        return { ...state, Entities: [] };
      } else {
        return { ...state, Entities: action.payload };
      }
    case GET_FILES_SEARCH:
      if (action.payload == []) {
        return { ...state, filesLoaded: [] };
      } else {
        return {
          ...state,
          filesLoaded: action.payload.results,
          paginaFiles: [
            action.payload.previous,
            action.payload.next,
            action.payload.count,
          ],
        };
      }
    case GET_ARCHIVED_SEARCH:
      if (action.payload == []) {
        return { ...state, filesArchived: [] };
      } else {
        return {
          ...state,
          filesArchived: action.payload.results,
          paginaArchivedFiles: [
            action.payload.previous,
            action.payload.next,
            action.payload.count,
          ],
        };
      }
    case GET_COUNTS_SEARCH:
      if (action.payload == []) {
        return { ...state, counts: [] };
      } else {
        return {
          ...state,
          counts: action.payload.results,
          paginaCounts: [
            action.payload.previous,
            action.payload.next,
            action.payload.count,
          ],
        };
      }

    case GET_USERS:
      return { ...state, users: action.payload };
    case GET_REASON:
      return { ...state, reasons: action.payload };
    case GET_PERSONS_ASSIGNMENTS:
      return { ...state, personsAssignments: action.payload };
    case GET_PERSONS_CUSTOMER:
      if (action.payload == []) {
        return { ...state, personsCustomers: [] };
      } else {
        return { ...state, personsCustomers: action.payload.results };
      }
    case GET_ALL_SEARCH:
      if (action.payload == []) {
        return { ...state, allsearch: [] };
      } else {
        return {
          ...state,
          allsearch: action.payload,
        };
      }

    case GET_FILES_ID:
      return { ...state, fileId: action.payload };
    case GET_FILES_ARCHIVE_ID:
      return { ...state, fileIdArchive: action.payload };
    case GET_COUNTS_ID:
      return { ...state, countId: action.payload };
    case GET_ENTITIES:
      return { ...state, Entities: action.payload };
    case DELETE_ENTITIES_ID:
      return {
        ...state,
        Entities: state.Entities.filter(
          (entitie) => entitie.id !== action.payload
        ),
      };
    case DELETE_PERSON_ID:
      return {
        ...state,
        persons: state.persons.filter((person) => person.id !== action.payload),
        pagina:
          state.persons.length <= 100
            ? [
                null,
                null,
                state.persons.filter((person) => person.id != action.payload)
                  .length,
              ]
            : state.pagina,
        errorDeletePerson: action.errorDeletePerson,
      };
    case GET_ASSIGNMENTS:
      return { ...state, Assignments: action.payload };
    case GET_METRICS:
      return { ...state, metrics: action.payload };
    case GET_NEWS:
      return { ...state, NewsAll: action.payload };
    case ADD_FILES_OPEN:
      const idExists = state.filesopen.find((el) => el.id == action.payload.id);
      return {
        ...state,
        filesopen: !idExists
          ? [...state.filesopen, action.payload]
          : state.filesopen,
      };
    case REMOVE_FILES_OPEN:
      return {
        ...state,
        filesopen: state.filesopen.filter((file) => file.id != action.payload),
      };
    default:
      return state;
  }
}
