import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import imagenProfile from "../../assets/img/user/user-13.jpg";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Store, ReactNotifications } from "react-notifications-component";
import PerfectScrollbar from "react-perfect-scrollbar";

import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import { AppSettings } from "./../../config/app-settings.js";
import SweetAlert from "react-bootstrap-sweetalert";
// import person from "../../assets/img/user/user-13.jpg";
import { AxiosURLImage, classesStatus, status, useCompareId } from "../../utils/index.js";
import Moment from "moment";
import { instance } from "../../utils/index.js";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import {
  deleteFilesId,
  removeFilesOpen,
  getFilesId,
  getFileRoadmap,
  getUsers,
} from "../../actions/index.js";
import DateRangePicker from "react-bootstrap-daterangepicker";
class PageWithTwoSidebar extends React.Component {
  static contextType = AppSettings;
  constructor(props) {
    super(props);
    this.state = {
      path: props.match.params.id,
      actual_date: Moment().format("L"),
      before_date: Moment().subtract(1, "days").format("L"),
      sweetAlertPrimary: false,
      sweetAlertInfo: false,
      sweetAlertWarning: false,
      sweetAlertSuccess: false,
      sweetAlertError: false,
      modalAsignment: false,
      modalReject: false,
      modalAccept: false,
      AsignaraError: false,
      AsignaraCommentError: false,
      buttonsError: "",
      Asignara: "",
      ido: "",
      activeTab: 0,
      comentarioAsignar: "",
      comentarioAceptar: "",
      comentarioFacebook: "",
      comentarioToFile: "",
      toggleCalendar: false,
      timer: null,
      showComments: "d-block",
      idSubcomentShow: 0,
      startDate: Moment().subtract(7, "days"),
      endDate: Moment(),
      currentWeek:
        Moment().subtract("days", 7).format("D MMMM YYYY") +
        " - " +
        Moment().format("D MMMM YYYY"),
      prevWeek:
        Moment().subtract("days", 15).format("D MMMM") +
        " - " +
        Moment().subtract("days", 8).format("D MMMM YYYY"),
    };
    this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitComentarioFace = this.submitComentarioFace.bind(this);
    this.submitComentarioToFile = this.submitComentarioToFile.bind(this);
    this.addNotification = this.addNotification.bind(this);
    this.submitReject = this.submitReject.bind(this);
  }
  addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <img src={imagenProfile} width="52" alt="" className="rounded" />
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }
  componentWillMount() {
    const { getUsers } = this.props;
    getUsers();
  }
  componentWillMountedverdad(personaId) {
    const { getFilesId, getFileRoadmap } = this.props;
    if (personaId != this.state.ido) {
      this.setState((state) => ({
        ido: personaId,
      }));
      getFilesId(personaId);
      getFileRoadmap(personaId);
    }
  }

  toggleModal(name) {
    switch (name) {
      case "modalAddTask":
        this.setState({ modalAsignment: !this.state.modalAsignment });
        break;
      case "modalAccept":
        this.setState({ modalAccept: !this.state.modalAccept });
        break;
      case "modalReject":
        this.setState({ modalReject: !this.state.modalReject });
        break;
      default:
        break;
    }
  }

  toggleSweetAlert(name) {
    switch (name) {
      case "primary":
        this.setState({ sweetAlertPrimary: !this.state.sweetAlertPrimary });
        break;
      case "info":
        this.setState({ sweetAlertInfo: !this.state.sweetAlertInfo });
        break;
      case "success":
        this.setState({ sweetAlertSuccess: !this.state.sweetAlertSuccess });
        break;
      case "warning":
        this.setState({ sweetAlertWarning: !this.state.sweetAlertWarning });
        break;
      case "error":
        this.setState({ sweetAlertError: !this.state.sweetAlertError });
        break;
      default:
        break;
    }
  }

  submitComentarioFace(event) {
    event.target.reset();
    event.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.append("parent", event.target.id);
    bodyFormData.append("comment", this.state.comentarioFacebook);
    instance
      .post(`files/${this.state.path}/comment`, bodyFormData)
      .then((res) => {
        if (res.status === 201 || res.status === 200 || res.data) {
          this.setState({
            comentarioFacebook: "",
          });

          this.props.getFileRoadmap(this.state.path);
        }
      })
      .catch((e) => console.log("Error in asignament", e));
  }
  submitComentarioToFile(event) {
    event.target.reset();
    event.preventDefault();
    var bodyFormData = new FormData();
   
   
    bodyFormData.append("comment", this.state.comentarioToFile);
    instance
      .post(`files/${this.state.path}/comment`, bodyFormData)
      .then((res) => {
        if (res.status === 201 || res.status === 200 || res.data) {
          // this.setState({
          //   comentarioToFile: "",
          // });

          this.props.getFileRoadmap(this.state.path);
        }
      })
      .catch((e) => console.log("Error in asignament", e));
  }
  borrar(e) {
    this.props.removeFilesOpen(e);
  }
  submitReject(event) {
    event.preventDefault();

    var bodyFormData = new FormData();
    bodyFormData.append("comment", this.state.comentarioAsignar);
    if (this.state.comentarioAsignar.length > 0) {
      instance
        .post(`files/${this.state.path}/reject`, bodyFormData)
        .then((res) => {
          if (res.data) {
            this.setState({
              Asignara: "",
              comentarioAsignar: "",
            });
            this.toggleModal("modalReject");
            this.props.getFilesId(this.state.path);
            this.props.getFileRoadmap(this.state.path);
          }
        })
        .catch((e) => {
          console.log("Error in asignament", e);
          if (e.response) {
            this.setState({
              buttonsError: e.response.data,
              comentarioAsignar: "",
            });

            setTimeout(() => {
              this.setState({
                buttonsError: "",
              });
            }, 3000);
          }
        });
    } else {
      this.setState({
        buttonsError: "Debes explicar el motivo",
        comentarioAsignar: "",
      });

      setTimeout(() => {
        this.setState({
          buttonsError: "",
        });
      }, 3000);
    }
  }
  handleSubmit(event) {
    event.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.append("comment", this.state.comentarioAceptar);
    instance
      .post(`files/${this.state.path}/accept`, bodyFormData)
      .then((res) => {
        if (res.status === 201 || res.status === 200 || res.data) {
          this.toggleModal("modalAccept");
          this.props.getFilesId(this.state.path);
          this.props.getFileRoadmap(this.state.path);
        }
      })
      .catch((e) => {
        if (e.response) {
          this.setState({
            buttonsError: e.response.data,
          });

          setTimeout(() => {
            this.setState({
              buttonsError: "",
            });
          }, 3000);
        }
      });
  }
  componentDidMount() {
    this.context.handleSetAppSidebarTwo(true);
  }

  componentWillUnmount() {
    this.context.handleSetAppSidebarTwo(false);
  }
  render() {
    const { fileId, roadmap, filesopen, errorRoadmap } = this.props;
    console.log(errorRoadmap);
    this.componentWillMountedverdad(this.props.match.params.id);

    this.handleDateApplyEvent = (event, picker) => {
      var startDate = picker.startDate;
      var endDate = picker.endDate;
      var gap = endDate.diff(startDate, "days");

      var currentWeek =
        startDate.format("D MMMM YYYY") + " - " + endDate.format("D MMMM YYYY");
      var prevWeek =
        Moment(startDate).subtract("days", gap).format("D MMMM") +
        " - " +
        Moment(startDate).subtract("days", 1).format("D MMMM YYYY");
      inputChanged(
        "",
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")
      );
      this.setState((state) => ({
        startDate: startDate,
        endDate: endDate,
        currentWeek: currentWeek,
        prevWeek: prevWeek,
        toggleCalendar: !this.state.toggleCalendar,
      }));
    };
    const onTabClick = (e, index) => {
      this.setState((state) => ({
        activeTab: index,
      }));
    };
    const decidedComments = (e, index) => {
      console.log(e.target.id);
      if (e.target.id) {
        let idSubcoment = e.target.id;
        let nuevoIdSucoment = Number(idSubcoment.replace("subcoment_file", ""));
        console.log(nuevoIdSucoment);
        this.setState((state) => ({
          idSubcomentShow: nuevoIdSucoment,
        }));
      }
      this.setState((state) => ({
        showComments:
          this.state.showComments === "d-block" ? "d-none" : "d-block",
      }));
    };
    function fromNow(date) {
      var seconds = Math.floor((new Date() - date) / 1000);
      var years = Math.floor(seconds / 31536000);
      var months = Math.floor(seconds / 2592000);
      var days = Math.floor(seconds / 86400);

      if (days > 548) {
        return years + " años atrás";
      }
      if (days >= 320 && days <= 547) {
        return "un año atrás";
      }
      if (days >= 45 && days <= 319) {
        return months + " meses atrás";
      }
      if (days >= 26 && days <= 45) {
        return "un mes atrás";
      }

      const hours = Math.floor(seconds / 3600);

      if (hours >= 36 && days <= 25) {
        return days + " días atrás";
      }
      if (hours >= 22 && hours <= 35) {
        return "un día atrás";
      }

      const minutes = Math.floor(seconds / 60);

      if (minutes >= 90 && hours <= 21) {
        return hours + " horas atrás";
      }
      if (minutes >= 45 && minutes <= 89) {
        return "una hora atrás";
      }
      if (seconds >= 90 && minutes <= 44) {
        return minutes + " minutos atrás";
      }
      if (seconds >= 45 && seconds <= 89) {
        return "un minuto atrás";
      }
      if (seconds >= 0 && seconds <= 45) {
        return "hace unos segundos";
      } else {
        return "Ahora";
      }
    }
    const inputChanged = (e, startDate, endDate) => {
      if ((e != "" && e != " " && e.length > 0) || (startDate && endDate)) {
        const valueSearch = e.toLowerCase();
        clearTimeout(this.state.timer);
        const newTimer = setTimeout(() => {
          const idfind = roadmap.filter(
            (element) =>
              (valueSearch.length > 0 &&
                element.comment.toLowerCase().includes(valueSearch)) ||
              (valueSearch.length > 0 &&
                element.receiver?.full_name
                  .toLowerCase()
                  .includes(valueSearch)) ||
              (valueSearch.length > 0 &&
                element.sender?.full_name
                  .toLowerCase()
                  .includes(valueSearch)) ||
              (element?.created_at.slice(0, 10) >= startDate &&
                element?.created_at.slice(0, 10) <= endDate)
          );
          if (idfind.length > 0) {
            const element = document.getElementById(`M${idfind[0].id}`);
            const colorOn =
              element && element.querySelector(`.timeline-icon a`);

            colorOn.classList.add("backgroundTimeLine");
            element.scrollIntoView({ behavior: "auto" });
          }
        }, 0);
        this.setState((state) => ({
          timer: newTimer,
        }));
      }
      if (e != "" && e != " " && e.length > 0 && startDate && endDate) {
        const valueSearch = e.toLowerCase();
        clearTimeout(this.state.timer);
        const newTimer = setTimeout(() => {
          const idfind = roadmap.filter(
            (element) =>
              (valueSearch.length > 0 &&
                element.comment.toLowerCase().includes(valueSearch)) ||
              (valueSearch.length > 0 &&
                element.receiver?.full_name
                  .toLowerCase()
                  .includes(valueSearch)) ||
              (valueSearch.length > 0 &&
                element.sender?.full_name.toLowerCase().includes(valueSearch) &&
                element?.created_at.slice(0, 10) >= startDate &&
                element?.created_at.slice(0, 10) <= endDate)
          );
          if (idfind.length > 0) {
            const element = document.getElementById(`M${idfind[0].id}`);
            const colorOn = element.querySelector(`.timeline-icon a`);
            colorOn.classList.add("backgroundTimeLine");
            element.scrollIntoView({ behavior: "auto" });
          }
        }, 0);
        this.setState((state) => ({
          timer: newTimer,
        }));
      } else {
        let padreHilos = document.getElementById("perfectScrollBarExpe");
        let hilos =
          padreHilos &&
          padreHilos.querySelectorAll(
            ".timeline .timeline-item .timeline-icon a"
          );
        hilos &&
          hilos.forEach((e) => {
            let valueInterno = e;
            while (valueInterno.classList.contains("backgroundTimeLine")) {
              valueInterno.classList.remove("backgroundTimeLine");
            }
          });
        padreHilos && padreHilos.scrollIntoView(true);
      }
    };
    const day_hoy = Moment();
    const inicioday =
      day_hoy.subtract(day_hoy.day(), "days").format().slice(0, 11) + "00:00";

    const opciones = {
      locale: {
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
      },
    };
    return (
      <div>
        <ol className="breadcrumb float-xl-end">
          <li className="breadcrumb-item">
            <Link to="/Expedientes">Expedientes</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`#`}>Hoja de ruta</Link>
          </li>

          {/* <li className="breadcrumb-item active">Data Tables</li> */}
        </ol>
        <h1 className="page-header">
          Expediente # {fileId.number}
          <small></small>
        </h1>
        <Panel>
          <PanelHeader>
            {filesopen && filesopen.length > 0
              ? "Expedientes abiertos :"
              : `Expediente # ${fileId.number}`}
          </PanelHeader>
          {filesopen && filesopen.length > 0 ? (
            <Tabs
              activeTab={this.state.activeTab}
              onTabClick={() => onTabClick}
              hideNavBtnsOnMobile={false}
            >
              {/* generating an array to loop through it  */}
              {filesopen.sort(useCompareId).map((e, i) => (
                <Tab
                  key={i}
                  style={
                    fileId.number == e.number
                      ? { backgroundColor: `var(--rts-primary-color)` }
                      : null
                  }
                >
                  <Link to={`/Expediente/${e.id}`}>Exp {e.number} </Link>
                  <i
                    className="far fa-lg fa-fw me-10px fa-circle-xmark ps-2"
                    style={{ color: "#ffff" }}
                    onClick={() => this.borrar(e.id)}
                  ></i>
                </Tab>
              ))}
            </Tabs>
          ) : null}
          <PanelBody>
            <div className="invoice-company">
              <span className="float-end hidden-print d-flex"></span>
              <div className="d-block d-flex flex-column flex-md-row align-items-center">
                <form id="formsearchTimeline" name="search_form">
                  <div className="form-group position-relative mx-3 width_seach_type w-sm-50 pe-4 d-flex">
                    {" "}
                    <button
                      className="btn btn-search position-absolute border-0"
                      disabled={true}
                    >
                      <i className="fa fa-search me-20px icon-search-action"></i>
                    </button>{" "}
                    <input
                      type="text"
                      id="inputTextSearch"
                      className="form-control px-5"
                      placeholder="Buscar..."
                      onChange={(e) => inputChanged(e.target.value, null, null)}
                    />
                  </div>
                </form>
                <div
                  style={{ padding: "0.625rem" }}
                  className="calendarExpedientes"
                >
                  <DateRangePicker
                    startDate={this.startDate}
                    endDate={this.endDate}
                    onApply={this.handleDateApplyEvent}
                    initialSettings={opciones}
                  >
                    <input type="text" className="form-control  col-4" />
                  </DateRangePicker>
                </div>
                {fileId.responsible ? (
                  <div className="ms-md-auto ms-lg-auto">
                    <strong>Responsable:</strong> {fileId.responsible.full_name}
                  </div>
                ) : null}
                {fileId.last_comment && fileId.last_comment.parent ? (
                  <div className="mx-auto me-md-0 ms-md-auto">
                    {" "}
                    <button
                      onClick={() => {
                        const element = document.getElementById(
                          `M${fileId?.last_comment?.parent}`
                        );
                        const colorOn =
                          element && element.querySelector(`.timeline-icon a`);
                        if (colorOn && element) {
                          colorOn.classList.add("backgroundTimeLine");
                          element.scrollIntoView({ behavior: "auto" });
                        }
                      }}
                      className="btn btn-sm btn-warning"
                    >
                      <i className="fas fa-lg fa-fw me-10px fa-circle-arrow-down"></i>{" "}
                      Último comentario{" "}
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </PanelBody>
          {errorRoadmap ? (
            <div className="text-center border border-warning text-warning px-2 pt-5px pb-5px rounded fs-12px justify-content-center d-flex align-items-center">
              {errorRoadmap}
            </div>
          ) : (
            <PerfectScrollbar
              id="perfectScrollBarExpe"
              className="hljs-wrapper"
              style={{ height: "calc(100vh - 345px)" }}
              options={{ suppressScrollX: true }}
            >
              {roadmap[0] &&
              localStorage.userId == roadmap[0].receiver?.id &&
              fileId.status === 30 ? (
                <div key={"nowOptions"} className="timeline">
                  <div className="timeline-item">
                    <div className="timeline-time">
                      <span className="date">Ahora</span>
                      <span className="time">{Moment().format("LT")}</span>
                    </div>
                    <div className="timeline-icon">
                      <Link to="#">&nbsp;</Link>
                    </div>
                    <div className="timeline-content">
                      <div className="timeline-header">
                        {localStorage.profile_pic ? (
                          <div className="userimage">
                            <img
                              className="h-100 w-100"
                              style={{ objectFit: "cover" }}
                              alt="imgperson"
                              src={AxiosURLImage + localStorage.profile_pic}
                            />
                          </div>
                        ) : (
                          <div
                            className="userimage"
                            style={{
                              display: "flex",
                              color: "white",
                              flexDirection: "column",
                              background: "var(--bs-body-color)",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              {fileId?.receiver?.full_name.slice(0, 1)}
                            </span>
                          </div>
                        )}
                        <div className="username">
                          <Link to="#">{localStorage?.full_name} </Link>
                          <div className="text-muted fs-12px">
                            Ahora{" "}
                            <i className="fa fa-globe-americas opacity-5 ms-1"></i>
                          </div>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <span className={classesStatus(fileId?.status)}>
                            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>{" "}
                            {status(fileId?.status)}
                          </span>{" "}
                          <br />
                          <div className="dropdown-menu dropdown-menu-end">
                            <Link
                              to="#"
                              className="dropdown-item d-flex align-items-center"
                            >
                              <i className="fa fa-fw fa-bookmark fa-lg"></i>
                              <div className="flex-1 ps-1">
                                <div>Save Post</div>
                                <div className="mt-n1 text-gray-500">
                                  <small>Add this to your saved items</small>
                                </div>
                              </div>
                            </Link>
                            <div className="dropdown-divider"></div>
                          </div>
                        </div>
                      </div>
                      <hr className="my-10px" />
                      <div className="d-flex align-items-center fw-bold">
                        <div
                          to="#"
                          className="flex-fill text-decoration-none text-center text-dark"
                        >
                          <button
                            onClick={() => {
                              this.toggleModal("modalAccept");
                            }}
                            style={{
                              background: "#00acac",
                              border: "1px solid #00acac",
                            }}
                            className="badge   text-white px-4 pt-9px pb-9px rounded fs-12px d-inline-flex align-items-center"
                          >
                            <i className="fa fa-thumbs-up fa-fw me-3px"></i>{" "}
                            Aceptar
                          </button>
                        </div>

                        <div
                          to="#"
                          className="flex-fill text-decoration-none text-center text-dark"
                        >
                          <button
                            onClick={() => {
                              this.toggleModal("modalReject");
                            }}
                            style={{
                              background: "#ff5b57",
                              border: "1px solid #ff5b57",
                            }}
                            className="badge border border-red text-white px-4 pt-9px pb-9px rounded fs-12px d-inline-flex align-items-center"
                          >
                            <i className="fas fa-lg fa-fw me-10px fa-circle-xmark"></i>{" "}
                            Rechazar
                          </button>
                        </div>
                      </div>
                      <hr className="mt-10px mb-3" />
                    </div>
                  </div>
                </div>
              ) : null}
              <div key={fileId + "comentario"} className="timeline">
                <div className="timeline-item">
                  <div className="timeline-time">
                    <span className="date">Ahora</span>
                    <span className="time">{Moment().format("hh:mm a")}</span>
                  </div>
                  <div className="timeline-icon">
                    <Link to="#">&nbsp;</Link>
                  </div>
                  <div className="timeline-content">
                    <div className="timeline-body">
                      <div className="ps-2 flex-1">
                        <form
                          id={"form-comentario"}
                          onSubmit={this.submitComentarioToFile}
                        >
                          <div className="position-relative">
                            <input
                              required
                              key={fileId + "coments-file"}
                              onChange={(e) =>
                                this.setState({
                                  comentarioToFile: e.target.value,
                                })
                              }
                              type="text"
                              className="form-control rounded-pill ps-3 py-2 fs-13px bg-light"
                              placeholder="Escribe tu comentario..."
                            />
                            <div className="position-absolute end-0 top-0 bottom-0 d-flex align-items-center px-2">
                              <button
                                className="btn bg-none text-dark shadow-none px-1"
                                type="submit"
                              >
                                <i className="fas fa-lg fa-fw me-10px fa-circle-chevron-right"></i>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      {/* )} */}
                    </div>
                  </div>
                </div>
              </div>
              {roadmap &&
                roadmap.map((e, index) => (
                  <div key={e.id} id={"M" + e.id} className="timeline">
                    <div className="timeline-item">
                      <div className="timeline-time">
                        <span className="date">
                          {this.state["actual_date"] ===
                          Moment(e["created_at"]).format("L")
                            ? "Hoy"
                            : this.state["before_date"] ===
                              Moment(e["created_at"]).format("L")
                            ? "Ayer"
                            : Moment(e["created_at"]).format("L")}
                        </span>
                        <span className="time">
                          {Moment(e["created_at"]).format("hh:mm a")}
                        </span>
                      </div>
                      <div className="timeline-icon">
                        <Link to="#">&nbsp;</Link>
                      </div>
                      <div className="timeline-content">
                        <div className="timeline-header">
                          {e.status != 60 ? (
                            <div className="userimage">
                              <img
                                className="h-100 w-100"
                                style={{ objectFit: "cover" }}
                                alt="imgperson"
                                src={AxiosURLImage + e?.sender?.profile_pic}
                              />
                            </div>
                          ) : (
                            <div
                              className="userimage"
                              style={{
                                display: "flex",
                                color: "white",
                                flexDirection: "column",
                                background: "var(--bs-body-color)",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span>{e.sender.full_name.slice(0, 1)}</span>
                            </div>
                          )}
                          <div className="username">
                            <Link to="#">{e.sender.full_name} </Link>
                            <div className="text-muted fs-12px">
                              {fromNow(Moment(e["created_at"]))}
                              <i className="fa fa-globe-americas opacity-5 ms-1"></i>
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <div
                              style={{ textAlign: "left" }}
                              className={
                                e.status === 40
                                  ? "badge border border-red text-red px-2 pt-5px pb-5px rounded fs-12px d-inline-flex flex-column"
                                  : e.status === 60
                                  ? "badge border border-green text-green px-2 pt-5px pb-5px rounded fs-12px d-inline-flex flex-column"
                                  : "badge border border-blue text-blue px-2 pt-5px pb-5px rounded fs-12px d-inline-flex flex-column"
                              }
                            >
                              <div>
                                <i className="fa fa-circle fs-9px fa-fw me-5px"></i>{" "}
                                {status(e.status)}{" "}
                                {e.status === 40 || e.status === 60
                                  ? " por"
                                  : " a"}
                              </div>{" "}
                              <span
                                className="ms-1 my-1 fw-bold"
                                style={{ color: "#000" }}
                              >
                                {e.receiver && e.receiver.full_name
                                  ? e.receiver.full_name
                                  : null}
                              </span>
                            </div>{" "}
                            <div className="dropdown-menu dropdown-menu-end">
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center"
                              >
                                <i className="fa fa-fw fa-bookmark fa-lg"></i>
                                <div className="flex-1 ps-1">
                                  <div>Save Post</div>
                                  <div className="mt-n1 text-gray-500">
                                    <small>Add this to your saved items</small>
                                  </div>
                                </div>
                              </Link>
                              <div className="dropdown-divider"></div>
                            </div>
                          </div>
                        </div>
                        <div className="timeline-body">
                          {e.comment ? (
                            <div className="lead mb-3">
                              {/* <i className="fa fa-quote-left fa-fw float-start opacity-5 me-3 mb-3 mt-3 fa-lg"></i> */}
                              {e.comment}
                              {/* <i className="fa fa-quote-right fa-fw float-end opacity-5 ms-3 mt-n2 fa-lg"></i> */}
                            </div>
                          ) : null}

                          {/* {e.status === 1000 ? null : ( */}
                          <div className="ps-2 flex-1">
                            {e.sub_comments && e.sub_comments.length > 0 ? (
                              <>
                                <div
                                  id={"subcoment_file" + e.id}
                                  className="my-1"
                                  style={{
                                    textAlign: "right",
                                    cursor: "pointer",
                                  }}
                                  onClick={decidedComments}
                                >
                                  {" "}
                                  <i>
                                    {" "}
                                    {e.sub_comments.length === 1
                                      ? e.sub_comments.length + " comentario"
                                      : e.sub_comments.length + " comentarios"}
                                  </i>
                                </div>
                                <div
                                  className={`${
                                    e.id == this.state.idSubcomentShow
                                      ? this.state.showComments
                                      : null
                                  } chats bg-light mb-2`}
                                >
                                  {e.sub_comments.map((subcomment, i) => (
                                    <div
                                      key={i}
                                      className={`chats-item ${
                                        (i + 1) % 2 === 0 ? "end" : "start"
                                      }`}
                                    >
                                      <span className="date-time">
                                        {this.state["before_date"] ===
                                        Moment(subcomment?.created_at).format(
                                          "L"
                                        )
                                          ? "Ayer " +
                                            Moment(
                                              subcomment?.created_at
                                            ).format("hh:mm a")
                                          : this.state["actual_date"] ===
                                            Moment(
                                              subcomment?.created_at
                                            ).format("L")
                                          ? "Hoy " +
                                            Moment(
                                              subcomment?.created_at
                                            ).format("hh:mm a")
                                          : Moment(
                                              subcomment?.created_at
                                            ).format("LT")}
                                      </span>
                                      <Link to="#" className="name">
                                        <strong>
                                          {subcomment?.sender?.full_name}
                                        </strong>
                                      </Link>
                                      <Link
                                        to="#"
                                        className="image"
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                      >
                                        {subcomment?.sender?.profile_pic.includes(
                                          "defaults"
                                        ) ? (
                                          <div
                                            className="image"
                                            style={{
                                              display: "flex",
                                              width: "50px",
                                              height: "50px",
                                              color: "white",
                                              flexDirection: "column",
                                              background:
                                                "var(--bs-body-color)",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <span>
                                              {e.sender.full_name.slice(0, 1)}
                                            </span>
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              width: "40px",
                                              height: "40px",
                                              overflow: "hidden",
                                              borderRadius: "36px",
                                            }}
                                          >
                                            <img
                                              className="h-100 w-100"
                                              style={{ objectFit: "cover" }}
                                              alt="image"
                                              src={
                                                AxiosURLImage +
                                                subcomment?.sender?.profile_pic
                                              }
                                            />
                                          </div>
                                        )}
                                      </Link>
                                      <div
                                        className={`message ${
                                          (i + 1) % 2 === 0
                                            ? "color-message-before"
                                            : ""
                                        }`}
                                        style={{
                                          padding: "10px 12px",
                                          background:
                                            (i + 1) % 2 === 0
                                              ? "#D1EECD"
                                              : "#fff",
                                        }}
                                      >
                                        {subcomment.comment}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </>
                            ) : null}
                            <form
                              id={e.id}
                              onSubmit={this.submitComentarioFace}
                            >
                              <div className="position-relative">
                                <input
                                  required
                                  key={e.id}
                                  onChange={(e) =>
                                    this.setState({
                                      comentarioFacebook: e.target.value,
                                    })
                                  }
                                  type="text"
                                  className="form-control rounded-pill ps-3 py-2 fs-13px bg-light"
                                  placeholder="Escribe tu comentario..."
                                />
                                <div className="position-absolute end-0 top-0 bottom-0 d-flex align-items-center px-2">
                                  <button
                                    className="btn bg-none text-dark shadow-none px-1"
                                    type="submit"
                                  >
                                    <i className="fas fa-lg fa-fw me-10px fa-circle-chevron-right"></i>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                          {/* )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </PerfectScrollbar>
          )}
          {/* <Modal
            isOpen={this.state.modalAsignment}
            toggle={() => this.toggleModal("modalAddTask")}
          >
            <ModalHeader
              // toggle={() => setmodalAsignment(!modalAsignment)}
              close={
                <button
                  className="btn-close"
                  onClick={() => {
                    this.toggleModal("modalAddTask");
                  }}
                ></button>
              }
            >
              Asignar Expediente
            </ModalHeader>
            <form onSubmit={this.handleSubmit}>
              <ModalBody>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="d-flex mx-auto" style={{ width: "80%" }}>
                    <div className=" field m-auto ">Asignar a</div>
                    <div>
                      <Select
                        className="w-300px m-auto"
                        options={users.map((person) => ({
                          label: person.first_name + person.last_name,
                          value: person.id,
                        }))}
                        placeholder="Selecciona"
                        name="Personas"
                        onChange={(e) => this.setState({ Asignara: e.value })}
                      />
                    </div>
                  </div>
                  {this.state.AsignaraError ? (
                    <div className="d-flex mx-auto" style={{ width: "80%" }}>
                      <div className="w-75px field m-auto "></div>
                      <div className="w-300px m-auto">
                        {" "}
                        <span style={{ color: "red" }}>
                          Este campo es necesario
                        </span>{" "}
                      </div>{" "}
                    </div>
                  ) : null}

                  <br />

                  <div className="d-flex  mx-auto" style={{ width: "80%" }}>
                    <div className="field m-auto">Comentario</div>
                    <div>
                      <textarea
                        onChange={(e) =>
                          this.setState({ comentarioAsignar: e.target.value })
                        }
                        placeholder="Escribe tu comentario"
                        className="w-300px m-auto p-2"
                        style={{
                          borderColor: "hsl(0, 0%, 80%)",
                          borderRadius: "4px",
                        }}
                      ></textarea>
                    </div>
                  </div>
                  {this.state.AsignaraCommentError ? (
                    <div className="d-flex mx-auto" style={{ width: "80%" }}>
                      <div className="w-75px field m-auto "></div>
                      <div className="w-300px m-auto">
                        {" "}
                        <span style={{ color: "red" }}>
                          Este campo es necesario
                        </span>{" "}
                      </div>{" "}
                    </div>
                  ) : null}
                </div>
              </ModalBody>
              <ModalFooter>
                <button
                  className="btn btn-white"
                  onClick={() => {
                    this.toggleModal("modalAddTask");
                  }}
                >
                  Cerrar
                </button>
                <button type="submit" className="btn btn-success">
                  Asignar
                </button>
              </ModalFooter>
            </form>
          </Modal> */}
          <ReactNotifications />
          <Modal
            isOpen={this.state.modalReject}
            toggle={() => this.toggleModal("modalReject")}
          >
            <ModalHeader
              // toggle={() => setmodalAsignment(!modalAsignment)}
              close={
                <button
                  className="btn-close"
                  onClick={() => {
                    this.toggleModal("modalReject");
                  }}
                ></button>
              }
            >
              Rechazar Expediente
            </ModalHeader>
            <form>
              <ModalBody>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="d-flex  mx-auto" style={{ width: "80%" }}>
                    <div className="field m-auto">Comentario</div>
                    <div>
                      <textarea
                        onChange={(e) =>
                          this.setState({ comentarioAsignar: e.target.value })
                        }
                        placeholder="Escribe tu comentario"
                        className="w-300px m-auto p-2"
                        style={{
                          borderColor: "hsl(0, 0%, 80%)",
                          borderRadius: "4px",
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <span className="text-red">{this.state.buttonsError}</span>
                  {/* {this.state.AsignaraCommentError ? (
                    <div className="d-flex mx-auto" style={{ width: "80%" }}>
                      <div className="w-75px field m-auto "></div>
                      <div className="w-300px m-auto">
                        {" "}
                        <span style={{ color: "red" }}>
                          Este campo es necesario
                        </span>{" "}
                      </div>{" "}
                    </div>
                  ) : null} */}
                </div>
              </ModalBody>
              <ModalFooter>
                <button
                  className="btn btn-white"
                  onClick={(e) => {
                    e.preventDefault();
                    this.toggleModal("modalReject");
                    this.setState({
                      comentarioAsignar: "",
                    });
                  }}
                >
                  Cerrar
                </button>
                <button onClick={this.submitReject} className="btn btn-danger">
                  Rechazar
                </button>
              </ModalFooter>
            </form>
          </Modal>
          <Modal
            isOpen={this.state.modalAccept}
            toggle={() => this.toggleModal("modalAccept")}
          >
            <ModalHeader
              // toggle={() => setmodalAsignment(!modalAsignment)}
              close={
                <button
                  className="btn-close"
                  onClick={() => {
                    this.toggleModal("modalAccept");
                  }}
                ></button>
              }
            >
              Aceptar Expediente
            </ModalHeader>
            <form>
              <ModalBody>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="d-flex  mx-auto" style={{ width: "80%" }}>
                    <div className="field m-auto">Comentario</div>
                    <div>
                      <textarea
                        onChange={(e) =>
                          this.setState({ comentarioAceptar: e.target.value })
                        }
                        placeholder="Escribe tu comentario"
                        className="w-300px m-auto p-2"
                        style={{
                          borderColor: "hsl(0, 0%, 80%)",
                          borderRadius: "4px",
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <span className="text-red">{this.state.buttonsError}</span>
                </div>
              </ModalBody>
              <ModalFooter>
                <button
                  className="btn btn-white"
                  onClick={(e) => {
                    e.preventDefault();
                    this.toggleModal("modalAccept");
                    this.setState({
                      comentarioAceptar: "",
                    });
                  }}
                >
                  Cerrar
                </button>
                <button onClick={this.handleSubmit} className="btn btn-success">
                  Aceptar
                </button>
              </ModalFooter>
            </form>
          </Modal>
          {this.state.sweetAlertError && (
            <SweetAlert
              danger
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={() => {
                removeFilesOpen(this.state.path);
                deleteFilesId(this.state.path);
                this.handleSubmit();
              }}
              onCancel={() => this.toggleSweetAlert("error")}
            >
              You will not be able to recover this imaginary file!
            </SweetAlert>
          )}
        </Panel>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  filesLoaded: state.counterSlice.filesLoaded,
  fileId: state.counterSlice.fileId,
  roadmap: state.counterSlice.roadmap,
  users: state.counterSlice.users,
  filesopen: state.counterSlice.filesopen,
  errorRoadmap: state.counterSlice.errorRoadmap,
});
const mapDispatchToProps = {
  deleteFilesId,
  removeFilesOpen,
  getFilesId,
  getFileRoadmap,
  getUsers,
};

// bind our actions to this.props

export default connect(mapStateToProps, mapDispatchToProps)(PageWithTwoSidebar);
