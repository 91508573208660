import React from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { instance, successPermissions } from "../../utils/index";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { AppSettings } from "../../config/app-settings.js";
import {
  deletePersonId,
  getEntities,
  getPersonsCustomer,
  getReasons,
} from "../../actions/index";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import TablePrueba from "./tablaTest";
class Freelancer extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);

    this.showTab = this.showTab.bind(this);
    this.setValue = this.setValue.bind(this);

    this.changeTypePerson = this.changeTypePerson.bind(this);

    this.state = {
      path: props.match.params.id,
      vista: 1,
      tabPost: false,
      tabAbout: true,
      tabPhoto: false,
      tabVideo: false,
      tabFriend: false,
      disabledbtn: true,
      persona_id: [],
      setValueis: "",
      city: "",
      redirect: false,
      sweetAlertError: false,
      isLoading: false,
      isLoadingCausante: false,
      isLoadingFree: false,
      isLoadingBen: false,
      banderaRT: true,
      Cities: [],
      value: "",
      ido: "",
      is_freelance: null,
      is_customer: null,
      is_staff: null,
      modalDialogAlertError: "",
      modalDialogAlert: false,
    };
  }

  componentWillMount() {
    axios
      .get("/city.json")
      .then((res) =>
        this.setState((state) => ({
          Cities: res.data,
        }))
      )
      .catch((err) => console.log(err.response));
  }

  componentWillMountedverdad(personaId) {
    if (personaId != this.state.ido) {
      this.setState((state) => ({
        ido: personaId,
      }));
      instance
        .get(`people/${personaId}`)
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          this.setState((state) => ({
            persona_id: data,
          }));
        })
        .catch((e) => console.warn("Error in saveDataLog", e.response));
    }
  }
  componentDidMount() {
    this.context.handleSetAppContentClass("p-0");
    this.context.handleSetAppSidebarTwo(false);
    const { getEntities, getReasons } = this.props;
    getEntities();
    getReasons();
  }

  componentWillUnmount() {
    this.context.handleSetAppContentClass("");

    this.context.handleSetAppSidebarTwo(false);
  }

  setValue(e) {
    getPersonsCustomer(e);
    this.setState((state) => ({
      setValueis: e,
    }));
  }
  changeTypePerson(person) {
    if (person == "Cliente") {
      return this.setState((state) => ({
        is_customer: true,
        is_freelance: false,
        is_staff: false,
      }));
    } else if (person == "Freelance") {
      return this.setState((state) => ({
        is_freelance: true,
        is_customer: false,
        is_staff: false,
      }));
    } else {
      return this.setState((state) => ({
        is_staff: true,
        is_customer: false,
        is_freelance: false,
      }));
    }
  }

  showTab(e, tab) {
    e.preventDefault();
    this.setState((state) => ({
      tabPost: tab === "post" ? true : false,
      tabAbout: tab === "about" ? true : false,
      tabPhoto: tab === "photo" ? true : false,
      tabVideo: tab === "video" ? true : false,
      tabFriend: tab === "friend" ? true : false,
    }));
  }
  handleSubmit(IdSelected) {
    instance
      .delete(`people/${IdSelected}`)
      .then((res) => {
        if (res.data) {
          this.setState((val) => ({
            ...val,
            ["redirect"]: true,
          }));
          return res.data;
        }
      })
      .catch((e) => {
        return this.setState((state) => ({
          sweetAlertError: false,
          modalDialogAlert: true,
          modalDialogAlertError: e?.response?.data?.detail,
        }));
      });
  }

  CreandoExpediente(state) {
    var bodyFormData = new FormData();

    if (state.first_name && state.persona_id.first_name != state.first_name) {
      bodyFormData.append("first_name", state.first_name);
    }
    if (state.last_name && state.persona_id.last_name != state.last_name) {
      bodyFormData.append("last_name", state.last_name);
    }
    if (
      state.document_type &&
      state.persona_id.document_type != state.document_type
    ) {
      bodyFormData.append("document_type", Number(state.document_type));
    }
    if (
      state.document_id &&
      state.persona_id.document_id != state.document_id
    ) {
      bodyFormData.append("document_id", state.document_id);
    }
    if (state.address && state.persona_id.address != state.address) {
      bodyFormData.append("address", state.address);
    }
    if (
      state.phone_personal &&
      state.persona_id.phone_personal != state.phone_personal
    ) {
      bodyFormData.append("phone_personal", state.phone_personal);
    }
    if (
      state.phone_office &&
      state.persona_id.phone_office != state.phone_office
    ) {
      bodyFormData.append("phone_office", state.phone_office);
    }
    if (state.phone_home && state.persona_id.phone_home != state.phone_home) {
      bodyFormData.append("phone_home", state.phone_home);
    }
    if (state.email && state.persona_id.email != state.email) {
      bodyFormData.append("email", state.email);
    }
    if (state.city && state.persona_id.city != state.city) {
      bodyFormData.append("city", state.city);
    }
    if (
      state.is_customer != null &&
      state.persona_id.is_customer != state.is_customer
    ) {
      bodyFormData.append("is_customer", state.is_customer);
    }
    if (
      state.is_freelance != null &&
      state.persona_id.is_freelance != state.is_freelance
    ) {
      bodyFormData.append("is_freelance", state.is_freelance);
    }
    if (state.is_staff != null && state.persona_id.is_staff != state.is_staff) {
      bodyFormData.append("is_staff", state.is_staff);
    }

    instance
      .patch(`people/${state.path}`, bodyFormData)
      .then((res) => {
        instance
          .get(`people/${this.props.match.params.id}`)
          .then((res) => {
            return res.data;
          })
          .then((data) => {
            this.setState((state) => ({
              persona_id: data,
              vista: 1,
            }));
          })
          .catch((e) => console.warn("Error in saveDataLog", e.response));
      })
      .catch((e) => {
        return this.setState((state) => ({
          sweetAlertError: false,
          modalDialogAlert: true,
          modalDialogAlertError: e?.response?.data?.detail,
        }));
      });
  }
  render() {
    this.componentWillMountedverdad(this.props.match.params.id);

    if (this.state.redirect) {
      return <Redirect to="/Freelance" />;
    }
    var typeDoc = [
      { label: "CC", value: 10 },
      { label: "NE", value: 20 },
      { label: "PST", value: 30 },
      { label: "NIT", value: 40 },
    ];

    function defaultypeDocument(person) {
      if (person.document_type == 10) {
        return {
          label: "NIT",
          value: 10,
        };
      } else if (person.document_type == 20) {
        return {
          label: "NE",
          value: 20,
        };
      } else if (person.document_type == 30) {
        return {
          label: "PST",
          value: 30,
        };
      } else if (person.document_type == 40) {
        return {
          label: "NIT",
          value: 40,
        };
      } else
        return {
          label: "CC",
          value: 10,
        };
    }
    const columnsForTable = [
      {
        Header: "Freelancer",
        accessor: "first_name",
        sortable: true,
      },

      {
        Header: "Documento",
        accessor: "document_id",
        sortable: true,
      },
      {
        Header: "Dirección",
        accessor: "address",
        sortable: true,
      },
      {
        Header: "Email",
        accessor: "email",
        sortable: true,
      },

      {
        Header: "Modificado",
        accessor: "updated_at",
        sortable: true,
      },
      {
        Header: "Tel Casa",
        accessor: "phone_home",
        sortable: true,
      },
      {
        Header: "Tel Personal",
        accessor: "phone_personal",
        sortable: true,
      },
      {
        Header: "Tel Oficina",
        accessor: "phone_office",
        sortable: true,
      },
    ];

    const relationsForTable =
      successPermissions("can_view_files_detail") == "d-none"
        ? [
            {
              Header: "Expediente",
              accessor: "number",
              sortable: true,
            },

            {
              Header: "Fecha",
              accessor: "open_date",
              sortable: true,
            },
            {
              Header: "Estado",
              accessor: "status",
              sortable: true,
            },
          ]
        : [
            {
              Header: "Expediente",
              accessor: "number",
              sortable: true,
            },

            {
              Header: "Fecha",
              accessor: "open_date",
              sortable: true,
            },
            {
              Header: "Estado",
              accessor: "status",
              sortable: true,
            },
            {
              Header: "Acciones",
              accessor: "Acciones",
              sortable: false,
            },
          ];

    return (
      <div style={{ padding: "3%" }}>
        <ol className="breadcrumb  float-xl-end">
          <li className="breadcrumb-item">
            <Link to="/dashboard/v3">Panel</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to="/Freelance">Freelancers</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to="#">Freelancer # {this.state.persona_id?.person?.id}</Link>
          </li>

          {/* <li className="breadcrumb-item active">Data Tables</li> */}
        </ol>
        <h1 className="page-header">
          Freelancer # {this.state.persona_id?.person?.id} <small></small>
        </h1>
        <Panel>
          <PanelHeader>
            {successPermissions("full_access_people") == "d-none" ? null : (
              <>
                {" "}
                <button
                  title="Ver"
                  className="btn btn-sm btn-success mb-10px me-1"
                  onClick={() =>
                    this.setState((state) => ({
                      vista: 1,
                    }))
                  }
                >
                  <i className="far fa-lg fa-fw fs-14px fa-eye"></i>
                </button>
                <button
                  title="Editar"
                  onClick={() =>
                    this.setState((state) => ({
                      vista: 2,
                      // disabledbtn: !this.state.disabledbtn,
                    }))
                  }
                  to="/extra/invoice"
                  className="btn btn-sm btn-orange mb-10px me-1"
                >
                  <i className="fas fa-lg fa-fw fs-14px fa-pen-to-square"></i>{" "}
                </button>
              </>
            )}
            {successPermissions("full_access_people_delete") ==
            "d-none" ? null : (
              <button
                title="Borrar"
                onClick={() =>
                  this.setState((state) => ({
                    sweetAlertError: true,
                  }))
                }
                className="btn btn-sm btn-danger mb-10px me-1"
              >
                <i className="fa fa-trash fs-14px text-white"></i>
              </button>
            )}
          </PanelHeader>

          <div className="hljs-wrapper">
            <div className="profile-content">
              <div className="tab-content p-0">
                <div>
                  <div
                    className={
                      "tab-pane fade " +
                      (this.state.tabAbout ? "show active " : "")
                    }
                  >
                    <div className="table-responsive form-inline">
                      {this.state.vista === 1 ? (
                        <div>
                          <TablePrueba
                            data={
                              this.state.persona_id.person
                                ? [this.state.persona_id.person]
                                : []
                            }
                            columnsData={columnsForTable}
                            pagination={false}
                          >
                            {" "}
                          </TablePrueba>

                          <div
                            className="my-3"
                            style={{
                              height: "auto",
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "center",
                            }}
                          >
                            {this.state.persona_id?.freelance_in &&
                            this.state.persona_id.freelance_in.length > 0 ? (
                              <Panel className="m-2">
                                <PanelHeader>Freelance en:</PanelHeader>
                                <div className="p-3">
                                  <TablePrueba
                                    data={this.state.persona_id.freelance_in}
                                    columnsData={relationsForTable}
                                    pagination={true}
                                    height={"20vh"}
                                  ></TablePrueba>
                                </div>{" "}
                              </Panel>
                            ) : null}
                            {this.state.persona_id?.beneficiaries_in &&
                            this.state.persona_id.beneficiaries_in.length >
                              0 ? (
                              <Panel className="m-2">
                                <PanelHeader>Beneficiario en:</PanelHeader>{" "}
                                <div className="p-3">
                                  <TablePrueba
                                    data={
                                      this.state.persona_id.beneficiaries_in
                                    }
                                    columnsData={relationsForTable}
                                    pagination={true}
                                    height={"20vh"}
                                  ></TablePrueba>
                                </div>
                              </Panel>
                            ) : null}{" "}
                          </div>
                        </div>
                      ) : (
                        <table className="table table-profile align-middle">
                          <tbody
                            style={{
                              height: "auto",
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "center",
                            }}
                          >
                            <tr>
                              <td>
                                <span className="fw-bold">Nombres</span>
                                <br />
                                <input
                                  // disabled={this.state.disabledbtn}
                                  style={{
                                    border: "1px solid hsl(0, 0%, 80%)",
                                    borderRadius: "4px",
                                    padding: "10px",
                                  }}
                                  onChange={(e) =>
                                    this.setState((state) => ({
                                      first_name: e.target.value,
                                    }))
                                  }
                                  defaultValue={
                                    this.state.persona_id?.person.first_name
                                  }
                                  className=" w-300px py-2"
                                  type="text"
                                ></input>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <span className="fw-bold">Apellidos</span>
                                <br />
                                <input
                                  // disabled={this.state.disabledbtn}
                                  style={{
                                    border: "1px solid hsl(0, 0%, 80%)",
                                    borderRadius: "4px",
                                    padding: "10px",
                                  }}
                                  onChange={(e) =>
                                    this.setState((state) => ({
                                      last_name: e.target.value,
                                    }))
                                  }
                                  defaultValue={
                                    this.state.persona_id?.person.last_name
                                  }
                                  className=" w-300px py-2"
                                  type="text"
                                ></input>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="fw-bold">Dirección</span>
                                <br />
                                <input
                                  // disabled={this.state.disabledbtn}
                                  style={{
                                    border: "1px solid hsl(0, 0%, 80%)",
                                    borderRadius: "4px",
                                    padding: "10px",
                                  }}
                                  onChange={(e) =>
                                    this.setState((state) => ({
                                      address: e.target.value,
                                    }))
                                  }
                                  defaultValue={
                                    this.state.persona_id?.person.address
                                  }
                                  className=" w-300px py-2"
                                  type="text"
                                ></input>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="fw-bold">Email</span>
                                <br />
                                <input
                                  // disabled={this.state.disabledbtn}
                                  style={{
                                    border: "1px solid hsl(0, 0%, 80%)",
                                    borderRadius: "4px",
                                    padding: "10px",
                                  }}
                                  onChange={(e) =>
                                    this.setState((state) => ({
                                      email: e.target.value,
                                    }))
                                  }
                                  defaultValue={
                                    this.state.persona_id?.person.email
                                  }
                                  className=" w-300px py-2"
                                  type="text"
                                ></input>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="fw-bold">Tipo Documento</span>
                                <br />
                                {this.state.persona_id?.person.document_type ? (
                                  <Select
                                    // isDisabled={this.state.disabledbtn}
                                    defaultValue={() =>
                                      defaultypeDocument(
                                        this.state.persona_id?.person
                                      )
                                    }
                                    className="widthInput w-md-300px"
                                    options={
                                      typeDoc.length > 0 &&
                                      typeDoc.map((doc) => ({
                                        value: doc.value,
                                        label: doc.label,
                                      }))
                                    }
                                    placeholder="Selecciona"
                                    onChange={(e) =>
                                      this.setState((state) => ({
                                        document_type: e.value,
                                      }))
                                    }
                                  />
                                ) : null}
                                {!this.state.persona_id?.person
                                  .document_type ? (
                                  <Select
                                    // isDisabled={this.state.disabledbtn}
                                    defaultValue={{
                                      label: "CC",
                                      value: 10,
                                    }}
                                    className="widthInput w-md-300px m-1"
                                    options={
                                      typeDoc.length > 0 &&
                                      typeDoc.map((doc) => ({
                                        value: doc.value,
                                        label: doc.label,
                                      }))
                                    }
                                    placeholder="Selecciona"
                                    onChange={(e) =>
                                      this.setState((state) => ({
                                        document_type: e.value,
                                      }))
                                    }
                                  />
                                ) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="fw-bold">Documento</span>
                                <br />
                                <input
                                  // disabled={this.state.disabledbtn}
                                  style={{
                                    border: "1px solid hsl(0, 0%, 80%)",
                                    borderRadius: "4px",
                                    padding: "10px",
                                  }}
                                  onChange={(e) =>
                                    this.setState((state) => ({
                                      document_id: e.target.value,
                                    }))
                                  }
                                  defaultValue={
                                    this.state.persona_id?.person.document_id
                                  }
                                  className=" w-300px py-2"
                                  type="text"
                                ></input>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <span className="fw-bold">Teléfono casa</span>
                                <br />
                                <input
                                  // disabled={this.state.disabledbtn}
                                  style={{
                                    border: "1px solid hsl(0, 0%, 80%)",
                                    borderRadius: "4px",
                                    padding: "10px",
                                  }}
                                  onChange={(e) =>
                                    this.setState((state) => ({
                                      phone_home: e.target.value,
                                    }))
                                  }
                                  defaultValue={
                                    this.state.persona_id?.person.phone_home
                                  }
                                  className=" w-300px py-2"
                                  type="text"
                                ></input>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="fw-bold">Número celular</span>
                                <br />
                                <input
                                  // disabled={this.state.disabledbtn}
                                  style={{
                                    border: "1px solid hsl(0, 0%, 80%)",
                                    borderRadius: "4px",
                                    padding: "10px",
                                  }}
                                  onChange={(e) =>
                                    this.setState((state) => ({
                                      phone_personal: e.target.value,
                                    }))
                                  }
                                  defaultValue={
                                    this.state.persona_id?.person.phone_personal
                                  }
                                  className=" w-300px py-2"
                                  type="text"
                                ></input>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="fw-bold">
                                  Teléfono oficina
                                </span>
                                <br />
                                <input
                                  // disabled={this.state.disabledbtn}
                                  style={{
                                    border: "1px solid hsl(0, 0%, 80%)",
                                    borderRadius: "4px",
                                    padding: "10px",
                                  }}
                                  onChange={(e) =>
                                    this.setState((state) => ({
                                      phone_office: e.target.value,
                                    }))
                                  }
                                  defaultValue={
                                    this.state.persona_id?.person.phone_office
                                  }
                                  className=" w-300px py-2"
                                  type="text"
                                ></input>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <span className="fw-bold">Departamento</span>
                                <br />
                                <Select
                                  // isDisabled={this.state.disabledbtn}
                                  className="widthInput w-md-300px"
                                  options={
                                    this.state.Cities.length > 0 &&
                                    this.state.Cities.map((personCus) => ({
                                      value: personCus.id,
                                      label: personCus.departamento,
                                    }))
                                  }
                                  placeholder="Selecciona"
                                  defaultValue={false}
                                  name="city"
                                  onChange={(e) =>
                                    this.setState((state) => ({
                                      ["department"]: e.value,
                                    }))
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="fw-bold">Ciudad</span>
                                <br />
                                {this.state.persona_id?.person.city ? (
                                  <Select
                                    // isDisabled={this.state.disabledbtn}
                                    className="widthInput w-md-300px"
                                    options={
                                      this.state.Cities.length > 0 &&
                                      this.state.Cities.filter(
                                        (e) => e.id == this.state["department"]
                                      )[0] &&
                                      this.state.Cities.filter(
                                        (e) => e.id == this.state["department"]
                                      )[0]["ciudades"].map((personCus) => ({
                                        value: personCus,
                                        label: personCus,
                                      }))
                                    }
                                    placeholder="Selecciona"
                                    defaultValue={{
                                      value: this.state.persona_id?.person.city,
                                      label: this.state.persona_id?.person.city,
                                    }}
                                    onChange={(e) =>
                                      this.setState((state) => ({
                                        ["city"]: e.value,
                                      }))
                                    }
                                  />
                                ) : null}
                                {!this.state.persona_id?.person.city ? (
                                  <Select
                                    // isDisabled={this.state.disabledbtn}
                                    className="widthInput w-md-300px   m-1"
                                    options={
                                      this.state.Cities.length > 0 &&
                                      this.state.Cities.filter(
                                        (e) => e.id == this.state["department"]
                                      )[0] &&
                                      this.state.Cities.filter(
                                        (e) => e.id == this.state["department"]
                                      )[0]["ciudades"].map((personCus) => ({
                                        value: personCus,
                                        label: personCus,
                                      }))
                                    }
                                    placeholder="Selecciona"
                                    defaultValue={false}
                                    onChange={(e) =>
                                      this.setState((state) => ({
                                        ["city"]: e.value,
                                      }))
                                    }
                                  />
                                ) : null}
                              </td>
                            </tr>
                            <tr>
                              <br />
                              <td>
                                <div
                                  className="d-flex mx-auto w-300px justify-content-around"
                                  style={{ padding: "0.5rem" }}
                                >
                                  {/* <tr className="d-flex">
                            {" "}
                           
                            <td className="fw-bold my-auto">Cliente</td>
                            <td className=" m-auto  h-100 d-flex">
                         
                              <input
                                // disabled={this.state.disabledbtn}
                                type="checkbox"
                                id="Cliente"
                                name="Cliente"
                                onChange={(e) =>
                                  this.setState((state) => ({
                                    ["is_customer"]: e.target.checked,
                                  }))
                                }
                                checked={
                                  this.state.is_customer != null
                                    ? this.state.is_customer
                                    : this.state.persona_id?.person.is_customer
                                }
                              ></input>
                            </td>
                          </tr>
                          <tr className="d-flex">
                          <td className="fw-bold my-auto">Freelance</td>
                            <td className=" m-auto  h-100 d-flex">
                          
                         
                              {" "}
                              <input
                                // disabled={this.state.disabledbtn}
                                type="checkbox"
                                id="Freelance"
                                name="Freelance"
                                onChange={(e) =>
                                  this.setState((state) => ({
                                    ["is_freelance"]: e.target.checked,
                                  }))
                                }
                                checked={
                                  this.state.is_freelance != null
                                    ? this.state.is_freelance
                                    : this.state.persona_id?.person.is_freelance
                                }
                              ></input>
                            </td>
                          </tr>
                          <tr className="d-flex">   
                        
                            <td className="fw-bold my-auto">De la Firma</td>
                            <td className=" m-auto h-100 d-flex">
                       
                              <input
                                // disabled={this.state.disabledbtn}
                                type="checkbox"
                                id="Staff"
                                name="Staff"
                                onChange={(e) =>
                                  this.setState((state) => ({
                                    ["is_staff"]: e.target.checked,
                                  }))
                                }
                                checked={
                                  this.state.is_staff != null
                                    ? this.state.is_staff
                                    : this.state.persona_id?.person.is_staff
                                }
                              ></input>
                            </td>
                          </tr> */}
                                </div>
                              </td>
                            </tr>
                            <tr className="divider">
                              <td colSpan="2"></td>
                            </tr>
                            <tr className="highlight">
                              <td className="field" style={{ border: "none" }}>
                                &nbsp;
                              </td>
                              <td
                                style={{ border: "none" }}
                                className="d-flex my-auto"
                                //   style={{ justifyContent: "flex-start" }}
                              >
                                <button
                                  onClick={() => {
                                    this.CreandoExpediente(this.state);
                                  }}
                                  type="submit"
                                  className="btn btn-warning w-150px"
                                >
                                  Guardar cambios
                                </button>{" "}
                                <button
                                  onClick={() =>
                                    this.setState((state) => ({
                                      vista: 1,
                                    }))
                                  }
                                  className="btn btn-white border-0 w-150px ms-2px"
                                >
                                  Cancelar
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Panel>
        {this.state.sweetAlertError && (
          <SweetAlert
            danger
            showCancel
            confirmBtnText="Sí, borrar!"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            cancelBtnText="Cancelar"
            title="Estás seguro de borrar"
            onConfirm={() => {
              //   removeFilesOpen(this.state.path);
              //   deleteFilesId(this.state.path);
              this.handleSubmit(this.state.persona_id?.person.id);
            }}
            onCancel={() =>
              this.setState((state) => ({
                sweetAlertError: false,
              }))
            }
          >
            El empleado{" "}
            {this.state.persona_id?.person.first_name +
              " " +
              this.state.persona_id?.person.last_name}
          </SweetAlert>
        )}
        <Modal isOpen={this.state.modalDialogAlert}>
          <ModalHeader
            close={
              <button
                className="btn-close"
                onClick={() =>
                  this.setState((state) => ({
                    modalDialogAlert: !this.state.modalDialogAlert,
                  }))
                }
              ></button>
            }
          >
            Algo ha ocurrido
          </ModalHeader>
          <ModalBody>
            <div className="alert alert-danger mb-0">
              <h5>
                <i className="fa fa-info-circle"></i> Algo salió mal
              </h5>
              <p>
                {this.state.modalDialogAlertError
                  ? this.state.modalDialogAlertError
                  : "Lo sentimos algo a fallado, vuelve a intentar en un momento."}
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-white"
              onClick={() =>
                this.setState((state) => ({
                  modalDialogAlert: !this.state.modalDialogAlert,
                }))
              }
            >
              Cerrar
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  Entities: state.counterSlice.Entities,
  personsCustomers: state.counterSlice.personsCustomers,
  reasons: state.counterSlice.reasons,
});
const mapDispatchToProps = {
  getEntities,
  getPersonsCustomer,
  getReasons,
};

export default connect(mapStateToProps, mapDispatchToProps)(Freelancer);

