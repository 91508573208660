import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import imagenProfile from "../../assets/img/user/user-13.jpg";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Store, ReactNotifications } from "react-notifications-component";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { AppSettings } from "../../config/app-settings.js";
import SweetAlert from "react-bootstrap-sweetalert";
import person from "../../assets/img/user/user-13.jpg";
import { count_type, pension_type, status } from "../../utils/index.js";
import Moment from "moment";
import { instance } from "../../utils/index.js";

import {
    deleteCountId,
  removeFilesOpen,
  getFilesId,

  getUsers,getCountsId
} from "../../actions/index.js";
import TablePrueba from "./tablaTest";
import { ReactToPrint  } from "react-to-print";
class VerConteo extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);
    this.state = {
      path: props.match.params.id,
      actual_date: Moment().format("L"),
      before_date: Moment().subtract(1, "days").format("L"),
      sweetAlertPrimary: false,
      sweetAlertInfo: false,
      sweetAlertWarning: false,
      sweetAlertSuccess: false,
      sweetAlertError: false,
      modalAsignment: false,
      modalReject: false,
      AsignaraError: false,
      AsignaraCommentError: false,
      Asignara: "",
      comentarioAsignar: "",
      comentarioFacebook: "",
    };
    this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitComentarioFace = this.submitComentarioFace.bind(this);
    this.addNotification = this.addNotification.bind(this);
    this.submitReject = this.submitReject.bind(this);
  
  }
  addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <img src={imagenProfile} width="52" alt="" className="rounded" />
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }
  tableRef = React.createRef();

  componentWillMount() {
    const { getFilesId, getUsers,getCountsId } = this.props;
    getFilesId(this.state.path);
    getUsers();
    getCountsId(this.state.path);
  }
  toggleModal(name) {
    switch (name) {
      case "modalAddTask":
        this.setState({ modalAsignment: !this.state.modalAsignment });
        break;
      case "modalReject":
        this.setState({ modalReject: !this.state.modalReject });
        break;
      default:
        break;
    }
  }

  toggleSweetAlert(name) {
    switch (name) {
      case "primary":
        this.setState({ sweetAlertPrimary: !this.state.sweetAlertPrimary });
        break;
      case "info":
        this.setState({ sweetAlertInfo: !this.state.sweetAlertInfo });
        break;
      case "success":
        this.setState({ sweetAlertSuccess: !this.state.sweetAlertSuccess });
        break;
      case "warning":
        this.setState({ sweetAlertWarning: !this.state.sweetAlertWarning });
        break;
      case "error":
        this.setState({ sweetAlertError: !this.state.sweetAlertError });
        break;
      default:
        break;
    }
  }

  submitComentarioFace(event) {
   
    event.target.reset();
    event.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.append("parent", event.target.id);
    bodyFormData.append("comment", this.state.comentarioFacebook);
    instance
      .post(`files/${this.state.path}/comment`, bodyFormData)
      .then((res) => {
       
        if (res.status === 201 || res.status === 200) {
          this.setState({
            comentarioFacebook: "",
          });

          this.props.getFileRoadmap(this.state.path);
        }
      })
      .catch((e) => console.log("Error in asignament", e));
  }
  submitReject(event) {
    
    event.preventDefault();

    if (this.state.comentarioAsignar === "") {
      this.setState({ AsignaraCommentError: !this.state.AsignaraCommentError });
      setTimeout(() => {
        this.setState({
          AsignaraCommentError: !this.state.AsignaraCommentError,
        });
      }, 1000);
    }
    var bodyFormData = new FormData();

    bodyFormData.append("comment", this.state.comentarioAsignar);
    instance
      .post(`files/${this.state.path}/assign`, bodyFormData)
      .then((res) => {
        
        if (res.status === 201) {
          this.setState({
            Asignara: "",
            comentarioAsignar: "",
          });
          this.toggleModal("modalAddTask");
          this.props.getFileRoadmap(this.state.path);
        }
      })
      .catch((e) => console.log("Error in asignament", e));
  }
  

 
  handleSubmit() {
    instance
      .post(`files/${this.state.path}/accept`)
      .then((res) => {
        
        if (res.status === 201 || res.status === 200) {
          this.addNotification(
            "default",
            "",
            `Expediente # ${this.state.path} copiado`,
            "bottom-center"
          );

          this.props.getFileRoadmap(this.state.path);
        }
      })
      .catch((e) => console.log("Error in asignament", e));
  }
  componentDidMount() {
    this.context.handleSetAppSidebarTwo_count(true);
  }

  componentWillUnmount() {
    this.context.handleSetAppSidebarTwo_count(false);
  }
  render() {
    const {  countId,deleteCountId } = this.props;

    
     function discriminate(val, option) {
      var factor = 1 / 12;
      var base =countId.count_type == 10 ? 360 : 365;
      var year = Math.floor(val / base);
      base = val / base - year;
      var month = Math.floor(base / factor);
      base = base / factor;
      base = base - Math.floor(base);
      var days = Math.floor(base * 30);
      if (option == "year") {
        return Math.round(year);
      } else if (option == "months") {
        return Math.round(month);
      } else if (option == "days") {
        return Math.round(days);
      } else {
        return Math.round(val / 7);
      }
    }
    const columnsForTable =[
      {
        Header: "Empleador",
        accessor: "employer",
        sortable: true,
      },
      {
        Header: "Cotizado A",
        accessor: "contribution",
        sortable: true,
      },
      {
        Header: "Tipo Aporte",
        accessor: "contribution_type",
        sortable: true,
      },

      {
        Header: "Ingreso",
        accessor: "start_date",
        sortable: true,
      },
      {
        Header: "Egreso",
        accessor: "end_date",
        sortable: true,
      },
      {
        Header: "Total parcial",
        accessor: "partial_total",
        sortable: true,
      },
      {
        Header: "Dias Sim",
        accessor: "simultaneous_days",
        sortable: true,
      },

      {
        Header: "Total Días",
        accessor: "days_total",
        sortable: true,
      },

    ]

    function print(firstTable,secondTable,countId) {
      const daysTotal =countId?.days_total;
      const weekTtotal =  Math.round(daysTotal / 7)

      let innerHtmlTableone=`<table   className="table table-panel table-bordered mb-0" style={{minWidth:"40rem"}}>
      <thead
     
        style={{
        
          fontSize: "1rem",
        }}
      >
        <tr className="d-flex ">
          <th className="col-4">Nombre del cliente</th>
          <th className="col text-center">Identificación</th>
          <th className="col text-center">Fecha de nacimiento</th>
       
        </tr>
      </thead>
      <tbody>  <tr>
      <td>${countId.name}</td>
      <td>${countId.document}</td>
      <td>${countId.birth}</td>
    </tr>  </tbody></table>`
    let innerHtmlTableTwo=` <table   className="table table-panel table-bordered mb-0" style={{minWidth:"40rem"}}>
    <thead
   
      style={{
      
        fontSize: "1rem",
      }}
    >
      <tr className="d-flex ">
        <th className="col-4">Tipo de pensión</th>
        <th className="col text-center">Tipo de Conteo</th>
        <th className="col text-center">Conteo Número</th>
        <th className="col text-center">Responsable</th>
     
      </tr>
    </thead>
    <tbody>  <tr>
    <td>${pension_type(countId.pension_type)}</td>
    <td>${count_type(countId.count_type) } días</td>
    <td>${countId.id}</td>
    <td>${countId.user.full_name}</td>
  </tr>  </tbody></table>`
let innerHtmlTableComents= `<table>
<thead

  style={{
  
    fontSize: "1rem",
  }}
>
  <tr className="">
    <th className="col-4">Observaciones</th>
  </tr>
</thead>
<tbody>  <tr>

<td>${countId.comments}</td>
</tr>  </tbody></table>`
let innerHtmlTableDays= `<div class="contain_days"><div class="empy_div"></div><div class="total_days"><table>
<thead>
  <tr className="">
    <th >Días totales</th>
    <th >Semanas totales</th>
  </tr>
</thead>
<tbody>
<tr>
<td>${daysTotal}</td>
<td>${weekTtotal}</td>
</tr>
</tbody></table></div></div>`
let tableItems = secondTable ? secondTable.innerHTML :``;
let tableResumen = firstTable ? firstTable.innerHTML :``;

      let innerHTML = `<!DOCTYPE html>
      <html lang="pt-BR">
      <head>   
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
      <link href="/stylesPrint.css" rel="stylesheet" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet">
      </head>
      <body><main><div id='impresionReview'>
        <div class="watermark"></div>
         <div class="containerLogo">
         <div class="textoLogo"><h1>Conteo del Expediente No. ${countId.file.number}</h1>
         </div> 
         <div class="container_img"><img src="https://siep.restrepofajardo.com/img/logo1.png" alt="logo"/>
         </div>
         </div>`;
      innerHTML +=innerHtmlTableone + innerHtmlTableTwo + tableResumen  + innerHtmlTableDays + tableItems  + innerHtmlTableComents + `</div></main> <div class="page-footer"><span>${localStorage.full_name}</span><span>${Moment().format("D [de] MMMM [del] YYYY, h:mm a")}</span></div></body></html>`;
      
      let fakeIFrame = window.document.createElement("iframe");
    
      document.body.appendChild(fakeIFrame);
      let fakeContent = fakeIFrame.contentWindow;
      fakeContent.document.open();
      fakeContent.document.write(innerHTML);
      fakeContent.document.close();
      fakeContent.focus();
      fakeIFrame.addEventListener("load", () => {
          fakeContent.print();
          fakeIFrame.remove();
      });
  }

    return (
      <div>
         <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">
          <Link to="/Conteos">Conteos</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to="#">Conteo # {countId?.id} </Link>
        </li>
        {/* <li className="breadcrumb-item active">Data Tables</li> */}
      </ol>
      <h1 className="page-header">Conteo # {countId?.id}  </h1>
  <div className="w-100 d-flex justify-content-end"><button title="Imprimir"
                onClick={()=>{
                  let firstTable=countId.items.length>0 ?document.getElementById("printerNow"):null
                  let secondTable=countId.items.length>0 ?document.getElementById("secondTablePrint"):null;
                  print(firstTable,secondTable,countId)}}
                className="btn btn-sm btn-white mb-10px ms-auto me-0"
              >
                <i className="fa fa-print t-plus-1 fa-fw fa-lg"></i>
              </button></div>
      
              
     { !countId.id?<div className="fa-3x d-flex justify-content-center"> 
        <i className="fas fa-cog fa-spin"></i> </div>:   <Panel>
          <PanelHeader>Conteo # {countId?.id}</PanelHeader>
         
          <div className="hljs-wrapper" >
          <div className="profile-content">
            <div className="tab-content p-0">
             
              {countId.items && countId.items?.length>0?<div className="table-responsive" id={"printerNow"}>
              <TablePrueba   data={countId.items?countId.items:[]}  columnsData={columnsForTable} pagination={true} > </TablePrueba>
              <br />
              </div>:null}
                <div className=" form-inline">
          <div className="">
                    <div style={{ textAlign: "right" }} className="row">
                      <div className="col">
                        <span
                          className="px-2"
                          style={{ fontWeight: "bold", fontSize: "1rem" }}
                        >
                          TOTAL DÍAS : {countId?.days_total}
                        </span>{" "}
                        <span
                          className=" px-2"
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            borderLeft: "1px solid gray",
                          }}
                        >
                          TOTAL SEMANAS :{Math.round(countId?.days_total / 7)}
                        </span>
                      </div>
                    </div>
                  </div></div>
                  <br />
                  <PerfectScrollbar id={"secondTablePrint"} >
                
                    <table   className="table table-panel table-bordered mb-0" style={{minWidth:"40rem"}}>
                      <thead
                     
                        style={{
                        
                          fontSize: "1rem",
                        }}
                      >
                        <tr className="d-flex ">
                          <th className="col-4">Resumen</th>
                          <th className="col text-center">Calculo</th>
                          <th className="col text-center">Años </th>
                          <th className="col text-center">Meses</th>
                          <th className="col text-center">Días</th>
                          <th className="col text-center">Semana</th>
                        </tr>
                      </thead>
                      <tbody>
                 
                        <tr className="d-flex">
                          <td className="col-4">
                            Público No Cotizado a Cajas
                          </td>
                          <td className="col text-center">
                            {countId?.public_no_fund}
                          </td>
                          <td className="col text-center ">
                            {discriminate(countId?.public_no_fund, "year")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.public_no_fund, "months")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.public_no_fund, "days")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.public_no_fund, "weeks")}
                          </td>
                        </tr>
                    
                    
                        <tr className="d-flex">
                          <td className="col-4">Público Cotizado a Cajas</td>
                          <td className="col text-center">
                            {countId?.public_fund}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.public_fund, "year")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.public_fund, "months")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.public_fund, "days")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.public_fund, "weeks")}
                          </td>
                        </tr>
                     
                 
                        <tr className="d-flex">
                          <td className="col-4">Fondo</td>
                          <td className="col text-center">
                            {countId?.fund}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.fund, "year")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.fund, "months")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.fund, "days")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.fund, "weeks")}
                          </td>
                        </tr>
                     
                  
                        <tr className="d-flex">
                          <td className="col-4">Total Público</td>
                          <td className="col text-center">
                            {countId?.public_total}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.public_total, "year")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.public_total, "months")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.public_total, "days")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.public_total, "weeks")}
                          </td>
                        </tr>
                    
                  
                        <tr className="d-flex">
                          <td className="col-4">
                            Tiempo Cotizado al 1 Abril 94
                          </td>
                          <td className="col text-center">
                            {countId?.time_94}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.time_94, "year")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.time_94, "months")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.time_94, "days")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.time_94, "weeks")}
                          </td>
                        </tr>
                      
                  
                        <tr className="d-flex">
                          <td className="col-4 my-auto">
                            Tiempo Cotizado al 22 de Julio 2005
                          </td>
                          <td className="col text-center my-auto">
                            {countId?.time_2005}
                          </td>
                          <td className="col text-center my-auto">
                            {discriminate(countId?.time_2005, "year")}
                          </td>
                          <td className="col text-center my-auto">
                            {discriminate(countId?.time_2005, "months")}
                          </td>
                          <td className="col text-center my-auto">
                            {discriminate(countId?.time_2005, "days")}
                          </td>
                          <td className="col text-center my-auto">
                            {discriminate(countId?.time_2005, "weeks")}
                          </td>
                        </tr>
                     
                   
                        {" "}
                        <tr className="d-flex">
                          <td className="col-4">Privado Tradicionales</td>
                          <td className="col text-center">
                            {countId?.private_traditional}{" "}
                          </td>
                          <td className="col text-center">
                            {discriminate(
                              countId?.private_traditional,
                              "year"
                            )}
                          </td>
                          <td className="col text-center">
                            {discriminate(
                              countId?.private_traditional,
                              "months"
                            )}
                          </td>
                          <td className="col text-center">
                            {discriminate(
                              countId?.private_traditional,
                              "days"
                            )}
                          </td>
                          <td className="col text-center">
                            {discriminate(
                              countId?.private_traditional,
                              "weeks"
                            )}
                          </td>
                        </tr>
                      
                 
                        <tr className="d-flex">
                          <td className="col-4">Privado Autoliss</td>
                          <td className="col text-center">
                            {countId?.private_autoliss}{" "}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.private_autoliss, "year")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.private_autoliss, "months")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.private_autoliss, "days")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.private_autoliss, "weeks")}
                          </td>
                        </tr>
                      
                  
                        <tr className="d-flex">
                          <td className="col-4">Total Privado</td>
                          <td className="col text-center">
                            {countId?.private_total}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.private_total, "year")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.private_total, "months")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.private_total, "days")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.private_total, "weeks")}
                          </td>
                        </tr>
                     
                     
                        <tr className="d-flex">
                          <td className="col-4">Total Cotizaciones</td>
                          <td className="col text-center">
                            {countId?.total_contributions}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.total_contributions, "year")}
                          </td>
                          <td className="col text-center">
                            {discriminate(
                              countId?.total_contributions,
                              "months"
                            )}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.total_contributions, "days")}
                          </td>
                          <td className="col text-center">
                            {discriminate(countId?.total_contributions, "weeks")}
                          </td>
                        </tr>
                       </tbody>
                    </table>
                  </PerfectScrollbar>
          <ReactNotifications />
          <Modal
            isOpen={this.state.modalReject}
            toggle={() => this.toggleModal("modalReject")}
          >
            <ModalHeader
              // toggle={() => setmodalAsignment(!modalAsignment)}
              close={
                <button
                  className="btn-close"
                  onClick={() => {
                    this.toggleModal("modalReject");
                  }}
                ></button>
              }
            >
              Rechazar Expediente
            </ModalHeader>
            <form onSubmit={this.submitReject}>
              <ModalBody>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="d-flex  mx-auto" style={{ width: "80%" }}>
                    <div className="field m-auto">Comentario</div>
                    <div>
                      <textarea
                        onChange={(e) =>
                          this.setState({ comentarioAsignar: e.target.value })
                        }
                        placeholder="Escribe tu comentario"
                        className="w-300px m-auto p-2"
                        style={{
                          borderColor: "hsl(0, 0%, 80%)",
                          borderRadius: "4px",
                        }}
                      ></textarea>
                    </div>
                  </div>
                  {this.state.AsignaraCommentError ? (
                    <div className="d-flex mx-auto" style={{ width: "80%" }}>
                      <div className="w-75px field m-auto "></div>
                      <div className="w-300px m-auto">
                        {" "}
                        <span style={{ color: "red" }}>
                          Este campo es necesario
                        </span>{" "}
                      </div>{" "}
                    </div>
                  ) : null}
                </div>
              </ModalBody>
              <ModalFooter>
                <button
                  className="btn btn-white"
                  onClick={() => {
                    this.toggleModal("modalReject");
                  }}
                >
                  Cerrar
                </button>
                <button type="submit" className="btn btn-danger">
                  Rechazar
                </button>
              </ModalFooter>
            </form>
          </Modal>
          {this.state.sweetAlertError && (
            <SweetAlert
              danger
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={() => {
        
                deleteCountId(this.state.path);
                this.handleSubmit();
              }}
              onCancel={() => this.toggleSweetAlert("error")}
            >
              You will not be able to recover this imaginary file!
            </SweetAlert>
          )}
          
         
          </div>
          </div>
          </div>
        </Panel>}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  filesLoaded: state.counterSlice.filesLoaded,
  fileId: state.counterSlice.fileId,
  users: state.counterSlice.users,
  countId: state.counterSlice.countId,
});
const mapDispatchToProps = {
  
  removeFilesOpen,
  getFilesId,

  getUsers,
  getCountsId,deleteCountId
};

// bind our actions to this.props

export default connect(mapStateToProps, mapDispatchToProps)(VerConteo);
