import React from "react";
import { connect } from "react-redux";
import Moment from "moment";
import { instance, successPermissions } from "../../utils/index";
import { Store, ReactNotifications } from "react-notifications-component";
import { Panel, PanelHeader } from "./../../components/panel/panel.jsx";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { AppSettings } from "./../../config/app-settings.js";
import {
  getEntities,
  getPersonsCustomer,
  getReasons,
  getFilesId,
} from "../../actions/index";
import { Link, Redirect } from "react-router-dom";
class CrearExpediente extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);
    this.showTab = this.showTab.bind(this);
    this.setValue = this.setValue.bind(this);

    this.state = {
      path: props.match.params.id,
      tabPost: false,
      tabAbout: true,
      tabPhoto: false,
      tabVideo: false,
      tabFriend: false,
      Beneficiarios: [],
      setValueis: "",
      creadopor: 86,
      isLoading: false,
      isLoadingCausante: false,
      isLoadingFree: false,
      isLoadingBen: false,
      banderaRT: true,
      options: [],
      value: "",
      ido: "",
    };
  }
  componentWillMount() {
    instance
      .get(`files/reasons`)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        this.setState((state) => ({
          options: data.map((val) => ({
            value: val,
            label: val,
          })),
        }));
      })
      .catch((e) => console.warn("Error in saveDataLog", e.response));
  }
  componentDidMount() {
    this.context.handleSetAppContentClass("p-0");
    this.context.handleSetAppSidebarTwo(true);
    const { getEntities, getReasons, getFilesId } = this.props;
    getEntities();
    getReasons();
    getFilesId(this.state.path);
    // getPersonsCustomer();
  }

  componentWillUnmount() {
    this.context.handleSetAppContentClass("");

    this.context.handleSetAppSidebarTwo(false);
  }
  componentWillMountedverdad(personaId) {
    const { getFilesId } = this.props;
    if (personaId != this.state.ido) {
      this.setState((state) => ({
        ido: personaId,
      }));
      getFilesId(personaId);
    }
  }

  setValue(e) {
    getPersonsCustomer(e);
    this.setState((state) => ({
      setValueis: e,
    }));
  }
  addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }
  showTab(e, tab) {
    e.preventDefault();
    this.setState((state) => ({
      tabPost: tab === "post" ? true : false,
      tabAbout: tab === "about" ? true : false,
      tabPhoto: tab === "photo" ? true : false,
      tabVideo: tab === "video" ? true : false,
      tabFriend: tab === "friend" ? true : false,
    }));
  }
  fede() {
    this.setState((val) => ({
      ...val,
      ["redirect"]: true,
    }));
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to="/Expedientes" />;
    }
    this.componentWillMountedverdad(this.props.match.params.id);
    // this.entidadset();
    const { personsCustomers, getPersonsCustomer, Entities, fileId } =
      this.props;
    // getPersonsCustomer();

    console.log(fileId);

    function CreandoExpediente(state, fileId, addNotification) {
      if (
        state.Expediente ||
        state.process_number ||
        state.Apertura ||
        state.Consulta ||
        state.Entidad ||
        state.Cliente ||
        state.Causante ||
        state.Freelance ||
        state.Beneficiarios.length > 0
      ) {
        var bodyFormData = new FormData();

        if (state.Expediente && fileId?.number != state.Expediente) {
          bodyFormData.append("number", state.Expediente);
        }
        if (
          state.process_number &&
          fileId?.process_number != state.process_number
        ) {
          bodyFormData.append("process_number", state.process_number);
        }
        if (state.Apertura && fileId?.open_date != state.Apertura) {
          bodyFormData.append("open_date", state.Apertura);
        }
        if (state.Consulta && fileId?.reason != state.Consulta) {
          bodyFormData.append("reason", state.Consulta);
        }
        if (state.Entidad && fileId?.entity != state.Entidad) {
          bodyFormData.append("entity", state.Entidad);
        }
        if (state.Cliente && fileId?.customer?.id != state.Cliente) {
          bodyFormData.append("customer", state.Cliente);
        }
        if (state.Causante && fileId?.founder?.id != state.Causante) {
          bodyFormData.append("founder", state.Causante);
        }
        if (state.Freelance && fileId?.freelance?.id != state.Freelance) {
          bodyFormData.append("freelance", state.Freelance);
        }
        if (state.Beneficiarios.length > 0) {
          let beneficiariosMap = state.Beneficiarios.map(
            (benef) => benef.value
          );
          bodyFormData.append("beneficiaries", beneficiariosMap);
        }
        instance
          .patch(`files/${fileId?.id}`, bodyFormData)
          .then((res) => {
            if (res.data) {
              addNotification(
                "default",
                "",
                `Cambios actualizados`,
                "bottom-center"
              );
            }
          })
          .catch((e) => console.log("Error in asignament", e.response));
      }
    }
    return !fileId.number ? (
      <div className="fa-3x d-flex justify-content-center">
        <i className="fas fa-cog fa-spin"></i>{" "}
      </div>
    ) : (
      <div style={{ padding: "3%" }}>
        <ol className="breadcrumb float-xl-end">
          <li className="breadcrumb-item">
            <Link to="/Expedientes">Expedientes</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/Expediente/${this.state.path}`}>Hoja de ruta</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/Expedientes">Ver expediente</Link>
          </li>
          {/* <li className="breadcrumb-item active">Data Tables</li> */}
        </ol>
        <h1 className="page-header">
          Expediente {fileId?.number}
          <small></small>
        </h1>
        <Panel>
          <PanelHeader>Ver / editar expediente</PanelHeader>

          <div className="hljs-wrapper">
            <div className="profile-content">
              <div className="tab-content p-0">
                <div>
                  <div
                    className={
                      "tab-pane fade " +
                      (this.state.tabAbout ? "show active " : "")
                    }
                  >
                    <div className="table-responsive form-inline">
                      <table className="table table-profile align-middle">
                        <tbody
                          style={{
                            height: "auto",
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                          }}
                        >
                          <tr>
                            <td>
                              <span className="fw-bold">Expediente</span> <br />
                              <input
                                style={{
                                  border: "1px solid hsl(0, 0%, 80%)",
                                  borderRadius: "4px",
                                  padding: "10px",
                                }}
                                onChange={(e) =>
                                  this.setState((state) => ({
                                    Expediente: e.target.value,
                                  }))
                                }
                                defaultValue={fileId?.number}
                                className=" w-300px py-2 form-control"
                                type="text"
                              ></input>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="fw-bold">Número de proceso</span>{" "}
                              <br />
                              <input
                                style={{
                                  border: "1px solid hsl(0, 0%, 80%)",
                                  borderRadius: "4px",
                                  padding: "10px",
                                }}
                                onChange={(e) =>
                                  this.setState((state) => ({
                                    process_number: e.target.value,
                                  }))
                                }
                                defaultValue={fileId?.process_number}
                                className=" w-300px py-2 form-control"
                                type="text"
                                placeholder="####"
                              ></input>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="fw-bold">Fecha de apertura</span>{" "}
                              <br />
                              <input
                                style={{
                                  border: "1px solid hsl(0, 0%, 80%)",
                                  borderRadius: "4px",
                                  padding: "10px",
                                }}
                                onChange={(e) =>
                                  this.setState((state) => ({
                                    Apertura: e.target.value,
                                  }))
                                }
                                defaultValue={fileId?.open_date}
                                className="form-control w-300px py-2"
                                type="date"
                              ></input>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <span className="fw-bold">Tipo Consulta</span>{" "}
                              <br />
                              {/* <CreatableSelect
                                className="w-300px"
                                placeholder="Selecciona"
                               
                                defaultValue={{
                                    value: fileId?.reason,
                                    label: fileId?.reason,
                                  }}
                                onChange={(newValue) => {
                                  this.setState((state) => ({
                                    value: newValue,
                                    Consulta: newValue.value,
                                  }));
                                }}
                                options={this.state.options}
                                // value={this.state.value}
                              /> */}
                              <Select
                                className=" w-300px"
                                options={this.state?.options}
                                defaultValue={{
                                  value: fileId.reason,
                                  label: fileId.reason,
                                }}
                                name="Consulta"
                                onChange={(newValue) => {
                                  this.setState((state) => ({
                                    Consulta: newValue.value,
                                  }));
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="fw-bold">Entidad</span> <br />
                              <Select
                                className="w-300px"
                                options={Entities.map((entitie) => ({
                                  value: entitie.id,
                                  label: entitie.name,
                                }))}
                                placeholder="Selecciona"
                                value={{
                                  value: fileId?.entity?.id,
                                  label: fileId?.entity?.name,
                                }}
                                name="Entidad"
                                onChange={(e) =>
                                  this.setState((state) => ({
                                    Entidad: e.value,
                                  }))
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <div>
                              <span className="fw-bold">Cliente</span> <br />
                              <Select
                                id="clientec"
                                className="w-300px"
                                isLoading={this.state.isLoading}
                                onInputChange={(e) => {
                                  this.setState((state) => ({
                                    isLoading: true,
                                  }));
                                  setTimeout(() => {
                                    this.setState((state) => ({
                                      isLoading: false,
                                    }));
                                  }, 700);
                                  if (e.length > 1) {
                                    getPersonsCustomer(e, "&is_customer=true");
                                  }
                                }}
                                options={
                                  personsCustomers &&
                                  personsCustomers.map((personCus) => ({
                                    value: personCus.id,
                                    label:
                                      personCus.first_name +
                                      " " +
                                      personCus.last_name,
                                  }))
                                }
                                placeholder="Selecciona"
                                defaultValue={
                                  fileId.customer
                                    ? {
                                        value: fileId?.customer?.id,
                                        label: fileId?.customer?.full_name,
                                      }
                                    : null
                                }
                                name="Cliente"
                                onChange={(e) => {
                                  this.setState((state) => ({
                                    Cliente: e.value,
                                  }));
                                }}
                              />
                            </div>
                          </tr>
                          <tr>
                            <td>
                              <span className="fw-bold">Causante</span> <br />
                              <Select
                                className="w-300px"
                                options={
                                  personsCustomers &&
                                  personsCustomers.map((personCus) => ({
                                    value: personCus.id,
                                    label:
                                      personCus.first_name +
                                      " " +
                                      personCus.last_name,
                                  }))
                                }
                                placeholder="Selecciona"
                                defaultValue={
                                  fileId.founder
                                    ? {
                                        value: fileId?.founder?.id,
                                        label: fileId?.founder?.full_name,
                                      }
                                    : null
                                }
                                isLoading={this.state.isLoadingCausante}
                                onInputChange={(e) => {
                                  this.setState((state) => ({
                                    isLoadingCausante: true,
                                  }));
                                  setTimeout(() => {
                                    this.setState((state) => ({
                                      isLoadingCausante: false,
                                    }));
                                  }, 700);
                                  if (e.length > 1) {
                                    getPersonsCustomer(e);
                                  }
                                }}
                                name="Causante"
                                onChange={(e) =>
                                  this.setState((state) => ({
                                    Causante: e.value,
                                  }))
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="fw-bold">Freelance</span> <br />
                              <Select
                                className="w-300px"
                                options={
                                  personsCustomers &&
                                  personsCustomers.map((personCus) => ({
                                    value: personCus.id,
                                    label:
                                      personCus.first_name +
                                      " " +
                                      personCus.last_name,
                                  }))
                                }
                                isLoading={this.state.isLoadingFree}
                                onInputChange={(e) => {
                                  this.setState((state) => ({
                                    isLoadingFree: true,
                                  }));
                                  setTimeout(() => {
                                    this.setState((state) => ({
                                      isLoadingFree: false,
                                    }));
                                  }, 700);
                                  if (e.length > 1) {
                                    getPersonsCustomer(e, "&is_freelance=true");
                                  }
                                }}
                                placeholder="Selecciona"
                                defaultValue={
                                  fileId.freelance
                                    ? {
                                        value: fileId?.freelance?.id,
                                        label: fileId?.freelance?.full_name,
                                      }
                                    : null
                                }
                                name="Freelance"
                                onChange={(e) =>
                                  this.setState((state) => ({
                                    Freelance: e.value,
                                  }))
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="fw-bold">Beneficiarios</span>{" "}
                              <br />
                              <Select
                                classNamePrefix="select"
                                className="w-300px"
                                options={
                                  personsCustomers &&
                                  personsCustomers.map((personCus) => ({
                                    value: personCus.id,
                                    label:
                                      personCus.first_name +
                                      " " +
                                      personCus.last_name,
                                  }))
                                }
                                placeholder="Selecciona"
                                isLoading={this.state.isLoadingBen}
                                onInputChange={(e) => {
                                  this.setState((state) => ({
                                    isLoadingBen: true,
                                  }));
                                  setTimeout(() => {
                                    this.setState((state) => ({
                                      isLoadingBen: false,
                                    }));
                                  }, 700);
                                  if (e.length > 1) {
                                    getPersonsCustomer(e);
                                  }
                                }}
                                defaultValue={
                                  fileId?.beneficiaries?.length > 0
                                    ? fileId?.beneficiaries?.map(
                                        (personCus) => ({
                                          value: personCus.id,
                                          label: personCus.full_name,
                                        })
                                      )
                                    : null
                                }
                                isMulti
                                name="Beneficiarios"
                                onChange={(e) =>
                                  this.setState((state) => ({
                                    Beneficiarios: e,
                                  }))
                                }
                              />
                            </td>
                          </tr>
                          <br />

                          {successPermissions("can_write_files") ==
                          "d-none" ? null : (
                            <tr className="highlight w-100 ml-auto">
                              <td
                                style={{ border: "none" }}
                                className="d-flex my-auto justify-content-center"
                                //   style={{ justifyContent: "flex-start" }}
                              >
                                <button
                                  onClick={() =>
                                    CreandoExpediente(
                                      this.state,
                                      fileId,
                                      this.addNotification
                                    )
                                  }
                                  className="btn btn-warning w-150px"
                                >
                                  Guardar cambios
                                </button>{" "}
                                <button
                                  onClick={() => this.fede()}
                                  className="btn btn-danger border-0 w-150px ms-2px"
                                >
                                  Cancelar
                                </button>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Panel>
        <ReactNotifications />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  Entities: state.counterSlice.Entities,
  personsCustomers: state.counterSlice.personsCustomers,
  reasons: state.counterSlice.reasons,
  fileId: state.counterSlice.fileId,
});
const mapDispatchToProps = {
  getEntities,
  getPersonsCustomer,
  getReasons,getFilesId
};

export default connect(mapStateToProps, mapDispatchToProps)(CrearExpediente);
