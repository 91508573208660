import { useTable, useSortBy, usePagination } from "react-table";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";
import autoTable from "jspdf-autotable";
import { useDispatch, useSelector } from "react-redux";
import { AppSettings } from "./../../config/app-settings";
import Highlight from "react-highlight";
import React, { useState, useEffect, useRef,useContext } from "react";
import Moment from "moment";

import { useDownloadExcel } from "react-export-table-to-excel";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  deleteEntitiesId,
  getEntities,
  getEntitiesSearch,
} from "../../actions/index.js";
import { instance, successPermissions } from "../../utils/index.js";

const Entidades = () => {
  const tableRef = useRef(null);
  const context = useContext(AppSettings);
  const [timer, setTimer] = useState(null);
  const [modalDialog, setmodalDialog] = useState(false);
  const [sweetAlertError, setsweetAlertError] = useState(false);
  const [IdSelected, setIdSelected] = useState(null);
  const [NameDelete, setNameDelete] = useState(null);
  const [IdSelectedModifi, setIdSelectedModifi] = useState(null);
  const [modalDialogCreate, setmodalDialogCreate] = useState(false);
  const [NameSelectedCreate, setNameSelectedCreate] = useState(null);
  const [modalDialogAlert, setmodalDialogAlert] = useState(false);
  const [modalDialogAlertError, setmodalDialogAlertError] = useState(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Entidades table",
    sheet: "Entidades",
  });
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { Entities } = state.counterSlice;
  function uppercaseWord(word) {
    const firstLetter = word.charAt(0);
    const firstLetterCap = firstLetter.toUpperCase();
    const remainingLetters = word.slice(1);
    return firstLetterCap + remainingLetters;
  }

  useEffect(() => {
    dispatch(getEntities());
    if (
      context &&
      localStorage.email &&
      localStorage.email.includes("siep.com.co")
    ) {
      context.handleSetActualizarEmail();
    }
  }, []);

  const columns = React.useMemo(
    () =>
      successPermissions("full_access_entity") == "d-none"
        ? [
            {
              Header: "Id",
              accessor: "id",
              sortable: true,
            },
            {
              Header: "Nombre",
              accessor: "name",
              sortable: true,
            },
            {
              Header: "Creado",
              accessor: "created_at",
              sortable: true,
            },

            {
              Header: "Modificado",
              accessor: "updated_at",
              sortable: true,
            },
          ]
        : [
            {
              Header: "Id",
              accessor: "id",
              sortable: true,
            },
            {
              Header: "Nombre",
              accessor: "name",
              sortable: true,
            },
            {
              Header: "Creado",
              accessor: "created_at",
              sortable: true,
            },

            {
              Header: "Modificado",
              accessor: "updated_at",
              sortable: true,
            },
            {
              Header: "Acciones",
              accessor: "Acciones",
              sortable: false,
            },
          ],
    []
  );

  var data = Entities;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useSortBy,
    usePagination
  );
  function handleSubmit() {
    if (IdSelected) {
      instance
        .delete(`files/entities/${IdSelected}`)
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data) {
            setsweetAlertError(false);
          }
        })

        .catch((e) => {
          console.log("error", e);
          setsweetAlertError(false);
          setmodalDialogAlert(true);
          setmodalDialogAlertError(e?.response?.data?.detail);
        });
    }
  }
  function patchEntitie() {
    instance
      .patch(`files/entities/${IdSelectedModifi[0]}`, {
        name: uppercaseWord(IdSelectedModifi[1]),
      })
      .then((res) => {
        dispatch(getEntities());
        setmodalDialog(!modalDialog);
        return res.data;
      })
      .then((data) => {
        return data;
      })

      .catch((e) => {
        console.log("error", e);
        setmodalDialogAlert(true);
        setmodalDialogAlertError(e?.response?.data?.detail);
      });
  }

  function createEntitie() {
    instance
      .post(`files/entities/`, {
        name: uppercaseWord(NameSelectedCreate),
      })
      .then((res) => {
        dispatch(getEntities());
        setmodalDialogCreate(!modalDialogCreate);
        return res.data;
      })
      .catch((e) => {
        console.log("error", e);
        setmodalDialogAlert(true);
        setmodalDialogAlertError(e?.response?.data?.detail);
      });
  }
  function pdfdownload(current) {
    const doc = new jsPDF();
    autoTable(doc, { html: current });
    doc.save("table.pdf");
  }
  const handlePrint = useReactToPrint({
    pageStyle: "@page {size:auto;margin-bottom: 20mm }",
    content: () => {
      const tableStat = tableRef.current.cloneNode(true);
      const PrintElem = document.createElement("div");
      PrintElem.setAttribute("class", "containerPrint");
      const header =
        `<div class="page-header"><h1>Entidades</div></h1>` +
        `<img src="https://siep.restrepofajardo.com/img/logo.png" alt="" class="watermark"/>` +
        `<div class="page-footer"><span>${
          localStorage.full_name
        }</span><span>${Moment().format(
          "D [de] MMMM [del] YYYY, h:mm a"
        )}</span></div>`;
      PrintElem.innerHTML = header;
      PrintElem.appendChild(tableStat);
      return PrintElem;
    },
  });
  const fakeApi = (e) => {
    dispatch(getEntitiesSearch(e));
  };
  const inputChanged = (e) => {
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      fakeApi(e.target.value);
    }, 200);

    setTimer(newTimer);
  };
  return (
    <div>
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">
          <Link to="/dashboard">Panel</Link>
        </li>
        <li className="breadcrumb-item active">Entidades</li>
      </ol>
      <h1 className="page-header">Entidades </h1>
      <Panel>
        <PanelHeader>Entidades</PanelHeader>
        <PanelBody>
          <div className="invoice-company">
            <span className="float-end hidden-print">
              {successPermissions("full_access_entity") == "d-none" ? null : (
                <button
                  onClick={() => setmodalDialogCreate(true)}
                  className="btn btn-sm btn-primary mb-10px me-1"
                >
                  Crear entidad
                </button>
              )}
              {successPermissions("full_access_entity_buttons_options") ==
              "d-none" ? null : (
                <>
                  {" "}
                  <button
                    onClick={() => pdfdownload(tableRef.current)}
                    className="btn btn-sm btn-white mb-10px me-1"
                  >
                    <i className="fa fa-file-pdf t-plus-1 text-blue fa-fw fa-lg"></i>{" "}
                  </button>
                  <button
                    onClick={onDownload}
                    className="btn btn-sm btn-white mb-10px me-1"
                  >
                    <i className="fa fa-file-excel t-plus-1 text-green fa-fw fa-lg"></i>
                  </button>
                  <button
                    onClick={handlePrint}
                    className="btn btn-sm btn-white mb-10px me-1"
                  >
                    <i className="fa fa-print t-plus-1 fa-fw fa-lg"></i>
                  </button>
                </>
              )}
            </span>
            <div className="d-block d-md-flex align-items-center">
              <label className="d-none d-lg-block pe-2 mb-0">
                Entidades por página:
              </label>
              <div className="d-none d-lg-block">
                <select
                  className="form-select"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Mostrar {pageSize}
                    </option>
                  ))}
                </select>
              </div>
              <form action="" method="POST" name="search_form">
                <div className="form-group position-relative mx-3 width_seach_type w-sm-50 pe-4 d-flex">
                  {" "}
                  <button
                    className="btn btn-search position-absolute border-0"
                    disabled={true}
                  >
                    <i className="fa fa-search me-20px icon-search-action"></i>
                  </button>{" "}
                  <input
                    type="text"
                    id="inputTextSearch"
                    className="form-control px-5"
                    onChange={inputChanged}
                    placeholder="Buscar entidad ..."
                  />{" "}
                </div>
              </form>
            </div>
          </div>
        </PanelBody>
        <div className="table-responsive">
          {Entities.length == 0 ? (
            <div className="fw-bold text-center mb-3">
              No se encontraron resultados
            </div>
          ) : (
            <>
              <table
                ref={tableRef}
                // style={{ background: "var(--app-sidebar-bg)" }}
                className="table table-panel table-bordered mb-0"
                {...getTableProps()}
              >
                <thead>
                  {headerGroups.map((headerGroup, i) => (
                    <tr
                      key={i}
                      {...headerGroup.getHeaderGroupProps()}
                      className="nav-Tables-head"
                    >
                      {headerGroup.headers.map((column) => (
                        <th
                          className="text-center"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div
                            className="d-flex align-items-center text-center"
                            style={{
                              minWidth: "100px",
                              justifyContent: "center",
                            }}
                          >
                            <span>{column.render("Header")}</span>
                            <span className="">
                              {column.sortable ? (
                                column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <i className="fa fa-sort-down fa-fw fs-14px text-blue"></i>
                                  ) : (
                                    <i className="fa fa-sort-up fa-fw fs-14px text-blue"></i>
                                  )
                                ) : (
                                  <i className="fa fa-sort fa-fw fs-14px opacity-3"></i>
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="colorRow-Tables">
                        {row.cells.map((cell, index) => {
                          return (
                            <td key={row.id} {...cell.getCellProps()}>
                              {cell.column.Header == "Acciones" ? (
                                <td
                                  nowrap="true "
                                  className="vertical-middle"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <button
                                    title="Editar"
                                    onClick={() =>
                                      setmodalDialog(true) ||
                                      setIdSelectedModifi([
                                        row.original["id"],
                                        row.original["name"],
                                      ])
                                    }
                                    className={`${successPermissions(
                                      "full_access_entity"
                                    )} btn btn-sm btn-orange  me-1 p-auto`}
                                  >
                                    <i className="fas fa-lg fa-fw fs-14px fa-pen-to-square"></i>
                                  </button>

                                  <button
                                    title="Borrar"
                                    onClick={() =>
                                      setsweetAlertError(true) ||
                                      setIdSelected(row.original["id"]) ||
                                      setNameDelete(row.original["name"])
                                    }
                                    className={`${successPermissions(
                                      "full_access_entity_Delete"
                                    )}  btn btn-sm btn-danger p-auto`}
                                  >
                                    <i className="fa fa-trash fs-14px text-white"></i>
                                  </button>
                                </td>
                              ) : cell.column.Header == "Modificado" ? (
                                Moment(row.original["updated_at"]).format(
                                  "L[-]LT"
                                )
                              ) : cell.column.Header == "Creado" ? (
                                Moment(row.original["created_at"]).format(
                                  "L[-]LT"
                                )
                              ) : (
                                cell.render("Cell")
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>{" "}
              <PanelBody>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="me-1 d-none d-sm-block">Ir a página:</div>
                  <div className="w-50px mx-2 me-auto d-none d-sm-block">
                    <input
                      className="form-control"
                      type="number"
                      defaultValue={pageIndex + 1}
                      onChange={(e) => {
                        const page = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        gotoPage(page);
                      }}
                    />
                  </div>
                  <ul className="pagination mb-0">
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                      >
                        <i className="fa fa-angle-double-left"></i>
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                      >
                        <i className="fa fa-angle-left"></i>
                      </button>
                    </li>
                    <li className="page-item d-flex align-items-center px-2">
                      <div>
                        Página{" "}
                        <strong>
                          {pageIndex + 1} of {pageOptions.length}
                        </strong>
                      </div>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                      >
                        <i className="fa fa-angle-right"></i>
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                      >
                        <i className="fa fa-angle-double-right"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </PanelBody>
            </>
          )}
        </div>
        {sweetAlertError && (
          <SweetAlert
            danger
            showCancel
            confirmBtnText="Sí, borrar!"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            cancelBtnText="Cancelar"
            title="Estás seguro de borrar"
            onConfirm={() => handleSubmit()}
            onCancel={() => setsweetAlertError(false)}
          >
            <span
              style={{ fontStyle: "italic", color: "red", fontWeight: "600" }}
            >
              la entidad {NameDelete} ?
            </span>
          </SweetAlert>
        )}
        <Modal isOpen={modalDialog}>
          <ModalHeader
            close={
              <button
                className="btn-close"
                onClick={() => setmodalDialog(!modalDialog)}
              ></button>
            }
          >
            Entidad
          </ModalHeader>
          <ModalBody className="my-3">
            {IdSelectedModifi ? (
              <tr className="w-100 ">
                <td className="field text-center w-auto">Nombre</td>

                <td style={{ width: "70%" }}>
                  <input
                    style={{
                      border: "1px solid hsl(0, 0%, 80%)",
                      borderRadius: "4px",
                      padding: "10px",
                    }}
                    onChange={(e) =>
                      setIdSelectedModifi([IdSelectedModifi[0], e.target.value])
                    }
                    defaultValue={IdSelectedModifi[1]}
                    className=" w-300px py-2"
                    type="text"
                  ></input>
                </td>
              </tr>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-white"
              onClick={() => setmodalDialog(!modalDialog)}
            >
              Cerrar
            </button>
            <button onClick={() => patchEntitie()} className="btn btn-warning">
              Editar
            </button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={modalDialogCreate}>
          <ModalHeader
            close={
              <button
                className="btn-close"
                onClick={() => setmodalDialogCreate(!modalDialogCreate)}
              ></button>
            }
          >
            Entidad
          </ModalHeader>
          <ModalBody className="my-3">
            <tr className="w-100 ">
              <td className="field text-center w-auto">Nombre</td>

              <td style={{ width: "70%" }}>
                <input
                  style={{
                    border: "1px solid hsl(0, 0%, 80%)",
                    borderRadius: "4px",
                    padding: "10px",
                  }}
                  onChange={(e) => setNameSelectedCreate(e.target.value)}
                  className=" w-300px py-2"
                  type="text"
                ></input>
              </td>
            </tr>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-white"
              onClick={() => setmodalDialogCreate(!modalDialogCreate)}
            >
              Cerrar
            </button>
            <button onClick={() => createEntitie()} className="btn btn-warning">
              Crear
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalDialogAlert}>
          <ModalHeader
            close={
              <button
                className="btn-close"
                onClick={() => setmodalDialogAlert(!modalDialogAlert)}
              ></button>
            }
          >
            Algo ha ocurrido
          </ModalHeader>
          <ModalBody>
            <div className="alert alert-danger mb-0">
              <h5>
                <i className="fa fa-info-circle"></i> Algo salió mal
              </h5>
              <p>
                {modalDialogAlertError
                  ? modalDialogAlertError
                  : "Lo sentimos algo a fallado, vuelve a intentar en un momento."}
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-white"
              onClick={() => setmodalDialogAlert(!modalDialogAlert)}
            >
              Cerrar
            </button>
          </ModalFooter>
        </Modal>

        <div className="hljs-wrapper">
          <Highlight className="typescript"></Highlight>
        </div>
      </Panel>
    </div>
  );
};

export default Entidades;
