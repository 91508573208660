import React from "react";
import { Link, Redirect } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Collapse, CardHeader, CardBody, Card } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AppSettings } from "./../../config/app-settings.js";
import Select from "react-select";
import Moment from "moment";
import {
  deleteCountId,
  removeFilesOpen,
  getFilesArchiveId,
  getUsers,
  getFileRoadmap,
} from "../../actions/index.js";
import SweetAlert from "react-bootstrap-sweetalert";

import { connect } from "react-redux";
import {
  count_type,
  instance,
  pension_type,
  successPermissions,
} from "../../utils/index.js";
import TablePrueba from "../../pages/option/tablaTest.js";
class SidebarRightArchived extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapse: [{ id: "Datos", collapse: true }],
      path: window.location.pathname.replace("/ExpedienteArchivado/", ""),
      sweetAlertPrimary: false,
      sweetAlertInfo: false,
      sweetAlertWarning: false,
      sweetAlertSuccess: false,
      sweetAlertError: false,
      redirect: false,
      modalAsignment: false,
      modalControlCambios: false,
      AsignaraError: "",
      AsignaraCommentError: false,
      Asignara: "",
      comentarioAsignar: "",
      cambiosControl: [],
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentWillMount() {
    const { getUsers } = this.props;

    getUsers();
    instance
      .get(`files/${this.state.path}/history`)
      .then((res) => {
        if (res.data) {
          this.setState((state) => ({
            cambiosControl: res.data?.changes,
          }));
        }
      })
      .catch((err) => console.log(err));
  }

  inputChanged(value) {
    const valueSearch = value.toLowerCase();
    if (value != "" && value != " " && value.length > 0) {
      this.setState((state) => ({
        cambiosControl: this.state.cambiosControl.filter(
          (element) =>
            element.user.toLowerCase().includes(valueSearch) ||
            element.message.toLowerCase().includes(valueSearch)
        ),
      }));
    } else {
      instance
        .get(`files/${this.state.path}/history`)
        .then((res) => {
          if (res.data) {
            this.setState((state) => ({
              cambiosControl: res.data?.changes,
            }));
          }
        })
        .catch((err) => console.log(err));
    }
  }

  toggleSweetAlert(name) {
    switch (name) {
      case "primary":
        this.setState({ sweetAlertPrimary: !this.state.sweetAlertPrimary });
        break;
      case "info":
        this.setState({ sweetAlertInfo: !this.state.sweetAlertInfo });
        break;
      case "success":
        this.setState({ sweetAlertSuccess: !this.state.sweetAlertSuccess });
        break;
      case "warning":
        this.setState({ sweetAlertWarning: !this.state.sweetAlertWarning });
        break;
      case "error":
        this.setState({ sweetAlertError: !this.state.sweetAlertError });
        break;
      default:
        break;
    }
  }
  toggleCollapse(index) {
    var newArray = [];
    for (let collapseObj of this.state.collapse) {
      if (collapseObj.id === index) {
        collapseObj.collapse = !collapseObj.collapse;
      } else {
        collapseObj.collapse = false;
      }
      newArray.push(collapseObj);
    }

    this.setState({
      collapse: newArray,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.append("receiver", this.state.Asignara);
    bodyFormData.append("number", this.state.NumberFile);
    bodyFormData.append("comment", this.state.Asignara);

    instance
      .post(`files/${this.props.fileIdArchive?.id}/reopen`, bodyFormData)
      .then((res) => {
        this.setState((state) => ({
          redirect: true,
        }));
      })
      .catch((e) => {
        if (e.response) {
          this.setState((state) => ({
            AsignaraError: e.response.data,
          }));
          setTimeout(() => {
            this.setState((state) => ({
              AsignaraError: "",
            }));
          }, 3000);
        }
      });
  }
  toggleModal(name) {
    switch (name) {
      case "modalAddTask":
        this.setState({ modalAsignment: !this.state.modalAsignment });
        break;
      case "modalControlCambios":
        this.setState({ modalControlCambios: !this.state.modalControlCambios });
        break;

      case "modalReject":
        this.setState({ modalReject: !this.state.modalReject });
        break;
      default:
        break;
    }
  }

  render() {
    const { deleteCountId, fileIdArchive, users, countId } = this.props;

    if (this.state.redirect) {
      return <Redirect to="/Archivados" />;
    }
    const actions = [
      successPermissions("can_set_file_status_to_reopen") == "d-none"
        ? null
        : [
            "Re-abrir",
            <i className="fas fa-lg fa-fw me-10px fa-file-arrow-up"></i>,
          ],
      [
        "Control de cambios",
        <i className="fas fa-lg fa-fw me-10px fa-rectangle-list"></i>,
      ],
    ];

    const columnsForTable = [
      {
        Header: "Usuario",
        accessor: "user",
        sortable: true,
      },

      {
        Header: "Modificado",
        accessor: "date",
        sortable: true,
      },
      {
        Header: "Mensaje",
        accessor: "message",
        sortable: true,
      },
    ];

    return (
      <AppSettings.Consumer>
        {({ appSidebarTwo_archivado, toggleAppSidebarEndMobile }) => (
          <React.Fragment>
            {appSidebarTwo_archivado && (
              <React.Fragment>
                <div id="sidebar-right" className="app-sidebar app-sidebar-end">
                  <PerfectScrollbar
                    className="app-sidebar-content h-100"
                    options={{ suppressScrollX: true }}
                  >
                    <div
                      className="p-20px text-white d-flex "
                      style={{
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      {actions
                        .filter((e) => e)
                        .map((e) => (
                          <div
                            onClick={() => {
                              if (e[0] == "Re-abrir") {
                                this.toggleModal("modalAddTask");
                              } else {
                                this.toggleModal("modalControlCambios");
                              }
                            }}
                            className="btn  btn-primary my-1"
                          >
                            <span
                              className="d-flex align-items-center text-center"
                              style={{
                                justifyContent: "center",
                              }}
                            >
                              {e[1]}
                              <span>
                                <span className="d-flex">
                                  <b>{e[0]}</b>
                                </span>
                              </span>
                            </span>
                          </div>
                        ))}
                      <br />
                      <br />

                      <div
                        id="accordion"
                        className="accordion rounded overflow-hidden"
                      >
                        {this.state.collapse.map((value, i) => (
                          <Card
                            className="bg-gray-700 text-white border-0 rounded-0 cursor-pointer"
                            key={i}
                          >
                            <CardHeader
                              className={
                                "card-header bg-gray-900 text-white pointer-cursor border-0 d-flex align-items-center rounded-0 " +
                                (!value.collapse ? "collapsed " : "")
                              }
                              onClick={() => this.toggleCollapse(value.id)}
                            >
                              <i className="fa fa-circle text-teal fs-6px me-2"></i>{" "}
                              {value.id}
                            </CardHeader>
                            {value.id == "Acciones" ? (
                              <Collapse isOpen={value.collapse}>
                                <CardBody className="p-3">Hola</CardBody>
                              </Collapse>
                            ) : (
                              <Collapse isOpen={value.collapse}>
                                <CardBody className="p-3">
                                  <strong> Archivado</strong>
                                  <br />
                                  {fileIdArchive?.archived_by?.full_name} <br />
                                  <strong> Fecha archivado</strong>
                                  <br />
                                  {Moment(fileIdArchive?.archived_at).format(
                                    "L[-]LT"
                                  )}{" "}
                                  <br />
                                  <strong> Último comentario</strong>
                                  <br />
                                  {fileIdArchive?.archived_comment} <br />
                                  {/* <br />
                                  <Link to="/ExpedienteNuevo">
                                    Ver mas{" "}
                                    <i className="fas fa-lg fa-fw me-10px fa-circle-plus"></i>{" "}
                                  </Link> */}
                                </CardBody>
                              </Collapse>
                            )}
                          </Card>
                        ))}
                      </div>
                    </div>
                  </PerfectScrollbar>
                </div>
                <Modal
                  isOpen={this.state.modalAsignment}
                  toggle={() => this.toggleModal("modalAddTask")}
                >
                  <ModalHeader
                    // toggle={() => setmodalAsignment(!modalAsignment)}
                    close={
                      <button
                        className="btn-close"
                        onClick={() => {
                          this.toggleModal("modalAddTask");
                        }}
                      ></button>
                    }
                  >
                    Re-abrir Expediente
                  </ModalHeader>
                  <form onSubmit={this.handleSubmit}>
                    <ModalBody>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          className="d-flex mx-auto"
                          style={{ width: "80%" }}
                        >
                          <div className=" field m-auto ">Número</div>
                          <div>
                            <input
                              style={{
                                border: "1px solid hsl(0, 0%, 80%)",
                                borderRadius: "4px",
                                padding: "9px",
                              }}
                              type="text"
                              className="w-200px w-md-300px"
                              placeholder="Nuevo número de expediente"
                              onChange={(e) =>
                                this.setState({ NumberFile: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <br />
                        <div
                          className="d-flex mx-auto"
                          style={{ width: "80%" }}
                        >
                          <div className=" field m-auto ">Asignar a</div>
                          <div>
                            <Select
                              className="w-200px w-md-300px m-auto"
                              options={users.map((person) => ({
                                label: person.first_name + person.last_name,
                                value: person.id,
                              }))}
                              placeholder="Selecciona"
                              name="Personas"
                              onChange={(e) =>
                                this.setState({ Asignara: e.value })
                              }
                            />
                          </div>
                        </div>

                        <br />

                        <div
                          className="d-flex  mx-auto"
                          style={{ width: "80%" }}
                        >
                          <div className="field m-auto">Comentario</div>
                          <div>
                            <textarea
                              onChange={(e) =>
                                this.setState({
                                  comentarioAsignar: e.target.value,
                                })
                              }
                              placeholder="Escribe tu comentario"
                              className="w-200px w-md-300px m-auto p-2"
                              style={{
                                borderColor: "hsl(0, 0%, 80%)",
                                borderRadius: "4px",
                              }}
                            ></textarea>
                          </div>
                        </div>

                        <div
                          className="d-flex mx-auto"
                          style={{ width: "80%" }}
                        >
                          <div className="w-75px field m-auto "></div>
                          <div className="w-300px m-auto">
                            {" "}
                            <span style={{ color: "red" }}>
                              {this.state.AsignaraError}
                            </span>{" "}
                          </div>{" "}
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <button
                        className="btn btn-white"
                        onClick={() => {
                          this.toggleModal("modalAddTask");
                        }}
                      >
                        Cerrar
                      </button>
                      <button type="submit" className="btn btn-success">
                        Re-abrir
                      </button>
                    </ModalFooter>
                  </form>
                </Modal>
                <Modal
                  isOpen={this.state.modalControlCambios}
                  toggle={() => this.toggleModal("modalControlCambios")}
                >
                  <ModalHeader
                    // toggle={() => setmodalAsignment(!modalAsignment)}
                    close={
                      <button
                        className="btn-close"
                        onClick={() => {
                          this.toggleModal("modalControlCambios");
                        }}
                      ></button>
                    }
                  >
                    Control de cambios
                  </ModalHeader>

                  <ModalBody>
                    {this.state.cambiosControl &&
                    this.state.cambiosControl?.length > 0 ? (
                      <form id="formsearchTimeline" name="search_form">
                        <div className="form-group position-relative  width_seach_type w-sm-50  d-flex">
                          {" "}
                          <button
                            className="btn btn-search position-absolute border-0"
                            disabled={true}
                          >
                            <i className="fa fa-search me-20px icon-search-action "></i>
                          </button>{" "}
                          <input
                            type="text"
                            id="inputTextSearch"
                            className="form-control px-5"
                            placeholder="Buscar"
                            onChange={(e) => this.inputChanged(e.target.value)}
                          />
                        </div>
                      </form>
                    ) : null}
                    {this.state.cambiosControl &&
                    this.state.cambiosControl.length > 0 ? (
                      <TablePrueba
                        height={"50vh"}
                        data={this.state.cambiosControl}
                        columnsData={columnsForTable}
                        pagination={true}
                      ></TablePrueba>
                    ) : (
                      <span className="text-center">
                        No tienes cambios actualmente
                      </span>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <button
                      className="btn btn-white"
                      onClick={() => {
                        this.toggleModal("modalControlCambios");
                      }}
                    >
                      Cerrar
                    </button>
                  </ModalFooter>
                </Modal>
                <div className="app-sidebar-bg app-sidebar-end"></div>
                <div className="app-sidebar-mobile-backdrop app-sidebar-end">
                  <Link
                    to="/"
                    onClick={toggleAppSidebarEndMobile}
                    className="stretched-link"
                  ></Link>
                </div>
                {this.state.sweetAlertError && (
                  <SweetAlert
                    danger
                    showCancel
                    confirmBtnText="Sí,borrar!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    cancelBtnText="Cancelar"
                    title="Estás seguro de borrar?"
                    onConfirm={() => {
                      deleteCountId(countId.id);
                      this.handleSubmit();
                    }}
                    onCancel={() => this.toggleSweetAlert("error")}
                  >
                    el conteo # {countId.id}
                  </SweetAlert>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </AppSettings.Consumer>
    );
  }
}
const mapStateToProps = (state) => ({
  fileIdArchive: state.counterSlice.fileIdArchive,
  users: state.counterSlice.users,
  countId: state.counterSlice.countId,
});
const mapDispatchToProps = {
  deleteCountId,
  removeFilesOpen,
  getFilesArchiveId,
  getUsers,
  getFileRoadmap,
};

// bind our actions to this.props

export default connect(mapStateToProps, mapDispatchToProps)(SidebarRightArchived);
