import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { instance } from "../../../utils";
import PerfectScrollbar from "react-perfect-scrollbar";

import { addFilesOpen } from "../../../actions";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
class DropdownNotification extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      data: null,
      dataUnread: null,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }
  componentDidMount() {
    if (localStorage.first_name && localStorage.refresh) {
      this.fetchData();
      this.fetchDataUnread();
    }
    // Establecer un intervalo para llamar fetchData cada 10 minutos
    this.intervalId = setInterval(this.fetchData, 10 * 60 * 1000); // 10 minutos en milisegundos
  }

  componentWillUnmount() {
    // Limpiar el intervalo al desmontar el componente para evitar fugas de memoria
    clearInterval(this.intervalId);
  }

  fetchData = async () => {
    instance
      .get(`notification/`)
      .then((res) => {
        return res.data;
      })
      .then((jsonData) => {
        console.log("jsonData", jsonData);
        this.setState({ data: jsonData });
      })
      .catch((e) => console.warn("Error in saveDataLog", e.response));
  };
  fetchDataUnread = async () => {
    instance
      .get(`notification/unread`)
      .then((res) => {
        return res.data;
      })
      .then((jsonData) => {
        console.log("jsonData", jsonData);
        this.setState({ dataUnread: jsonData });
      })
      .catch((e) => console.warn("Error in saveDataLog", e.response));
  };

  readAll = async () => {
    await instance
      .post(`notification/read/all`)
      .then((resde) => {
        return resde.data;
      })
      .then(async (jsonDatade) => {
        console.log("jsonDatadetail", jsonDatade);
        if (jsonDatade) {
          await this.fetchData();
          await this.fetchDataUnread();
        }

        // this.setState({ data: jsonData });
      })
      .catch((e) => console.log("Error in saveDataLog", e));
  };

  readAcepted(e) {
    console.log(e);
    instance
      .post(`notification/read/${e}`)
      .then((res) => {
        return res.data;
      })
      .then((jsonData) => {
        console.log("jsonDatadetail", jsonData);
        this.fetchData();
        this.fetchDataUnread();
        // this.setState({ data: jsonData });
      })
      .catch((e) => console.warn("Error in saveDataLog", e.response));
  }
  render() {
    const { data, dataUnread } = this.state;
    const { addFilesOpen } = this.props;
    console.log(data);
    return (
      <Dropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        className="navbar-item dropdown"
        tag="div"
      >
        <DropdownToggle className="navbar-link dropdown-toggle icon" tag="a">
          <i className="fa fa-bell"></i>
          <span className="badge">{dataUnread?.count || 0}</span>
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu media-list dropdown-menu-end"
          style={{ width: "40rem" }}
          end
          tag="div"
        >
          <DropdownItem className="dropdown-header" tag="div" header>
            NOTIFICACIONES ({data?.count || 0})
          </DropdownItem>
          <PerfectScrollbar style={{ height: "calc(50vh)", width: "100%" }}>
            {data &&
              data.results.map((notification) => (
                <DropdownItem>
                  <Link
                    className="dropdown-item media w-100"
                    style={{
                      backgroundColor: notification.read
                        ? null
                        : "rgba(181,178,178,0.2)",
                      whiteSpace: "normal",
                      borderTop: "none",
                    }}
                    to={
                      notification.extra_data.file_status === 20
                        ? `/ExpedienteArchivado/${notification.extra_data.file_id}`
                        : notification.extra_data.file_status >= 50 ||
                          notification.extra_data.assignment_status >= 50
                        ? `/Expediente/${notification.extra_data.file_id}`
                        : `/Dashboard`
                    }
                    onClick={() =>
                      notification.extra_data.file_status === 20
                        ? this.readAcepted(notification.id)
                        : notification.extra_data.file_status >= 50 ||
                          notification.extra_data.assignment_status >= 50
                        ? this.readAcepted(notification.id) ||
                          addFilesOpen({
                            id: notification.extra_data.file_id,
                            number: notification.extra_data.file_number
                              ? Number(notification.extra_data.file_number)
                              : 3276,
                          })
                        : this.readAcepted(notification.id)
                    }
                  >
                    <div className="media-left">
                      <i className="fab fa-facebook-messenger media-object bg-gray-300"></i>
                    </div>
                    <div className="media-body">
                      <h6
                        className="media-heading"
                        style={{
                          color: notification.read
                            ? "rgba(32, 37, 42,0.83)"
                            : null,
                          whiteSpace: "normal",
                        }}
                      >
                        {notification.title}
                        {notification.read ? null : (
                          <i className="fa fa-exclamation-circle text-danger ms-1"></i>
                        )}
                        {/* {notification.priority > 1 ? (
                        <span className="me-2">
                          <i className="fa fa-exclamation-circle text-danger"></i>
                        </span>
                      ) : null} */}
                      </h6>
                      <div className={`text-gray fs-10px`}>
                        {notification.message}
                      </div>
                    </div>
                  </Link>
                </DropdownItem>
              ))}
          </PerfectScrollbar>
          {/* <DropdownItem className="dropdown-item media">
						<div className="media-left">
							<img src="../assets/img/user/user-1.jpg" className="media-object" alt="" />
							<i className="fab fa-facebook-messenger text-blue media-object-icon"></i>
						</div>
						<div className="media-body">
							<h6 className="media-heading">John Smith</h6>
							<p>Quisque pulvinar tellus sit amet sem scelerisque tincidunt.</p>
							<div className="text-muted fs-10px">25 minutes ago</div>
						</div>
					</DropdownItem>
					<DropdownItem className="dropdown-item media">
						<div className="media-left">
							<img src="../assets/img/user/user-2.jpg" className="media-object" alt="" />
							<i className="fab fa-facebook-messenger text-blue media-object-icon"></i>
						</div>
						<div className="media-body">
							<h6 className="media-heading">Olivia</h6>
							<p>Quisque pulvinar tellus sit amet sem scelerisque tincidunt.</p>
							<div className="text-muted fs-10px">35 minutes ago</div>
						</div>
					</DropdownItem>
					<DropdownItem className="dropdown-item media">
						<div className="media-left">
							<i className="fa fa-plus media-object bg-gray-500"></i>
						</div>
						<div className="media-body">
							<h6 className="media-heading"> New User Registered</h6>
							<div className="text-muted fs-10px">1 hour ago</div>
						</div>
					</DropdownItem>
					<DropdownItem className="dropdown-item media">
						<div className="media-left">
							<i className="fa fa-envelope media-object bg-gray-500"></i>
							<i className="fab fa-google text-warning media-object-icon fs-14px"></i>
						</div>
						<div className="media-body">
							<h6 className="media-heading"> New Email From John</h6>
							<div className="text-muted fs-10px">2 hour ago</div>
						</div>
					</DropdownItem> */}
          <DropdownItem
            className="dropdown-footer text-center"
            onClick={this.readAll}
          >
            Marcar todos como leídos
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}
const mapStateToProps = (state) => ({
  filesopen: state.counterSlice.filesopen,
});
const mapDispatchToProps = {
  addFilesOpen,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropdownNotification);
