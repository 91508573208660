import React from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import imagenProfile from "../../../assets/img/user/user-13.jpg";
import { AxiosURL, AxiosURLImage, instance } from "../../../utils";

class DropdownProfile extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.logOut = this.logOut.bind(this);
    this.state = {
      dropdownOpen: false,
      redirect: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }
  logOut() {
    const refresh_token = localStorage.refresh;
    const servicio = "users/auth/logout/";
    var formData = new FormData();

    formData.append("refresh_token", refresh_token);

    instance
      .post(servicio, formData)
      .then((res) => {
        return res.status;
      })
      .then((data) => {
        if (data === 200) {
          localStorage.clear();
          this.setState((state) => ({
            redirect: true,
          }));
        }
      });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    return (
      <Dropdown
        style={{ cursor: "pointer" }}
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        className="navbar-item navbar-user dropdown"
        tag="div"
      >
        <DropdownToggle
          tag="a"
          className="navbar-link dropdown-toggle d-flex align-items-center"
        >
          {localStorage.profile_pic ? (
            <img src={AxiosURLImage + localStorage.profile_pic} alt="iamged" />
          ) : null}
          <span>
            <span className="d-none d-md-inline">{localStorage.full_name}</span>
            <b className="caret"></b>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-end" end tag="div">
          <DropdownItem>
            <Link
              to="/Perfil"
              className=""
              style={{ textDecoration: "none", color: "currentcolor" }}
            >
              Editar perfil
            </Link>
          </DropdownItem>
          {/* <DropdownItem className="d-flex align-items-center">
            Inbox
            <span className="badge bg-danger rounded-pill ms-auto pb-4px">
              2
            </span>
          </DropdownItem>
          <DropdownItem>Calendar</DropdownItem>
          <DropdownItem>Setting</DropdownItem> */}
          <div className="dropdown-divider"></div>
          <DropdownItem onClick={() => this.logOut()}>
            Cerrar sesión
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default DropdownProfile;
