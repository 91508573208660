import { instance } from "../utils";
import axios from "axios";
export const GET_FILES = "GET_FILES";
export const GET_METRICS = "GET_METRICS";
export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const GET_FILES_REJECTED = "GET_FILES_REJECTED";
export const GET_FILES_ASSIGNED = "GET_FILES_ASSIGNED";
export const GET_FILES_ACCEPTED = "GET_FILES_ACCEPTED";
export const UP_PERMISSIONS = "UP_PERMISSIONS";
export const RE_OPEN = "RE_OPEN";
export const GET_FILES_ARCHIVED = "GET_FILES_ARCHIVED";
export const GET_PERSONS = "GET_PERSONS";
export const GET_NEWS = "GET_NEWS";
export const GET_FILES_DETAILS = "GET_FILES_DETAILS";
export const ADD_FILES_OPEN = "ADD_FILES_OPEN";
export const REMOVE_FILES_OPEN = "REMOVE_FILES_OPEN";
export const GET_FILES_ID = "GET_FILES_ID";
export const GET_FILES_ARCHIVE_ID = "GET_FILES_ARCHIVE_ID";
export const GET_ENTITIES = "GET_ENTITIES";
export const GET_ENTITIES_SEARCH = "GET_ENTITIES_SEARCH";
export const GET_ASSIGNMENTS = "GET_ASSIGNMENTS";
export const DELETE_FILES_ID = "DELETE_FILES_ID";
export const GET_PERSONS_ASSIGNMENTS = "GET_PERSONS_ASSIGNMENTS";
export const GET_PERSONS_CUSTOMER = "GET_PERSONS_CUSTOMER";
export const GET_USERS = "GET_USERS";
export const GET_REASON = "GET_REASON";
export const GET_PERSONS_SEARCH = "GET_PERSONS_SEARCH";
export const GET_FILES_SEARCH = "GET_FILES_SEARCH";
export const GET_ARCHIVED_SEARCH = "GET_ARCHIVED_SEARCH";
export const DELETE_ENTITIES_ID = "DELETE_ENTITIES_ID";
export const DELETE_PERSON_ID = "DELETE_PERSON_ID";
export const GET_ALL_SEARCH = "GET_ALL_SEARCH";
export const GET_FREELANCE = "GET_FREELANCE";
export const RESET_PERSONS = "RESET_PERSONS";
export const RESET_FILES = "RESET_FILES";
export const GET_FILE_ROADMAP = "GET_FILE_ROADMAP";
export const GET_COUNTS = "GET_COUNTS";
export const GET_COUNTS_SEARCH = "GET_COUNTS_SEARCH";
export const DELETE_COUNT_ID = "DELETE_COUNT_ID";
export const GET_COUNTS_ID = "GET_COUNTS_ID";
export const GET_FILES_TEST = "GET_FILES_TEST";
export const RESET_ERRORS = "RESET_ERRORS";

export const resetPersons = () => async (dispatch) => {
  dispatch({ type: RESET_PERSONS });
};
export const resetErrors = () => async (dispatch) => {
  dispatch({ type: RESET_ERRORS });
};
export const resetFiles = () => async (dispatch) => {
  dispatch({ type: RESET_FILES });
};
export const reOpenFiles = (id) => async (dispatch) => {
  instance
    .post(`files/${id}/reopen`)
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      dispatch({ type: RE_OPEN, payload: data });
    })

    .catch((e) => console.warn("Error in saveDataLog", e.response));
};
export const getFiles = (value, boolean) => async (dispatch) => {
  let val = value ? value + `&is_open=${boolean}` : `?is_open=${boolean}`;
  instance
    .get(`files/${val}`)
    .then((res) => {
      if (res.data && res.data.results.length > 0) {
        return res.data;
      } else {
        let data = { results: ["No se encontraron resultados"] };
        return data;
      }
    })
    .then((data) => {
      dispatch({ type: GET_FILES, payload: data });
    })

    .catch((e) =>
      dispatch({
        type: GET_FILES,
        payload: {
          results: [e.response.data.detail || "No se encontraron resultados"],
        },
      })
    );
};
export const getFilesArchived = (value, boolean) => async (dispatch) => {
  let val = value ? `/` + value : ``;
  instance
    // .get(`files/${val}`)
    .get(`files/archive${val}`)
    .then((res) => {
      if (res.data && res.data.results.length > 0) {
        return res.data;
      } else {
        let data = { results: ["No se encontraron resultados"] };
        return data;
      }
    })
    .then((data) => {
      dispatch({ type: GET_FILES_ARCHIVED, payload: data });
    })

    .catch((e) =>
      dispatch({
        type: GET_FILES_ARCHIVED,
        payload: {
          results: [e.response.data.detail || "No se encontraron resultados"],
        },
      })
    );
};
export const getFilesTest = (value, boolean) => async (dispatch) => {
  let val = value ? value + `&is_open=${boolean}` : `?is_open=${boolean}`;
  instance
    .get(`files/${val}`)
    .then((res) => {
      if (res.data && res.data.results.length > 0) {
        return res.data;
      } else {
        let data = { results: ["No se encontraron resultados"] };
        return data;
      }
    })
    .then((data) => {
      dispatch({ type: GET_FILES_TEST, payload: data });
    })

    .catch((e) => console.warn("Error in saveDataLog", e.response));
};
export const getPermissions = () => async (dispatch) => {
  dispatch({ type: GET_PERMISSIONS });
};
export const upPermissions = (data) => async (dispatch) => {
  dispatch({ type: UP_PERMISSIONS, payload: data });
};
export const getCounts = (value) => async (dispatch) => {
  let val = value ? "/" + value : ``;
  instance
    .get(`files/counts${val}`)
    .then((res) => {
      if (res.data && res.data.results.length > 0) {
        return res.data;
      } else {
        let data = { results: ["No se encontraron resultados"] };
        return data;
      }
    })
    .then((data) => {
      dispatch({ type: GET_COUNTS, payload: data });
    })

    .catch((e) =>
      dispatch({
        type: GET_COUNTS,
        payload: {
          results: [e.response.data.detail || "No se encontraron resultados"],
        },
      })
    );
};
export const getFilesId = (id) => async (dispatch) => {
  instance
    .get(`files/${id}`)
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      dispatch({ type: GET_FILES_ID, payload: data });
    })

    .catch((e) => console.warn("Error in saveDataLog", e.response));
};
export const getFilesArchiveId = (id) => async (dispatch) => {
  instance
    .get(`files/archive/${id}`)
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      dispatch({ type: GET_FILES_ARCHIVE_ID, payload: data });
    })

    .catch((e) => console.warn("Error in saveDataLog", e.response));
};
export const getCountsId = (id) => async (dispatch) => {
  instance
    .get(`files/counts/${id}`)
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      dispatch({ type: GET_COUNTS_ID, payload: data });
    })

    .catch((e) => console.warn("Error in saveDataLog", e.response));
};
export const getFileRoadmap = (id) => async (dispatch) => {
  instance
    .get(`files/${id}/tracking`)
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      dispatch({ type: GET_FILE_ROADMAP, payload: data, errorRoadmap: null });
    })

    .catch((e) => {
      dispatch({
        type: GET_FILE_ROADMAP,
        payload: [],
        errorRoadmap: e.response.data,
      });
    });
};
export const getReasons = () => async (dispatch) => {
  instance
    .get(`files/reasons`)
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      dispatch({ type: GET_REASON, payload: data });
    })

    .catch((e) => console.warn("Error in saveDataLog", e.response));
};
export const deleteFilesId = (id) => async (dispatch) => {
  instance
    .delete(`files/${id}`)
    .then((res) => {
      if (res.status === 200 || res.status === 204) {
        return id;
      }
    })
    .then((data) => {
      dispatch({ type: DELETE_FILES_ID, payload: data });
    })

    .catch((e) => console.warn("Error in saveDataLog", e.response));
};
export const deleteEntitiesId = (id) => async (dispatch) => {
  instance
    .delete(`files/entities/${id}`)
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      dispatch({ type: DELETE_ENTITIES_ID, payload: id });
    })

    .catch((e) => console.warn("Error in saveDataLog", e.response));
};
export const deletePersonId = (id) => async (dispatch) => {
  instance
    .delete(`people/${id}`)
    .then((res) => {
      return id;
    })
    .then((data) => {
      dispatch({
        type: DELETE_PERSON_ID,
        payload: id,
        errorDeletePerson: null,
      });
    })

    .catch((e) => {
      dispatch({
        type: DELETE_PERSON_ID,
        payload: 0,
        errorDeletePerson: e.response?.data?.detail,
      });
    });
};
export const deleteCountId = (id) => async (dispatch) => {
  instance
    .delete(`files/counts/${id}`)
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      dispatch({ type: DELETE_COUNT_ID, payload: id, errorDeleteCount: null });
    })

    .catch((e) => {
      dispatch({
        type: DELETE_COUNT_ID,
        payload: id,
        errorDeleteCount: e.response.data,
      });
    });
};
export const getPersons = (value) => async (dispatch) => {
  let val = value ? value : "";
  instance

    .get(`people/${val}`)
    .then((res) => {
      console.log(res.data);

      if (res.data && res.data.results.length > 0) {
        return res.data;
      } else {
        let data = { results: ["No se encontraron resultados"] };
        return data;
      }
    })
    .then((data) => {
      dispatch({ type: GET_PERSONS, payload: data });
    })

    .catch((e) =>
      dispatch({
        type: GET_PERSONS,
        payload: {
          results: [e.response.data.detail || "No se encontraron resultados"],
        },
      })
    );
};
export const getfreelancers = (value) => async (dispatch) => {
  let val = value ? value : "";
  instance
    // .get(`http://localhost:3005/persons.json`)
    .get(`people/${val}`)
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      dispatch({ type: GET_FREELANCE, payload: data });
    })

    .catch((e) => console.warn("Error in saveDataLog", e.response));
};
export const getPersonsSearch = (search, optionSearch) => async (dispatch) => {
  var buscar = search && search.length > 0 ? search : " ";
  var option = optionSearch ? optionSearch : "";
  if (buscar.length == 0) {
    dispatch({
      type: GET_PERSONS_SEARCH,
      payload: [],
    });
  } else {
    instance
      .get(`people/?search=${search}${option}`)
      .then((res) => {
        if (res.data && res.data.results.length > 0) {
          return res.data;
        } else {
          let data = { results: ["No se encontraron resultados"] };
          return data;
        }
      })
      .then((data) => {
        dispatch({
          type: GET_PERSONS_SEARCH,
          payload: data,
        });
      })

      .catch((e) =>
        dispatch({
          type: GET_PERSONS_SEARCH,
          payload: {
            results: [e.response.data.detail || "No se encontraron resultados"],
          },
        })
      );
  }
};
export const getFreelancersSearch = (search) => async (dispatch) => {
  var buscar = search && search.length > 0 ? search : " ";
  if (buscar.length == 0) {
    dispatch({
      type: GET_PERSONS_SEARCH,
      payload: [],
    });
  } else {
    instance
      // .get(`http://localhost:3005/persons.json`)
      .get(`people/?search=${search}`)
      .then((res) => {
        return res.data.results;
      })
      .then((data) => {
        dispatch({
          type: GET_PERSONS_SEARCH,
          payload: data,
        });
      })

      .catch((e) => console.warn("Error in saveDataLog", e.response));
  }
};
export const getFilesSearch =
  (search, boolean, entity, status, range) => async (dispatch) => {
    const buscar = search && search.length > 0 ? search : " ";
    const statusValue = status ? "&status=" + status : "";
    const entityValue = entity ? "&entity=" + entity : "";
    const rangeValue = range ? "&open_date__range=" + range : "";

    if (
      buscar.length === 0 &&
      statusValue === "" &&
      entityValue === "" &&
      rangeValue === ""
    ) {
      dispatch({
        type: GET_FILES_SEARCH,
        payload: [],
      });
    } else {
      instance
        // .get(`http://localhost:3005/persons.json`)
        .get(
          `files?search=${search}&is_open=${boolean}${entityValue}${statusValue}${rangeValue}`
        )
        .then((res) => {
          if (res.data && res.data.results.length > 0) {
            return res.data;
          } else {
            let data = { results: ["No se encontraron resultados"] };
            return data;
          }
        })
        .then((data) => {
          dispatch({ type: GET_FILES_SEARCH, payload: data });
        })

        .catch((e) =>
          dispatch({
            type: GET_FILES_SEARCH,
            payload: {
              results: [
                e.response.data.detail || "No se encontraron resultados",
              ],
            },
          })
        );
    }
  };
export const getFilesArchivedSearch = (search, boolean) => async (dispatch) => {
  const buscar = search && search.length > 0 ? search : " ";

  if (buscar.length == 0) {
    dispatch({
      type: GET_ARCHIVED_SEARCH,
      payload: [],
    });
  } else {
    instance
      .get(`files?search=${search}&is_open=${boolean}`)
      .then((res) => {
        if (res.data && res.data.results.length > 0) {
          return res.data;
        } else {
          let data = { results: ["No se encontraron resultados"] };
          return data;
        }
      })
      .then((data) => {
        dispatch({ type: GET_ARCHIVED_SEARCH, payload: data });
      })

      .catch((e) =>
        dispatch({
          type: GET_ARCHIVED_SEARCH,
          payload: {
            results: [e.response.data.detail || "No se encontraron resultados"],
          },
        })
      );
  }
};

export const getCountsSearch = (search, optionSearch) => async (dispatch) => {
  var buscar = search && search.length > 0 ? search : " ";
  var option = optionSearch ? optionSearch : "";
  if (buscar.length == 0) {
    dispatch({
      type: GET_COUNTS_SEARCH,
      payload: [],
    });
  } else {
    instance
      .get(`files/counts/?search=${search}${option}`)
      .then((res) => {
        if (res.data && res.data.results.length > 0) {
          return res.data;
        } else {
          let data = { results: ["No se encontraron resultados"] };
          return data;
        }
      })
      .then((data) => {
        dispatch({
          type: GET_COUNTS_SEARCH,
          payload: data,
        });
      })

      .catch((e) => console.warn("Error in saveDataLog", e.response));
  }
};
export const getUsers = () => async (dispatch) => {
  instance
    // .get(`http://localhost:3005/persons.json`)
    .get(`users/`)
    .then((res) => {
      return res.data.results;
    })
    .then((data) => {
      dispatch({ type: GET_USERS, payload: data });
    })

    .catch((e) => console.warn("Error in saveDataLog", e.response));
};

export const getMetrics = () => async (dispatch) => {
  instance
    // .get(`http://localhost:3005/persons.json`)
    .get(`files/metrics`)
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      dispatch({ type: GET_METRICS, payload: data });
    })

    .catch((e) => console.warn("Error in saveDataLog", e.response));
};

export const getPersonsAssignments = () => async (dispatch) => {
  instance
    // .get(`http://localhost:3005/persons.json`)
    .get(`people?is_freelance=true`)
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      dispatch({ type: GET_PERSONS_ASSIGNMENTS, payload: data });
    })

    .catch((e) => console.warn("Error in saveDataLog", e.response));
};
export const getPersonsCustomer =
  (search, optionSearch) => async (dispatch) => {
    var buscar = search && search.length > 0 ? search : "";
    var option = optionSearch ? optionSearch : "";
    if (buscar.length == 0) {
      dispatch({
        type: GET_PERSONS_CUSTOMER,
        payload: [],
      });
    } else {
      instance
        // .get(`http://localhost:3005/persons.json`)
        .get(`people/?search=${buscar}${option}`)
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          dispatch({ type: GET_PERSONS_CUSTOMER, payload: data });
        })

        .catch((e) => console.warn("Error in saveDataLog", e.response));
    }
  };
export const getAll = (search) => async (dispatch) => {
  var buscar = search && search.length > 0 ? search : "";
  if (buscar.length == 0) {
    dispatch({
      type: GET_ALL_SEARCH,
      payload: [],
    });
  } else {
    instance
      // .get(`http://localhost:3005/persons.json`)
      .get(`search/?search=${buscar}`)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        dispatch({ type: GET_ALL_SEARCH, payload: data });
      })

      .catch((e) => console.warn("Error in saveDataLog", e.response));
  }
};

export const getEntities = () => async (dispatch) => {
  instance
    // .get(`http://localhost:3005/persons.json`)
    .get(`files/entities/`)
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      dispatch({ type: GET_ENTITIES, payload: data });
    })

    .catch((e) => console.warn("Error in saveDataLog", e.response));
};
export const getFilesRejected = () => async (dispatch) => {
  instance
    // .get(`http://localhost:3005/persons.json`)
    .get(`files/rejected`)
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      dispatch({ type: GET_FILES_REJECTED, payload: data });
    })

    .catch((e) => console.warn("Error in saveDataLog", e.response));
};
export const getFilesAssigned = () => async (dispatch) => {
  instance
    // .get(`http://localhost:3005/persons.json`)
    .get(`files/assigned`)
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      dispatch({ type: GET_FILES_ASSIGNED, payload: data });
    })

    .catch((e) => console.warn("Error in saveDataLog", e.response));
};
export const getFilesAccepted = () => async (dispatch) => {
  instance
    // .get(`http://localhost:3005/persons.json`)
    .get(`files/accepted`)
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      dispatch({ type: GET_FILES_ACCEPTED, payload: data });
    })

    .catch((e) => console.warn("Error in saveDataLog", e.response));
};
export const getEntitiesSearch = (search) => async (dispatch) => {
  var buscar = search && search.length > 0 ? search : " ";
  if (buscar.length == 0) {
    dispatch({
      type: GET_ENTITIES_SEARCH,
      payload: [],
    });
  } else {
    instance
      // .get(`http://localhost:3005/persons.json`)
      .get(`files/entities/?search=${buscar}`)
      .then((res) => {
        
        return res.data;
      })
      .then((data) => {
        dispatch({ type: GET_ENTITIES_SEARCH, payload: data });
      })

      .catch((e) => console.warn("Error in saveDataLog", e.response));
  }
};
export const getAssignments = () => async (dispatch) => {
  instance
    // .get(`http://localhost:3005/persons.json`)
    .get(`files/assignments/`)
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      dispatch({ type: GET_ASSIGNMENTS, payload: data });
    })

    .catch((e) => console.warn("Error in saveDataLog", e.response));
};
export const getNews = () => async (dispatch) => {
  axios
    // .get(`http://localhost:3005/persons.json`)
    .get(
      `https://www.datos.gov.co/api/id/gpzw-wmxd.json?$query=select%20*%2C%20%3Aid%20order%20by%20%60fecha_corte%60%20desc%20limit%20100`
    )
    .then((res) => {
      
      return res.data;
    })
    .then((data) => {
      dispatch({ type: GET_NEWS, payload: data });
    })

    .catch((e) => console.warn("Error in saveDataLog", e.response));
};
export const getFilesDetails = (id) => async (dispatch) => {
  const request = await fetch(
    `http://www.omdbapi.com/?apikey=20dac387&i=${id}`
  );
  const data = await request.json();
  dispatch({
    type: GET_FILES_DETAILS,
    payload: data,
  });
};

export const addFilesOpen = (payload) => ({
  type: ADD_FILES_OPEN,
  payload,
});
export const removeFilesOpen = (payload) => ({
  type: REMOVE_FILES_OPEN,
  payload,
});
