import React from "react";
import { Link, Redirect } from "react-router-dom";
import { AxiosURLImage, instance } from "../../utils";
import { AppSettings } from "./../../config/app-settings";
import Select from "react-select";
import axios from "axios";
import { Store, ReactNotifications } from "react-notifications-component";
import Moment from "moment";
import { Modal, ModalHeader, ModalBody, ModalFooter, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown, Dropdown  } from "reactstrap";

class Profile extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);

    this.showTab = this.showTab.bind(this);
    this.state = {
      tabPost: false,
      tabAbout: true,
      tabPhoto: false,
      tabVideo: false,
      tabFriend: false,
      Cities: [],
      persona_id:{},
      ido: "",
      modalChangePhoto:false,
      modalPassword:false,
      imagen:"",
      errorUpdatePhoto:"",
      old_password:"",
      password:"",
      password2:""
    };
  }
  componentDidMount() {
    this.context.handleSetAppContentClass("p-0");
  }
  toggleModal(name) {
    switch (name) {
      case "modalChangePhoto":
        this.setState({ modalChangePhoto: !this.state.modalChangePhoto });
        break;
        case "modalPassword":
          this.setState({ modalPassword: !this.state.modalPassword });
          break;
      default:
        break;
    }
  }

  componentWillMount() {
    this.componentWillMountedverdad(localStorage?.userId);
 
    axios
      .get("/city.json")
      .then((res) =>
        this.setState((state) => ({
          Cities: res.data,
        }))
      )
      .catch((err) => console.log(err.response));
  }
  componentWillUnmount() {
    this.context.handleSetAppContentClass("");
  }

  showTab(e, tab) {
    e.preventDefault();
   
    this.setState((state) => ({
      tabPost: tab === "post" ? true : false,
      tabAbout: tab === "about" ? true : false,
      tabPhoto: tab === "photo" ? true : false,
      tabVideo: tab === "video" ? true : false,
      tabFriend: tab === "friend" ? true : false,
    }));
  }

  addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
 
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }

  componentWillMountedverdad(personaId) {
    if (personaId != this.state.ido) {
      this.setState((state) => ({
        ido: personaId,
      }));
      instance
        .get(`people/?user=${personaId}`)
        .then((res) => {
          return res.data?.results;
        })
        .then((data) => {
          
          this.setState((state) => ({
            persona_id: data[0],
          }));
        })
        .catch((e) => console.warn("Error in saveDataLog", e.response));
    }
  }

  handleHeaderInverse = (e) => {
 
      this.context.handleSetAppHeaderInverse(e);
    
  };
  
  fede() {
    this.setState((val) => ({
      ...val,
      ["redirect"]: true,
    }));
  }
  updatePerson(persona_id) {
    this.setState((val) => ({
      
      persona_id: persona_id,
    }));
  }
  render() {
   
     if (this.state.redirect) {
    return <Redirect to="/Expedientes" />;
  }

  function CreandoExpediente(state,addNotification,updatePerson,handleHeaderInverse) {
    var bodyFormData = new FormData();
    
    
    if (state.address && state.persona_id.address != state.address) {
      bodyFormData.append("address", state.address);
    }
    if (
      state.phone_personal &&
      state.persona_id.phone_personal != state.phone_personal
    ) {
      bodyFormData.append("phone_personal", state.phone_personal);
    }
    if (
      state.phone_office &&
      state.persona_id.phone_office != state.phone_office
    ) {
      bodyFormData.append("phone_office", state.phone_office);
    }
    if (state.phone_home && state.persona_id.phone_home != state.phone_home) {
      bodyFormData.append("phone_home", state.phone_home);
    }
    if (state.email && state.persona_id.email != state.email) {
      bodyFormData.append("email", state.email);
    }
    if (state.city && state.persona_id.city != state.city) {
      bodyFormData.append("city", state.city);
    }
    if (state.birthdate && state.persona_id.birthdate != state.birthdate) {
      bodyFormData.append("birthdate", state.birthdate);
    }

    
    instance
      .patch(`people/${state?.persona_id?.id}`, bodyFormData)
      .then((res) => {
        if(res.data){

          if(res.data.birthdate.slice(5) == Moment().format("MM-DD")) { 
            handleHeaderInverse(false);
          } 
             addNotification(
            "default",
            "",
            `Cambios actualizados`,
            "bottom-center"
          );
          updatePerson(res.data)
        }
   }).catch((err) =>{if(err.response) console.log(err.response)});
  }
  const changePhoto = (event,state) => {
    const photo = event.target.files[0];

this.setState((val) => ({
    
  ["imagen"]: photo,
}));


  };
  const submitUpdatePhoto = (event,photo)=>{
    event.preventDefault()
    if(photo !== ""){
      var bodyFormData = new FormData();
      bodyFormData.append("profile_pic", photo);
      instance
      .patch(`users/current/${localStorage.userId}`, bodyFormData)
      .then((res) => {
        if(res.data){
      
        window.location.reload()
         localStorage.profile_pic = res.data.profile_pic;
        }
      }).catch((err) =>{if(err.response) {
      
         this.setState((val) => ({
          
            ["errorUpdatePhoto"]: err.response.data,
          }));

          setTimeout(() => {
            this.setState({
              ["errorUpdatePhoto"]: "",
            });;
          }, 3000);
        
      
      }});
    }else {



      this.setState((val) => ({
          
        ["errorUpdatePhoto"]: "Debes agregar una imagen",
      }));

      setTimeout(() => {
        this.setState({
          ["errorUpdatePhoto"]: "",
        });;
      }, 3000);
    }

  }
  const submitUpdatePassword = (event,old_password,password,password2,addNotification)=>{
    event.preventDefault()
    if(old_password !== "" && password !== "" && password2 !== ""){
      var bodyFormData = new FormData();
      bodyFormData.append("old_password", old_password);
      bodyFormData.append("password", password);
      bodyFormData.append("password2", password2);
      instance
      .post(`users/auth/change-password/`, bodyFormData)
      .then((res) => {
        if(res.data || res.status == 200){
      
          addNotification(
            "default",
            "",
            `Contraseña actualizada`,
            "bottom-center"
          );
        }
      }).catch((err) =>{if(err.response) {
      
         this.setState((val) => ({
          
            ["errorUpdatePhoto"]: err.response.data,
          }));

          setTimeout(() => {
            this.setState({
              ["errorUpdatePhoto"]: "",
            });;
          }, 3000);
        
      
      }});
    }else {



      this.setState((val) => ({
          
        ["errorUpdatePhoto"]: "Debes llenar todos los espacios.",
      }));

      setTimeout(() => {
        this.setState({
          ["errorUpdatePhoto"]: "",
        });;
      }, 3000);
    }

  }
 
    return (
      <div>
        <div className="profile">
          <div className="profile-header">
            <div className="profile-header-cover"></div>
            <div className="profile-header-content">
              <div className="profile-header-img">
                {localStorage.profile_pic ? (
                  <img
                    height={"100%"}
                    width={"100%"}
                    src={AxiosURLImage + localStorage.profile_pic}
                    alt="imageProfile"
                  />
                ) : null}
              </div>
              <div className="profile-header-info">
                <h4 className="mt-0 mb-1">{localStorage.full_name}</h4>
                <p className="mb-2">Usuario</p>
                <button
                  onClick={() => {
                    this.toggleModal("modalChangePhoto");
                  }}
                  className="btn btn-xs btn-success mb-2"
                >
                  Cambiar foto
                </button>
                <br />
                <button
                  onClick={() => {
                    this.toggleModal("modalPassword");
                  }}
                  className="btn btn-xs btn-warning"
                >
                  Cambiar contraseña
                </button>
              </div>
            </div>
            <ul className=" nav nav-tabs">
              <li className="nav-item">
                <Link
                  to="#"
                  className={
                    "nav-link " + (this.state.tabAbout ? "active " : "")
                  }
                >
                  ABOUT
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="profile-content">
          <div className="tab-content p-0">
            <div
              className={
                "tab-pane fade " + (this.state.tabAbout ? "show active " : "")
              }
            >
              <div className="table-responsive form-inline">
                <table className="table table-profile align-middle">
                  <tbody
                    style={{
                      height: "auto",
                      display: "flex",
                      // flexWrap: "wrap",
                      flexDirection: "column",
                    }}
                  >
                    <tr>
                      <td className="d-flex">
                        <span className="fw-bold maxWithlabels my-auto">
                          Correo electrónico
                        </span>

                        <input
                          disabled={true}
                          style={{
                            border: "1px solid hsl(0, 0%, 80%)",
                            borderRadius: "4px",
                            padding: "10px",
                          }}
                          defaultValue={this.state.persona_id?.email}
                          className=" w-300px py-2"
                          type="text"
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <td className="d-flex">
                        <span className="fw-bold my-auto maxWithlabels">
                          Fecha de nacimiento
                        </span>

                        <input
                          name="open_date"
                          style={{
                            border: "1px solid hsl(0, 0%, 80%)",
                            borderRadius: "4px",
                            padding: "10px",
                          }}
                          onChange={(e) =>
                            this.setState((state) => ({
                              birthdate: e.target.value,
                            }))
                          }
                          defaultValue={this.state.persona_id?.birthdate}
                          className="form-control w-300px py-2"
                          type="date"
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <td className="d-flex">
                        <span className="fw-bold maxWithlabels my-auto">
                          Dirección
                        </span>

                        <input
                          // disabled={this.state.disabledbtn}
                          style={{
                            border: "1px solid hsl(0, 0%, 80%)",
                            borderRadius: "4px",
                            padding: "10px",
                          }}
                          onChange={(e) =>
                            this.setState((state) => ({
                              address: e.target.value,
                            }))
                          }
                          defaultValue={this.state.persona_id?.address}
                          className=" w-300px py-2"
                          type="text"
                        ></input>
                      </td>
                    </tr>

                    <tr>
                      <td className="d-flex">
                        <span className="fw-bold maxWithlabels my-auto">
                          Teléfono casa
                        </span>

                        <input
                          // disabled={this.state.disabledbtn}
                          style={{
                            border: "1px solid hsl(0, 0%, 80%)",
                            borderRadius: "4px",
                            padding: "10px",
                          }}
                          onChange={(e) =>
                            this.setState((state) => ({
                              phone_home: e.target.value,
                            }))
                          }
                          defaultValue={this.state.persona_id?.phone_home}
                          className=" w-300px py-2"
                          type="text"
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <td className="d-flex">
                        <span className="fw-bold maxWithlabels my-auto">
                          Número celular
                        </span>

                        <input
                          // disabled={this.state.disabledbtn}
                          style={{
                            border: "1px solid hsl(0, 0%, 80%)",
                            borderRadius: "4px",
                            padding: "10px",
                          }}
                          onChange={(e) =>
                            this.setState((state) => ({
                              phone_personal: e.target.value,
                            }))
                          }
                          defaultValue={this.state.persona_id?.phone_personal}
                          className=" w-300px py-2"
                          type="text"
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <td className="d-flex">
                        <span className="fw-bold maxWithlabels my-auto">
                          Teléfono oficina
                        </span>

                        <input
                          // disabled={this.state.disabledbtn}
                          style={{
                            border: "1px solid hsl(0, 0%, 80%)",
                            borderRadius: "4px",
                            padding: "10px",
                          }}
                          onChange={(e) =>
                            this.setState((state) => ({
                              phone_office: e.target.value,
                            }))
                          }
                          defaultValue={this.state.persona_id?.phone_office}
                          className=" w-300px py-2"
                          type="text"
                        ></input>
                      </td>
                    </tr>

                    <tr>
                      <td className="d-flex">
                        <span className="fw-bold maxWithlabels my-auto">
                          Departamento
                        </span>

                        <Select
                          // isDisabled={this.state.disabledbtn}
                          className="widthInput w-md-300px"
                          options={
                            this.state.Cities.length > 0 &&
                            this.state.Cities.map((personCus) => ({
                              value: personCus.id,
                              label: personCus.departamento,
                            }))
                          }
                          placeholder="Selecciona"
                          defaultValue={false}
                          name="city"
                          onChange={(e) =>
                            this.setState((state) => ({
                              ["department"]: e.value,
                            }))
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="d-flex">
                        <span className="fw-bold maxWithlabels my-auto">
                          Ciudad
                        </span>

                        {this.state.persona_id?.city ? (
                          <Select
                            // isDisabled={this.state.disabledbtn}
                            className="widthInput w-md-300px"
                            options={
                              this.state.Cities.length > 0 &&
                              this.state.Cities.filter(
                                (e) => e.id == this.state["department"]
                              )[0] &&
                              this.state.Cities.filter(
                                (e) => e.id == this.state["department"]
                              )[0]["ciudades"].map((personCus) => ({
                                value: personCus,
                                label: personCus,
                              }))
                            }
                            placeholder="Selecciona"
                            defaultValue={{
                              value: this.state.persona_id?.city,
                              label: this.state.persona_id?.city,
                            }}
                            onChange={(e) =>
                              this.setState((state) => ({
                                ["city"]: e.value,
                              }))
                            }
                          />
                        ) : null}
                        {!this.state.persona_id?.city ? (
                          <Select
                            // isDisabled={this.state.disabledbtn}
                            className="widthInput w-md-300px   m-1"
                            options={
                              this.state.Cities.length > 0 &&
                              this.state.Cities.filter(
                                (e) => e.id == this.state["department"]
                              )[0] &&
                              this.state.Cities.filter(
                                (e) => e.id == this.state["department"]
                              )[0]["ciudades"].map((personCus) => ({
                                value: personCus,
                                label: personCus,
                              }))
                            }
                            placeholder="Selecciona"
                            defaultValue={false}
                            onChange={(e) =>
                              this.setState((state) => ({
                                ["city"]: e.value,
                              }))
                            }
                          />
                        ) : null}
                      </td>
                    </tr>

                    <tr className="divider">
                      <td colSpan="2"></td>
                    </tr>
                    <tr className="highlight w-100">
                      <td className="field " style={{ border: "none" }}>
                        &nbsp;
                      </td>
                      <td
                        style={{ border: "none" }}
                        className="d-flex my-auto  justify-content-center"
                        //   style={{ justifyContent: "flex-start" }}
                      >
                        <button
                          onClick={() => {
                            CreandoExpediente(
                              this.state,
                              this.addNotification,
                              this.updatePerson,
                              this.handleHeaderInverse
                            );
                            // this.fede();
                          }}
                          type="submit"
                          className="btn btn-warning w-150px"
                        >
                          Guardar cambios
                        </button>{" "}
                        <button
                          onClick={() => this.fede()}
                          className="btn btn-white border-0 w-150px ms-2px"
                        >
                          Cancelar
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <ReactNotifications />
        <Modal
          isOpen={this.state.modalPassword}
          toggle={() => this.toggleModal("modalPassword")}
        >
          <ModalHeader
            // toggle={() => setmodalAsignment(!modalAsignment)}
            close={
              <button
                className="btn-close"
                onClick={() => {
                  this.toggleModal("modalPassword");
                }}
              ></button>
            }
          >
            Cambiar Contraseña
          </ModalHeader>
          <form>
            <ModalBody>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div className="d-flex  mx-auto" style={{ width: "100%" }}>
                  <div className="field m-auto">Contraseña anterior</div>
                  <div>
                    <input
                      onChange={(e) =>
                        this.setState((state) => ({
                          old_password: e.target.value,
                        }))
                      }
                      type="text"
                      name="old_password"
                      placeholder="Escribe tu contraseña anterior"
                      className="form-control w-200px w-md-300px m-auto p-2"
                      style={{
                        borderColor: "hsl(0, 0%, 80%)",
                        borderRadius: "4px",
                      }}
                    ></input>
                  </div>
                </div>
                <div className="d-flex  mx-auto my-2" style={{ width: "100%" }}>
                  <div className="field m-auto">Nueva contraseña</div>
                  <div>
                    <input
                      onChange={(e) =>
                        this.setState((state) => ({
                          password: e.target.value,
                        }))
                      }
                      type="text"
                      name="password"
                      placeholder="Escribe tu nueva contraseña"
                      className="form-control w-200px w-md-300px m-auto p-2"
                      style={{
                        borderColor: "hsl(0, 0%, 80%)",
                        borderRadius: "4px",
                      }}
                    ></input>
                  </div>
                </div>
                <div className="d-flex  mx-auto" style={{ width: "100%" }}>
                  <div className="field m-auto">Confirmar nueva contraseña</div>
                  <div>
                    <input
                      onChange={(e) =>
                        this.setState((state) => ({
                          password2: e.target.value,
                        }))
                      }
                      type="text"
                      name="password2"
                      placeholder="Confirma tu nueva contraseña"
                      className="form-control w-200px w-md-300px m-auto p-2"
                      style={{
                        borderColor: "hsl(0, 0%, 80%)",
                        borderRadius: "4px",
                      }}
                    ></input>
                  </div>
                </div>
                <div className="d-flex mx-auto" style={{ width: "100%" }}>
                  <div className="w-75px field m-auto "></div>
                  <div className="w-300px m-auto">
                    {" "}
                    <span style={{ color: "red" }}>
                      {this.state.errorUpdatePhoto}
                    </span>{" "}
                  </div>{" "}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                className="btn btn-white"
                onClick={(e) => {
                  e.preventDefault();
                  this.toggleModal("modalPassword");
                }}
              >
                Cerrar
              </button>
              <button
                onClick={(e) =>
                  submitUpdatePassword(
                    e,
                    this.state.old_password,
                    this.state.password,
                    this.state.password2,
                    this.addNotification
                  )
                }
                className="btn btn-success"
              >
                Actualizar contraseña
              </button>
            </ModalFooter>
          </form>
        </Modal>
        <Modal
          isOpen={this.state.modalChangePhoto}
          toggle={() => this.toggleModal("modalChangePhoto")}
        >
          <ModalHeader
            // toggle={() => setmodalAsignment(!modalAsignment)}
            close={
              <button
                className="btn-close"
                onClick={() => {
                  this.toggleModal("modalChangePhoto");
                }}
              ></button>
            }
          >
            Rechazar Expediente
          </ModalHeader>
          <form>
            <ModalBody>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div className="d-flex  mx-auto" style={{ width: "80%" }}>
                  <div className="field m-auto">Imagen</div>
                  <div>
                    <input
                      onChange={(e) => changePhoto(e)}
                      type="file"
                      name="profile_pic"
                      accept="image/*"
                      placeholder="Selecciona una imagen"
                      className="form-control w-300px m-auto p-2"
                      style={{
                        borderColor: "hsl(0, 0%, 80%)",
                        borderRadius: "4px",
                      }}
                    ></input>
                  </div>
                </div>

                <div className="d-flex mx-auto" style={{ width: "80%" }}>
                  <div className="w-75px field m-auto "></div>
                  <div className="w-300px m-auto">
                    {" "}
                    <span style={{ color: "red" }}>
                      {this.state.errorUpdatePhoto}
                    </span>{" "}
                  </div>{" "}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                className="btn btn-white"
                onClick={(e) => {
                  e.preventDefault();
                  this.toggleModal("modalChangePhoto");
                }}
              >
                Cerrar
              </button>
              <button
                onClick={(e) => submitUpdatePhoto(e, this.state.imagen)}
                className="btn btn-success"
              >
                Actualizar foto
              </button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}

export default Profile;
