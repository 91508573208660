import React from "react";
import { Link, Redirect } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Collapse, CardHeader, CardBody, Card } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AppSettings } from "./../../config/app-settings.js";
import Select from "react-select";
import {
  deleteFilesId,
  removeFilesOpen,
 
  getUsers,
  getFileRoadmap,
} from "../../actions/index.js";
import SweetAlert from "react-bootstrap-sweetalert";

import { connect } from "react-redux";
import { instance, successPermissions } from "../../utils/index.js";
import TablePrueba from "../../pages/option/tablaTest.js";
class SidebarRight extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapse: [{ id: "Información", collapse: true }],
      path: window.location.pathname.replace("/Expediente/", ""),
      sweetAlertPrimary: false,
      sweetAlertInfo: false,
      sweetAlertWarning: false,
      sweetAlertSuccess: false,
      sweetAlertError: false,
      redirect: false,
      redirectRemove: false,
      modalAsignment: false,
      modalControlCambios: false,
      modalArchivar: false,
      modalSendMail: false,
      AsignaraError: false,
      AsignaraCommentError: false,
      ArchivarCommentError: "",
      cambiosControl: [],
      Asignara: "",
      redirectCountCreate: false,
      redirectCountEdit: false,
      comentarioAsignar: "",
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitArchivar = this.handleSubmitArchivar.bind(this);
    this.handleSubmitSendMail = this.handleSubmitSendMail.bind(this);
  }
  componentWillMount() {
    const { getUsers } = this.props;

    getUsers();
    if (!this.state.path.includes("ExpedienteVer")) {
      instance
        .get(`files/${this.state.path}/history`)
        .then((res) => {
          if (res.data) {
            this.setState((state) => ({
              cambiosControl: res.data?.changes,
            }));
          }
        })
        .catch((err) => console.log(err));
    }
  }
  componentDidMount() {
    this.parseQueryParams();
  }

  parseQueryParams() {
    const query = window.location?.pathname;
    if (query) {
      this.setState({ path: query.replace("/Expediente/", "") });
    }
    // for (let param of query.entries()) {
    //     if (this.state.foo!== param[1]) {
    //         this.setState({foo: param[1]});
    //     }
    // }
  }
  toggleSweetAlert(name) {
    switch (name) {
      case "primary":
        this.setState({ sweetAlertPrimary: !this.state.sweetAlertPrimary });
        break;
      case "info":
        this.setState({ sweetAlertInfo: !this.state.sweetAlertInfo });
        break;
      case "success":
        this.setState({ sweetAlertSuccess: !this.state.sweetAlertSuccess });
        break;
      case "warning":
        this.setState({ sweetAlertWarning: !this.state.sweetAlertWarning });
        break;
      case "error":
        this.setState({ sweetAlertError: !this.state.sweetAlertError });
        break;
      default:
        break;
    }
  }
  toggleCollapse(index) {
    var newArray = [];
    for (let collapseObj of this.state.collapse) {
      if (collapseObj.id === index) {
        collapseObj.collapse = !collapseObj.collapse;
      } else {
        collapseObj.collapse = false;
      }
      newArray.push(collapseObj);
    }

    this.setState({
      collapse: newArray,
    });
  }
  handleSubmitDelete() {
    this.setState((state) => ({
      redirect: true,
    }));
  }
  handleSubmitRemoveFiles() {
    this.setState((state) => ({
      redirectRemove: true,
    }));
  }
  handleRedirectCreate() {
    this.setState((state) => ({
      redirectCountCreate: true,
    }));
  }
  handleRedirectEdit() {
    this.setState((state) => ({
      redirectCountEdit: true,
    }));
  }

  toggleModal(name) {
    switch (name) {
      case "modalAddTask":
        this.setState({ modalAsignment: !this.state.modalAsignment });
        break;
      case "modalControlCambios":
        this.setState({ modalControlCambios: !this.state.modalControlCambios });
        break;
      case "modalArchivar":
        this.setState({ modalArchivar: !this.state.modalArchivar });
        break;
      case "modalSendMail":
        this.setState({ modalSendMail: !this.state.modalSendMail });
        break;
      case "modalReject":
        this.setState({ modalReject: !this.state.modalReject });
        break;
      default:
        break;
    }
  }
  inputChanged(value) {
    const valueSearch = value.toLowerCase();
    if (value != "" && value != " " && value.length > 0) {
      this.setState((state) => ({
        cambiosControl: this.state.cambiosControl.filter(
          (element) =>
            element.user.toLowerCase().includes(valueSearch) ||
            element.message.toLowerCase().includes(valueSearch)
        ),
      }));
    } else {
      instance
        .get(`files/${this.state.path}/history`)
        .then((res) => {
          if (res.data) {
            this.setState((state) => ({
              cambiosControl: res.data?.changes,
            }));
          }
        })
        .catch((err) => console.log(err));
    }
  }

  handleSubmitArchivar(event) {
    event.preventDefault();
    let number = event.target[0].value;
    let comment = event.target[1].value;

    if (number === "" || comment === "") {
      this.setState({
        ArchivarCommentError: "Debes llenar todos los espacios",
      });
      setTimeout(() => {
        this.setState({
          ArchivarCommentError: "",
        });
      }, 3000);
    } else {
      var bodyFormData = new FormData();
      bodyFormData.append("number", number);
      bodyFormData.append("comment", comment);
      instance
        .post(`files/${this.props.fileId.id}/archive`, bodyFormData)
        .then((res) => {
          if (res.status === 201 || res.data) {
            this.toggleModal("modalArchivar");
            this.setState((state) => ({
              redirect: true,
            }));
            this.props.getFileRoadmap(this.props.fileId.id);
          }
        })
        .catch((e) => {
          this.setState({
            ArchivarCommentError: e.response.data,
          });
          setTimeout(() => {
            this.setState({
              ArchivarCommentError: "",
            });
          }, 3000);
        });
    }
  }

  handleSubmitSendMail(event) {
    event.preventDefault();

    instance
      .get(`files/generate_pdf/${this.props.fileId.id}/`)
      .then((res) => {
        if (res.status === 201 || res.data) {
          this.toggleModal("handleSubmitSendMail");
        }
      })
      .catch((e) => {
        alert("no se pdo enviar");
      });
  }

  handleSubmit(event) {
    event.preventDefault();
   
    if (
      this.state.Asignara.length === 0 ||
      this.state.comentarioAsignar.length === 0
    ) {
      if (this.state.Asignara.length === 0) {
        this.setState({ AsignaraError: !this.state.AsignaraError });
        setTimeout(() => {
          this.setState({ AsignaraError: !this.state.AsignaraError });
        }, 3000);
      }
      if (this.state.comentarioAsignar.length === 0) {
        this.setState({ AsignaraCommentError: "Este campo es necesario" });
        setTimeout(() => {
          this.setState({
            AsignaraCommentError: "",
          });
        }, 3000);
      }
    } else {
      var bodyFormData = new FormData();
      bodyFormData.append("receiver", this.state.Asignara);
      bodyFormData.append("comment", this.state.comentarioAsignar);
      instance
        .post(`files/${this.props.fileId.id}/assign`, bodyFormData)
        .then((res) => {
          if (res.status === 201 || res.data) {
            this.setState({
              Asignara: "",
              comentarioAsignar: "",
            });
            localStorage.ExpedienteAsignado = this.props.fileId.id;
            this.toggleModal("modalAddTask");
            this.props.getFileRoadmap(this.props.fileId.id);
            this.handleSubmitRemoveFiles();
            this.handleSubmitDelete();
          }
        })
        .catch((e) => {
          this.setState({
            AsignaraCommentError: e.response.data,
          });
          setTimeout(() => {
            this.setState({
              AsignaraCommentError: "",
            });
          }, 3000);
        });
    }
  }

  render() {
    const columnsForTable = [
      {
        Header: "Usuario",
        accessor: "user",
        sortable: true,
      },

      {
        Header: "Modificado",
        accessor: "date",
        sortable: true,
      },
      {
        Header: "Mensaje",
        accessor: "message",
        sortable: true,
      },
    ];

    const { deleteFilesId, fileId, users, removeFilesOpen } = this.props;

    if (this.state.redirect) {
      return <Redirect to="/Expedientes" />;
    }
    if (this.state.redirectCountCreate) {
      return <Redirect to={`/ConteoNuevo/${fileId.id}|${fileId.number}`} />;
    }
    if (this.state.redirectCountEdit) {
      return <Redirect to={`/EditarConteo/${fileId?.has_count}`} />;
    }
    if (this.state.redirectRemove) {
      removeFilesOpen(fileId?.id);
    }

    const actions = [
      successPermissions("full_access_counts") == "d-none"
        ? null
        : [
            fileId.has_count == false ? "Crear conteo" : "Editar conteo",
            <i className="fas fa-lg fa-fw me-10px fa-calculator"></i>,
          ],
      successPermissions("can_archive_files") == "d-none"
        ? null
        : [
            "Archivar",
            <i className="fas fa-lg fa-fw me-10px fa-box-archive"></i>,
          ],
      successPermissions("full_access_count_buttons_options") == "d-none"
        ? null
        : ["Borrar", <i className="fas fa-lg fa-fw me-10px fa-trash-can"></i>],
      successPermissions("can_create_file_assignment") == "d-none"
        ? null
        : [
            "Asignar",
            <i className="fas fa-lg fa-fw me-10px fa-share-from-square"></i>,
          ],
      successPermissions("can_view_file_history") == "d-none"
        ? null
        : [
            "Control de cambios",
            <i className="fas fa-lg fa-fw me-10px fa-rectangle-list"></i>,
          ],
      [
        "Icarus",
        <i className="fas fa-lg fa-fw me-10px fa-share-from-square"></i>,
      ],
      // Enviar correo
      // [
      //   "Enviar correo",
      //   <i className="fas fa-lg fa-fw me-10px fa-envelope"></i>,
      // ],
    ];
    return (
      <AppSettings.Consumer>
        {({ appSidebarTwo, toggleAppSidebarEndMobile }) => (
          <React.Fragment>
            {appSidebarTwo && (
              <React.Fragment>
                <div id="sidebar-right" className="app-sidebar app-sidebar-end">
                  <PerfectScrollbar
                    className="app-sidebar-content h-100"
                    options={{ suppressScrollX: true }}
                  >
                    <div
                      className="p-20px text-white d-flex "
                      style={{
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      {actions
                        .filter((e) => e)
                        .map((e) =>
                          e[0] === "Asignar" && fileId?.status == 30 ? null : (
                            <div
                              className={
                                e[0] !== "Icarus"
                                  ? `btn  btn-primary my-1`
                                  : e[0] == "Icarus" && fileId.icarus_link
                                  ? `btn  btn-primary my-1`
                                  : "d-none"
                              }
                              onClick={() => {
                                if (e[0] == "Borrar") {
                                  this.toggleSweetAlert("error");
                                } else if (e[0] == "Asignar") {
                                  this.setState({
                                    modalAsignment: !this.state.modalAsignment,
                                  });
                                } else if (e[0] == "Crear conteo") {
                                  if (localStorage.inputs_counts) {
                                    localStorage.removeItem("inputs_counts");
                                  }
                                  this.handleRedirectCreate();
                                } else if (e[0] == "Editar conteo") {
                                  if (localStorage.inputs_counts) {
                                    localStorage.removeItem("inputs_counts");
                                  }
                                  this.handleRedirectEdit();
                                } else if (e[0] == "Icarus") {
                                  window.open(fileId?.icarus_link);
                                } else if (e[0] == "Control de cambios") {
                                  this.toggleModal("modalControlCambios");
                                } else if (e[0] == "Archivar") {
                                  this.toggleModal("modalArchivar");
                                } else if (e[0] == "Enviar correo") {
                                  this.toggleModal("modalSendMail");
                                }
                              }}
                            >
                              <span
                                className="d-flex align-items-center text-center"
                                style={{
                                  justifyContent: "center",
                                }}
                              >
                                {e[1]}
                                <span>
                                  <span className="d-flex">
                                    <b>{e[0]}</b>
                                  </span>
                                </span>
                              </span>
                            </div>
                          )
                        )}
                      {/* <button className="" onClick={()=>onTabClicker()}>Ir to id</button> */}
                      <br />
                      <br />

                      <div
                        id="accordion"
                        className="accordion rounded overflow-hidden"
                      >
                        {this.state.collapse.map((value, i) => (
                          <Card
                            className="bg-gray-700 text-white border-0 rounded-0 cursor-pointer"
                            key={i}
                          >
                            <CardHeader
                              className={
                                "card-header bg-gray-900 text-white pointer-cursor border-0 d-flex align-items-center rounded-0 " +
                                (!value.collapse ? "collapsed " : "")
                              }
                              onClick={() => this.toggleCollapse(value.id)}
                            >
                              <i className="fa fa-circle text-teal fs-6px me-2"></i>{" "}
                              {value.id}
                            </CardHeader>
                            {value.id == "Acciones" ? (
                              <Collapse isOpen={value.collapse}>
                                <CardBody className="p-3">Hola</CardBody>
                              </Collapse>
                            ) : (
                              <Collapse
                                isOpen={value.collapse}
                                style={{ background: "white" }}
                              >
                                <CardBody
                                  className="p-3"
                                  style={{ color: "#000" }}
                                >
                                  <strong> Entidad</strong>
                                  <br />
                                  {fileId?.entity?.name} <br />{" "}
                                  <strong>Cliente</strong>
                                  <br />{" "}
                                  {successPermissions(
                                    "full_access_people",
                                    "can_view_customers"
                                  ) == "d-none" ? (
                                    fileId?.customer?.full_name
                                  ) : (
                                    <Link
                                      to={`/Persona/${fileId?.customer?.id}`}
                                    >
                                      {fileId?.customer?.full_name}
                                    </Link>
                                  )}
                                  <br />
                                  <strong>Documento</strong>
                                  <br /> {fileId?.customer?.document?.number}
                                  <br />
                                  <strong>Freelance</strong>
                                  <br />{" "}
                                  {successPermissions(
                                    "full_access_people",
                                    "can_view_freelancers"
                                  ) == "d-none" ? (
                                    fileId?.freelance?.full_name
                                  ) : (
                                    <Link
                                      to={`/Freelancer/${fileId?.freelance?.id}`}
                                    >
                                      {fileId?.freelance?.full_name}
                                    </Link>
                                  )}
                                  <br />
                                  <br />
                                  <Link
                                    className="btn btn-warning my-1 p-2"
                                    style={{
                                      color: "#ffff",
                                      textDecoration: "none",
                                    }}
                                    to={`/ExpedienteVer/${this.state.path}`}
                                  >
                                    Ver más{" "}
                                    <i className="fas fa-lg fa-fw me-10px fa-solid fa-circle-arrow-right"></i>{" "}
                                  </Link>
                                </CardBody>
                              </Collapse>
                            )}
                          </Card>
                        ))}
                      </div>
                    </div>
                  </PerfectScrollbar>
                </div>
                {/* modalenviarcorreo */}

                <Modal
                  isOpen={this.state.modalSendMail}
                  toggle={() => this.toggleModal("modalSendMail")}
                >
                  <ModalHeader
                    // toggle={() => setmodalAsignment(!modalAsignment)}
                    close={
                      <button
                        className="btn-close"
                        onClick={() => {
                          this.toggleModal("modalSendMail");
                        }}
                      ></button>
                    }
                  >
                    Enviar expediente por correo
                  </ModalHeader>
                  <form onSubmit={this.handleSubmitSendMail}>
                    {/* <ModalBody><div
                          className="d-flex  mx-auto my-2"
                          style={{ width: "80%" }}
                        >
                          <div className="field m-auto">Número</div>
                          <div>
                            <input
                           
                              placeholder="Escribe nuevo número expediente"
                              className="w-300px m-auto p-2 form-control"
                              style={{
                                borderColor: "hsl(0, 0%, 80%)",
                                borderRadius: "4px",
                              }}
                            ></input>
                          </div>
                        </div>
                   
             
                        
                    </ModalBody> */}
                    <ModalFooter>
                      <button
                        className="btn btn-white"
                        onClick={() => {
                          this.toggleModal("modalSendMail");
                        }}
                      >
                        Cerrar
                      </button>
                      <button type="submit" className="btn btn-success">
                        Enviar
                      </button>
                    </ModalFooter>
                  </form>
                </Modal>

                {/* modalarchivar */}
                <Modal
                  isOpen={this.state.modalArchivar}
                  toggle={() => this.toggleModal("modalArchivar")}
                >
                  <ModalHeader
                    // toggle={() => setmodalAsignment(!modalAsignment)}
                    close={
                      <button
                        className="btn-close"
                        onClick={() => {
                          this.toggleModal("modalArchivar");
                        }}
                      ></button>
                    }
                  >
                    Archivar expediente
                  </ModalHeader>
                  <form onSubmit={this.handleSubmitArchivar}>
                    <ModalBody>
                      <div
                        className="d-flex  mx-auto my-2"
                        style={{ width: "80%" }}
                      >
                        <div className="field m-auto">Número</div>
                        <div>
                          <input
                            placeholder="Escribe nuevo número expediente"
                            className="w-300px m-auto p-2 form-control"
                            style={{
                              borderColor: "hsl(0, 0%, 80%)",
                              borderRadius: "4px",
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="d-flex  mx-auto" style={{ width: "80%" }}>
                        <div className="field m-auto">Comentario</div>
                        <div>
                          <textarea
                            placeholder="Escribe tu comentario"
                            className="w-300px m-auto p-2"
                            style={{
                              borderColor: "hsl(0, 0%, 80%)",
                              borderRadius: "4px",
                            }}
                          ></textarea>
                        </div>
                      </div>

                      {this.state.ArchivarCommentError ? (
                        <div
                          className="d-flex mx-auto"
                          style={{ width: "80%" }}
                        >
                          <div className="w-75px field m-auto "></div>
                          <div className="w-300px m-auto">
                            {" "}
                            <span style={{ color: "red" }}>
                              {this.state.ArchivarCommentError}
                            </span>{" "}
                          </div>{" "}
                        </div>
                      ) : null}
                    </ModalBody>
                    <ModalFooter>
                      <button
                        className="btn btn-white"
                        onClick={() => {
                          this.toggleModal("modalArchivar");
                        }}
                      >
                        Cerrar
                      </button>
                      <button type="submit" className="btn btn-success">
                        Archivar
                      </button>
                    </ModalFooter>
                  </form>
                </Modal>
                <Modal
                  isOpen={this.state.modalControlCambios}
                  toggle={() => this.toggleModal("modalControlCambios")}
                >
                  <ModalHeader
                    // toggle={() => setmodalAsignment(!modalAsignment)}
                    close={
                      <button
                        className="btn-close"
                        onClick={() => {
                          this.toggleModal("modalControlCambios");
                        }}
                      ></button>
                    }
                  >
                    Control de cambios
                  </ModalHeader>

                  <ModalBody>
                    {this.state.cambiosControl &&
                    this.state.cambiosControl.length > 0 ? (
                      <form id="formsearchTimeline" name="search_form">
                        <div className="form-group position-relative  width_seach_type w-sm-50  d-flex">
                          {" "}
                          <button
                            className="btn btn-search position-absolute"
                            disabled={true}
                          >
                            <i className="fa fa-search me-20px icon-search-action border-0"></i>
                          </button>{" "}
                          <input
                            type="text"
                            id="inputTextSearch"
                            className="form-control px-5"
                            placeholder="Buscar"
                            onChange={(e) => this.inputChanged(e.target.value)}
                          />
                        </div>
                      </form>
                    ) : null}

                    {this.state.cambiosControl &&
                    this.state.cambiosControl.length > 0 ? (
                      <TablePrueba
                        height={"50vh"}
                        data={this.state.cambiosControl}
                        columnsData={columnsForTable}
                        pagination={true}
                      ></TablePrueba>
                    ) : (
                      <span className="text-center">
                        No tienes cambios actualmente
                      </span>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <button
                      className="btn btn-white"
                      onClick={() => {
                        this.toggleModal("modalControlCambios");
                      }}
                    >
                      Cerrar
                    </button>
                  </ModalFooter>
                </Modal>
                <Modal
                  isOpen={this.state.modalAsignment}
                  toggle={() => this.toggleModal("modalAddTask")}
                >
                  <ModalHeader
                    // toggle={() => setmodalAsignment(!modalAsignment)}
                    close={
                      <button
                        className="btn-close"
                        onClick={() => {
                          this.toggleModal("modalAddTask");
                        }}
                      ></button>
                    }
                  >
                    Asignar Expediente
                  </ModalHeader>
                  <form onSubmit={this.handleSubmit}>
                    <ModalBody>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          className="d-flex mx-auto"
                          style={{ width: "80%" }}
                        >
                          <div className=" field m-auto ">Asignar a</div>
                          <div>
                            <Select
                              className="w-300px m-auto"
                              options={users.map((person) => ({
                                label: person.first_name + person.last_name,
                                value: person.id,
                              }))}
                              placeholder="Selecciona"
                              name="Personas"
                              onChange={(e) =>
                                this.setState({ Asignara: e.value })
                              }
                            />
                          </div>
                        </div>
                        {this.state.AsignaraError ? (
                          <div
                            className="d-flex mx-auto"
                            style={{ width: "80%" }}
                          >
                            <div className="w-75px field m-auto "></div>
                            <div className="w-300px m-auto">
                              {" "}
                              <span style={{ color: "red" }}>
                                Este campo es necesario
                              </span>{" "}
                            </div>{" "}
                          </div>
                        ) : null}

                        <br />

                        <div
                          className="d-flex  mx-auto"
                          style={{ width: "80%" }}
                        >
                          <div className="field m-auto">Comentario</div>
                          <div>
                            <textarea
                              onChange={(e) =>
                                this.setState({
                                  comentarioAsignar: e.target.value,
                                })
                              }
                              placeholder="Escribe tu comentario"
                              className="w-300px m-auto p-2"
                              style={{
                                borderColor: "hsl(0, 0%, 80%)",
                                borderRadius: "4px",
                              }}
                            ></textarea>
                          </div>
                        </div>
                        {this.state.AsignaraCommentError ? (
                          <div
                            className="d-flex mx-auto"
                            style={{ width: "80%" }}
                          >
                            <div className="w-75px field m-auto "></div>
                            <div className="w-300px m-auto">
                              {" "}
                              <span style={{ color: "red" }}>
                                {this.state.AsignaraCommentError}
                              </span>{" "}
                            </div>{" "}
                          </div>
                        ) : null}
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <button
                        className="btn btn-white"
                        onClick={(e) => {
                          e.preventDefault();
                          this.toggleModal("modalAddTask");
                        }}
                      >
                        Cerrar
                      </button>
                      <button type="submit" className="btn btn-success">
                        Asignar
                      </button>
                    </ModalFooter>
                  </form>
                </Modal>
                <div className="app-sidebar-bg app-sidebar-end"></div>
                <div className="app-sidebar-mobile-backdrop app-sidebar-end">
                  <Link
                    to="/"
                    onClick={toggleAppSidebarEndMobile}
                    className="stretched-link"
                  ></Link>
                </div>
                {this.state.sweetAlertError && (
                  <SweetAlert
                    danger
                    showCancel
                    confirmBtnText="Sí, borrar!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    cancelBtnText="Cancelar"
                    title="Estás seguro de borrar"
                    onConfirm={() => {
                      removeFilesOpen(fileId.id);
                      deleteFilesId(fileId.id);
                      this.handleSubmitDelete();
                    }}
                    onCancel={() => this.toggleSweetAlert("error")}
                  >
                    <span
                      style={{
                        fontStyle: "italic",
                        color: "red",
                        fontWeight: "600",
                      }}
                    >
                      el expediente #{fileId.number} ?
                    </span>
                  </SweetAlert>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </AppSettings.Consumer>
    );
  }
}
const mapStateToProps = (state) => ({
  filesLoaded: state.counterSlice.filesLoaded,
  fileId: state.counterSlice.fileId,
  users: state.counterSlice.users,
});
const mapDispatchToProps = {
  deleteFilesId,
  removeFilesOpen,

  getUsers,
  getFileRoadmap,
};

// bind our actions to this.props

export default connect(mapStateToProps, mapDispatchToProps)(SidebarRight);
