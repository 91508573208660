import React from "react";
import { Link } from "react-router-dom";
import { AppSettings } from "./../../config/app-settings.js";

class ExtraError extends React.Component {
  static contextType = AppSettings;

  componentDidMount() {
    this.context.handleSetAppSidebarNone(true);
    this.context.handleSetAppHeaderNone(true);
    this.context.handleSetAppContentClass("p-0");
  }

  componentWillUnmount() {
    this.context.handleSetAppSidebarNone(false);
    this.context.handleSetAppHeaderNone(false);
    this.context.handleSetAppContentClass("");
  }

  render() {
    return (
      <div className="error">
        <div className="error-code">404</div>
        <div className="error-content">
          <div className="error-message">We couldn't find it...</div>
          <div className="error-desc mb-4">
            The page you're looking for doesn't exist. <br />
            Perhaps, there pages will help find what you're looking for.
          </div>
          <div>
            <Link to="/dashboard" className="btn btn-success px-3">
              Go Home
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default ExtraError;
