import axios from "axios";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { AxiosURL } from "../../utils/index.js";
import { AppSettings } from "./../../config/app-settings.js";
import { connect } from "react-redux";
import { upPermissions } from "../../actions/index.js";
import logoBlack from "../../assets/logo_black.png";
class LoginV3 extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      errorPassword: "",
    };
  }

  componentDidMount() {
    this.context.handleSetAppSidebarNone(true);
    this.context.handleSetAppHeaderNone(true);
    this.context.handleSetAppContentClass("p-0");
  }

  componentWillUnmount() {
    this.context.handleSetAppSidebarNone(false);
    this.context.handleSetAppHeaderNone(false);
    this.context.handleSetAppContentClass("");
  }

  handleSubmit(event) {
    const { upPermissions } = this.props;
    event.preventDefault();
    const email = event.target[0].value.trim();
    const password = event.target[1].value.trim();
    const servicio = "users/auth/login/";

    var formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    axios({
      method: "post",
      contentType: "application/json",
      url: `${AxiosURL}${servicio}`,
      params: {
        t: new Date().getTime(),
      },
      data: formData,
    })
      .then((res) => {
        let respuesta = res.data;
        if (respuesta) {
          console.log("respuesta", respuesta);
          localStorage.my_code = respuesta.access;
          localStorage.refresh = respuesta.refresh;
          localStorage.userId = respuesta.user.id;
          localStorage.profile_pic = respuesta.user.profile_pic;
          localStorage.first_name = respuesta.user.first_name;
          localStorage.full_name = respuesta.user.full_name;
          localStorage.email = respuesta.user.email;
          localStorage.permissions = JSON.stringify(respuesta.permissions);
          if (
            respuesta.user &&
            respuesta.user.person &&
            respuesta.user.person.birthdate
          ) {
            localStorage.birthDaySiep = respuesta.user.person.birthdate;
          }
          // upPermissions(respuesta?.permissions)
          this.props.history.push("/dashboard");
        }
      })
      .catch((e) => {
        this.setState((state) => ({
          errorPassword: e.response.data,
        }));
        setTimeout(() => {
          this.setState((state) => ({
            errorPassword: "",
          }));
        }, 3000);
      });
  }

  render() {
    const today = new Date();
    const YearNow = today.getFullYear();

    return (
      <div className="login login-with-news-feed">
        <div className="news-feed">
          <div className="news-image"></div>
          <div className="news-caption">
            <h4 className="caption-title">
              <b>Restrepo Fajardo Abogados</b>
            </h4>
            <p>
              Ayudar a las personas a planificar su jubilación es ayudarles a
              alcanzar sus metas y sueños de vida.
            </p>
          </div>
        </div>
        <div className="login-container">
          <div className="login-header mb-30px">
            <div className="brand">
              <div
                className="d-flex align-items-center"
                style={{ padding: "10%" }}
              >
                <img style={{ maxWidth: "100%" }} src={logoBlack}></img>
              </div>
            </div>
          </div>
          <div className="login-content">
            <form onSubmit={this.handleSubmit} className="fs-13px">
              <div className="form-floating mb-15px">
                <input
                  type="email"
                  required
                  name="emailAddress"
                  className="form-control h-45px fs-13px"
                  placeholder="Email Address"
                  id="emailAddress"
                />
                <label
                  htmlFor="emailAddress"
                  className="d-flex align-items-center fs-13px text-gray-600"
                >
                  Correo electrónico
                </label>
              </div>
              <div className="form-floating mb-15px">
                <input
                  type="password"
                  name="password"
                  required
                  className="form-control h-45px fs-13px"
                  placeholder="Password"
                  id="password"
                />
                <label
                  htmlFor="password"
                  className="d-flex align-items-center fs-13px text-gray-600"
                >
                  Contraseña
                </label>
              </div>
              <div className="mb-15px">
                {" "}
                <span
                  style={{ textAlign: "center" }}
                  className="text-red mb-15px"
                >
                  {this.state.errorPassword}
                </span>
              </div>

              <div className="mb-15px">
                <button
                  style={{
                    backgroundColor: "#016BBD",
                    borderColor: "#016BBD",
                    color: "#ffff",
                  }}
                  type="submit"
                  className="btn  d-block h-45px w-100 btn-lg fs-14px"
                >
                  Ingresar
                </button>
              </div>
              <div className="mb-30px pb-30px text-inverse">
                No recuerdas tu contraseña? Click{" "}
                <Link to="/user/registro" className="text-primary">
                  aquí
                </Link>{" "}
                para recuperarla.
              </div>
              <hr className="bg-gray-600 opacity-2" />
              <div className="text-gray-600 text-center text-gray-500-darker mb-0">
                &copy; Restrepo Fajardo & Asociados {YearNow}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  permissions: state.counterSlice.permissions,
});
const mapDispatchToProps = {
  upPermissions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginV3));
