import { useTable, useSortBy, usePagination } from "react-table";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
// import makeData from "./make-data";
import { AppSettings } from "./../../config/app-settings";
import SweetAlert from "react-bootstrap-sweetalert";
import React, { useState, useEffect, useRef,useContext } from "react";
import Moment from "moment";
// import { AppSettings } from "../../config/app-settings";
// import PageWithSearchSidebar from "../option/page-with-search-sidebar.js";
import { useDownloadExcel } from "react-export-table-to-excel";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Tooltip from "rc-tooltip";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";
import autoTable from "jspdf-autotable";
import {
  deletePersonId,
  getPersons,
  getPersonsSearch,
  resetErrors,
  resetPersons,
} from "../../actions/index.js";
import {
  CopyRight,
  instance,
  successPermissions,
  useCompareId,
} from "../../utils/index.js";
import { Store, ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import imagenProfile from "../../assets/img/user/user-13.jpg";
import axios from "axios";
const Personas = () => {
  const tableRef = useRef(null);
  const context = useContext(AppSettings);
  const [initialPageIndex, setinitialPageIndex] = useState(0);
  const [modalDialog, setmodalDialog] = useState(false);
  const [timer, setTimer] = useState(null);
  const [NameDelete, setNameDelete] = useState(null);
  const [modalDeletePerson, setmodalDeletePerson] = useState(false);
  const [modalDialogPersonCreate, setmodalDialogPersonCreate] = useState(false);
  const [IdSelected, setIdSelected] = useState(null);
  const [modalDialogAlert, setmodalDialogAlert] = useState(false);
  const [modalDialogAlertError, setmodalDialogAlertError] = useState(null);
  const [Cities, setCities] = useState([]);
  const [inputs, setInputs] = useState({
    is_client: true,
    is_freelance: false,
    is_staff: false,
    document_type: 10,
    city: "",
    department: 1,
  });
  const fakeApi = (e) => {
    dispatch(getPersonsSearch(e, "&is_customer=true"));
    setinitialPageIndex(0);
  };
  const inputChanged = (e) => {
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      fakeApi(e.target.value);
    }, 200);

    setTimer(newTimer);
  };
  var handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setInputs((val) => ({ ...val, [name]: value }));
  };
  function pdfdownload(current) {
    const doc = new jsPDF();
    autoTable(doc, { html: current });
    doc.save("table.pdf");
  }
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Clientes table",
    sheet: "Clientes",
  });
  // const handlePrint = useReactToPrint({
  //   content: () => tableRef.current,
  // });

  const handlePrint = useReactToPrint({
    pageStyle: "@page {size:auto;margin-bottom: 20mm }",
    content: () => {
      const tableStat = tableRef.current.cloneNode(true);
      const PrintElem = document.createElement("div");
      PrintElem.setAttribute("class", "containerPrint");
      const header =
        `<div class="page-header"><h1>Personas</div></h1>` +
        `<img src="https://siep.restrepofajardo.com/img/logo.png" alt="" class="watermark"/>` +
        `<div class="page-footer"><span>${
          localStorage.full_name
        }</span><span>${Moment().format(
          "D [de] MMMM [del] YYYY, h:mm a"
        )}</span></div>`;
      PrintElem.innerHTML = header;
      PrintElem.appendChild(tableStat);
      return PrintElem;
    },
  });
  const state = useSelector((state) => state);

  const dispatch = useDispatch();
  const { persons, pagina, errorDeletePerson } = state.counterSlice;


useEffect(() => {
  if(errorDeletePerson && errorDeletePerson.length>0){
  
    setmodalDialogAlert(true);
    setmodalDialogAlertError(errorDeletePerson);
    dispatch(resetErrors())
  }
}, [errorDeletePerson]);
  useEffect(() => {
    dispatch(resetPersons());
    dispatch(getPersons("?is_customer=true"));
    axios
      .get("/city.json")
      .then((res) => setCities(res.data))
      .catch((err) => console.log(err.response));
    if (
      context &&
      localStorage.email &&
      localStorage.email.includes("siep.com.co")
    ) {
      context.handleSetActualizarEmail();
    }
  }, []);

  function handleSubmit() {
    if (IdSelected) {
      dispatch(deletePersonId(IdSelected));
      setmodalDeletePerson(false);
    }
  }
  function CreatePerson(e) {
    e.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.append("first_name", inputs.first_name);
    bodyFormData.append("last_name", inputs.last_name);
    bodyFormData.append("document_type", Number(inputs.document_type));
    bodyFormData.append("document_id", inputs.document_id);
    bodyFormData.append("address", inputs.address);
    bodyFormData.append("phone_personal", inputs.phone_personal);
    bodyFormData.append("phone_office", inputs.phone_office);
    bodyFormData.append("phone_home", inputs.phone_home);
    bodyFormData.append("email", inputs.email);
    bodyFormData.append("is_customer", inputs.is_client);
    bodyFormData.append("is_freelance", inputs.is_freelance);
    bodyFormData.append("is_staff", inputs.is_staff);
    bodyFormData.append("city", inputs.city);
    instance
      .post(`people/`, bodyFormData)
      .then((res) => {
        setmodalDialogPersonCreate(!modalDialogPersonCreate);
        return res.data;
      })
      .catch((e) => {
        console.log("error", e);

        setmodalDialogAlert(true);
        setmodalDialogAlertError(e?.response?.data?.detail);
      });
  }
  const columns = React.useMemo(
    () =>
      successPermissions("full_access_people") == "d-none"
        ? [
            {
              Header: "Id",
              accessor: "id",
              sortable: true,
              desc: false,
            },
            {
              Header: "Persona",
              accessor: "first_name",
              sortable: true,
            },

            {
              Header: "Documento",
              accessor: "document_id",
              sortable: true,
            },
            {
              Header: "Dirección",
              accessor: "address",
              sortable: true,
            },
            {
              Header: "Email",
              accessor: "email",
              sortable: true,
            },

            {
              Header: "Modificado",
              accessor: "updated_at",
              sortable: true,
            },
          ]
        : [
            {
              Header: "Id",
              accessor: "id",
              sortable: true,
              desc: false,
            },
            {
              Header: "Persona",
              accessor: "first_name",
              sortable: true,
            },

            {
              Header: "Documento",
              accessor: "document_id",
              sortable: true,
            },
            {
              Header: "Dirección",
              accessor: "address",
              sortable: true,
            },
            {
              Header: "Email",
              accessor: "email",
              sortable: true,
            },

            {
              Header: "Modificado",
              accessor: "updated_at",
              sortable: true,
            },
            {
              Header: "Acciones",
              accessor: "Acciones",
              sortable: false,
            },
          ],
    []
  );

  function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <img src={imagenProfile} width="52" alt="" className="rounded" />
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }
  console.log(persons);
  const data = persons && persons;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: initialPageIndex },
    },
    useSortBy,
    usePagination
  );
  useEffect(() => {
    const counter = 100;
    let other = (pageIndex + 1) * pageSize;
    if (persons.length / pageSize - 1 <= pageIndex + 1) {
      if (
        (other % counter >= counter - pageSize && pagina[1]) ||
        (other % counter == 0 && pageSize == 50)
      ) {
        // if ((pageIndex + 1) % pageSize == 0 && pagina[1]) {
        dispatch(
          getPersons(
            pagina[1].replace(
              "https://beta.siep.restrepofajardo.com/api/v1/people/",
              ""
            )
          )
        );
      }
    }
    setinitialPageIndex(pageIndex);
  }, [pageIndex]);

  return (
    <div>
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">
          <Link to="/dashboard">Panel</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to="/Personas">Clientes</Link>
        </li>
        {/* <li className="breadcrumb-item active">Data Tables</li> */}
      </ol>
      <h1 className="page-header">Clientes </h1>
      <Panel>
        <PanelHeader>Clientes</PanelHeader>
        <PanelBody>
          <div className="invoice-company">
            <span className="float-end hidden-print">
              {successPermissions("full_access_people") == "d-none" ? null : (
                <button
                  onClick={() => setmodalDialogPersonCreate(true)}
                  className="btn btn-sm btn-primary mb-10px me-1"
                >
                  Crear Cliente
                </button>
              )}
              {successPermissions("full_access_people_buttons_options") ==
              "d-none" ? null : (
                <>
                  {" "}
                  <button
                    onClick={() => pdfdownload(tableRef.current)}
                    className="btn btn-sm btn-white mb-10px me-1"
                  >
                    <i className="fa fa-file-pdf t-plus-1 text-blue fa-fw fa-lg"></i>{" "}
                  </button>
                  <button
                    onClick={onDownload}
                    className="btn btn-sm btn-white mb-10px me-1"
                  >
                    <i className="fa fa-file-excel t-plus-1 text-green fa-fw fa-lg"></i>
                  </button>
                  <button
                    onClick={handlePrint}
                    className="btn btn-sm btn-white mb-10px me-1"
                  >
                    <i className="fa fa-print t-plus-1 fa-fw fa-lg"></i>
                  </button>
                </>
              )}
            </span>
            <div className="d-block d-md-none">
              <br />
              <br />
            </div>

            <div className="d-block d-md-flex align-items-center">
              <label className="d-none d-lg-block form-label pe-2 mb-0">
                Personas por página:
              </label>
              <div className="d-none d-lg-block">
                <select
                  className="form-select"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Mostrar {pageSize}
                    </option>
                  ))}
                </select>
              </div>
              <form action="" method="POST" name="search_form">
                <div className="form-group position-relative mx-3 width_seach_type w-sm-50 pe-4 d-flex">
                  {" "}
                  <button
                    className="btn btn-search position-absolute border-0"
                    disabled={true}
                  >
                    <i className="fa fa-search me-20px icon-search-action"></i>
                  </button>{" "}
                  <input
                    type="text"
                    id="inputTextSearch"
                    className="form-control px-5 "
                    onChange={inputChanged}
                    // onChange={(e) => {
                    //   dispatch(
                    //     getPersonsSearch(e.target.value, "&is_customer=true")
                    //   );
                    //   setinitialPageIndex(0);
                    // }}
                    placeholder="Buscar persona ..."
                  />
                </div>
              </form>
            </div>
          </div>
        </PanelBody>
        <div className="table-responsive">
          <ReactNotifications />
          {persons && persons.length === 0 ? (
            <div className="fa-3x d-flex justify-content-center mb-3">
              <i className="fas fa-cog fa-spin"></i>{" "}
            </div>
          ) : persons && typeof persons[0] === "string" ? (
            <div className="fw-bold text-center mb-3">{persons[0]}</div>
          ) : (
            <>
              <table
                ref={tableRef}
                // style={{ background: "var(--app-sidebar-bg)" }}
                className="table table-panel table-bordered mb-0"
                {...getTableProps()}
              >
                <thead>
                  {headerGroups.map((headerGroup, i) => (
                    <tr
                      key={i}
                      {...headerGroup.getHeaderGroupProps()}
                      className="nav-Tables-head"
                    >
                      {headerGroup.headers.map((column) => (
                        <th
                          className="text-center"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div
                            className="d-flex align-items-center text-center"
                            style={{
                              minWidth: "100px",
                              justifyContent: "center",
                            }}
                          >
                            <span>{column.render("Header")}</span>
                            <span className="">
                              {column.sortable ? (
                                column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <i className="fa fa-sort-down fa-fw fs-14px text-blue"></i>
                                  ) : (
                                    <i className="fa fa-sort-up fa-fw fs-14px text-blue"></i>
                                  )
                                ) : (
                                  <i className="fa fa-sort fa-fw fs-14px opacity-3"></i>
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="colorRow-Tables">
                        {row.cells.map((cell, index) => {
                          return (
                            <td key={row.id} {...cell.getCellProps()}>
                              {cell.column.Header == "Id" ? (
                                <>
                                  {cell.render("Cell")}{" "}
                                  <Tooltip
                                    prefixCls="rc-slider-tooltip"
                                    overlay={
                                      <span
                                        type="text"
                                        id="pelanga"
                                        style={{
                                          color: "white",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <strong>Tel. casa:</strong>
                                        <br />
                                        <span
                                          id={"phoneHome" + row.id}
                                          title={row.original["phone_home"]}
                                        >
                                          {row.original["phone_home"]}{" "}
                                        </span>
                                        <span
                                          onClick={() =>
                                            CopyRight("phoneHome" + row.id) ||
                                            addNotification(
                                              "default",
                                              "",
                                              "Copiado",
                                              "bottom-center"
                                            )
                                          }
                                          style={{
                                            textDecorationLine: "underline",
                                            color: "#348fe2",
                                          }}
                                        >
                                          Copy
                                        </span>
                                        <br />
                                        <strong>Tel. oficina:</strong> <br />
                                        <span
                                          id={"phoneOffice" + row.id}
                                          title={row.original["phone_office"]}
                                        >
                                          {row.original["phone_office"]}{" "}
                                        </span>
                                        <span
                                          onClick={() =>
                                            CopyRight("phoneOffice" + row.id) ||
                                            addNotification(
                                              "default",
                                              "",
                                              "Copiado",
                                              "bottom-center"
                                            )
                                          }
                                          style={{
                                            textDecorationLine: "underline",
                                            color: "#348fe2",
                                          }}
                                        >
                                          Copy
                                        </span>
                                        <br />
                                        <strong>Tel. oficina:</strong> <br />
                                        <span
                                          id={"phonePersonal" + row.id}
                                          title={row.original["phone_personal"]}
                                        >
                                          {row.original["phone_personal"]}{" "}
                                        </span>
                                        <span
                                          onClick={() =>
                                            CopyRight(
                                              "phonePersonal" + row.id
                                            ) ||
                                            addNotification(
                                              "default",
                                              "",
                                              "Copiado",
                                              "bottom-center"
                                            )
                                          }
                                          style={{
                                            textDecorationLine: "underline",
                                            color: "#348fe2",
                                          }}
                                        >
                                          Copy
                                        </span>
                                      </span>
                                    }
                                    placement="bottom"
                                    key={index}
                                  >
                                    <i
                                      style={{
                                        color: "var(--app-theme)",
                                        cursor: "pointer",
                                      }}
                                      className="far fa-lg fa-fw me-10px fa-eye"
                                    ></i>
                                  </Tooltip>
                                </>
                              ) : cell.column.Header == "Acciones" ? (
                                <div
                                  nowrap="true "
                                  className="vertical-middle"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Link
                                    title="Editar"
                                    to={`/Persona/${row.original["id"]}`}
                                    className={`${successPermissions(
                                      "full_access_people"
                                    )} btn btn-sm btn-green  me-1 p-auto`}
                                  >
                                    <i className="fab fa-lg fa-fw fs-14px fa-sistrix text-white"></i>
                                  </Link>

                                  <button
                                    title="Borrar"
                                    onClick={() =>
                                      setmodalDeletePerson(true) ||
                                      setIdSelected(row.original["id"]) ||
                                      setNameDelete(row.original["first_name"])
                                    }
                                    className={`${successPermissions(
                                      "full_access_people_borrar"
                                    )} btn btn-sm btn-danger p-auto`}
                                  >
                                    <i className="fa fa-trash fs-14px text-white"></i>
                                  </button>
                                </div>
                              ) : cell.column.Header == "Modificado" ? (
                                Moment(row.original["updated_at"]).format(
                                  "L[-]LT"
                                )
                              ) : cell.column.Header == "Persona" ? (
                                row.original["first_name"] +
                                " " +
                                row.original["last_name"]
                              ) : (
                                cell.render("Cell")
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <PanelBody>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="me-1 d-none d-sm-block">Ir a página:</div>
                  <div className="w-50px mx-2 me-auto d-none d-sm-block">
                    <input
                      className="form-control px-2"
                      type="number"
                      value={pageIndex + 1}
                      onChange={(e) => {
                        const page = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        gotoPage(page);
                      }}
                    />{" "}
                  </div>

                  <ul className="pagination mb-0">
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                      >
                        <i className="fa fa-angle-double-left"></i>
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                      >
                        <i className="fa fa-angle-left"></i>
                      </button>
                    </li>
                    <li className="page-item d-flex align-items-center px-2">
                      <div>
                        Pág.{" "}
                        <strong>
                          {pageIndex + 1} de{" "}
                          {pagina[2] / pageSize -
                            Math.round(pagina[2] / pageSize) >
                          0
                            ? Math.round(pagina[2] / pageSize) + 1
                            : Math.round(pagina[2] / pageSize)}
                        </strong>
                      </div>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                      >
                        <i className="fa fa-angle-right"></i>
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                      >
                        <i className="fa fa-angle-double-right"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </PanelBody>
              {pagina[2] ? (
                <div className="hljs-wrapper">
                  <div className="p-3">
                    {" "}
                    <span style={{ color: "#4a9be5", fontSize: "12px" }}>
                      {pagina[2]}
                    </span>{" "}
                    resultados
                  </div>
                </div>
              ) : null}
            </>
          )}
        </div>
        {modalDeletePerson ? (
          <SweetAlert
            danger
            showCancel
            confirmBtnText="Sí, borrar!"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            cancelBtnText="Cancelar"
            title="Estás seguro de borrar"
            onConfirm={() => handleSubmit()}
            onCancel={() => setmodalDeletePerson(!modalDeletePerson)}
          >
            <span
              style={{ fontStyle: "italic", color: "red", fontWeight: "600" }}
            >
              la persona {NameDelete} ?
            </span>
          </SweetAlert>
        ) : null}
        <Modal
          isOpen={modalDialog}
          // toggle={() => this.toggleModal("modalDialog")}
        >
          <ModalHeader
            // toggle={() => this.toggleModal("modalDialog")}
            close={
              <button
                className="btn-close"
                onClick={() => setmodalDialog(!modalDialog)}
              ></button>
            }
          >
            Modal Dialog
          </ModalHeader>
          <ModalBody>
            <p>Modal body content here...</p>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-white"
              onClick={() => setmodalDialog(!modalDialog)}
            >
              Close
            </button>
            <button className="btn btn-success">Action</button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={modalDialogPersonCreate}>
          <ModalHeader
            close={
              <button
                className="btn-close"
                onClick={() =>
                  setmodalDialogPersonCreate(!modalDialogPersonCreate)
                }
              ></button>
            }
          >
            Crear Cliente
          </ModalHeader>
          <form onSubmit={CreatePerson}>
            <ModalBody className="m-auto w-100" style={{ overflowY: "auto" }}>
              <tr className="my-1 d-flex" style={{ justifyContent: "center" }}>
                <td className="d-flex column-create-inputs flex-md-row">
                  <div>
                    <td className="field">Nombre</td>{" "}
                    <input
                      required
                      name="first_name"
                      style={{
                        border: "1px solid hsl(0, 0%, 80%)",
                        borderRadius: "4px",
                        padding: "10px",
                      }}
                      onChange={handleChange}
                      className="widthInput w-md-200px py-2  m-1"
                      type="text"
                    ></input>
                  </div>
                  <div>
                    <td className="field">Apellido</td>
                    <input
                      required
                      style={{
                        border: "1px solid hsl(0, 0%, 80%)",
                        borderRadius: "4px",
                        padding: "10px",
                      }}
                      name="last_name"
                      onChange={handleChange}
                      className="widthInput w-md-200px py-2  m-1"
                      type="text"
                    ></input>
                  </div>
                </td>
              </tr>
              <tr className="my-1 d-flex" style={{ justifyContent: "center" }}>
                <td className="d-flex column-create-inputs flex-md-row">
                  <div>
                    <td className="field">Dirección</td>{" "}
                    <input
                      required
                      name="address"
                      onChange={handleChange}
                      style={{
                        border: "1px solid hsl(0, 0%, 80%)",
                        borderRadius: "4px",
                        padding: "10px",
                      }}
                      className="widthInput w-md-200px py-2  m-1"
                      type="text"
                    ></input>
                  </div>
                  <div>
                    <td className="field">Email</td>
                    <input
                      required
                      name="email"
                      onChange={handleChange}
                      style={{
                        border: "1px solid hsl(0, 0%, 80%)",
                        borderRadius: "4px",
                        padding: "10px",
                      }}
                      className="widthInput w-md-200px py-2  m-1"
                      type="text"
                    ></input>
                  </div>
                </td>
              </tr>
              <tr className="my-1 d-flex" style={{ justifyContent: "center" }}>
                <td className="d-flex column-create-inputs flex-md-row">
                  <div>
                    <td className="field">Tipo Documento</td>{" "}
                    <select
                      required
                      name="document_type"
                      style={{
                        border: "1px solid hsl(0, 0%, 80%)",
                        borderRadius: "4px",
                        padding: "10px",
                      }}
                      onChange={handleChange}
                      className="widthInput w-md-200px py-2  m-1"
                      type="text"
                    >
                      <option value="10">CC</option>
                      <option value="20">NE</option>
                      <option value="30">PST</option>
                      <option value="40">NIT</option>
                    </select>
                  </div>
                  <div>
                    <td className="field">Documento</td>
                    <input
                      required
                      name="document_id"
                      style={{
                        border: "1px solid hsl(0, 0%, 80%)",
                        borderRadius: "4px",
                        padding: "10px",
                      }}
                      onChange={handleChange}
                      className="widthInput w-md-200px py-2  m-1"
                      type="text"
                    ></input>
                  </div>
                </td>
              </tr>
              <tr className="my-1 d-flex" style={{ justifyContent: "center" }}>
                <td className="d-flex column-create-inputs flex-md-row">
                  <div>
                    <td className="field">Teléfono casa</td>{" "}
                    <input
                      name="phone_home"
                      style={{
                        border: "1px solid hsl(0, 0%, 80%)",
                        borderRadius: "4px",
                        padding: "10px",
                      }}
                      onChange={handleChange}
                      className="widthInput w-md-200px py-2  m-1"
                      type="text"
                    ></input>
                  </div>
                  <div>
                    <td className="field">Número celular</td>
                    <input
                      required
                      name="phone_personal"
                      style={{
                        border: "1px solid hsl(0, 0%, 80%)",
                        borderRadius: "4px",
                        padding: "10px",
                      }}
                      onChange={handleChange}
                      className="widthInput w-md-200px py-2  m-1"
                      type="text"
                    ></input>
                  </div>
                </td>
              </tr>
              <tr className="my-1 d-flex" style={{ justifyContent: "center" }}>
                <td className="d-flex column-create-inputs flex-md-row">
                  <div>
                    <td className="field">Teléfono oficina</td>{" "}
                    <input
                      name="phone_office"
                      style={{
                        border: "1px solid hsl(0, 0%, 80%)",
                        borderRadius: "4px",
                        padding: "10px",
                      }}
                      onChange={handleChange}
                      className="widthInput w-md-200px py-2  m-1"
                      type="text"
                    ></input>
                  </div>
                  <div>
                    <td className="field w-200px m-1">
                      <div>
                        <td className="field">Departamento</td>{" "}
                        <Select
                          className="widthInput w-md-200px m-1"
                          options={
                            Cities.length > 0 &&
                            Cities.map((personCus) => ({
                              value: personCus.id,
                              label: personCus.departamento,
                            }))
                          }
                          placeholder="Selecciona"
                          defaultValue={false}
                          name="city"
                          onChange={(e) =>
                            setInputs((val) => ({
                              ...val,
                              ["department"]: e.value,
                            }))
                          }
                        />
                      </div>
                    </td>
                  </div>
                </td>
              </tr>
              <tr className="my-1 d-flex" style={{ justifyContent: "center" }}>
                <td className="d-flex column-create-inputs flex-md-row">
                  <div>
                    <td className="field">Ciudad</td>{" "}
                    <Select
                      className="widthInput w-md-200px   m-1"
                      options={
                        Cities.length > 0 &&
                        Cities.filter((e) => e.id == inputs["department"])[0][
                          "ciudades"
                        ].map((personCus) => ({
                          value: personCus,
                          label: personCus,
                        }))
                      }
                      placeholder="Selecciona"
                      defaultValue={false}
                      name="city"
                      onChange={(e) =>
                        setInputs((val) => ({ ...val, ["city"]: e.value }))
                      }
                    />
                  </div>
                  <div className="widthInput w-md-200px"></div>
                </td>
              </tr>
              <br />
              <tr
                className="my-1 d-flex"
                style={{ justifyContent: "space-around" }}
              >
                <div className="d-flex">
                  <input
                    type="checkbox"
                    id="Cliente"
                    checked={inputs.is_client}
                    name="Cliente"
                    // onChange={(e) =>
                    //   setInputs((val) => ({
                    //     ...val,
                    //     ["is_client"]: e.target.checked,
                    //   }))
                    // }
                  ></input>
                  <td className="field mx-2">Cliente</td>
                </div>
                <div className="d-flex">
                  <input
                    type="checkbox"
                    id="Freelance"
                    name="Freelance"
                    checked={inputs.is_freelance}
                    // onChange={(e) =>
                    //   setInputs((val) => ({
                    //     ...val,
                    //     ["is_freelance"]: e.target.checked,
                    //   }))
                    // }
                  ></input>
                  <td className="field mx-2">Freelance</td>
                </div>
                <div className="d-flex">
                  <input
                    type="checkbox"
                    id="Staff"
                    name="Staff"
                    checked={inputs.is_staff}
                    // onChange={(e) =>
                    //   setInputs((val) => ({
                    //     ...val,
                    //     ["is_staff"]: e.target.checked,
                    //   }))
                    // }
                  ></input>
                  <td className="field mx-2">De la Firma</td>
                </div>
              </tr>

              <br />
              <br />
            </ModalBody>
            <ModalFooter>
              <button
                className="btn btn-white"
                onClick={() =>
                  setmodalDialogPersonCreate(!modalDialogPersonCreate)
                }
              >
                Close
              </button>
              <button type="submit" className="btn btn-warning">
                Crear
              </button>
            </ModalFooter>
          </form>
        </Modal>
        <Modal isOpen={modalDialogAlert}>
          <ModalHeader
            close={
              <button
                className="btn-close"
                onClick={() => setmodalDialogAlert(!modalDialogAlert)}
              ></button>
            }
          >
            Algo ha ocurrido
          </ModalHeader>
          <ModalBody>
            <div className="alert alert-danger mb-0">
              <h5>
                <i className="fa fa-info-circle"></i> Algo salió mal
              </h5>
              <p>
                {modalDialogAlertError
                  ? modalDialogAlertError
                  : "Lo sentimos algo a fallado, vuelve a intentar en un momento."}
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-white"
              onClick={() => setmodalDialogAlert(!modalDialogAlert)}
            >
              Cerrar
            </button>
          </ModalFooter>
        </Modal>
      </Panel>
    </div>
  );
};
// people?is_customer=true&search=diego
export default Personas;
