import React from "react";
import { instance } from "../../utils/index.js";
import { AppSettings } from "./../../config/app-settings.js";

class HelpPanel extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);

    this.state = {
      expand: false,
      theme: "teal",
      darkMode: false,
      comentarioHelp:"",
      errorHelp:""
    };

  }

  toggleExpand = (e) => {
    e.preventDefault();
    this.setState((state) => ({
      expand: !this.state.expand,
    }));
  };
  updateHelp =(e,comment)=>{
    e.preventDefault();
    if(comment.length>0){

var bodyFormData = new FormData();
bodyFormData.append("url", window.location.href);
bodyFormData.append("message",comment);
instance
.post(`feedback/`, bodyFormData)
.then((res) => {
 if(res.status == 200 || res.status == 201){
  this.setState((state) => ({
    errorHelp: "Enviado con éxito",
   
  }));

  setTimeout(() => {
    this.setState({
      errorHelp: "",
      comentarioHelp:""
    });;
  }, 3000);
 }
})
.catch((e) => console.log(e.response));

    }else{
      this.setState((state) => ({
        errorHelp: "Debes ingresar un comentario",
      }));

      setTimeout(() => {
        this.setState({
          errorHelp: "",
        });;
      }, 3000);

    }
  }

  render() {
    return (
      <AppSettings.Consumer>
        {({ HelpReportPanel }) => (
          <div
            className={
              "theme-panel " +
              (this.state.expand || HelpReportPanel ? "active" : "")
            }
          >
            <a
              href="#0"
              onClick={(e) => this.toggleExpand(e)}
              className="theme-collapse-btn-help" //rueda
            >
              {this.state.expand ? (
                <i className="fas fa-circle-xmark"></i>
              ) : (
                <i className="fas fa-circle-question"></i>
              )}
            </a>
            <div
              className="theme-panel-content"
              data-scrollbar="true"
              data-height="100%"
            >
              <h5>Reporta un incidente en el SIEP</h5>

              <div className="theme-list">
                <span>
                  Por favor cuentanos, si has tenido alguna dificultad, danos
                  todos los detalles para poder mejorar.
                </span>
              </div>

              <div className="theme-panel-divider"></div>

              <div className="row mt-10px">
                <textarea
                  value={this.state.comentarioHelp}
                  onChange={(e) =>
                    this.setState({
                      comentarioHelp: e.target.value,
                    })
                  }
                  placeholder="Escribe tu comentario"
                  className="w-200px w-md-300px m-auto p-2"
                  style={{
                    borderColor: "hsl(0, 0%, 80%)",
                    borderRadius: "4px",
                    height: "15rem",
                  }}
                ></textarea>
                <span
                  className={
                    this.state.errorHelp == "Enviado con éxito"
                      ? "text-green"
                      : "text-red"
                  }
                >
                  {this.state.errorHelp}
                </span>
              </div>

              <div className="theme-panel-divider"></div>

              <a
                onClick={(e) => this.updateHelp(e, this.state.comentarioHelp)}
                href="#0"
                className="btn btn-warning d-block w-100 rounded-pill"
                data-toggle="reset-local-storage"
              >
                <b>Enviar reporte</b>
              </a>
            </div>
          </div>
        )}
      </AppSettings.Consumer>
    );
  }
}

export default HelpPanel;