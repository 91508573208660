import { legacy_createStore as createStore, combineReducers, applyMiddleware,compose} from "redux";
import counterSlice from "../reducers/index";
import thunk from "redux-thunk";
const reducer = combineReducers({
  counterSlice,
});
const composeAlt = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composedEnhancers = composeAlt(applyMiddleware(thunk));
const store = createStore(reducer, composedEnhancers);

export default store;
