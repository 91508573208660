import React from "react";
import { Link } from "react-router-dom";
import DropdownNotification from "./dropdown/notification.jsx";
import DropdownLanguage from "./dropdown/language.jsx";
import DropdownProfile from "./dropdown/profile.jsx";
import SearchForm from "./search/form.jsx";
import DropdownMegaMenu from "./dropdown/mega.jsx";
import Moment from "moment";
import { AppSettings } from "./../../config/app-settings.js";
import logoWhite from "../../assets/logo_white.png";

class Header extends React.Component {
  static contextType = AppSettings;
  constructor(props) {
    super(props);
    this.toggleAppHeaderMegaMenuMobile =
      this.toggleAppHeaderMegaMenuMobile.bind(this);
    this.state = { appHeaderMegaMenuMobile: false };
  }

  toggleAppHeaderMegaMenuMobile() {
    this.setState({
      appHeaderMegaMenuMobile: !this.state.appHeaderMegaMenuMobile,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.birthDayPeople !== this.state.birthDayPeople) {
      if (
        localStorage.birthDaySiep &&
        localStorage?.birthDaySiep.slice(5) === Moment().format("MM-DD") &&
        window.location.pathname != "/user/login" &&
        window.location.pathname != "/"
      ) {
        this.context.handleSetAppHeaderInverse(false);
      }
      // Aquí puedes realizar acciones adicionales según el cambio en el estado count
    }
  }
  loadDataBirth() {
    // Simulación de carga de datos

    this.setState({ birthDayPeople: localStorage.birthDaySiep });
  }
  componentDidMount() {
    this.loadDataBirth();
  }
  render() {
    var confettiPlayers = [];

    function makeItConfetti() {
      var confetti = document.querySelectorAll(".confetti");

      // if (confetti && !confetti[0].animate) {
      //   return false;
      // }

      for (var i = 0, len = confetti.length; i < len; ++i) {
        var candycorn = confetti && confetti[i];
        candycorn.innerHTML =
          '<div class="rotate"><div class="askew"></div></div>';
        var scale = Math.random() * 0.7 + 0.3;
        var player = candycorn.animate(
          [
            {
              transform: `translate3d(${
                (i / len) * 100
              }vw,-5vh,0) scale(${scale}) rotate(0turn)`,
              opacity: scale,
            },
            {
              transform: `translate3d(${
                (i / len) * 100 + 10
              }vw,105vh,0) scale(${scale}) rotate(${
                Math.random() > 0.5 ? "" : "-"
              }2turn)`,
              opacity: 1,
            },
          ],
          {
            duration: Math.random() * 3000 + 5000,
            iterations: Infinity,
            delay: -(Math.random() * 7000),
          }
        );

        confettiPlayers.push(player);
      }
    }

    makeItConfetti();
    onChange({ currentTarget: { value: "bookmarks" } });

    function onChange(e) {
      document.body.setAttribute("data-type", "bookmarks");
      confettiPlayers.forEach(
        (player) =>
          (player.playbackRate = e.currentTarget.value === "bookmarks" ? 2 : 1)
      );
    }

    return (
      <AppSettings.Consumer>
        {({
          toggleAppSidebarMobile,
          toggleAppSidebarEnd,
          toggleAppSidebarEndMobile,
          toggleAppTopMenuMobile,
          appHeaderLanguageBar,
          appHeaderMegaMenu,
          appHeaderInverse,
          appSidebarTwo,
          appSidebarTwo_archivado,
          appSidebarTwo_count,
          appTopMenu,
          appSidebarNone,
        }) => (
          <div
            id="header"
            className={
              "app-header " +
              (appHeaderInverse
                ? "app-header-inverse"
                : "app-header-inverse-birthday")
            }
          >
            <div className="confetti-land">
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
              <div className="confetti"></div>
            </div>
            <div className="navbar-header">
              {appSidebarTwo && (
                <button
                  type="button"
                  className="navbar-mobile-toggler"
                  onClick={toggleAppSidebarEndMobile}
                >
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
              )}
              {appSidebarTwo_count && (
                <button
                  type="button"
                  className="navbar-mobile-toggler"
                  onClick={toggleAppSidebarEndMobile}
                >
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
              )}
              {appSidebarTwo_archivado && (
                <button
                  type="button"
                  className="navbar-mobile-toggler"
                  onClick={toggleAppSidebarEndMobile}
                >
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
              )}
              <Link to="/dashboard" className="navbar-brand">
                <img src={logoWhite}></img>
              </Link>

              {appHeaderMegaMenu && (
                <button
                  type="button"
                  className="navbar-mobile-toggler"
                  onClick={this.toggleAppHeaderMegaMenuMobile}
                >
                  <span className="fa-stack fa-lg text-inverse">
                    <i className="far fa-square fa-stack-2x"></i>
                    <i className="fa fa-cog fa-stack-1x"></i>
                  </span>
                </button>
              )}
              {appTopMenu && !appSidebarNone && (
                <button
                  type="button"
                  className="navbar-mobile-toggler"
                  onClick={toggleAppTopMenuMobile}
                >
                  <span className="fa-stack fa-lg text-inverse">
                    <i className="far fa-square fa-stack-2x"></i>
                    <i className="fa fa-cog fa-stack-1x"></i>
                  </span>
                </button>
              )}
              {appSidebarNone && appTopMenu && (
                <button
                  type="button"
                  className="navbar-mobile-toggler"
                  onClick={toggleAppTopMenuMobile}
                >
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
              )}
              {!appSidebarNone && (
                <button
                  type="button"
                  className="navbar-mobile-toggler"
                  onClick={toggleAppSidebarMobile}
                >
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
              )}
            </div>

            {appHeaderMegaMenu && (
              <DropdownMegaMenu collapse={this.state.appHeaderMegaMenuMobile} />
            )}
            <div
              className={
                "navbar-nav" +
                " " +
                (appHeaderInverse ? "" : "translateBirthday")
              }
            >
              <div
                style={{
                  margin: "0",
                  padding: " 0",
                  display: "flex",
                  flexDirection: "row",
                  flex: "1 1",

                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SearchForm />
              </div>
              <DropdownNotification />

              {appHeaderLanguageBar && <DropdownLanguage />}

              <DropdownProfile />

              {appSidebarTwo && (
                <div className="navbar-divider d-none d-md-block"></div>
              )}
              {appSidebarTwo_count && (
                <div className="navbar-divider d-none d-md-block"></div>
              )}
              {appSidebarTwo_archivado && (
                <div className="navbar-divider d-none d-md-block"></div>
              )}

              {appSidebarTwo && (
                <div className="navbar-item d-none d-md-block">
                  <Link
                    to="/"
                    onClick={toggleAppSidebarEnd}
                    className="navbar-link icon"
                  >
                    <i className="fa fa-th"></i>
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </AppSettings.Consumer>
    );
  }
}

export default Header;
