import React, { useState, useEffect, useRef } from "react";
import Moment from "moment";
import { contribution_type, instance, useCompareIngreso } from "../../utils/index";
import { useTable, useSortBy, usePagination } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { Panel, PanelHeader } from "./../../components/panel/panel.jsx";
import Select from "react-select";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getEntities, getFilesSearch } from "../../actions/index";
import { Link,useParams } from "react-router-dom";

const CrearConteo = () => {
  const tableRef = useRef(null);
  const { id } = useParams();
  
  const [modalDialog, setmodalDialog] = useState(false);
  const [CloseModal, setCloseModal] = useState(false);
  const [baseId, setBaseId] = useState(0);
  const formSelect = document.getElementById("formItem");
  const [tablavalores, settablavalores] = useState({Birth: Moment().format("YYYY-MM-DD")});
  const [tablavaloresId, settablavaloresId] = useState({});
  const [modalDialogAlert, setmodalDialogAlert] = useState(false);
  const [TextError_file, setTextError] = useState("");
  const [TextError_2005, setTextError_2005] = useState("");
  const [TextError_94, setTextError_94] = useState("");
  const [TextError_fund, setTextError_fund] = useState("");
  const [TextError_public_total, setTextError_public_total] = useState("");
  const [TextError_private_total, setTextError_private_total] = useState("");
  const [idCountView, setidCountView] = useState("");
  const [modalDialogSuccess, setmodalDialogSuccess] = useState(false);
  const [inputs, setInputs] = useState(localStorage.inputs_counts?JSON.parse(localStorage.inputs_counts):{
    birth: Moment().format("YYYY-MM-DD"),
    isLoading: false,
    comments:"",
    beneficiaries: [],
    conteoType: 10,
    pensionType: 10,
    contribution_type: 20,
    items: [],
    modificate: false,
  } );
  
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEntities());
  if(id&& id !="count"){

    setInputs((val) => ({
      ...val,
      ["Expediente"]: id.split("|")[0],
    }))
  }

  }, []);

  
  const { filesLoaded } = state.counterSlice;
  var handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    settablavalores((val) => ({ ...val, [name]: value }));
  };

  function resetvariables() {
    let nuevoorderarray = sortItems(inputs["items"]);
    let arrayChange = [];
    nuevoorderarray.forEach((item, i) => {
      item.simultaneous_days = null;
      item.partial_total = 0;
      item.days_total = 0;
      arrayChange.push(item);
    });

    setInputs((val) => ({
      ...val,
      ["items"]: arrayChange,
      ["totalprivado"]: 0,
      ["privadotradicionales"]: 0,
      ["privadoautoliss"]: 0,
      ["tiempo94"]: 0,
      ["tiempo2005"]: 0,
      ["fondo"]: 0,
      ["publiconocajas"]: 0,
      ["publicocajas"]: 0,
      ["totalpublico"]: 0,
      ["totalcotizaciones"]: 0,
      ["total_dias"]: 0,
    }));
  }

  function tradicionales(item) {
    var start = new Date(0);
    var end = new Date(1994, 11, 31);
    var sim = getSimult(item.start_date, item.end_date, start, end);
    return sim != 0 ? sim : 0;
  }
  function autoliss(item) {
    var start = new Date(1995, 0, 1);
    var end = new Date();
    var sim = getSimult(item.start_date, item.end_date, start, end);
    return sim != 0 ? sim : 0;
  }
  function checkItemDates(item) {
    return {
      start_date: new Date(item.start_date),
      end_date: new Date(item.end_date),
    };
  }
  function before94(item) {
    var it = checkItemDates(item);
    var start = new Date(0);
    start = it.start_date < start ? it.start_date : start;
    var end = new Date(1994, 3, 1);
    var sim = getSimult(item.start_date, item.end_date, start, end);
    if (item.simultaneous_days != null || item.simultaneous_days > 0) {
      sim = sim != 0 ? sim - item.simultaneous_days : 0;
    }
    return sim != 0 ? sim : 0;
  }
  function before2005(item) {
    var it = checkItemDates(item);
    var start = new Date(0);
    start = it.start_date < start ? it.start_date : start;
    var end = new Date(2005, 6, 22);
    var sim = getSimult(item.start_date, item.end_date, start, end);
    if (item.simultaneous_days != null || item.simultaneous_days > 0) {
      sim = sim != 0 ? sim - item.simultaneous_days : 0;
    }
    return sim != 0 ? sim : 0;
  }
  function calculate() {
    resetvariables();
    let nuevoorderarray = sortItems(inputs["items"]);
    let arrayChange = [];
    var totalprivado = 0;
    let privadotradicionales = 0;
    let privadoautoliss = 0;
    let tiempo94 = 0;
    let tiempo2005 = 0;
    let publicocajas = 0;
    let publiconocajas = 0;
    let fondo = 0;
    let days_total = 0;
    nuevoorderarray.forEach((item, i) => {
      item.partial_total = getDays(item.start_date, item.end_date);
      if (verSimul(item, i)) item = inputs["items"][i];
      item.days_total = item.partial_total - item.simultaneous_days;
      if (item.contribution_type == 10) {
        totalprivado += item.days_total;
        privadotradicionales += tradicionales(item);
        privadoautoliss += autoliss(item);
      }
      if (item.contribution_type == 40) publicocajas += item.days_total;
      if (item.contribution_type == 30) publiconocajas += item.days_total;
      if (item.contribution_type == 20) {
        fondo += item.days_total;
        totalprivado += item.days_total;
      }
      tiempo94 += before94(item);
      tiempo2005 += before2005(item);
      days_total += item.days_total;
      arrayChange.push(item);
    });
    let total_public = publicocajas + publiconocajas;
    setInputs((val) => ({
      ...val,
      ["items"]: arrayChange,
      ["totalprivado"]: totalprivado,
      ["privadotradicionales"]: privadotradicionales,
      ["privadoautoliss"]: privadoautoliss,
      ["tiempo94"]: tiempo94,
      ["tiempo2005"]: tiempo2005,
      ["fondo"]: fondo,
      ["publiconocajas"]: publiconocajas,
      ["publicocajas"]: publicocajas,
      ["totalpublico"]: total_public,
      ["totalcotizaciones"]: total_public + totalprivado,
      ["total_dias"]: days_total,
    }));
  }

  useEffect(() => {
    calculate();
    localStorage.inputs_counts= JSON.stringify(inputs) ;
  }, [inputs["items"].length, inputs["conteoType"]]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Empleador",
        accessor: "employer",
        sortable: true,
      },
      {
        Header: "Cotizado A",
        accessor: "contribution",
        sortable: true,
      },
      {
        Header: "Tipo Aporte",
        accessor: "contribution_type",
        sortable: true,
      },

      {
        Header: "Ingreso",
        accessor: "start_date",
        sortable: true,
      },
      {
        Header: "Egreso",
        accessor: "end_date",
        sortable: true,
      },
      {
        Header: "Total parcial",
        accessor: "partial_total",
        sortable: true,
      },
      {
        Header: "Dias Sim",
        accessor: "simultaneous_days",
        sortable: true,
      },

      {
        Header: "Total Días",
        accessor: "days_total",
        sortable: true,
      },

      {
        Header: "Acciones",
        accessor: "Acciones",
        sortable: false,
      },
    ],
    []
  );

  var data = inputs["items"];
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useSortBy,
    usePagination
  );
  const pensionTypes = [
    { name: "Vejez", value: 10 },
    { name: "Inválidez", value: 20 },
    { name: "Indemnización", value: 30 },
    { name: "Sobreviviente", value: 40 },
  ];
  const contributionsTypes = [
    { name: "Privado", value: 10 },
    { name: "Fondo", value: 20 },
    { name: "Público no cajas", value: 30 },
    { name: "Público cajas", value: 40 },
  ];
  const conteoTypes = [
    { name: "360 Días", value: 10 },
    { name: "365 Días", value: 20 },
  ];

  function verSimul(item, i) {
    for (var n = 0; n < inputs["items"].length; n++) {
      var bid = item.internalId.toString();
      var sim = [];
      if (!(bid in sim)) {
        sim[bid] = new Object();
      }
      if (n > i) continue;
      if (n != i) {
        var it = inputs["items"][n];
        if (sim[bid].hasOwnProperty(it.internalId)) continue;
        var days = getSimult(item.start_date, item.end_date, it.start_date, it.end_date);

        if (days != 0) {
          sim[bid][it.internalId] = days;
          if (item["simultaneous_days"] != null) {
            item["simultaneous_days"] += days;
          } else {
            item["simultaneous_days"] = days;
          }
          inputs["items"][i] = item;
        }
      }
    }
    return true;
  }
  function get360Days(a, b) {
    var ds = a.getDate(),
      ms = a.getMonth() + 1,
      ys = a.getFullYear(),
      de = b.getDate(),
      me = b.getMonth() + 1,
      ye = b.getFullYear();

    if (ds == 31) ds = 30;
    if (de == 31) de = 30;
    return Math.abs(de - ds + 30 * (me - ms) + 360 * (ye - ys));
  }
  function getSimult(a, b, c, d) {
    if (!(a instanceof Date)) a = new Date(a);
    if (!(b instanceof Date)) b = new Date(b);
    if (!(c instanceof Date)) c = new Date(c);
    if (!(d instanceof Date)) d = new Date(d);
    if (
      !(a instanceof Date) ||
      !(b instanceof Date) ||
      !(c instanceof Date) ||
      !(d instanceof Date)
    )
      throw new Error("no dates");

    if (a <= c && c <= b) return b <= d ? getDays(c, b) : getDays(c, d);
    if (a <= d && d <= b) return a <= c ? getDays(c, d) : getDays(a, d);
    if (c < a && b < d) return getDays(a, b);
    return 0;
  }
  function sortItems(arraygeneral) {
    var array = arraygeneral.sort(useCompareIngreso);
    return array;
  }
  function getDays(a, b) {
    var d94 = new Date(1995, 0, 1);
    if (!(a instanceof Date)) a = new Date(a);
    if (!(b instanceof Date)) b = new Date(b);
    if (!(a instanceof Date) || !(b instanceof Date))
      throw new Error("no dates");
    if (inputs.conteoType == 10 && a >= d94) {
      return get360Days(a, b) + 1;
    } else {
      var res = 0,
        one = 3600 * 24 * 1000;
      res = a - b;
      return Math.round(Math.abs(res / one)) + 1;
    }
  }
  function discriminate(val, option) {
    var factor = 1 / 12;
    var base = inputs["conteoType"] == 10 ? 360 : 365;
    var year = Math.floor(val / base);
    base = val / base - year;
    var month = Math.floor(base / factor);
    base = base / factor;
    base = base - Math.floor(base);
    var days = Math.floor(base * 30);
    if (option == "year") {
      return Math.round(year);
    } else if (option == "months") {
      return Math.round(month);
    } else if (option == "days") {
      return Math.round(days);
    } else {
      return Math.round(val / 7);
    }
  }
  function submitReject(event) {
    event.preventDefault();
    if (CloseModal) {
      setCloseModal(false);
    } else {
      if (tablavaloresId && tablavaloresId.internalId) {
        let arrayChange = [];

        inputs["items"].forEach((item, i) => {
          if (tablavaloresId.internalId == item.internalId) {
            item.employer = event.target[0].value;
            item.contribution = event.target[1].value;
            item.contribution_type = Number(event.target[3].value);
            item.start_date = event.target[4].value;
            item.end_date = event.target[5].value;
          }
          arrayChange.push(item);
        });

        setInputs((val) => ({
          ...val,
          ["items"]: arrayChange,
        }));

        localStorage.inputs_counts = JSON.stringify(inputs);

        calculate();
        settablavaloresId({});
      } else {
        setBaseId(baseId + 1);
        setInputs((val) => ({
          ...val,
          ["items"]: [
            ...inputs["items"],
            {
              ["internalId"]: baseId + 1,
              ["conteoType"]: inputs["conteoType"],
              ["contribution"]: tablavalores.contribution,
              ["end_date"]: tablavalores.end_date,
              ["employer"]: tablavalores.employer,
              ["start_date"]: tablavalores.start_date,
              ["contribution_type"]: tablavalores.contribution_type,
            },
          ],
        }));
      }
    }

    // event.target.reset();
    setmodalDialog(!modalDialog);
  }

  document.body.onkeydown = function (e) {
    function onSubmit() {
      // Prevenimos el envio del formulario.

      setBaseId(baseId + 1);
      setInputs((val) => ({
        ...val,
        ["items"]: [
          ...inputs["items"],
          {
            ["internalId"]: baseId + 1,
            ["conteoType"]: inputs["conteoType"],

            ["contribution"]: tablavalores.contribution,
            ["end_date"]: tablavalores.end_date,
            ["employer"]: tablavalores.employer,
            ["start_date"]: tablavalores.start_date,
            ["contribution_type"]: tablavalores.contribution_type,
          },
        ],
      }));
    }
    if (formSelect && e.ctrlKey && e.keyCode == 13) {
      if (
        formSelect.contribution.value &&
        formSelect.start_date.value &&
        formSelect.contribution_type.value &&
        formSelect.end_date.value &&
        formSelect.employer.value
      ) {
        formSelect.submit = onSubmit(formSelect);
        formSelect.reset();
      }
    }
  };

  function crearConteo() {
    const dateBirth = Moment().format("YYYY-MM-DD");
    if (
      inputs.Expediente &&
      tablavalores.Birth != dateBirth &&
      tablavalores.Cliente &&
      tablavalores.Identification &&
      inputs.pensionType &&
      inputs.conteoType
    ) {
      var bodyFormData = new FormData();

      bodyFormData.append("file", inputs.Expediente);
      bodyFormData.append("name", tablavalores.Cliente); //cliente
      bodyFormData.append("document", tablavalores.Identification);
      bodyFormData.append("birth", tablavalores.Birth);
      bodyFormData.append("pension_type", inputs.pensionType);
      bodyFormData.append("count_type", inputs.conteoType);

      if (inputs.total_dias != 0) {
        bodyFormData.append("days_total", inputs.total_dias);
      }
      if (inputs.publiconocajas != 0) {
        bodyFormData.append("public_no_fund", inputs.publiconocajas);
      }
      if (inputs.publicocajas != 0) {
        bodyFormData.append("public_fund", inputs.publicocajas);
      }
      if (inputs.fondo != 0) {
        bodyFormData.append("fund", inputs.fondo);
      }
      if (inputs.items && inputs?.items?.length > 0) {
        inputs.items.forEach((item, index) => {
          let property = Object.entries(item);
          let indice = [index];
          property.forEach((elemento) => {
            let el = elemento[0];

            if (elemento[0] != "internalId") {
              bodyFormData.append(
                `items[${indice}]${el}`,
                elemento[1] ? elemento[1] : 0
              );
            }
          });
        });

        // bodyFormData.append("items",JSON.stringify(inputs.items));
      }
      if (inputs.totalpublico != 0) {
        bodyFormData.append("public_total", inputs.totalpublico);
      }
      if (inputs.tiempo94 != 0) {
        bodyFormData.append("time_94", inputs.tiempo94);
      }
      if (inputs.tiempo2005 != 0) {
        bodyFormData.append("time_2005", inputs.tiempo2005);
      }
      if (inputs.privadotradicionales != 0) {
        bodyFormData.append("private_traditional", inputs.privadotradicionales);
      }
      if (inputs.privadoautoliss != 0) {
        bodyFormData.append("private_autoliss", inputs.privadoautoliss);
      }
      if (inputs.totalprivado != 0) {
        bodyFormData.append("private_total", inputs.totalprivado);
      }
      if (inputs.totalcotizaciones != 0) {
        bodyFormData.append("total_contributions", inputs.totalcotizaciones);
      }
      if (inputs.comments != "") {
        bodyFormData.append("comments", inputs.comments);
      }

      instance
        .post(`files/counts/`, bodyFormData)
        .then((res) => {
          if (res.data) {
            setidCountView(res.data.id);
            setmodalDialogSuccess(true);
            // setmodalDialogFileCreate(false)
          }
        })
        .catch((err) => {
          if (err.response) {
            setmodalDialogAlert(true);
            if (err.response.data.file) {
              setTextError("Expediente: " + err.response.data.file);
            }
            if (err.response.data.time_2005) {
              setTextError_2005(
                "Tiempo Cotizado al 22 de Julio 2005: " +
                  err.response.data.time_2005
              );
            }
            if (err.response.data.time_94) {
              setTextError_94(
                "Tiempo Cotizado al 1 Abril 94: " + err.response.data.time_94
              );
            }
            if (err.response.data.private_total) {
              setTextError_private_total(
                "Total Privado: " + err.response.data.private_total
              );
            }
            if (err.response.data.public_total) {
              setTextError_public_total(
                "Total Público: " + err.response.data.public_total
              );
            }
            if (err.response.fund) {
              setTextError_fund("Fondo: " + err.response.fun);
            } else {
              setTextError("Ocurrió un error, intentalo más tarde");
            }
          }
        });
    } else {
      setmodalDialogAlert(true);
      if (tablavalores.Birth == dateBirth) {
        setTextError("Debes seleccionar una fecha válida");
      } else {
        setTextError("Debes llenar todos los espacios");
      }
    }
  }

  return (
    <>
      <div style={{ padding: "1% 3%" }}>
        <ol className="breadcrumb float-xl-end">
          <li className="breadcrumb-item">
            <Link to="/Conteos">Conteos</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to="#">Crear conteos</Link>
          </li>
        </ol>
      </div>
      <div style={{ padding: "3%" }}>
        <Panel>
          <PanelHeader>Crear conteo</PanelHeader>

          <div className="hljs-wrapper">
            <div className="profile-content">
              <div className="tab-content p-0">
                <div>
                  <div className=" form-inline">
                    <table className="table table-profile align-middle d-flex table-count-default">
                      <tbody
                        style={{
                          height: "auto",
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "center",
                        }}
                      >
                        <tr>
                          <td>
                            <span className="fw-bold">Nombre del cliente</span>
                            <br />
                            <input
                              name="Cliente"
                              style={{
                                border: "1px solid hsl(0, 0%, 80%)",
                                borderRadius: "4px",
                                padding: "10px",
                              }}
                              onChange={handleChange}
                              placeholder="Ingresar nombre"
                              className=" w-300px py-2"
                              type="text"
                            ></input>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="fw-bold">Identificación</span>
                            <br />
                            <input
                              name="Identification"
                              style={{
                                border: "1px solid hsl(0, 0%, 80%)",
                                borderRadius: "4px",
                                padding: "10px",
                              }}
                              onChange={handleChange}
                              placeholder="Ingresar identificación"
                              className=" w-300px py-2"
                              type="text"
                            ></input>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="fw-bold">Fecha de nacimiento</span>
                            <br />
                            <input
                              style={{
                                border: "1px solid hsl(0, 0%, 80%)",
                                borderRadius: "4px",
                                padding: "10px",
                              }}
                              name="Birth"
                              onChange={handleChange}
                              defaultValue={tablavalores?.Birth}
                              className="form-control w-300px py-2"
                              type="date"
                            ></input>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="fw-bold">Tipo de Pensión</span>
                            <br />
                            <Select
                              className="w-300px"
                              options={pensionTypes.map((type) => ({
                                value: type.value,
                                label: type.name,
                              }))}
                              placeholder="Selecciona"
                              value={{ label: "Vejez", value: 10 }}
                              name="pensionType"
                              onChange={(e) =>
                                setInputs((val) => ({
                                  ...val,
                                  ["pensionType"]: e.value,
                                }))
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="fw-bold">Tipo de conteo</span>
                            <br />
                            <Select
                              className="w-300px"
                              options={conteoTypes.map((count) => ({
                                value: count.value,
                                label: count.name,
                              }))}
                              placeholder="Selecciona"
                              defaultValue={{ label: "360 días", value: 10 }}
                              name="conteoType"
                              onChange={(e) =>
                                setInputs((val) => ({
                                  ...val,
                                  ["conteoType"]: e.value,
                                }))
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <div>
                            <span className="fw-bold">Expediente</span>
                            <br />
                            <Select
                              id="clientec"
                              className="w-300px"
                              isLoading={inputs.isLoading}
                              onInputChange={(e) => {
                                setInputs((val) => ({
                                  ...val,
                                  ["isLoading"]: true,
                                }));
                                setTimeout(() => {
                                  setInputs((val) => ({
                                    ...val,
                                    ["isLoading"]: false,
                                  }));
                                }, 700);
                                if (e.length > 1) {
                                  dispatch(getFilesSearch(e, true));
                                }
                              }}
                              options={
                                filesLoaded &&
                                filesLoaded
                                  .filter((elem) => elem.has_count == false)
                                  .map((personCus) => ({
                                    value: personCus.id,
                                    label: personCus.number,
                                  }))
                              }
                              placeholder="Selecciona"
                              defaultValue={
                                id && id != "count"
                                  ? {
                                      label: id.split("|")[1],
                                      value: id.split("|")[0],
                                    }
                                  : false
                              }
                              name="Expediente"
                              onChange={(e) =>
                                setInputs((val) => ({
                                  ...val,
                                  ["Expediente"]: e.value,
                                }))
                              }
                            />
                          </div>
                        </tr>
                        <tr>
                          <td>
                            <span className="fw-bold ">Responsable</span>
                            <br />
                            <input
                              style={{
                                border: "1px solid hsl(0, 0%, 80%)",
                                borderRadius: "4px",
                                padding: "10px",
                              }}
                              disabled={true}
                              defaultValue={localStorage?.full_name}
                              className=" w-300px py-2"
                            ></input>
                          </td>
                        </tr>
                      </tbody>
                      <button
                        onClick={() => {
                          setmodalDialog(!modalDialog);
                        }}
                        style={{ margin: "1% auto", borderTop: "none" }}
                        type="submit"
                        className="btn btn-warning w-150px"
                      >
                        <i className="far fa-lg fa-fw  fa-square-plus"></i>{" "}
                        Agregar item
                      </button>{" "}
                    </table>
                    <PerfectScrollbar>
                      <table
                        ref={tableRef}
                        style={{ background: "var(--app-sidebar-bg)" }}
                        className="table table-panel table-bordered mb-0 "
                        {...getTableProps()}
                      >
                        <thead>
                          {headerGroups.map((headerGroup) => (
                            <>
                              <tr
                                {...headerGroup.getHeaderGroupProps()}
                                className="nav-Tables-head"
                              >
                                {headerGroup.headers.map((column) => (
                                  <th
                                    className="text-center"
                                    {...column.getHeaderProps(
                                      column.getSortByToggleProps()
                                    )}
                                  >
                                    <div
                                      className="d-flex align-items-center "
                                      style={{
                                        minWidth: "100px",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <span>{column.render("Header")}</span>
                                      <span className="">
                                        {column.sortable ? (
                                          column.isSorted ? (
                                            column.isSortedDesc ? (
                                              <i className="fa fa-sort-down fa-fw fs-14px text-blue"></i>
                                            ) : (
                                              <i className="fa fa-sort-up fa-fw fs-14px text-blue"></i>
                                            )
                                          ) : (
                                            <i className="fa fa-sort fa-fw fs-14px opacity-3"></i>
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </div>
                                  </th>
                                ))}
                              </tr>
                            </>
                          ))}
                        </thead>

                        <tbody {...getTableBodyProps()}>
                          {page.map((row, i) => {
                            prepareRow(row);
                            return (
                              <tr
                                key={i}
                                {...row.getRowProps()}
                                className="colorRow-Tables"
                              >
                                {row.cells.map((cell, index) => {
                                  return (
                                    <td key={index} {...cell.getCellProps()}>
                                      {cell.column.Header == "Acciones" ? (
                                        <td
                                          nowrap="true "
                                          className="vertical-middle"
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <button
                                            onClick={() => {
                                              setmodalDialog(!modalDialog);
                                              settablavaloresId((val) => ({
                                                ...val,
                                                ["employer"]:
                                                  row.original["employer"],
                                                ["contribution"]:
                                                  row.original["contribution"],
                                                ["start_date"]:
                                                  row.original["start_date"],
                                                ["end_date"]:
                                                  row.original["end_date"],
                                                ["contribution_type"]:
                                                  row.original[
                                                    "contribution_type"
                                                  ],
                                                ["internalId"]:
                                                  row.original["internalId"],
                                              }));
                                            }}
                                            className="btn btn-sm btn-orange  me-1 p-auto"
                                          >
                                            <i className="fas fa-lg fa-fw fs-14px fa-pen-to-square"></i>
                                          </button>
                                          <button
                                            onClick={(e) => {
                                              setInputs((val) => ({
                                                ...val,
                                                ["items"]: inputs[
                                                  "items"
                                                ].filter(
                                                  (ele) =>
                                                    ele.internalId !=
                                                    row.original["internalId"]
                                                ),
                                              }));
                                            }}
                                            className="btn btn-sm btn-danger p-auto"
                                          >
                                            <i className="fa fa-trash fs-14px text-white"></i>
                                          </button>
                                        </td>
                                      ) : cell.column.Header ==
                                        "Tipo Aporte" ? (
                                        contribution_type(
                                          row.original["contribution_type"]
                                        )
                                      ) : (
                                        cell.render("Cell")
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </PerfectScrollbar>
                    <div
                      id={"paginatePrint"}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <div className="me-1 d-none d-sm-block">Ir a página:</div>
                      <div className="w-50px mx-2 me-auto d-none d-sm-block">
                        <input
                          className="form-control"
                          type="number"
                          defaultValue={pageIndex + 1}
                          onChange={(e) => {
                            const page = e.target.value
                              ? Number(e.target.value) - 1
                              : 0;
                            gotoPage(page);
                          }}
                        />
                      </div>
                      <ul className="pagination mb-0">
                        <li className="page-item">
                          <button
                            className="page-link"
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                          >
                            <i className="fa fa-angle-double-left"></i>
                          </button>
                        </li>
                        <li className="page-item">
                          <button
                            className="page-link"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                          >
                            <i className="fa fa-angle-left"></i>
                          </button>
                        </li>
                        <li className="page-item d-flex align-items-center px-2">
                          <div>
                            Página{" "}
                            <strong>
                              {pageIndex + 1} de {pageOptions.length}
                            </strong>
                          </div>
                        </li>
                        <li className="page-item">
                          <button
                            className="page-link"
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                          >
                            <i className="fa fa-angle-right"></i>
                          </button>
                        </li>
                        <li className="page-item">
                          <button
                            className="page-link"
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                          >
                            <i className="fa fa-angle-double-right"></i>
                          </button>
                        </li>
                      </ul>
                    </div>
                    <br />{" "}
                    <div className="container">
                      <div style={{ textAlign: "right" }} className="row">
                        <div className="col">
                          <span
                            className="px-2"
                            style={{ fontWeight: "bold", fontSize: "1rem" }}
                          >
                            TOTAL DÍAS : {Math.round(inputs["total_dias"])}
                          </span>{" "}
                          <span
                            className=" px-2"
                            style={{
                              fontWeight: "bold",
                              fontSize: "1rem",
                              borderLeft: "1px solid gray",
                            }}
                          >
                            TOTAL SEMANAS :
                            {Math.round(inputs["total_dias"] / 7)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <br />
                    <PerfectScrollbar id={"createCountPrint"}>
                      <table
                        className="table table-panel table-bordered mb-0"
                        style={{ minWidth: "40rem" }}
                      >
                        <thead
                          style={{
                            fontSize: "1rem",
                          }}
                        >
                          <tr className="d-flex ">
                            <th className="col-4">Resumen</th>
                            <th className="col text-center">Calculo</th>
                            <th className="col text-center">Años </th>
                            <th className="col text-center">Meses</th>
                            <th className="col text-center">Días</th>
                            <th className="col text-center">Semana</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="d-flex">
                            <td className="col-4">
                              Público No Cotizado a Cajas
                            </td>
                            <td className="col text-center">
                              {inputs["publiconocajas"]}
                            </td>
                            <td className="col text-center ">
                              {discriminate(inputs["publiconocajas"], "year")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["publiconocajas"], "months")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["publiconocajas"], "days")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["publiconocajas"], "weeks")}
                            </td>
                          </tr>
                          <tr className="d-flex">
                            <td className="col-4">Público Cotizado a Cajas</td>
                            <td className="col text-center">
                              {inputs["publicocajas"]}
                            </td>
                            <td className="col text-center ">
                              {discriminate(inputs["publicocajas"], "year")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["publicocajas"], "months")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["publicocajas"], "days")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["publicocajas"], "weeks")}
                            </td>
                          </tr>
                          <tr className="d-flex">
                            <td className="col-4">Fondo</td>
                            <td className="col text-center">
                              {inputs["fondo"]}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["fondo"], "year")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["fondo"], "months")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["fondo"], "days")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["fondo"], "weeks")}
                            </td>
                          </tr>
                          <tr className="d-flex">
                            <td className="col-4">Total Público</td>
                            <td className="col text-center">
                              {inputs["totalpublico"]}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["totalpublico"], "year")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["totalpublico"], "months")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["totalpublico"], "days")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["totalpublico"], "weeks")}
                            </td>
                          </tr>
                          <tr className="d-flex">
                            <td className="col-4">
                              Tiempo Cotizado al 1 Abril 94
                            </td>
                            <td className="col text-center">
                              {inputs["tiempo94"]}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["tiempo94"], "year")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["tiempo94"], "months")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["tiempo94"], "days")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["tiempo94"], "weeks")}
                            </td>
                          </tr>
                          <tr className="d-flex">
                            <td className="col-4">
                              Tiempo Cotizado al 22 de Julio 2005
                            </td>
                            <td className="col text-center">
                              {inputs["tiempo2005"]}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["tiempo2005"], "year")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["tiempo2005"], "months")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["tiempo2005"], "days")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["tiempo2005"], "weeks")}
                            </td>
                          </tr>

                          <tr className="d-flex">
                            <td className="col-4">Privado Tradicionales</td>
                            <td className="col text-center">
                              {inputs["privadotradicionales"]}{" "}
                            </td>
                            <td className="col text-center">
                              {discriminate(
                                inputs["privadotradicionales"],
                                "year"
                              )}
                            </td>
                            <td className="col text-center">
                              {discriminate(
                                inputs["privadotradicionales"],
                                "months"
                              )}
                            </td>
                            <td className="col text-center">
                              {discriminate(
                                inputs["privadotradicionales"],
                                "days"
                              )}
                            </td>
                            <td className="col text-center">
                              {discriminate(
                                inputs["privadotradicionales"],
                                "weeks"
                              )}
                            </td>
                          </tr>
                          <tr className="d-flex">
                            <td className="col-4">Privado Autoliss</td>
                            <td className="col text-center">
                              {inputs["privadoautoliss"]}{" "}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["privadoautoliss"], "year")}
                            </td>
                            <td className="col text-center">
                              {discriminate(
                                inputs["privadoautoliss"],
                                "months"
                              )}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["privadoautoliss"], "days")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["privadoautoliss"], "weeks")}
                            </td>
                          </tr>
                          <tr className="d-flex">
                            <td className="col-4">Total Privado</td>
                            <td className="col text-center">
                              {inputs["totalprivado"]}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["totalprivado"], "year")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["totalprivado"], "months")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["totalprivado"], "days")}
                            </td>
                            <td className="col text-center">
                              {discriminate(inputs["totalprivado"], "weeks")}
                            </td>
                          </tr>
                          <tr className="d-flex">
                            <td className="col-4">Total Cotizaciones</td>
                            <td className="col text-center">
                              {inputs["totalcotizaciones"]}
                            </td>
                            <td className="col text-center">
                              {discriminate(
                                inputs["totalcotizaciones"],
                                "year"
                              )}
                            </td>
                            <td className="col text-center">
                              {discriminate(
                                inputs["totalcotizaciones"],
                                "months"
                              )}
                            </td>
                            <td className="col text-center">
                              {discriminate(
                                inputs["totalcotizaciones"],
                                "days"
                              )}
                            </td>
                            <td className="col text-center">
                              {discriminate(
                                inputs["totalcotizaciones"],
                                "weeks"
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </PerfectScrollbar>
                    <div className="mb-2">
                      {" "}
                      <div className="field m-auto ">Obsevaciones</div>
                      <div>
                        <textarea
                          onChange={(e) =>
                            setInputs((val) => ({
                              ...val,
                              ["comments"]: e.target.value,
                            }))
                          }
                          placeholder="Escribe tus observaciones"
                          className="w-100"
                          style={{
                            borderColor: "hsl(0, 0%, 80%)",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      </div>
                    </div>
                    <tr className=" w-100 mx-auto d-flex  justify-content-center">
                      <td
                        className="d-flex my-auto"
                        //   style={{ justifyContent: "flex-start" }}
                      >
                        <button
                          onClick={() => crearConteo()}
                          type="submit"
                          className="btn btn-primary w-150px"
                        >
                          Guardar
                        </button>{" "}
                        <Link
                          to="/Conteos"
                          className="btn btn-white border-0 w-150px ms-2px"
                        >
                          Cancelar
                        </Link>
                      </td>
                    </tr>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            id="ModalAddItem"
            isOpen={modalDialog}
            toggle={() => setmodalDialog(!modalDialog)}
          >
            <ModalHeader
              // toggle={() => setmodalAsignment(!modalAsignment)}
              close={
                <button
                  className="btn-close"
                  onClick={() => {
                    settablavaloresId({});
                    setmodalDialog(!modalDialog);
                  }}
                ></button>
              }
            >
              Item de conteo
            </ModalHeader>
            <form id="formItem" onSubmit={(e) => submitReject(e)}>
              <ModalBody>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {inputs.items && inputs.items.length > 0 ? (
                    <tr className="mb-3 mx-auto">
                      <td>
                        <span>
                          Último item: {tablavalores.employer} |{" "}
                          {tablavalores.contribution}|
                          {contribution_type(tablavalores.contribution_type)} |{" "}
                          {tablavalores.start_date} |{tablavalores.end_date}
                        </span>
                      </td>
                    </tr>
                  ) : null}
                  <tbody
                    style={{
                      height: "auto",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    <tr>
                      <td>
                        <span className="fw-bold">Empleador</span>
                        <br />
                        <input
                          required
                          style={{
                            border: "1px solid hsl(0, 0%, 80%)",
                            borderRadius: "4px",
                            padding: "10px",
                          }}
                          name="employer"
                          defaultValue={
                            tablavaloresId.employer
                              ? tablavaloresId.employer
                              : null
                          }
                          onChange={handleChange}
                          placeholder="Empleador"
                          className=" w-300px py-2"
                          type="text"
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-bold">Cotizado A</span>
                        <br />
                        <input
                          required
                          style={{
                            border: "1px solid hsl(0, 0%, 80%)",
                            borderRadius: "4px",
                            padding: "10px",
                          }}
                          name="contribution"
                          onChange={handleChange}
                          defaultValue={
                            tablavaloresId.contribution
                              ? tablavaloresId.contribution
                              : null
                          }
                          placeholder="Cotizado A"
                          className=" w-300px py-2"
                          type="text"
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-bold">Tipo de aporte</span>
                        <br />
                        <Select
                          className="w-300px"
                          options={contributionsTypes.map((type) => ({
                            value: type.value,
                            label: type.name,
                          }))}
                          placeholder="Selecciona"
                          name="contribution_type"
                          defaultValue={
                            tablavaloresId.contribution_type
                              ? {
                                  value: tablavaloresId.contribution_type,
                                  label: contribution_type(
                                    tablavaloresId.contribution_type
                                  ),
                                }
                              : null
                          }
                          onChange={(e) =>
                            settablavalores((val) => ({
                              ...val,
                              ["contribution_type"]: e.value,
                            }))
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-bold">Fecha de Ingreso</span>
                        <br />
                        <input
                          required
                          style={{
                            border: "1px solid hsl(0, 0%, 80%)",
                            borderRadius: "4px",
                            padding: "10px",
                          }}
                          name="start_date"
                          onChange={handleChange}
                          defaultValue={
                            tablavaloresId.start_date
                              ? tablavaloresId.start_date
                              : null
                          }
                          placeholder="Fecha de Ingreso"
                          className="form-control w-300px py-2"
                          type="date"
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-bold">Fecha de Egreso</span>
                        <br />
                        <input
                          required
                          style={{
                            border: "1px solid hsl(0, 0%, 80%)",
                            borderRadius: "4px",
                            padding: "10px",
                          }}
                          defaultValue={
                            tablavaloresId.end_date
                              ? tablavaloresId.end_date
                              : null
                          }
                          name="end_date"
                          onChange={handleChange}
                          placeholder="Fecha de Egreso"
                          className="form-control w-300px py-2"
                          type="date"
                        ></input>
                      </td>
                    </tr>
                  </tbody>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="container">
                  <div className="row">
                    <span className="col-8">
                      Use Ctrl+Enter para agregar un nuevo item sin cerrar la
                      pantalla.
                    </span>
                    <div className="col-2">
                      {" "}
                      <button
                        className=" btn btn-white"
                        onClick={(e) => {
                          e.preventDefault();
                          settablavaloresId({});
                          setmodalDialog(!modalDialog);
                        }}
                      >
                        Cerrar
                      </button>
                    </div>
                    <div className="col-2">
                      <button type="submit" className=" btn btn-success">
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </ModalFooter>
            </form>
          </Modal>
          <Modal isOpen={modalDialogSuccess}>
            <ModalHeader
              close={
                <button
                  className="btn-close"
                  onClick={() => setmodalDialogSuccess(!modalDialogSuccess)}
                ></button>
              }
            >
              Conteo
            </ModalHeader>
            <ModalBody>
              <div className="alert alert-success mb-0">
                <p>Se ha creado correctamente</p>
              </div>
            </ModalBody>
            <ModalFooter>
              <Link to="/Conteos" className="btn btn-warning">
                Salir
              </Link>
              <Link to={`/Conteo/${idCountView}`} className="btn btn-success">
                Ver conteo
              </Link>
            </ModalFooter>
          </Modal>
          <Modal isOpen={modalDialogAlert}>
            <ModalHeader
              close={
                <button
                  className="btn-close"
                  onClick={() => setmodalDialogAlert(!modalDialogAlert)}
                ></button>
              }
            >
              Algo ha ocurrido
            </ModalHeader>
            <ModalBody>
              <div className="alert alert-danger mb-0">
                {/* <h5>
                <i className="fa fa-info-circle"></i> Algo salió mal
              </h5> */}
                <p>
                  {TextError_file}-{TextError_private_total}-
                  {TextError_public_total}-{TextError_fund}-{TextError_94}-
                  {TextError_2005}
                </p>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                className="btn btn-white"
                onClick={() => setmodalDialogAlert(!modalDialogAlert)}
              >
                Cerrar
              </button>
            </ModalFooter>
          </Modal>
        </Panel>
      </div>
    </>
  );
};

export default CrearConteo;
