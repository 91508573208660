import { useTable, useSortBy, usePagination } from "react-table";
import React from "react";
import Moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { classesStatus, contribution_type, status } from "../../utils";

export default function TablePrueba({ data,columnsData,pagination,height,idTable }) {
  
  const columns = React.useMemo(
    () => columnsData,
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );

  return ( <> <PerfectScrollbar style={{height: height}}> <table  id={idTable?idTable:"tableBackgroundTest"}
           
    
    className="table table-panel table-bordered mb-0"
    {...getTableProps()}
  >
    <thead>
      {headerGroups.map((headerGroup) => (
        <>
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="nav-Tables-head"
          >
            {headerGroup.headers.map((column) => (
              <th
                className="text-center"
                {...column.getHeaderProps(
                  column.getSortByToggleProps()
                )}
              >
                <div
                  className="d-flex align-items-center text-center"
                  style={{
                    minWidth: "100px",
                    justifyContent: "center",
                  }}
                >
                  <span>{column.render("Header")}</span>
                  <span className="">
                    {column.sortable ? (
                      column.isSorted ? (
                        column.isSortedDesc ? (
                          <i className="fa fa-sort-down fa-fw fs-14px text-blue"></i>
                        ) : (
                          <i className="fa fa-sort-up fa-fw fs-14px text-blue"></i>
                        )
                      ) : (
                        <i className="fa fa-sort fa-fw fs-14px opacity-3"></i>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </th>
            ))}
          </tr>
        </>
      ))}
    </thead>
   
    <tbody {...getTableBodyProps()}>
      {page.map((row, i) => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()} className="colorRow-Tables">
            {row.cells.map((cell, index) => {
              return (
                <td key={row.id} {...cell.getCellProps()}>
                  { cell.column.Header == "Modificado" ? 
                            Moment(row.original["updated_at"]).format("L[-]LT"):cell.column.Header == "Acciones" && row.original["id"] ?(   <Link
                                to={`/Expediente/${row.original["id"]}`}
                              
                                className="btn btn-sm btn-green  me-1 p-auto"
                              >
                                <i className="fab fa-lg fa-fw fs-14px fa-sistrix text-white"></i>
                              </Link>):cell.column.Header == "Estado" ? <span
                            className={classesStatus(row.original["status"])}
                            >
                              <i className="fa fa-circle fs-9px fa-fw me-5px"></i>{" "}
                              {/* {cell.render("Cell")} */}{" "}
                              {status(row.original["status"])}
                            </span>:cell.column.Header == "Tipo Aporte"? contribution_type(row.original?.contribution_type) || "no tiene":(
                    cell.render("Cell")
                  )}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  </table>
</PerfectScrollbar>
        {pagination?  <div id={"paginatePrint"} className="d-flex align-items-center justify-content-center">
            <div className="me-1 d-none d-sm-block">Ir a página:</div>
            <div className="w-50px mx-2 me-auto d-none d-sm-block">
              <input
                className="form-control"
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
              />
            </div>
            <ul className="pagination mb-0">
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <i className="fa fa-angle-double-left"></i>
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <i className="fa fa-angle-left"></i>
                </button>
              </li>
              <li className="page-item d-flex align-items-center px-2">
                <div>
                  Página{" "}
                  <strong>
                    {pageIndex + 1} de {pageOptions.length}
                  </strong>
                </div>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <i className="fa fa-angle-right"></i>
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <i className="fa fa-angle-double-right"></i>
                </button>
              </li>
            </ul>
          </div>:null}
        
  </> )
}