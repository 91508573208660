import React, { useState, useEffect, useRef } from "react";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Moment from "moment";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { getAll } from "../../../actions";
import { classesStatus, status } from "../../../utils";
import PerfectScrollbar from 'react-perfect-scrollbar';
class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      dateFilter: "",
      checkedBox: [false, false, false],
      personitas: [],
      fired: false,
      timer: null,
    };
    // this.handleOnChange = this.handleOnChange.bind(this);
    // this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);
  }
  // componentDidMount() {
  //   const { getFiles,filesSearch } = this.props;

  //   getFiles();
  // }
  // async componentDidMount() {
  //   const { getPersonsSearch } = this.props;
  //   try {
  //     getPersonsSearch(this.state.searchValue);
  //     // this.setState({posts: await res.json()})
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }
  handleOnChangeBox(e) {
    var boxer_e = e.target.id.slice(22, e.target.id.length);
    let allarray = this.state.checkedBox;
    for (var i = 0; i < allarray.length; i++) {
      if (i == boxer_e) {
        allarray[i] = !allarray[i];
      } else {
        allarray[i] = false;
      }
      this.setState(() => {
        return { checkedBox: allarray };
      });
    }
  }
  handleOnChange(e) {
    this.setState(() => {
      return { searchValue: e.toLowerCase(), dateFilter: e };
    });
  }
  handleOnChangefred() {
    this.setState(() => {
      return { fired: true };
    });
  }
  handleOnChangefredie() {
    this.setState(() => {
      return { fired: false };
    });
  }

  render() {
    const { allsearch, getAll } = this.props;
  
    const fakeApi = (e) => getAll(e);
    var specifiedElement = document.getElementById("formSearch");
    var specifiedElementcontainer = document.getElementById("searchGeneral");
    document.addEventListener("focusout", function (event) {
      if (specifiedElement) {
        specifiedElement.reset();
      }
    });
    document.addEventListener("click", function (event) {
      if (specifiedElementcontainer) {
        var isClickInside = specifiedElementcontainer.contains(event.target);

        if (!isClickInside) {
          getAll("");
        }
      }
    });

    var filterForDate = ["ultimos 7 dias", "ultimos 15 dias", "ultimo mes"];

    return (
      <div className="navbar-form ">
        <form action="" method="POST" name="search_form" id="formSearch">
          <div className="form-group position-relative">
            <button className="btn btn-search" disabled={true}>
              <i className="fa fa-search me-20px icon-search-action"></i>
            </button>
            <input
              type="text"
              className="form-control d-flex d-sm-flex"
              onChange={(e) => {
                clearTimeout(this.state.timer);

                const newTimer = setTimeout(() => {
                  fakeApi(e.target.value);
                }, 200);
                this.setState((state) => ({
                  timer: newTimer,
                }));
              }}
              placeholder="Busca expedientes, personas, etc ..."
            />

            {/* <UncontrolledButtonDropdown
              direction="down"
              className="  btn-filter"
            >
              <DropdownToggle
                color="default"
                className="dropdown-toggle"
                style={{
                  padding: "0rem",
                  border: "transparent",
                  color: "#ffff",
                  background: "transparent",
                  margin: "0.5rem",
                }}
              >
                <i className="fas fa-lg fa-fw me-10px fa-list"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem> Filtrar por:</DropdownItem>

                <DropdownItem divider></DropdownItem>

                {filterForDate.map((filtro, index) => (
                  <DropdownItem key={index}>
                    <div className="form-check form-switch mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={(e) => this.handleOnChangeBox(e)}
                        id={`flexSwitchCheckChecked${index}`}
                        checked={this.state.checkedBox[index]}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckChecked"
                      >
                        {" "}
                        {filtro}
                      </label>
                    </div>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledButtonDropdown> */}
            {(allsearch && allsearch.files && allsearch.files.length > 0) ||
            (allsearch && allsearch.people && allsearch.people.length > 0) ? (
              <div className="position-absolute w-100 mt-1 ">
                <PerfectScrollbar
                  id="searchGeneral"
                  style={{
                    background: "white",
                    alignContent: " space-between",
                    borderRadius: "10px",
                    paddingTop: "10px",
                    overflowY: "auto",
                    maxHeight: "60vmin",
                    border: "1px solid black",
                  }}
                >
                  <span
                    className="mx-4 d-flex fw-bold"
                    style={{ justifyContent: "right" }}
                  >
                    {allsearch.files.length + allsearch.people.length}{" "}
                    resultados encontrados
                  </span>
                  <br />
                  {allsearch &&
                    allsearch.files &&
                    allsearch.files.map((file) => (
                      <>
                        <div
                          // to={file?.is_open ?`/Expediente/${file.id}`:`/ExpedienteArchivado/${file.id}`}

                          className="d-flex w-100 p-3 textDecorationNone alternColorSearch"
                          key={file.id}
                        >
                          <span className="m-auto" style={{ color: "gray" }}>
                            <i className="fas fa-lg fa-fw me-10px fa-folder-open"></i>
                          </span>
                          <span
                            className="w-50 fw-bold"
                            style={{
                              marginLeft: "0",
                              marginRight: "auto",
                            }}
                          >
                            {" "}
                            <div>Expediente # {file["number"]}</div>
                            <span
                              className="fw-400 m-auto"
                              style={{
                                fontStyle: "italic",
                                margin: "auto",
                                textDecoration: "none",
                                color: "var(--bs-body-color)",
                              }}
                            >
                              <span className={classesStatus(file.status)}>
                                <i className="fa fa-circle fs-9px fa-fw me-5px"></i>{" "}
                                {/* {cell.render("Cell")} */}{" "}
                                {status(file.status)}
                              </span>

                              <br />
                            </span>
                          </span>
                          <div
                            className="m-auto d-flex"
                            style={{ color: "gray" }}
                          >
                            <Link
                              to={
                                file?.is_open
                                  ? `/Expediente/${file.id}`
                                  : `/ExpedienteArchivado/${file.id}`
                              }
                              onClick={() => getAll("")}
                              title="Ver"
                              className="btn btn-sm btn-green  me-1 p-auto"
                            >
                              <i className="fab fa-lg fa-fw fs-14px fa-sistrix text-white"></i>
                            </Link>
                          </div>
                          {/* <span className="w-50 " style={{ textAlign: "right" }}>
                          {" "}
                          <span
                            style={{
                              fontStyle: "italic",
                              fontSize: "0.6rem",
                            }}
                          >
                            Expediente
                          </span>{" "}
                          <br />
                          <span
                            style={{
                              fontStyle: "italic",
                              fontSize: "0.6rem",
                            }}
                          >
                            {file["number"]}
                          </span>
                        </span> */}
                        </div>
                        {/* <hr /> */}
                      </>
                    ))}

                  {allsearch.people &&
                    allsearch.people.length > 0 &&
                    allsearch.people.map((person) => (
                      <>
                        <div
                          className="d-flex w-100 p-3 textDecorationNone alternColorSearch"
                          key={person.id}
                        >
                          <span className="m-auto" style={{ color: "gray" }}>
                            {" "}
                            <i className="fas fa-lg fa-fw me-10px fa-user"></i>
                          </span>

                          <span
                            className="w-50 fw-bold"
                            style={{
                              marginLeft: "0",
                              marginRight: "auto",
                              //  color: "var(--bs-body-color)",
                            }}
                          >
                            {" "}
                            {person.full_name}{" "}
                            {/* <span
                            className="fw-400"
                            style={{
                              fontStyle: "italic",
                            
                              
                            }}
                          >
                            {" "}
                            {person.document.short} {person.document.number}
                          </span> */}
                            <br />
                            <span
                              className="fw-400"
                              style={{
                                fontStyle: "normal",
                                fontStyle: "italic",
                                color: "var(--bs-body-color)",
                              }}
                            >
                              {person.is_customer
                                ? `Cliente`
                                : person.is_freelance
                                ? `Freelancer`
                                : `Empleado`}
                            </span>
                          </span>
                          <div className="m-auto" style={{ color: "gray" }}>
                            <Link
                              to={
                                person.is_customer
                                  ? `/Persona/${person.id}`
                                  : person.is_freelance
                                  ? `/Freelancer/${person.id}`
                                  : `/Staffs/${person.id}`
                              }
                              onClick={() => getAll("")}
                              title="Ver"
                              className="btn btn-sm btn-green  me-1 p-auto"
                            >
                              <i className="fab fa-lg fa-fw fs-14px fa-sistrix text-white"></i>
                            </Link>
                          </div>
                          {/* <span className="w-50" style={{ textAlign: "right" }}>
                          {" "}
                          <span
                            style={{ fontStyle: "italic", fontSize: "0.6rem" }}
                          >
                            Persona
                          </span>{" "}
                          <br />
                          <span
                            style={{
                              fontStyle: "italic",
                              fontSize: "0.6rem",
                            }}
                          >
                            {person["id"]}
                          </span>
                        </span> */}
                        </div>
                        {/* <hr /> */}
                      </>
                    ))}
                </PerfectScrollbar>
              </div>
            ) : (
              allsearch &&
              allsearch.files &&
              allsearch.files.length == 0 &&
              allsearch &&
              allsearch.people &&
              allsearch.people.length == 0 && (
                <div className="position-absolute w-100 mt-1 ">
                  <PerfectScrollbar
                    id="searchGeneral"
                    style={{
                      background: "white",
                      alignContent: " space-between",
                      borderRadius: "10px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      overflowY: "auto",
                      maxHeight: "60vmin",
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    <span>No se encontraron resultados</span>
                    <br></br>
                  </PerfectScrollbar>
                </div>
              )
            )}
          </div>
        </form>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  personsSearch: state.counterSlice.personsSearch,
  filesSearch: state.counterSlice.filesSearch,
  allsearch: state.counterSlice.allsearch,
});
const mapDispatchToProps = {
  getAll,
};

// bind our actions to this.props

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
